import { InputFieldType } from "../Components/Form/Form"

const required = {
  value: true,
  message: "Required Field",
}

export const basic_text_validator = {
  required,
  fieldType: "text" as InputFieldType,
}

export const basic_number_validator = {
  required,
  fieldType: "number" as InputFieldType,
  pattern: {
    value: /^\d+(\.\d+)?$/,
    message: "Please enter only numerical characters"
  }
}

export const business_number_validator = {
  required: {
    value: true,
    message: "Business Number must be 9 Numerical Characters"
  },
  fieldType: "number" as InputFieldType,
  pattern: {
    value: /^\d+(\.\d+)?$/,
    message: "Please enter only numerical characters"
  }
}

export const dash_number_validator = {
  required,
  fieldType: "number" as InputFieldType,
}

export const username_validator = {
  required: {
    value: true,
    message: "Required Field",
  },
  minLength: {
    value: 6,
    message: "Minimum 6 characters",
  },
  maxLength: {
    value: 50,
    message: "Maximum 50 characters",
  }
}

export const email_validator = {
  fieldType: "email" as InputFieldType,
  required,
  pattern: {
    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g,
    message: "Please enter a valid email"
  }
}

export const unique_email_validator = {
  fieldType: "uniqueEmail" as InputFieldType,
  required,
  pattern: {
    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g,
    message: "Please enter a valid email"
  }
}

export const phone_validator = {
  fieldType: "tel" as InputFieldType,
  required,
  pattern: {
    value: /^\d{10}$/,
    message: "Please enter a valid 10 digits phone number (numbers only)"
  }
}

export const url_validator = {
  fieldType: "url" as InputFieldType,
  required,
  pattern: {
    value:/((([A-Za-z]{3,9}:(?:\/\/)?)?(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)|(www\.[A-Za-z0-9.-]+(?::\d+)?((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))/,
    message: "Please provide a valid URL"
  }
}

export const date_validator = {
  required
}

export const password_validator = {
  fieldType: "password" as InputFieldType,
  required: {
    value: true,
    message: "Required Field",
  },
  minLength: {
    value: 8,
    message: "Minimum 8 characters",
  },
  maxLength: {
    value: 100,
    message: "Maximum 100 characters",
  },
}

export const cpassword_validator = {
  fieldType: "password" as InputFieldType,
  required: {
    value: true,
    message: "Required Field",
  },
  minLength: {
    value: 8,
    message: "Minimum 8 characters",
  },
  maxLength: {
    value: 100,
    message: "Maximum 100 characters",
  }
}

export const boolean_selection_validator = {
  returnValueType: "boolean",
  required: {
    value: true,
    message: "Please select an option"
  }
}

export const selection_validator = {
  required: {
    value: true,
    message: "Please select an option"
  }
}

export const multiselect_validator = {
  required
}

export const address_validator = {
  returnValueType: "boolean",
  required: {
    value: true,
    message: "Please provide an address"
  }
}