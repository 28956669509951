import { useContext, useEffect, useState } from "react";
import { ConditionalRenderDefinition, FieldDefinition, FormData, FormDefinition } from "./Form/Form";
import FormSection from "./FormSection";
import { ConditionalRenderContext, ConditionalRenderContextType, LangContext, LangContextType, useConditionalRenderContext } from "../utilities/customHooks";
import { formContainerStyle } from "../Pages/AdminPages/ProfilePages/NewAdmin";
import { ToastContext, ToastContextType } from "../utilities/customHooks";
import textData from "../textData.json";
import Button from "./Button";

export type FormSectionDefinition = {
  sectionLabel: string,
  frSectionLabel?: string, 
  formDefinition: FormDefinition,
  conditionalRenderDefinition?: ConditionalRenderDefinition
}

export type FormGroupDefinition = {
  [sectionKey: string]: FormSectionDefinition;
}

export type FormGroupData = {
  [sectionKey: string]: FormData;
}

function FormGroup(
  {
    formGroupData,
    formGroupDefinition,
    saveFormSection,
    setIsEditing,
    readOnly,
    isViewOnly,
    isProfilePage,
    isProfilePageOnClick
  }
  :
  {
    formGroupData: FormGroupData | null,
    formGroupDefinition: FormGroupDefinition,
    saveFormSection?: (sectionKey: string, formSectionData: FormData) => void,
    setIsEditing?: (isEditing: boolean) => void,
    readOnly?: boolean,
    isViewOnly?: boolean,
    isProfilePage?: {
      buttonText: string,
      buttonDisabled: boolean
    },
    isProfilePageOnClick?: () => void

  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const sectionKeys = Object.keys(formGroupDefinition);

  useEffect(() => {
    for (const key of sectionKeys) {
      setDisabledGroupState(s => ({ ...s, [key]: true }));
    }
  }, []); 

  type DisabledState = {
    [sectionKey: string]: boolean
  }

  const [disabledGroupState, setDisabledGroupState] = useState<DisabledState>({});
  const conditionalRenderState = (useContext(ConditionalRenderContext) as ConditionalRenderContextType).conditionalRenderState;
  
  // [NOTE] time complexity is >= O^2 
  const sectionHTML = sectionKeys.map( key => {

    const formSectionData = formGroupData ? formGroupData[key] : null;

    const { sectionLabel, formDefinition, conditionalRenderDefinition } = formGroupDefinition[key];

    // render section if conditionalRenderFieldKey is undefined 
    // OR if conditionalRenderState of the given fieldKey evaluates to true
    const renderSection = (conditionalRenderDefinition === undefined)
      || 
      (conditionalRenderState[conditionalRenderDefinition.fieldKey] === conditionalRenderDefinition.renderWhenTrue);

    const handleSetDisabledState = (key: string, isDisabled: boolean) => {
      // if there is already a a section being edited and unsaved, don't allow undisable. Trigger a toaster for warning for now.
      const isEveryOtherSectionDisabled = Object.values(disabledGroupState).every(disabledSectionStateValue => disabledSectionStateValue === true);

      // [TODO] add check on if section input modified, then turning a section back to non-edit mode 
      // should indicate that the data is saved or canceled
      if (isEveryOtherSectionDisabled || isDisabled) {
        if(setIsEditing) setIsEditing(!isDisabled);
        setDisabledGroupState(s => {
          return {
            ...s,
            [key]: isDisabled,
          }
        })
      } else if (isEveryOtherSectionDisabled === false && isDisabled === false) {
        showToast({
          toastType: "failure",
          message: textData.Components.FormGroup.OtherEdit[langState],
          autoDisappearTimeInSeconds: 4,
        });
      }
    }

    const handleSaveFormSection = (sectionKey: string, sectionData: FormData) => {

      // disable (off edit mode) the section after clicking save
      handleSetDisabledState(sectionKey, true);
      if (saveFormSection) saveFormSection(sectionKey, sectionData);
    }
    
    return (
      renderSection 
      &&
      <FormSection
        key={key}
        sectionKey={key}
        sectionLabel={sectionLabel} 
        formData={formSectionData} 
        formDefinition={formDefinition}
        saveFormSection={(formSectionData: FormData) => handleSaveFormSection(key, formSectionData)}
        disabled={disabledGroupState[key]}
        setDisabled={handleSetDisabledState}
        readOnly={readOnly}
        isViewOnly={isViewOnly}
      />
    )
  })

  return (
    <div className={formContainerStyle}>
      {sectionHTML}
    {
    isProfilePage &&
    <div className="flex w-full justify-center mt-2">
      <Button 
        buttonType={"secondary"} 
        buttonText={isProfilePage.buttonText} 
        actionHandler={isProfilePageOnClick as () => void}
        disabled={isProfilePage.buttonDisabled}     
      />
    </div>
    }
    </div>
  )
}

export default FormGroup;