import { useLocation, useNavigate } from "react-router-dom";
import { ConditionalRenderContext, ConditionalRenderContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, useAsync, useRequiredParams } from "../../../utilities/customHooks";
import * as T from "../../../utilities/frontendTypes";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { createFounderProgramApplication, founderProgramEnroll, getProgramDetails } from "../../../services/founderService";
import Form, { FormData, FormDefinition } from "../../../Components/Form/Form";
import { founderProgramDetailsFormDefinition, lockedContentFormDefition } from "../../../utilities/formDefinitionTemplates";
import { flattenObject, getRegionNameFromRegion, handleFormSaveResponse, isFromUrls, returnLangCheckedFormDef } from "../../../utilities/helperFunctions";
import Button, { ButtonProp } from "../../../Components/Button";
import { ToastContext, ToastContextType } from "../../../utilities/customHooks"
import { useContext, useEffect, useState } from "react";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../../AdminPages/ProfilePages/NewAdmin";
import Modal from "../../../Components/Modal";
import { getUserProfile } from "../../../services/userService";
import parseStringToHTML from "html-react-parser";
import textData from "../../../textData.json";

function FounderProgramDetails() {
  const { langState } = useContext(LangContext) as LangContextType

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const programId = useRequiredParams("programId");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [programDetailsRes, setProgramDetailsRes] = useState<T.ProgramDetailsResponse | null>(null);
  const [founderProfileRes, setFounderProfileRes] = useState<T.UserProfileResponse | null>(null);

  const [isAttestationChecked, setIsAttestationChecked] = useState<boolean>(false);

  // Gain access conditional render state
  const conditionalRenderContext = useContext(ConditionalRenderContext) as ConditionalRenderContextType;

  // [TODO] Refactor and combine this useEffect into a conditional render handling module
  useEffect(() => {
    try {
      
      const fetchProgramDetails = async () => {
        showLoading();
        const programDetailsRes =  await getProgramDetails(programId);
        const founderProfileRes =  await getUserProfile("startupFounder");
        hideLoading();

        setFounderProfileRes(founderProfileRes);
        setProgramDetailsRes(programDetailsRes);

        const applicationFormDefinitionString = (programDetailsRes.programDetails as T.ProgramDetails).applicationFormDefinition;

        if (applicationFormDefinitionString !== undefined) {
          
          // Register conditional render state
          const conditionalRenderInitialState = {} as {[x: string]: boolean};
          const applicationFormDefinitionObj = JSON.parse(applicationFormDefinitionString) as FormDefinition;
          
          Object.values(applicationFormDefinitionObj).filter(value => value.conditionalRenderDefinition).forEach(value => {
            const fieldKey = value.conditionalRenderDefinition?.fieldKey;
            if (fieldKey === undefined) throw new Error("fieldKey cannot be undefined when registering conditional render state");
            conditionalRenderInitialState[fieldKey] = false;
          })

          conditionalRenderContext.replaceConditionalRenderState(conditionalRenderInitialState);
        }
      }
      
      fetchProgramDetails().catch(console.error);
     
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line 
  }, []);

  if (programDetailsRes === null || founderProfileRes === null) {
    return <LoadingIndicator />;
  }

  const programDetails = programDetailsRes.programDetails;
  const isEnrolled = programDetailsRes.isUserEnrolled
  // [TODO] passing unnecessary property fields into Form, clean up advised (see pattern in "New Program" as example)
  const isTier1 = programDetails.programData.tier === 1;
  
  if (!isEnrolled && programDetails.programData.lockedContent) {
    delete programDetails.programData.lockedContent;
  } 

  const flattenedProgramDetails = flattenObject(programDetails);
  const formData = {
    ...flattenedProgramDetails,
    // costRange: {
    //   low: programDetails.programData.costRangeLow,
    //   high: programDetails.programData.costRangeHigh,
    // }
  }

  const {name, applicationFormDefinition} = programDetails;

  const isCollectingAdditionalData = applicationFormDefinition !== undefined && applicationFormDefinition !== "";

  const handleApply = async (updatedFormData?: FormData) => {

    if (isRequiringAttestation && !isAttestationChecked) return alert(textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.AttestationAlert[langState]);
    
    showLoading();
    const response = await createFounderProgramApplication(programId, updatedFormData);
    hideLoading();
    if (response.success === true) navigate('/dashboard/founder-applications');

    handleFormSaveResponse(
      response, 
      showToast, 
      {message: langState === "fr" ? `La demande de candidature a été envoyée avec succès à ${name}`: `Application to ${name} has been sent successfully.`}, 
      {message: langState === "fr" ? `La demande de candidature n’a pas été envoyée à ${name}`: `Application to ${name} was not sent.`}
    );
  }
  
  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.Confirm[langState],
    actionHandler: handleApply,
  }

  const {personalInfo, companyInfo} = founderProfileRes.profile as T.StartupFounderProfile;
  const founderName = `${personalInfo?.firstName} ${personalInfo?.lastName}`;
  const legalNameCompanyName = companyInfo?.basicInfo?.legalName;
  const companyName = legalNameCompanyName ? legalNameCompanyName : companyInfo?.basicInfo?.operatingName;
  const isRequiringAttestation = programDetails.programData.isRequiringAttestation;

  const engAttestaion = parseStringToHTML(`
    I, <b>${founderName}</b>, have legal authority to represent <b>${companyName}</b>, 
    et atteste que l'aide fournie par <b>${getRegionNameFromRegion(programDetails.programRegion)}</b> under the Elevate IP program will not be used for 
    any services that are already funded by another government program or grant. Any use of the Elevate IP 
    program funds on services that are already funded by another government program or grant may lead to 
    immediate termination from the Elevate IP program and/or the immediate return of such funding.
    `)

  const frAttestaion = parseStringToHTML(`
    Je, <b>${founderName}</b>, suis légalement habilité à représenter <b>${companyName}</b>, 
    and attest that the support provided by <b>${getRegionNameFromRegion(programDetails.programRegion)}</b> dans le cadre du programme ÉleverlaPI ne sera pas utilisée pour des services déjà financés par un autre programme gouvernemental ou une autre subvention. Toute utilisation des fonds du programme ÉleverlaPI pour des services déjà financés par un autre programme gouvernemental ou une autre subvention pourrait entraîner la résiliation immédiate du programme d’ÉleverlaPI et/ou la demande de restitution immédiate de ces fonds.
    `)  

  const attestationHTML = (
    <div className='flex w-full text-text items-baseline my-5'>
      <input 
        type="checkbox" 
        checked={isAttestationChecked}
        onChange={() => setIsAttestationChecked(!isAttestationChecked)}
      />
      <p className="ml-5">
        {langState === "fr" ? frAttestaion : engAttestaion}
      </p>
    </div>
  );

  const confirmText = isTier1 ? textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.EnrollConfirmation[langState] : textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.ApplyConfirmation[langState]
  const modalHTML = (
    <div>
      <p>{confirmText}</p>
      <p className="font-bold my-5">{name}</p>
      {
        isRequiringAttestation && 
        attestationHTML
      }
    </div>
  );

  const collectDataModalHTML = () => (
    <div>
      <Form 
        formDefinition={JSON.parse(applicationFormDefinition!)} 
        formData={null}
        primaryButtonProp={primaryButtonProp}
      />
      {
        isRequiringAttestation && 
        attestationHTML
      }
    </div>
  )
  const handleEnroll = async () => {
    showLoading();
    const response = await founderProgramEnroll(programId);
    hideLoading();
    if (response.success === true){
      const programName = programDetails.name;
      navigate(`/dashboard/enrolled-services/${programId}`);
      handleFormSaveResponse(
        response,
        showToast, 
        {message: langState === "fr" ? `L'inscription à ${programName} a été effectuée avec succès.` : `Enrollment in ${programName} was successfully.`}, 
        {message: langState === "fr" ? `L'inscription à ${programName} n’a pas été traitée` : `Enrollment in ${programName} was not processed.`}
      );
      setIsModalOpen(false)
    }
  }
  const langCheckedLockedFormDefition = returnLangCheckedFormDef(lockedContentFormDefition, langState)
  const langCheckedFounderProgramDetailsFormDefinition = returnLangCheckedFormDef(founderProgramDetailsFormDefinition, langState)

  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        closeCallback={() => {{isEnrolled ? navigate('/dashboard/enrolled-services') : navigate('/dashboard/founder-services')}}}
      />
      <DetailsContentHeader label={textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.Header[langState]}/>
      {
        isEnrolled 
        ?
        <div className={`${formContainerStyle} h-[75%]`}>
          <div className={formWrapperStyle}>
            <Form 
              formDefinition={programDetails.programData.lockedContent ? langCheckedLockedFormDefition : langCheckedFounderProgramDetailsFormDefinition}
              formData={programDetails.programData.lockedContent ? {"content" : programDetails.programData.lockedContent} : formData}
              disabled={true}
            />
          </div>
        </div>
        :
        <div className={`${formContainerStyle} h-[75%]`}>
          <div className={formWrapperStyle}>
            <Form 
              formDefinition={langCheckedFounderProgramDetailsFormDefinition}
              formData={formData}
              disabled={true}
            />
          </div>

            <div className="flex justify-center mt-5">
              <Button 
                buttonType={"primary"} 
                buttonText={isTier1 ? textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.ButtonEnroll[langState] : textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.ButtonApply[langState]} 
                actionHandler={() => {setIsModalOpen(true)}}
              />
            </div> 
          <Modal 
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            modalHeader={isTier1 ? textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.ModalEnrollHeader[langState] : textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.ModalApplyHeader[langState]}
            contentLabel={isTier1 ? textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.ModalEnrollContent[langState] : textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.ModalApplyContent[langState]}
            primaryButton={isCollectingAdditionalData ? undefined : { buttonText: textData.Pages.FounderPages.ProgramPages.FounderProgramDetails.Confirm[langState], buttonCallback: () => isTier1 ? handleEnroll() : handleApply() }}
            modalHTML={isCollectingAdditionalData ? collectDataModalHTML() : modalHTML}              
          />
        </div>
      }
    </div>
  )
}

export default FounderProgramDetails;