import moment from "moment";
import * as T from "../utilities/frontendTypes";

function Card(
  {
    program,
    onClickCallback,
    isViewOnly,
  }
  :
  {
    program: {
      name: string,
      description: string,
      eventDate: Date,
      eventUrl: string,
      eventTime: string
    },
    onClickCallback?: (id: string) => void,
    isViewOnly?: boolean,
  }
) {
  
  const { name, description, eventDate, eventUrl, eventTime } = program;

  return (
    <div
      className="eventCard"
    >  
      <h3 className="font-semibold">{name}</h3>
      <div className="flex flex-col gap-2">
        <p>{moment(eventDate).format('MMM Do YYYY').toString()}</p>
        <p>{eventTime}</p>
      </div>
      <p className="">{description}</p>
      <a className="eventLink" target="_blank" href={eventUrl}>Register</a>
    </div>
  )
    
}

export default Card;