import instance from '../middleware/HTTPInterceptors';
import ErrorResponse from '../utilities/ErrorResponse';
import * as T from '../utilities/frontendTypes';
import { getAuthHeader } from '../utilities/helperFunctions';

export async function getFounderHeaders(): Promise<T.ExpertFounderHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/experts/get-founders`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getInProgressAssessmentAsync(userId: T.UserId): Promise<{success: boolean, founder: T.User}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/experts/get-in-progress-assessment/${userId}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getInProgressFounderHeaders(): Promise<T.ExpertFounderHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/experts/get-in-progress-founders`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getSubmittedFounderHeaders(): Promise<T.ExpertFounderHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/experts/get-submitted-founders`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function submitFounderAssessment(id: T.UserId, assessment: T.ScoreBreakdown, duration: number): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      id,
      assessment,
      duration
    }

    const res = await instance.put(`/api/experts/submit-assessment`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function saveFounderAssessment(id: T.UserId, assessment: T.ScoreBreakdown, duration: number): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      id,
      assessment,
      duration
    }

    const res = await instance.put(`/api/experts/save-assessment`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}  


export async function getExpertAssesmentStats(): Promise<{success: boolean, foundersArray: T.ExpertMemberHeader[], name: string}>  {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/experts/get-stats`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}


export async function addDDFlag(id: T.UserId, notes: string): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      id,
      notes
    }

    const res = await instance.put(`/api/experts/add-dd-flag`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getDueDilligenceFounderHeaders(): Promise<T.ExpertFounderHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/experts/get-dd-founders`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getMemberDetails(id: T.UserId): Promise<T.MemberDetailsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/experts/memberships/list/${id}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getRegionAdminNotes(userId: T.UserId): Promise<{success: boolean, notes: T.NotesObject[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/experts/get-admin-notes/${userId}`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function addNewNote(id: T.UserId, notes: string): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      id,
      notes
    }

    const res = await instance.put(`/api/experts/add-new-note`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getDownloadUrl(docKey: string): Promise<{success: boolean, url: string }> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    console.log(docKey)

    const res = await instance.get(`/api/experts/get-pdf/${docKey}`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}
