import Form, { FormData } from "../../../Components/Form/Form";
import * as T from "../../../utilities/frontendTypes";
import { adminVerificationFormDefinition } from "../../../utilities/formDefinitionTemplates"
import { updateUserProfile } from "../../../services/userService";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../../../utilities/customHooks";
import { handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { ButtonProp } from "../../../Components/Button";
import textData from "../../../textData.json";

function GrowthCoachVerification ( { setTempGrowthCoachUserRole } : { setTempGrowthCoachUserRole: Dispatch<SetStateAction<T.UserRole>>} ) {

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const { updateUserRole } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();

  useEffect(() => {
    setTempGrowthCoachUserRole("growthCoach" as T.UserRole);
  }, []) 

  async function handleSave(updatedFormData: FormData) {
    const updatedGrowthCoachProfile = {
      ...updatedFormData,
      userRole: "growthCoach"
    }

    showLoading();
    const response = await updateUserProfile("tempGrowthCoach", updatedGrowthCoachProfile as T.User);  
    hideLoading();

    if (response.success) {
      updateUserRole("growthCoach");
      navigate('/dashboard');
    }
    
    handleFormSaveResponse(response, showToast, {message: "Growth Coach Verified"});
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: (updatedFormData: FormData) => handleSave(updatedFormData),
  }
  
  return (
    <div className="flex w-[500px] grow items-center self-center">
      <Form               
        formData={null}
        formDefinition={adminVerificationFormDefinition}
        primaryButtonProp={primaryButtonProp}
      />
    </div>
  )
}

export default GrowthCoachVerification