import { useNavigate } from "react-router-dom";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import Form from "../../Components/Form/Form";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync, useReloadOnOutletSave, useRequiredParams } from "../../utilities/customHooks";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../AdminPages/ProfilePages/NewAdmin";
import { expertAssessmentFormDefition } from "../../utilities/formDefinitionTemplates";
import Button, { ButtonProp } from "../../Components/Button";
import { handleFormSaveResponse } from "../../utilities/helperFunctions";
import { useContext, useState } from "react";
import { getInProgressAssessmentAsync, saveFounderAssessment, submitFounderAssessment } from "../../services/ipExpertService";
import * as T from "../../utilities/frontendTypes";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import Modal from "../../Components/Modal";


const SubmittedFounderAssesmentDetails = () => {

  const navigate = useNavigate();
  const founderId = useRequiredParams("id")
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const [reload, setReload] = useState(false);
  const { reloadOnOutletSave, setReloadOnOutletSave } = useReloadOnOutletSave();
  const [tempUpdatedForm, setTempUpdatedForm] = useState<FormData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const confirmModal = (updatedForm: FormData) => {
    setIsModalOpen(true)
    setTempUpdatedForm(updatedForm)
  } 

  const inProgressAssessment = useAsync(() => getInProgressAssessmentAsync(founderId), [founderId]);

  if (inProgressAssessment.status === "pending") {
    return <LoadingIndicator />;
  }

  if (inProgressAssessment.status === "rejected") {
    return <ErrorIndicator />;
  }
  const founder = inProgressAssessment.value.founder

  return (
    <div className={detailsContentWrapperStyle}>
    <ContentPageTopButtons 
      hideBackButton
      closeCallback={() => navigate('/dashboard/submitted-assesments')}   
    />
    <DetailsContentHeader 
      label="Company Assessment"
    />
    <div className={`${formContainerStyle} h-[75%]`}>
      <div className={formWrapperStyle}>
        <Form 
        formDefinition={expertAssessmentFormDefition}
        formData={founder.founderAdditionalData?.serviceData?.expertAssessment?.scoreBreakdown ? founder.founderAdditionalData?.serviceData?.expertAssessment?.scoreBreakdown : null}
        readOnly={true}
        />       
      </div>
    </div>
  </div>
  )
}

export default SubmittedFounderAssesmentDetails