import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as T from "../utilities/frontendTypes";
import { Link } from "react-router-dom"
import Tooltip from "./Tooltip";
import dashboard from "../assets/Dashboard.png"
import parseStringToHTML from "html-react-parser";


export default function SideMenuNavButton({link, text, imgSrc, disabled, tooltipContent, activeImgSrc}: T.MenuData) {

  return (
    <div 
      id={text}
      className={
        `flex transition-all sideMenuCont
        ${disabled ? "cursor-not-allowed" : ""}`
      } 
      key={link}
    >
      <Link
        tabIndex={disabled ? -1 : 0}
        to={link}
        className={`iconPadding flex w-full ${disabled && 'pointer-events-none opacity-50 cursor-not-allowed'}`}
        aria-disabled={disabled}
      >
        <div className="flex justify-center items-center">
          <span className="activeShow">{activeImgSrc && parseStringToHTML(activeImgSrc)}</span>
          <span className="activeHide">{imgSrc && parseStringToHTML(imgSrc)}</span>
          <p className="ml-2 sideMenuText collapsedHide">{text}</p>
        </div>
      </Link>
      { 
        disabled && tooltipContent 
        && 
        <Tooltip content={tooltipContent} type="lock"/> 
      }
    </div>
  )
}