import { useContext } from "react";
import { AuthContext, AuthContextType } from "../utilities/customHooks";
import AdminProfile from "./AdminPages/ProfilePages/AdminProfile";
import FounderProfile from "./FounderPages/FounderProfile";
import FounderProfileUpdated from "./FounderPages/FounderProfileUpdated";

function Profile() {

  const { userRole } = useContext(AuthContext) as AuthContextType;

  const getProfilePage = () => {
    
    switch (userRole) {
      case "partnerAdmin":
      case "tempAdmin":
        return <AdminProfile />;
      case "superAdmin":
        return <AdminProfile />;
      case "serviceProvider":
        return <></>; // [TODO] Create profile page for service provider
      case "ipExpert":
        return <></>; // [TODO] Create profile page for service provider
      case "startupFounder":
        return <FounderProfileUpdated />
        return <FounderProfile />;
      default:
        throw new Error('Unknown user role');
    }
  }

  return (
    getProfilePage()
  )
}

export default Profile