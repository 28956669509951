import { useFormContext } from "react-hook-form";
import Form, { FieldValidator, FormDefinition } from "./Form";
import { disabledInputClasses, inputClasses } from "./InputField";
import Button, { ButtonProp } from "../Button";
import Modal from "../Modal";
import { useState } from "react";

export default function FormDefinitionEditor(
  {
    name,
    placeholder,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    name: string,
    placeholder?: string,
    value: string,
    setValue: (value: string) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {

  const { register, clearErrors } = useFormContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInputValidating, setIsInputValidating] = useState(false);

  const handleInputChange = (e: any) => { // Intentional any type
    clearErrors(name)
    setValue(e.currentTarget.value);
  }

  const handleValidateAndPreview = () => {
    setIsInputValidating(true);
    setIsModalOpen(true)
  }

  const handleCloseInputValidation = () => {
    setIsInputValidating(false);
    setIsModalOpen(false);
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: 'Test Input',
    actionHandler: () => handleTestInput(),
  }

  const secondaryButtonProp: ButtonProp = {
    buttonText: 'Close',
    actionHandler: () => handleCloseInputValidation(),
    buttonType: "secondary-inverse"
  }

  const handleTestInput = () => {
    alert("All test input are valid.");
  }

  const validateFormDefinition = (): FormDefinition => {
    try {
      return JSON.parse(value);
    } catch(e) {
      alert(e);
      setIsInputValidating(false);
      return {};
    }
  }

  const isInputEmpty = value === "";

  return (
    <div className="relative flex items-center">
      <div className="absolute -top-9 right-0">
        <Button
          size="small"
          buttonText={"Validate & Preview"} 
          actionHandler={handleValidateAndPreview}   
        />
      </div>
      <textarea
        rows={isInputEmpty ? 2 : 20}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        id={name}
        readOnly={readOnly}
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" } w-full`}
        {
          ...register(name, {
            validate: () => {
              if ( validator?.required && isInputEmpty) {
                return "Required Field."
              }
              return true;
            },
            onChange: e => handleInputChange(e),
          })
        }
      />
      {
        !isInputEmpty && isInputValidating &&
        <Modal 
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          modalHeader={``}
          contentLabel={``}
          modalHTML={
            <div className="flex flex-col text-text">
              <Form 
                formDefinition={validateFormDefinition()} 
                formData={null}
                primaryButtonProp={primaryButtonProp}
                secondaryButtonProp={secondaryButtonProp}
              />
            </div>
          }
        />
      }
    </div>
  )
}