import { useContext, useState } from 'react'
import { contentPageContainerStyle, contentPageWrapperStyle } from '../AdminPages/ProfilePages/PartnerAdminList'
import ContentPageTopButtons from '../../Components/ContentPageTopButtons'
import { DetailsContentHeader } from '../AdminPages/ProfilePages/NewAdmin'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import textData from "../../textData.json";
import { LangContext, LangContextType } from '../../utilities/customHooks';

export const FAQ = () => {
  const [childClickedAccordionItemIndex, setChildClickedAccordionItemIndex] = useState(0);
  const { langState } = useContext(LangContext) as LangContextType

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader
          label="FAQ"
        />
          <div className="self-baseline">
            <Accordion
            allowZeroExpanded={true}
            preExpanded={[childClickedAccordionItemIndex]}
            className="w-full overflow-y-auto pr-2"
            >    
            <AccordionItem
                uuid={0}
                className="mb-10 pr-1"
                key={`faq1`}
                onClick={() => setChildClickedAccordionItemIndex(0)}
                >
                <AccordionItemHeading>
                <AccordionItemButton
                    className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                >
                    <h3 className='font-bold'>
                      {textData.Pages.FounderPages.FAQ.Q1[langState]}
                    </h3>

                    <AccordionItemState>
                    {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                    </AccordionItemState>
                    </AccordionItemButton>
                    </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="mb-4">
                      {textData.Pages.FounderPages.FAQ.A1[langState]}

                    </p>
                </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                uuid={1}
                className="mb-10 pr-1"
                key={`faq2`}
                onClick={() => setChildClickedAccordionItemIndex(1)}
                >
                <AccordionItemHeading>
                  <AccordionItemButton
                    className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                  >
                    <h3 className='font-bold'>
                      {textData.Pages.FounderPages.FAQ.Q2[langState]}
                    </h3>
                    <AccordionItemState>
                    {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="mb-4">
                      {textData.Pages.FounderPages.FAQ.A2[langState]}
                    </p>
                </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                uuid={2}
                className="mb-10 pr-1"
                key={`faq3`}
                onClick={() => setChildClickedAccordionItemIndex(2)}
                >
                <AccordionItemHeading>
                <AccordionItemButton
                    className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                >
                  <h3 className='font-bold'>
                    {textData.Pages.FounderPages.FAQ.Q3[langState]}
                  </h3>
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                  </AccordionItemState>
                  </AccordionItemButton>
                  </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="mb-4">
                    {textData.Pages.FounderPages.FAQ.A3[langState]}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
      </div>
    </div>
  )
}
