import Form, { FormDefinition, FormData } from "../Components/Form/Form";
import { resetPassword } from "../services/publicService";
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, useRequiredParams } from "../utilities/customHooks";
import { password_validator } from "../utilities/formFieldValidators"
import { useNavigate } from "react-router-dom";
import { ToastContext, ToastContextType, } from "../utilities/customHooks";
import { useContext, useEffect, useState } from "react";
import Button, { ButtonProp } from "../Components/Button";
import { nonDashboardPageContainerStyle } from "./NotAuthorizedPage";
import ContentPageTopButtons from "../Components/ContentPageTopButtons";
import textData from "../textData.json";

function PasswordReset() {
  const { settings, setSettings, logout} = useContext(AuthContext) as AuthContextType;
  const { langState } = useContext(LangContext) as LangContextType
  const { setLang } = useContext(LangContext) as LangContextType
  const [isReset, setIsReset] = useState(false)

  useEffect(() => {
    const buttons = document.getElementsByClassName('gap-[16px]');
    Array.from(buttons).forEach(element => {
      element.classList.add('mt-[48px]');
    });
    const makeEl = () => {
    const radioInput = document.getElementById('password');
      if (radioInput && radioInput.parentNode) {
        // Create a new element to be added
        const newElement = document.createElement('span');
        newElement.textContent = langState === "fr" ? "Un minimum de 8 caractères, s'il vous plaît" : 'Minimum 8 characters, please';
        newElement.style.color = '#74817B';
        newElement.style.display = "flex";
        newElement.style.position = "absolute"
        newElement.style.bottom = "-24px"
        newElement.style.marginLeft = "10px"

        // Insert the new element after the radio input
        radioInput.parentNode.insertBefore(newElement, radioInput.nextSibling);
      }}
      makeEl()
  }, [])
  const handleLangToggle = async () => {
    if (langState === "eng") {
      setLang("fr")
    }
    if (langState === "fr") {
      setLang("eng")
    }
  }
  const navigate = useNavigate();
  
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const resetToken = useRequiredParams("resetToken");
  async function handleSave(updatedForm: FormData):Promise<void> {
    const password = {password: updatedForm['password'] as string}

    showLoading();
    const response = await resetPassword(resetToken, password);
    hideLoading();

    if (response.success === true) {
      showToast({
        toastType: "success",
        message: textData.Pages.PasswordReset.SuccessToast[langState]
      })
      setIsReset(true)
    }
  }

  const newPassword: FormDefinition = {
    password: { 
      label: textData.Pages.PasswordReset.NewPassword[langState],
      inputType: "text",
      validator: password_validator,
      placeholder: "Create a new password"
    }
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.PasswordReset.Confirm[langState],
    buttonType: "primaryFull",
    actionHandler: (updatedFormData: FormData) => handleSave(updatedFormData),
  }

  return (
    <div className="w-full h-full">
    <header className="headerRegister !bg-cGreen ">
    <h2 className="white font-semibold programheaderbutton ">{textData.Components.SideMenu.Dashboard[langState]}</h2>
    <div className='headerSect'>
      <button className='flex bodyLarger buttonSizer' onClick={() => handleLangToggle()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" className='mr-2 relative bottom-1'>
        <circle cx="8" cy="8.5" r="7.5" stroke="#FDFFFE"/>
        <path d="M10.5 8.5C10.5 10.6651 10.1701 12.6029 9.65316 13.9813C9.39401 14.6723 9.0975 15.1969 8.79361 15.5396C8.4892 15.883 8.21987 16 8 16C7.78013 16 7.5108 15.883 7.20639 15.5396C6.9025 15.1969 6.60599 14.6723 6.34684 13.9813C5.82994 12.6029 5.5 10.6651 5.5 8.5C5.5 6.33494 5.82994 4.39713 6.34684 3.01871C6.60599 2.32765 6.9025 1.80314 7.20639 1.46038C7.5108 1.11704 7.78013 1 8 1C8.21987 1 8.4892 1.11704 8.79361 1.46038C9.0975 1.80314 9.39401 2.32765 9.65316 3.01871C10.1701 4.39713 10.5 6.33494 10.5 8.5Z" stroke="#FDFFFE"/>
        <path d="M0.5 8.5H15.5" stroke="#FDFFFE" strokeLinecap="round"/>
        </svg>
          <span className='programheaderbutton relative'>{langState === "eng" ? "ENG" : "FR"}</span>
      </button>
      <Button
        buttonText={textData.Pages.LandingPage.pages.index.Login[langState]}
        buttonType='secondary-inverse'
        actionHandler={() => navigate("/login")}
      />
      <Button
        buttonText={textData.Pages.LandingPage.pages.index.Register[langState]}
        buttonType='primary'
        actionHandler={() => navigate("/register")}
      />
    </div>
    </header>
    <div className="flex justify-center items-center">
      <div className='flex flex-col w-[576px] mt-[10%]'>
        {!isReset ?
        <div>
          <h1 className="text-center mb-4">{textData.Pages.ForgotPassword.Reset[langState]}</h1>
          <p className="bodyLarger text-center">{textData.Pages.ForgotPassword.Enter[langState]}</p>
          <Form               
          formData={null}
          formDefinition={newPassword}
          primaryButtonProp={primaryButtonProp}
          />
        </div>
        :
        <div>
          <h1 className="text-center mb-4">{textData.Pages.ForgotPassword.Success[langState]}</h1>
          <p className="bodyLarger text-center mb-12">{textData.Pages.ForgotPassword.HasBeen[langState]}</p>
          <Button 
            buttonText={textData.Pages.ForgotPassword.Login[langState]}
            buttonType="primaryFull"
            actionHandler={() => navigate("/login")}
          />
        </div>
        }
      </div>
    </div>
  </div>

  )
}

export default PasswordReset