import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FieldValidator } from './Form';
import { useFormContext } from 'react-hook-form';
import { disabledInputClasses, inputClasses } from './InputField';
import textData from "../../textData.json";
import { LangContext, LangContextType } from '../../utilities/customHooks';
import { useContext } from 'react';

export default function DatePickerWithLabel(
  {
    name,
    placeholder,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
    future,
    futureDateMin,
    futureDateMax
  }
  :
  {
    scrollableYearDropdown: boolean,
    name: string,
    placeholder?: string,
    value: Date | null,
    setValue: (value: Date | null) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator, 
    future?: boolean,
    futureDateMin?: Date,
    futureDateMax?: Date
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const { register, clearErrors } = useFormContext();

  const date = value ? moment(value).toDate() : null;

  const handleSetValue = (date: Date | null) => {
    if (date) {
      setValue(date);
      clearErrors(name);
    }
  }
  const futureMinDate = futureDateMin ? futureDateMin : new Date();
  const futureMaxDate = futureDateMax ? futureDateMax : new Date();
  const maxDate = future ? null : futureMaxDate;
  const minDate = future ? futureMinDate : null;

  return (
    <DatePicker 
      className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
      selected={date}
      readOnly={readOnly}
      disabled={disabled}
      id={name}
      {
        ...register(name, {
          // ...validator,
          validate: () => {
            if (validator?.required && !date) {
              return textData.Components.Form.CustomList.EmptyField[langState];
            }
            return true;
          }
        })
      }
      scrollableYearDropdown={true}
      showYearDropdown
      yearDropdownItemNumber={115}
      onChange={handleSetValue}
      maxDate={maxDate}
      minDate={minDate}
    />
  )
}