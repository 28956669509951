import { useFormContext } from "react-hook-form";
import { FieldValidator } from "./Form";
import { disabledInputClasses, inputClasses } from "./InputField";
import textData from "../../textData.json";
import { useContext } from "react";
import { LangContext, LangContextType } from "../../utilities/customHooks";

export default function TextArea(
  {
    name,
    placeholder,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    name: string,
    placeholder?: string,
    value: string | number,
    setValue: (value: string | number) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const { register, clearErrors } = useFormContext();

  const handleInputChange = (e: any) => { // Intentional any type
    clearErrors(name)
    setValue(e.currentTarget.value);
  }

  return (
    <div className="relative flex items-center">
      <textarea
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        id={name}
        readOnly={readOnly}
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" } w-full h-fit`}
        style={{ minHeight: "100px" }}
        {
          ...register(name, {
            validate: () => {
              if ( validator?.required && value === "") {
                return textData.Components.Form.TextArea.Required[langState]
              }
              return true;
            },
            onChange: e => handleInputChange(e),
          })
        }
      />
    </div>
  )
}
