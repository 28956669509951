import { useContext } from "react";
import { LangContext, LangContextType } from "../utilities/customHooks";
import textData from "../textData.json";

function ErrorIndicator() {
  const { langState } = useContext(LangContext) as LangContextType;

  return (
    <div className="text:text">{textData.Components.ErrorIndicator.Error[langState]}</div>
  )
}

export default ErrorIndicator;