import { Outlet, useNavigate } from "react-router-dom";
import { OutletContextType, useAsync, useReloadOnOutletSave } from "../../utilities/customHooks";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import * as T from "../../utilities/frontendTypes";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../AdminPages/ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../AdminPages/ProfilePages/NewAdmin";
import List, { KeyValuePair } from "../../Components/List";
import { getFounderHeaders, getInProgressFounderHeaders, getSubmittedFounderHeaders } from "../../services/ipExpertService";
import { useState } from "react";

const DueDilligence = () => {

  const navigate = useNavigate();

  const founderAssementHeaders = useAsync(() => getSubmittedFounderHeaders(), []);

  if (founderAssementHeaders.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderAssementHeaders.status === "rejected") {
    return <ErrorIndicator />;
  }

  const founderHeaders = [...founderAssementHeaders.value.founders];
  const handleViewFounderDetails = (id: T.UserId) => {
    navigate(`/dashboard/submitted-assesments/${id}`);
  }

  const columnHeaderData = [
    {
      header: "Company",
      key: "companyName"
    }
  ];
  
  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label="Awaiting Due Dilligence"
          tooltip="These are Companies awaiting due dilligence checks from "
        />
        <List 
          contentArray={founderHeaders as KeyValuePair[]}
          columnHeaderData={columnHeaderData}
        />  
      </div>
    </div>
  );
}

export default DueDilligence