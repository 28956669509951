import { useNavigate } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import Form, { FormData } from "../../../Components/Form/Form";
import { createAdminProfileFormDefinition, createExpertProfileFormDefinition, createServiceProviderProfileFormDefinition } from "../../../utilities/formDefinitionTemplates";
import { createUserProfile } from "../../../services/adminService";
import { useContext, useState } from "react";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../../../utilities/customHooks";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import Tooltip from "../../../Components/Tooltip";
import { handleFormSaveResponse } from "../../../utilities/helperFunctions";
import Modal from "../../../Components/Modal";
import { ButtonProp } from "../../../Components/Button";
import textData from "../../../textData.json";

// [TODO] figure out a better way to store those variables (as well as reused data and components that 
//        are currently just sitting in a file).
export const formWrapperStyle = "flex w-full rounded-md border-[1px] border-text/30 p-5";

export const formContainerStyle = "flex flex-col w-full pr-5 h-[calc(var(--contentHeight))] overflow-y-auto mb-5"

export const detailsContentWrapperStyle = "text-text relative flex flex-col h-full items-center ml-5 w-[-webkit-fill-available]";

// function NewAdmin() {
//   const { langState } = useContext(LangContext) as LangContextType

//   const navigate = useNavigate();

//   const showToast = (useContext(ToastContext) as ToastContextType).showToast;

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [tempUpdatedForm, setTempUpdatedForm] = useState<FormData | null>(null);

//   const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  
//   const handleConfirmModal = (updatedForm: FormData) => {
//     setIsModalOpen(true);
//     setTempUpdatedForm(updatedForm);
//   }

//   const handleSave = async () => {
//     if (tempUpdatedForm === null) throw new Error("Cannot save new admin with null tempUpdatedForm data");
//     const {firstName, lastName, uniqueEmail, userRegion} = tempUpdatedForm;
  
//     const updatedAdminProfile = {
//       adminProfile:{
//         firstName: firstName as string,
//         lastName: lastName as string
//       },
//       email: (uniqueEmail as string),
//       userRegion: userRegion as string
//     }

//     showLoading();
//     const response = await createAdminProfile(updatedAdminProfile as T.User);
//     hideLoading();

//     if (response.success) {
//       navigate('/dashboard/admin-list/');
//     }
//     setIsModalOpen(false);
//     handleFormSaveResponse(
//       response, 
//       showToast, 
//       {message: langState === "fr" ? `Création réussie du nouvel administrateur ${firstName} ${lastName}` : `New admin ${firstName} ${lastName} successfully created`}, 
//       {message: textData.Pages.AdminPages.ProfilePages.NewAdmin.EmailInUse[langState]}
//     );
//   }

//   const modalHTML = (
//     <div className='flex flex-col text-text'>
//       <h1 className='text-center text-xl font-bold'>{textData.Pages.AdminPages.ProfilePages.NewAdmin.Preview[langState]}</h1>
//       <Form
//         disabled
//         formDefinition={createAdminProfileFormDefinition}
//         formData={tempUpdatedForm}
//       />
//     </div>
//   )

//   const primaryButtonProp: ButtonProp = {
//     buttonText: textData.Pages.AdminPages.ProfilePages.NewAdmin.Save[langState],
//     actionHandler: handleConfirmModal,
//   }

//   return (
//     <div className={detailsContentWrapperStyle}>
//       <ContentPageTopButtons 
//         hideBackButton
//         closeCallback={() => navigate('/dashboard/admin-list')}
//       />
//       <DetailsContentHeader label={textData.Pages.AdminPages.ProfilePages.NewAdmin.AddNew[langState]}/>
//       <div className={`${formContainerStyle}`}> 
//         <div className={formWrapperStyle}>  
//           <Form
//             formData={null}
//             formDefinition={createAdminProfileFormDefinition}
//             primaryButtonProp={primaryButtonProp}
//             disabled={false}
//           />
//           <Modal 
//             isOpen={isModalOpen} 
//             closeModal={() => setIsModalOpen(false)}
//             modalHTML={modalHTML} 
//             contentLabel={textData.Pages.AdminPages.ProfilePages.NewAdmin.ModalPreview[langState]}
//             primaryButton={{buttonText: textData.Pages.AdminPages.ProfilePages.NewAdmin.Confirm[langState], buttonCallback: () => handleSave()}}
//           />
//         </div>
//       </div>
//     </div>
//   )
// }

export const DetailsContentHeader = (
  {
    label,
    tooltip,
    level,
  }
  :
  {
    label: string,
    tooltip?: string, 
    level?: "h2" | "h3" | "h4", // default is "h1" basically
  }
) => {
  let containerStyle = "mb-5";
  let labelStyle = "text-3xl";

  switch(level) {
    case "h2":
      containerStyle = "mb-2"
      labelStyle = "text-2xl"
      break;
  }

  return (
    <div className={`flex ${containerStyle} self-baseline`}>
      <h1 className={`font-bold ${labelStyle}`}>{label}</h1>
      {tooltip && <Tooltip content={tooltip}/>}
    </div>
  )
};

// export default NewAdmin;