import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import IconButton from "./IconButton";
import Form, { FormData, FormDefinition } from "./Form/Form";
import { LangContext, LangContextType, ToastContext, ToastContextType } from "../utilities/customHooks";
import { useContext, useState } from "react";
import { ButtonProp } from "./Button";
import textData from "../textData.json";

function FormSection(
  {
    sectionKey,
    sectionLabel,
    formData,
    formDefinition,
    saveFormSection,
    disabled,
    setDisabled,
    readOnly,
    isViewOnly,
  }
  :
  {
    sectionKey: string,
    sectionLabel: string,
    formData: FormData | null,
    formDefinition: FormDefinition,
    saveFormSection: (formData: FormData) => void,
    disabled: boolean,
    setDisabled: (sectionKey: string, disabledState: boolean) => void,
    readOnly?: boolean,
    isViewOnly?: boolean,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const [isFormChanged, setIsFormChanged] = useState(false);

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Components.FormSection.Save[langState],
    actionHandler: saveFormSection
  }

  return (
    <div className="relative mb-5 flex flex-col w-full rounded-md border-[1px] border-text/30 p-5">
      {
        !readOnly &&
        <div className="flex absolute top-5 right-5">
          <IconButton 
            text={textData.Components.FormSection.Edit[langState]}
            icon={faPenToSquare} 
            actionCallback={() => {
              /* 
                [TODO] 
                Current UI/UX feels slightly broken. If we edit a field, dont save, press the edit pen icon again, 
                the 'info' toast warning shows and the form disables with the unsaved information.
                I will add a Modal/confirmation functionality to the Form component to provide better UX around unsaved text 
                and navigation in general (The case above will be covered) - B             
              */ 
               
              if (disabled === false && isFormChanged === true) {
                showToast({
                  toastType: "info",
                  message: textData.Components.FormSection.Unsaved[langState],
                  autoDisappearTimeInSeconds: 4,
                });
              }
              setDisabled(sectionKey, !disabled)
            }} 
          />
        </div>
      }
      <h1 className="text-xl font-semibold capitalize">{sectionLabel}</h1>
      <Form
        formData={formData}
        formDefinition={formDefinition}
        primaryButtonProp={primaryButtonProp}
        disabled={disabled}
        readOnly={readOnly}
        isViewOnly={disabled}
        isFormChangedCallback={setIsFormChanged}
      />
    </div>
  )
}

export default FormSection;