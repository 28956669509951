import Form, { FormData } from "../../../Components/Form/Form";
import { createNewProgram } from "../../../services/adminService";
import { useNavigate } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import { programDetailsFormDefinition } from "../../../utilities/formDefinitionTemplates";
import { useContext, useState } from "react";
import { handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../../../utilities/customHooks";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../ProfilePages/NewAdmin";
import { RangeData } from "../../../Components/Form/RangeInputFields";
import Modal from "../../../Components/Modal";
import { ButtonProp } from "../../../Components/Button";


function NewProgram() {
  
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempUpdatedForm, setTempUpdatedForm] = useState<FormData | null>(null);

  const confirmModal = (updatedForm: FormData) => {
    setIsModalOpen(true)
    setTempUpdatedForm(updatedForm)
  } 
  
  const handleSave = async () => {
    if (tempUpdatedForm === null) throw new Error("Cannot save new prorgam with null tempUpdatedForm data");

    // if (tempUpdatedForm.costRange === null) {
    //   tempUpdatedForm.costRange = {
    //     low: 0,
    //     high: 0,
      
    //   }
    // }
    const {low, high} = tempUpdatedForm.costRange as RangeData;
    delete tempUpdatedForm.costRange;
    const newProgramData = {
      // ...tempUpdatedForm,
      // costRangeLow: low,
      // costRangeHigh: high,
      applicationFormDefinition: JSON.parse(JSON.stringify(tempUpdatedForm.applicationFormDefinition)),
    };

    showLoading();
    const response = await createNewProgram(newProgramData as T.UpdatedProgram);
    hideLoading();

    if (response.success) navigate('/dashboard/my-services');
    handleFormSaveResponse(
      response, 
      showToast, 
      {message: `Service '${tempUpdatedForm.name}' has been succesfully created`}, 
      {message: `Service '${tempUpdatedForm.name}'could not be created, please try again. If you see this message again, please contact support.`}
    );
  }

  const modifiedProgramDetailsFormDefinition = {
    ...programDetailsFormDefinition,
  }
  delete modifiedProgramDetailsFormDefinition.ownerName;

  const modalHTML = (
    <div className='flex flex-col text-text'>
      <h1 className='text-center text-xl font-bold'>New Service Preview</h1>
      <Form
        isViewOnly={true}
        disabled={true}
        formDefinition={modifiedProgramDetailsFormDefinition}
        formData={tempUpdatedForm}
      />
    </div>
  )

  const primaryButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: confirmModal,
  }

  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        closeCallback={() => navigate('/dashboard/my-services')}
      />
      <DetailsContentHeader label="Add New Service"/>
      <div className={formContainerStyle}> 
        <div className={formWrapperStyle}> 
          <Form 
            formData={null}
            formDefinition={modifiedProgramDetailsFormDefinition}
            primaryButtonProp={primaryButtonProp}
          />
          <Modal 
            isOpen={isModalOpen} 
            closeModal={() => setIsModalOpen(false)}
            modalHTML={modalHTML} 
            contentLabel={"New service creation confirmation preview modal"}
            primaryButton={{buttonText: "Confirm", buttonCallback: () => handleSave()}}
          />
        </div>
      </div>
    </div>
  )
}

export default NewProgram