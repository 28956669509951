import { useFormContext } from "react-hook-form";
import { isStringNumeric } from "../../utilities/helperFunctions";
import { FieldValidator } from "./Form";
import { useContext, useEffect, useState } from "react";
import { disabledInputClasses, inputClasses } from "./InputField";
import textData from "../../textData.json";
import { LangContext, LangContextType } from "../../utilities/customHooks";

export type RangeData = {
  low: number,
  high: number,
}

export default function RangeInputFields(
  {
    name,
    placeholder,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    name: string,
    placeholder?: string,
    value: RangeData | null,
    setValue: (value: RangeData | null) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  // initialize the rangeState if value is not null
  let isFirstTime = true;
  useEffect(() => {
    if (isFirstTime && value) {
      setRangeState({low: value.low.toString(), high: value.high.toString()});
      isFirstTime = false;
    }
  }, [value])

  const emptyState = {low: "", high: ""};
  const [rangeState, setRangeState] = useState<{low: string, high: string}>(emptyState);

  const { register, clearErrors } = useFormContext();

  return (
    <div className="flex items-center"
      {
        ...register(name, {
          // ...validator,
          validate: () => {
            if (validator?.required && (!value || rangeState.high === "" || rangeState.low === "")) {
              return textData.Components.Form.RangeInputFields.NoData[langState]
            }
            if (+rangeState.high < +rangeState.low) {
              return textData.Components.Form.RangeInputFields.LowBound[langState]
            }
            return true;
          }
        })
      }
    >
      <input
        disabled={disabled !== undefined ? disabled : false}
        value={rangeState.low}
        placeholder={placeholder}
        name={`${name}-low`}
        id={`${name}-low`}
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" } min-w-[80px] grow`}
        onChange={ e => {
          const input = e.currentTarget.value;
          if (input === "") { // if input field is cleared, set parent state to null
            setRangeState({low: "", high: rangeState.high}); // this is simply update UI state as normal
            setValue(null);
          } else { // if the field isn't empty
            if (isStringNumeric(input)) {  // only allow input that are numeric string
              if (rangeState.high === "") { // if the other field is empty, update UI state only
                setRangeState({low: input, high: ""});
              } else { // update UI state and change input type to number and set to parent
                setRangeState({low: input, high: rangeState.high});
                setValue({low: -(-input), high: -(-rangeState.high)})
                if (+rangeState.high > +input) clearErrors(name)
              }
            }
          }
        }}
        readOnly={readOnly}
      />
      <div className="w-[10px] mx-3 h-[2px] bg-text"></div>
      <input
        disabled={disabled !== undefined ? disabled : false}
        type={"number"}
        value={rangeState.high}
        placeholder={placeholder}
        name={`${name}-high`}
        id={`${name}-high`}
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" } min-w-[80px] grow`}
        onChange={ e => {
          const input = e.currentTarget.value;
          // same logic as above
          if (input === "") { 
            setRangeState({low: rangeState.low, high: ""});
            setValue(null);
          } else {
            if (isStringNumeric(input)) {
              if (rangeState.low === "") {
                setRangeState({low: "", high: input});
              } else {
                setRangeState({low: rangeState.low, high: input});
                setValue({low: -(-rangeState.low), high: -(-input)})
                if (+input > +rangeState.low) clearErrors(name)
              }
            }
          }
        }}
        readOnly={readOnly}
      />
    </div>
  )
}