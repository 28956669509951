import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType, useAsync } from "../../utilities/customHooks";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import { getExpertAssesmentStats } from "../../services/ipExpertService";
import { useContext } from "react";
import List from "../../Components/List";
import * as T from "../../utilities/frontendTypes";


const IpExpertDashboard = () => {

  const navigate = useNavigate();
  const columnHeaderData = [
    {
      header: "Startup",
      key: "companyName"
    },
    {
      header: "Status",
      key: "actionItem",
      sortOptions: ["New Assessment Request", "Ongoing Assessment", "Submitted Assessment", "Complete"]
    },
    {
      header: "Last Updated",
      key: "lastUpdated"
    },
    {
      header: "Contact",
      key: "contactPerson"
    }
  ]

  const founderDetailsAsync = useAsync(() => getExpertAssesmentStats(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const handleViewMemberDetails = (memberHeaderUserId: string) => {
    navigate((`/dashboard/expert-assessments/${memberHeaderUserId}`));
  }

  const contentArray = founderDetailsAsync.value.foundersArray;

  return (
    <div className="resourcesCont !pt-0 h-full">
      <h2 className="">Welcome Back, {founderDetailsAsync.value.name}</h2>
      <List 
        contentArray={contentArray}
        columnHeaderData={columnHeaderData}
        returnDetailsId={handleViewMemberDetails}
        detailsIdName="memberId"
        isReverse
      />
    </div>
  )
}

export default IpExpertDashboard