import ContentPageTopButtons from "../Components/ContentPageTopButtons";
import { DetailsContentHeader } from "./AdminPages/ProfilePages/NewAdmin";
import textData from "../textData.json";
import { LangContext, LangContextType } from "../utilities/customHooks";
import { useContext } from "react";

// [TODO]
export const nonDashboardPageContainerStyle = "flex flex-col w-contentWidth max-w-maxContentWidth h-full items-center text-text";

function NotAuthorizedPage() {
  const { langState } = useContext(LangContext) as LangContextType
  return (
    <div className={nonDashboardPageContainerStyle} >
      <ContentPageTopButtons />
      <div>
        <DetailsContentHeader label="403" />
      </div>
      <p>{textData.Pages.NotAuthorizedPage[langState]}</p>
    </div>
  )
}

export default NotAuthorizedPage;