import { useState, ChangeEvent, useContext, useEffect } from 'react';
import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from '../../utilities/customHooks';
import { returnUserId } from '../../services/founderService';
import { inputClasses } from './InputField';
import Button from '../Button';
import instance from "../../middleware/HTTPInterceptors";
import LoadingIndicator from '../LoadingIndicator';
import ErrorIndicator from '../ErrorIndicator';
import textData from "../../textData.json";


interface ChildComponentProps {
  handlePdfStateChange: () => void;
}

const FileUpload: React.FC<ChildComponentProps>  = ( {handlePdfStateChange} ) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const { langState } = useContext(LangContext) as LangContextType
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const getId = useAsync(() => returnUserId(), []);
  const [uploaded, setUploaded] = useState(false)

  useEffect(() => {
    if (selectedFile && selectedFile.type === 'application/pdf') {
      handleFileUpload()
    }
  },[selectedFile])


  if (getId.status === "pending") {
    return <LoadingIndicator />;
  }

  if (getId.status === "rejected") {
    return <ErrorIndicator />;
  }

  const id = getId.value.founderId;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type === 'application/pdf') {
        setSelectedFile(file);
      } else {
        alert('Please select a PDF file.');
        setSelectedFile(null);
      }
    }
  };

  const handleFileUpload = async () => {

    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }

    const file = new FormData();

    if (selectedFile) {
      file.append('file', selectedFile);
      file.append('userId', id); // Add userId to form data

    showLoading(); 
    const response = await instance.post("/api/public/upload", file, {
        headers: {
        'Content-Type': 'multipart/form-data'}
    });
    hideLoading();
    if (response.status === 200) {
      handlePdfStateChange()
      showToast(
        {
          toastType: "success",
          message: "Pitch Deck Successfully Uploaded!"
        }
      )
    } else {
      showToast(
        {
          toastType: "failure",
          message: "Unable to Upload, please try again.  If the error persists please contact support."
        }
      )
    }
  }
}

  return (
    <div className='flex flex-col'>
      <label htmlFor="PitchDeckUpload" className="pdfInput">
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"><path d="M9.73791 24.7472C-0.144359 27.8678 2.19667 42.4313 12.5986 42.4313H22.2204L20.14 45.8121C13.6386 45.8121 8.1772 46.8522 3.75637 42.1714C-4.04538 34.6302 1.15591 21.8873 11.5581 20.8467C13.3786 17.4659 16.7591 14.8654 20.6596 13.8252C24.3002 -2.55839 46.6649 -4.63891 53.4261 10.4444C54.9864 13.565 55.5065 17.4659 54.7265 21.1068C68.7691 25.0077 66.169 45.8118 51.6059 45.8118H43.8042L41.7238 42.431C47.9652 42.431 60.4477 44.2515 60.4477 33.3295C60.4477 22.9277 49.5256 26.0481 50.826 21.8871C54.2068 10.7051 43.2847 0.562311 32.3618 4.72334C28.7212 6.28362 25.8605 9.40418 24.5601 13.3052C33.6615 13.3052 31.0614 18.2463 28.461 17.4659C21.9596 15.1253 14.4184 19.0262 12.8582 26.0478C12.598 28.1282 8.9573 27.8683 9.73762 24.7474L9.73791 24.7472ZM32.3624 36.4495L40.4239 49.4522L32.8827 41.6504V63.7546H31.3224V41.6504L23.7811 49.4522L32.1032 35.6694L32.3634 36.4497L32.3624 36.4495Z" fill="#678175"/></svg>
      <p className='text-cSecondaryGreen bodyLarger'>{textData.Pages.FounderPages.FounderProfile.Drag[langState]} <span className='underline hover:cursor-pointer'>{textData.Pages.FounderPages.FounderProfile.Browse[langState]}</span></p>
      <p className='text-cSecondaryGreen'>{textData.Pages.FounderPages.FounderProfile.Pdf[langState]}</p>
    </label>
      <input 
        id="PitchDeckUpload"
        className="pdfInput mb-2"
        type="file"
        accept="application/pdf" 
        onChange={handleFileChange}
      />
      <div className='m-auto'>
        {/* <Button 
          buttonText='Upload'
          actionHandler={handleFileUpload} 
          
          /> */}
      </div>
    </div>
  );
};

export default FileUpload;
