import { useFormContext } from "react-hook-form";
import * as T from "../../utilities/frontendTypes";
import CheckboxWithText from "./CheckboxWithText";
import { FieldValidator } from "./Form";
import { Tag } from "../Tag";
import Tooltip from "../Tooltip";
import Button from "../Button";
import { generateId } from "../../utilities/helperFunctions";
import { useContext, useEffect, useState } from "react";
import { LangContext, LangContextType, ToastContext, ToastContextType } from "../../utilities/customHooks";
import IconButton from "../IconButton";
import { faChevronRight, faX } from "@fortawesome/free-solid-svg-icons";
import { disabledInputClasses, inputClasses } from "./InputField";
import textData from "../../textData.json";

export default function CheckboxMultiSelect(
  {
    name,
    value,
    setValue,
    multiSelectData,
    isViewOnly,
    disabled,
    readOnly,
    validator,
    enableCustomSelections,
  }
  :
  {
    name: string,
    value: string[],
    setValue: (value: string[]) => void,
    multiSelectData: T.SelectionData[],
    isViewOnly?: boolean,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
    enableCustomSelections?: boolean,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const { register, clearErrors } = useFormContext();
  const [input, setInput] = useState("");
  const [displayCustomInputField, setDisplayCustomInputField] = useState(false);

  const handleCheckboxToggle = (checkboxKey: string, checkboxValue: boolean) => {
    const customSelection = customSelections.find( selectionData => selectionData.value === checkboxKey);

    const updatedStateValue = [...value];
    const targetKey = customSelection ? customSelection.text : checkboxKey;
    if (checkboxValue) {
      updatedStateValue.push(targetKey); // add checked key into stateArray
    } else {
      updatedStateValue.splice(updatedStateValue.indexOf(targetKey), 1); // remove checked key from stateArray
    }

    if (updatedStateValue.length > 0) {
      clearErrors(name);
    }

    setValue(updatedStateValue);

  }

  const isValueEmpty = value.length === 0;

  const checkboxesHTML: JSX.Element[] = []; // For edit mode
  const checkedTagsHTML: JSX.Element[] = []; // For viewOnly mode

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => { // After DB data is loaded, re-calculate the initialCustomSelectionState
    if (!isLoaded && initialCustomSelectionState.length !== 0) {
      setCustomSelections(initialCustomSelectionState)
      setIsLoaded(true);
    }
  }, [value]);
  const multiSelectValues = multiSelectData.map( checkboxData => checkboxData.value);
  // [TODO] O(n^2), potential performance issue 
  // if the text value coming from DB is not part of the formDefinition, then it must've been a custom value
  const initialCustomValues = value.filter( text => !multiSelectValues.includes(text));
  const initialCustomSelectionState = initialCustomValues.map( text => ({ text, value: generateId()}));

  const [customSelections, setCustomSelections] = useState<T.SelectionData[]>([]); // UI state for additional checkboxes management
 
  // Helper function for multiSelect specifically
  const pushToHTML = (key: string, text: string, isChecked: boolean, isRemovable?: boolean) => {
    checkboxesHTML.push(
      <div
        className=""
        key={key}
      >
        <CheckboxWithText
          name={key} 
          text={text}
          value={isChecked} 
          setValue={handleCheckboxToggle}
          disabled={disabled}
          readOnly={readOnly}
        />
        {
          isRemovable &&
          <IconButton
            icon={faX} 
            actionCallback={() => handleRemoveItemById(key)} // Naming this "..ByID" because the "value" is the temp generated id
            style="text-text/70 hover:text-text w-[10px] pl-3"
          />
        }
      </div>
    );

    if (isChecked) checkedTagsHTML.push(<Tag key={key} text={text} style="mr-2" />);
  }

  // Map values based on formDefinition 
  multiSelectData.forEach( checkboxData => {
    const key = checkboxData.value; // Coming from formDefinitionTemplates/additionalData/selectionData
    const isChecked = !isValueEmpty ? value.includes(key) : false;
    const text = checkboxData.text;
    pushToHTML(key, text, isChecked);
  });

  // Map values based on initial customSelectionState
  customSelections.forEach( checkboxData => {
    const key = checkboxData.value;
    const text = checkboxData.text;
    const isChecked = initialCustomValues.includes(text)
    pushToHTML(key, text, isChecked, true);
  });

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const handleRemoveItemById = (id: string) => {
    const filteredCustomSelections = customSelections.filter( selectionData => selectionData.value !== id);
    setCustomSelections(filteredCustomSelections);
    const filteredValues = value.filter( text => text !== customSelections.find( selectionData => selectionData.value === id)?.text);
    setValue([...filteredValues]);
  }

  const multiSelectTexts = multiSelectData.map( checkboxData => checkboxData.text); 
  const handleAddItem = () => {
    if (input !== "" && (!multiSelectValues.includes(input) && !multiSelectTexts.includes(input))) {
      const newItem = {value: generateId(), text: input};
      setCustomSelections( state => [...state, newItem]);
      setValue([...value, input]);
      setInput("");
      clearErrors(name);
    } else if (input === "") {
      showToast({
        toastType: "info",
        message: textData.Components.Form.CheckboxMultiSelect.EmptyField[langState],
      })
    } else {
      showToast({
        toastType: "info",
        message: textData.Components.Form.CheckboxMultiSelect.AlreadySelected[langState],
      })
    }
  }

  return (
    isViewOnly
    ?
    (
      isValueEmpty
      ?
      <div className="flex flex-col">
        <h3>{textData.Components.Form.CheckboxMultiSelect.NoSelection[langState]}</h3>
        <Tooltip content={textData.Components.Form.CheckboxMultiSelect.SelectionTooltip[langState]} position="bottom-right"/>
      </div>
      :
      <div>
        {checkedTagsHTML}
      </div>
    )
    :
    <div className="flex flex-col"
      {
        ...register(name, {
          validate: () => {
            if (validator?.required && isValueEmpty) {
              return textData.Components.Form.CheckboxMultiSelect.MakeSelection[langState];
            }
            return true;
          },
        })
      }
    >
      {checkboxesHTML}
      {
        enableCustomSelections && 
        // <div
        //   className="mx-2"
        // >
        //   <IconButton 
        //     icon={faChevronRight} 
        //     actionCallback={() => setDisplayCustomInputField(s => !s)}
        //     style={`!w-[13px] !h-[13px] transition ${ displayCustomInputField ? "rotate-90" : "rotate-0"}`} 
        //   />
        //   <span className="ml-5">{textData.Components.Form.CheckboxMultiSelect.Other[langState]}</span>
        // </div>
        <div className='flex items-center my-2'>
        <input 
          type="checkbox" 
          checked={displayCustomInputField}
          readOnly={readOnly} 
          disabled={disabled} 
          onChange={() => setDisplayCustomInputField(s => !s)}
          onKeyDown={(e) => { if (e.key === "Enter") setDisplayCustomInputField(s => !s)}}
        />
        <p className="ml-5">{"Other"}</p>
      </div>
      }
      {
        enableCustomSelections &&
        <div className={
          `
            flex w-full 
            
            ${
              displayCustomInputField ? 
              "opacity-1 mt-1 h-auto [&>button]:py-1 [&>button]:px-3 [&>button]:border-2 [&>input]:p-2 [&>input]:border-1" : 
              "opacity-0 h-0 mt-1 [&>button]:py-0 [&>button]:border-0 [&>input]:py-0 [&>input]:border-0"
            }
          `
        }>
          <Button
            buttonType={"primary"} 
            buttonText={textData.Components.Form.CheckboxMultiSelect.Add[langState]}
            actionHandler={() => handleAddItem()}
            style="!mr-2"
          />
          <input 
            className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
            onChange={(e) => setInput(e.currentTarget.value)}
            value={input}
          />
        </div>
      }
    </div>
  )
}