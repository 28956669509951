import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

function TempExpertDashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/dashboard/expert-verification");
    }, []) 
    return (
      <div className='text-text'>Please Go to Verification</div>
    )
  }
  
  export default TempExpertDashboard