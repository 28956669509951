import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from "../utilities/customHooks";
import { getRegionAdminNotes, addNewNote } from "../services/ipExpertService";
import moment from "moment";
import { useContext, useEffect, useState } from "react";

const CompanyNotes = ({id}:{id: string}) => {
    const [notesHtml, setNotesHtml] = useState(<></>)
    const [noteCount, setNoteCount] = useState(true)
    // Move useContext outside of conditional blocks
    const toastContext = useContext(ToastContext) as ToastContextType;
    const loadingContext = useContext(LoadingContext) as LoadingContextType;
    const {showToast} = toastContext;
    const {showLoading, hideLoading} = loadingContext;
    const [text, setText] = useState('');
    const notesAsync = useAsync(() => getRegionAdminNotes(id), [id, noteCount]);

    const handleChange = (event: { target: { value: any; }; }) => {
      setText(event.target.value);
    };
    
    useEffect(() => {
      if (notesAsync.status === "pending") {
        showLoading();
      } else {
        hideLoading();
      }
    }, [notesAsync.status, showLoading, hideLoading]);
  
    useEffect(() => {
      if (notesAsync.status === "resolved") {
        const notes = notesAsync.value.notes;

        if (notes.length > 0) {
          const parsedNotes = notes.map(note => ({
                ...note,
                date: new Date(note.date)
            }));
          const sortedNotes = parsedNotes.sort((a, b) => b.date.getTime() - a.date.getTime());
          const html = (
            <div>
              {sortedNotes.map((note, index) => (
                <div key={index}>
                  <div className="flex justify-between w-full mb-2">
                    <p className="font-semibold">{note.author}</p>
                    <p>{moment(note.date).format('MMM Do YYYY').toString()}</p>
                  </div>
                  <div className="flex mt-2 mb-2">
                    <span className="notesLine">{note.notes}</span>
                  </div>
                </div>
              ))}
            </div>
          )
          setNotesHtml(html);
        } else {
          setNotesHtml(<p>Currently no notes for this company</p>);
        }
      }
    }, [notesAsync.status, id, noteCount]);
  
    const handleAddNote = async (note: string) => {
      showLoading();
      const response = await addNewNote(id, note)
      hideLoading();
      if (response.success) {
        showToast({
          toastType: "success",
          message: "Note Added!",
        });
        //RERENDER WHOLE COMPONENET INCLUDING ORIGINAL FETCH FUNCTIONS AND TEXTAREA
        setText("")
        setNoteCount(!noteCount)
      }
    }

    return (
      <div className="flex flex-col w-full">
        <form id="notes" className="flex flex-wrap w-full items-center relative mb-2">
          <label className="bodyLarger w-full" htmlFor="notes">
            <p className="font-semibold bodyLarger"></p>
            <textarea value={text} onChange={handleChange} placeholder="Write your note..." id="notes" className=" w-full input bodyLarger h-fit !pr-12 !pb-8" name="notes"></textarea>
          </label>
          <button onClick={() => handleAddNote(text)} type="button" className="absolute bottom-4 right-3">
            <span className="inline">Add</span><svg className="ml-1 mb-1 inline" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none"><path d="M15.0934 0.6338L0.461944 6.79892C0.169364 6.9228 -0.0125092 7.20835 0.000671281 7.52553C0.0138504 7.84535 0.218566 8.11245 0.522561 8.2091L6.30983 10.0673L6.49434 13.3982C6.51191 13.7444 6.73683 14.0414 7.06808 14.1538C7.16033 14.1854 7.25258 14.2013 7.34484 14.2013C7.58558 14.2013 7.8193 14.0976 7.98623 13.9069L9.42538 12.2472L12.1402 14.2285C12.3115 14.3524 12.511 14.4174 12.7157 14.4174C12.8369 14.4174 12.9582 14.3946 13.0777 14.348C13.3905 14.2241 13.6154 13.9517 13.6787 13.6196L15.9886 1.35258C16.0334 1.11623 15.9438 0.875499 15.7567 0.725251C15.5678 0.575889 15.3139 0.539789 15.0934 0.6338ZM13.6587 2.21527L6.62104 9.22385L1.17542 7.47632L13.6587 2.21527ZM7.38803 13.2277L7.24657 10.6586L8.69715 11.7182L7.38803 13.2277ZM12.7923 13.4544C12.7879 13.4816 12.772 13.5018 12.7448 13.5132C12.7176 13.5247 12.693 13.5203 12.671 13.5045L7.43457 9.68257L14.9018 2.24439L12.7923 13.4544Z" fill="#1A2E25"/></svg>
          </button>
        </form>
        <div>
        {notesHtml}

        </div>
      </div>
    )
  }

export default CompanyNotes

