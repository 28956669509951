import * as T from "../utilities/frontendTypes";

export type ButtonProp = {
  buttonText: string,
  actionHandler: (obj?: any) => void, // 'any' here is intentional due to a button has no knowledge about the obj that it's passing back to parent
  buttonType?: ButtonType,
}
export type ButtonType = T.ActionTypes | ('primary-inverse' | 'secondary-inverse') | "primaryFull" | "secondaryFull";

export default function Button(
  {
    buttonType,
    buttonText,
    actionHandler,
    disabled,
    size,
    style,
  }
  :
  {
    buttonText: string,
    actionHandler: () => void,
    buttonType?: ButtonType,
    disabled?: boolean,
    size?: "small" | "large",
    style?: string,
  }
) {

  const buttonTypeClassNames = () => {
    switch(buttonType) {
      case "primary":
        return `primaryButton ${disabled ? "primaryButtonDisabled" : "" }`;
      case "primaryFull":
          return ` w-full primaryButton ${disabled ? "primaryButtonDisabled" : "" }`;
      case "primary-inverse":
        return `bg-background border-primary text-primary ${disabled ? "" : "hover:bg-primary/10" }`;
      case "secondary":
        return `clearButton ${disabled ? "clearButtonDisabled" : "hover:bg-secondary/10"}` ;
        case "secondaryFull":
          return `clearButton w-full`;
      case "secondary-inverse":
        return `clearButton border-white text-white ${disabled ? "" : "hover:bg-secondary/10" }`;
      case "tertiary":
        return `border-0 text-text shadow-none ${disabled ? "" : "hover:shadow-md" }`;
      case "danger":
        return `border-2 border-danger text-danger ${disabled ? buttonDisabledClassNames : "hover:bg-danger hover:text-white"}`;
      default:
        throw new Error("Unknown button type");
    }
  }

  const buttonDisabledClassNames = "cursor-not-allowed";
  let sizeStyle = "p-1 px-3 text-md";
  if (size === "small") sizeStyle = "p-[1px] px-[8px]";
  if (size === "large") sizeStyle = "p-1 px-3 text-md w-full";

  return (
    <button
      type="button"
      disabled={disabled}
      className={
        `
          ${buttonTypeClassNames()}
          ${disabled && buttonDisabledClassNames}
          ${sizeStyle}
          ${style ? style : ""}
        `
      }
      onClick={ actionHandler }
    >
      {buttonText}
    </button>
  )
}

Button.defaultProps = {
  buttonType: "primary",
};
