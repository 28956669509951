import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getFounderApplicationHeaders } from "../../../services/founderService";
import { LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import List, { KeyValuePair } from "../../../Components/List";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../../AdminPages/ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../../AdminPages/ProfilePages/NewAdmin";
import { useContext, useEffect, useState } from "react";
import * as T from "../../../utilities/frontendTypes";
import { getLastPartOfPath } from "../../../utilities/helperFunctions";
import textData from "../../../textData.json";

function FounderApplications() {
  const { langState } = useContext(LangContext) as LangContextType

  const location = useLocation();

  useEffect(() => {
    setIsDetailsViewOpen(location.pathname !== "/dashboard/enrolled-services");
  }, [location]);

  const [isDetailsViewOpen, setIsDetailsViewOpen] = useState(false);
  const handleViewApplicationDetails = (id: T.ApplicationId) => {
    navigate(`/dashboard/founder-applications/${id}`, {state: {from: location.pathname}})
  }

  const navigate = useNavigate();
  const founderApplicationHeadersAsync = useAsync(() => getFounderApplicationHeaders(), [location]);

  if (founderApplicationHeadersAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderApplicationHeadersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  let applicationHeaders = founderApplicationHeadersAsync.value.founderApplicationHeaders;

  const columnHeaderData = [
    {
      header: textData.Pages.FounderPages.ProgramPages.FounderApplications.Col1[langState],
      key: "programName",
    },
    {
      header: textData.Pages.FounderPages.ProgramPages.FounderApplications.Col2[langState],
      key: "status",
      sortOptions: ["pending", "approved", "rejected"],
    },
    {
      header: textData.Pages.FounderPages.ProgramPages.FounderApplications.Col3[langState],
      key: "dateCreated",
    }
  ];

return (
  <div className={contentPageContainerStyle}>
    <div className={contentPageWrapperStyle}>
      <ContentPageTopButtons />
      <DetailsContentHeader 
        label={textData.Pages.FounderPages.ProgramPages.FounderApplications.Header[langState]}
        tooltip={textData.Pages.FounderPages.ProgramPages.FounderApplications.Tooltip[langState]}
      />
      <List 
        contentArray={applicationHeaders as KeyValuePair[]}
        columnHeaderData={columnHeaderData}
        detailsIdName="applicationId"
        returnDetailsId={handleViewApplicationDetails}
        listHighlightId={getLastPartOfPath(location.pathname)}
      /> 
    </div>
    {/* Program Application Details Route */}
    <Outlet />
  </div>
)
}

export default FounderApplications