import instance from '../middleware/HTTPInterceptors';
import ErrorResponse from '../utilities/ErrorResponse';
import { HTTPResponse } from '../utilities/frontendTypes';
import * as T from "../utilities/frontendTypes";


type AutocompleteRequestBodyType = {
  input: string
}
export async function getAutocompleteByInput(input: string): Promise<HTTPResponse> {

  try {

    const reqBody: AutocompleteRequestBodyType = {
      input
    }

    const res = await instance.post("/api/public/places", reqBody);
    
    return res.data;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export const getPlaceById = async (placeId: string): Promise<HTTPResponse> => {

  try {

    const res = await instance.get(`/api/public/places/${placeId}`);
    
    return res.data;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
};

type Location = {
  lat: number,
  lng: number,
  province: string, // [TODO] think, should this be province?
}

export const getRegionByLocation = async (location: Location): Promise<T.UserRegion | "notSupported"> => {

  try {

    const res = await instance.post(`/api/public/get-region`, location);

    return res.data.data;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

// [TODO] refactor email: {email: string} 
export async function sendPwResetEmail(email: {email: string}): Promise<T.HTTPResponse> {

  try {
    const res = await instance.post(`/api/auth/forgotpassword`, email);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

// [TODO] refactor password: {password: string} 
export async function resetPassword(resetToken: string, password: {password: string}): Promise<T.HTTPResponse> {

  try {

    const res = await instance.put(`/api/auth/resetpassword/${resetToken}`, password);

    if (res.status === 201) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export const checkEmail = async (email: string): Promise<HTTPResponse> => {

  try {

    const res = await instance.get(`/api/public/check-email/${email}`);
    return res.data;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
};

export const triggerDelayedResponse = async () => {
  try {

    const res = await instance.get(`/api/public/delayed-response`);

    return res.data;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}