export type Theme = {
  [cssVar: string]: string
}

export function applyTheme(theme: Theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

// [TODO] { color: {}, space: {}... }
export function createTheme(
  {
    primary,
    secondary,
    terteiry,
    background,
    text,
    danger,
    success,
    info,
    highlight,

    primaryLight,
    primaryDark,
    secondaryLight,
    secondaryDark,
    backgroundSecondary,
    backgroundTerteiry,
    buttonText,
    secondaryButtonText
  }
  :
  {
    primary: string,
    secondary: string,
    terteiry: string,
    background: string,
    text: string,
    danger: string,
    success: string,
    info: string,
    highlight: string,

    primaryLight: string,
    primaryDark: string,
    secondaryLight: string,
    secondaryDark: string,
    backgroundSecondary: string,
    backgroundTerteiry: string,
    buttonText: string,
    secondaryButtonText: string
  }
) {
  return {
    // Root Colors
    "--theme-primary": primary,
    "--theme-secondary": secondary,
    "--theme-terteiry": terteiry,
    "--theme-background": background,
    "--theme-text": text,
    "--theme-danger": danger,
    "--theme-success": success,
    "--theme-info": info,
    "--theme-highlight": highlight,

    "--theme-primary-light": primaryLight,
    "--theme-primary-dark": primaryDark,
    "--theme-secondary-light": secondaryLight,
    "--theme-secondary-dark": secondaryDark,
    "--theme-background-secondary": backgroundSecondary,
    "--theme-background-terteiry": backgroundTerteiry,
    "--theme-button-text": buttonText,
    "--theme-secondary-button-text": secondaryButtonText,
  };
}