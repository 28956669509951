import { useFormContext } from "react-hook-form";
import { FieldValidator } from "./Form";
import { disabledInputClasses, inputClasses } from "./InputField";
import textData from "../../textData.json";
import { LangContext, LangContextType } from "../../utilities/customHooks";
import { useContext } from "react";

export default function DropdownSingleSelect(
  {
    placeholder,
    name,
    selections,
    allowCustomValue,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    placeholder?: string,
    name: string,
    selections: {value: string, text: string}[],
    allowCustomValue: boolean,
    value: string | boolean,
    setValue: (value: string | boolean) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const selectionsHTML = selections.map( (selection, index) => {
    return <option value={selection.value} key={index}>{selection.text}</option>;
  });

  const datalistOptionsHTML = selections.map( (selection, index) => {
    return <option value={selection.text} key={index}/>
  })

  const { register, clearErrors } = useFormContext();

  const isReturningBoolean = validator && validator.returnValueType && validator.returnValueType === "boolean";
  const processedValue = typeof value === "boolean" ? (value ? "yes" : "no") : value as string; // [TODO] temp solution for handling boolean selection
  const handleSetValue = (e: any) => { // [TODO] any type
    const returnValue = e.currentTarget.value;
    setValue(isReturningBoolean ? returnValue === "yes" : returnValue)
    clearErrors(name);
  }

  return (
    allowCustomValue
    ?
    <>
      <input 
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
        list={`${name}-option-list`}
        id={name}
        value={value as string}
        disabled={disabled || readOnly}
        {
          ...register(name, {
            // ...validator,
            validate: () => {
              if (validator?.required && value === "") {
                return textData.Components.Form.DropdownSingleSelect.MakeSelection[langState];
              }
              return true;
            },
            onChange: e => handleSetValue(e)
          })
        }
      />
      <datalist id={`${name}-option-list`}>
        <option value={''} disabled hidden>{placeholder}</option>
        { datalistOptionsHTML }
      </datalist>  
    </>
    :
    <div className="relative">
      <select
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
        id={name}
        value={processedValue}
        disabled={disabled || readOnly}
        {
          ...register(name, {
            // ...validator,
            validate: () => {
              if ( validator?.required && value === "") {
                return textData.Components.Form.DropdownSingleSelect.MakeSelection[langState];
              }
              return true;
            },
            onChange: e => handleSetValue(e)
          })
        }
      >
        <option value={''} disabled hidden>{placeholder}</option>
        { selectionsHTML }
      </select>
      {
        !disabled && !readOnly
        &&
        <span className={dropdownArrowClasses}></span>
      }
    </div>
  )
}

export const dropdownArrowClasses = `absolute top-1/2 -translate-y-1/2 right-2 border-4 border-solid border-l-transparent border-r-transparent border-b-0 border-t-terteiry`;