import { getCoachInProgressDetailsAsync } from "../../../services/adminService";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import Form, { FormDefinition, FormData } from "../../../Components/Form/Form";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync, useReloadOnOutletSave, useRequiredParams } from "../../../utilities/customHooks";
import { DetailsContentHeader, detailsContentWrapperStyle } from "../../AdminPages/ProfilePages/NewAdmin";
import { useContext, useEffect, useState } from "react";
import { sendMassEmail, founderStatusUpdateReject } from "../../../services/adminService";
import Button, { ButtonProp } from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import { basic_text_validator } from "../../../utilities/formFieldValidators";
import { inputClasses } from "../../../Components/Form/InputField";
import { dropdownArrowClasses } from "../../../Components/Form/DropdownSingleSelect";
import CompanyNotes from "../../../Components/AdminNotes";
import { useNavigate } from "react-router-dom";

const CoachRecommendationAdminDetails = () => {
  
  const navigate = useNavigate();
  const memberId = useRequiredParams("memberId")
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const [reload, setReload] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expertName, setExpertName] = useState("");
  const [reloadOnSave, setReloadOnSave] = useState(false);
  const { reloadOnOutletSave, setReloadOnOutletSave } = useReloadOnOutletSave();
  const [rejecting, setRejecting] = useState(false)
  const [html, setHtml] = useState(<></>)

  useEffect(() => {
    setHtml(<CompanyNotes id={memberId}/>)
  }, [memberId]);


  const handleConfirmation = async (emailType: string, expertName?: string) => {
    showLoading();
    const res = expertName ? await sendMassEmail([memberId], emailType, expertName) : await sendMassEmail([memberId], emailType);
    hideLoading();
    if (res.success) {
      setReloadOnSave(!reloadOnSave);
      setReloadOnOutletSave(!reloadOnOutletSave)
      showToast({
        toastType: "success",
        message: "Invitation Successfully Sent!",
      });
      navigate('/dashboard/admin-dd-application');
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to proceed. If Error persists please conteact support",
      });
    }
  }

  const rejectForm: FormDefinition = {
    notes: {
      label: "Please add a note for the Company regaring why they are not able to move forward within the EIP Program",
      inputType: "textarea",
      validator: basic_text_validator,
    }
  }
  
  const rejectCompany = async (updatedFormData: FormData) => {
    const tempNotes = updatedFormData as any
    const notes = tempNotes.notes
    showLoading();
    const res = await founderStatusUpdateReject(memberId, notes)
    hideLoading();
    if (res.success) {
      showToast({
        toastType: "success",
        message: "Invitation Successfully Sent!",
      });
      navigate("/dashboard");
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to proceed. If Error persists please conteact support",
      });
    }  
  }


  const primaryButtonPropDD: ButtonProp = {
    buttonText: "Save",
    actionHandler: rejectCompany,
  }

  const handleDropdownChange = (e: any) => {
    setExpertName(e.target.value);
  };
  
  const modalHTML = (
    rejecting 
    ?
    <div className="mt-2 mb-2">
      <Form
        formDefinition={rejectForm}
        formData={null}
        primaryButtonProp={primaryButtonPropDD}
      />
    </div>
    :
    <div className="flex flex-col justify-center items-center">
      <div className="relative mb-5 w-full">
        <select 
          id="dropdown" 
          value={expertName} 
          onChange={handleDropdownChange}
          className={inputClasses}
        >
          <option value="" disabled>Select an IP Expert:</option>
          <option value="alexis">Alexis Conrad</option>
          <option value="jivitesh">Jivitesh Chawla</option>
        </select>
        <span className={dropdownArrowClasses}></span>
      </div>
      <Button
        buttonText="IP Expert Invite"
        actionHandler={() => handleConfirmation("expert", expertName)}
        disabled={expertName === "" ? true : false}
      />
    </div>
  )

  const rejectingModal = () => {
    setIsModalOpen(true)
    setRejecting(true)
  }

  const expertModal = () => {
    setIsModalOpen(true)
    setRejecting(false)
  }


  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        closeCallback={() => navigate('/dashboard/admin-dd-application')}   
      />
      <DetailsContentHeader 
        label="Company Notes"
      />
        {html}
        <div className="flex w-full justify-between mt-10">
          <Button
            buttonText="IP Expert Invite"
            actionHandler={() => expertModal()}
          />
          <Button
            buttonText="Decline Company for EIP"
            actionHandler={() => rejectingModal()}
          />
          <Modal 
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            modalHeader={"Which IP Expert are you inviting selected companies to meet?"}
            contentLabel=""
            modalHTML={modalHTML}
          />
      </div>
    </div>
  )
}

export default CoachRecommendationAdminDetails