import { useFormContext } from "react-hook-form";
import { isStringNumeric } from "../../utilities/helperFunctions";
import { FieldValidator } from "./Form";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { checkEmail } from "../../services/publicService";
import { LangContext, LangContextType, LoadingContext, LoadingContextType } from "../../utilities/customHooks";
import textData from "../../textData.json";

export default function InputField(
  {
    name,
    placeholder,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
    validate,
  }
  :
  {
    name: string,
    placeholder?: string,
    value: string | number,
    setValue: (value: string | number) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator: FieldValidator,
    validate?: (value: string) => string | undefined, 
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const { register, clearErrors, setError } = useFormContext();
  
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const fieldType = validator.fieldType;

  const [showPassword, setShowPassword] = useState(false); // State for password visibility; only being used when fieldType is password

  // solution to the issue where react-form-hook always show required error on pre-populated fields
  useEffect(() => {
    register(name, validator);
    if (value) {
      clearErrors(`${name}`);
      register(`${name}`, { required: false });
    }
  }, [value]);

  const isDisplayLowerCase = fieldType === "uniqueEmail" || fieldType === "email";

  const handleInputChange = (e: any) => { // Intentional any type
    const newValue = e.currentTarget.value;
    if (fieldType === "number" && !isStringNumeric(newValue)) return;
    if (fieldType === "uniqueEmail") {
      clearErrors('duplicateEmail')
    }
    setValue(isDisplayLowerCase ? (newValue as string).toLocaleLowerCase() : newValue);

  }

  const checkDuplicateEmail = async (email: string | number) => {
    if (email) {
      showLoading();
      const response = await checkEmail(email as string);
      hideLoading();
      if (!response.success) {
        setError('uniqueEmail', { type: 'custom', message: textData.Components.Form.InputField.EmailInUse[langState] });
      } 
      else if (response.success) {
        clearErrors('duplicateEmail')
      }
    }
  }

  return (
    <div className="relative flex items-center">
      <input
        disabled={disabled || readOnly}
        type={fieldType === "password" ? (showPassword ? "text" : fieldType) : fieldType}
        value={value}
        placeholder={placeholder}
        id={name}
        className={`${inputClasses} border-cSecondaryGreen border ${(disabled || readOnly) ? disabledInputClasses : "" }`}
        readOnly={readOnly}
        {
          ...register(name, {
            onChange: e => handleInputChange(e),
            validate: validate,
            ...(name === 'uniqueEmail' ? { onBlur: () => checkDuplicateEmail(value)} : {}),
          })
        }
      />
      { 
        fieldType === "password" &&
        <button
          className="absolute right-[0.5rem] hover:cursor-pointer"
          onClick={ () => setShowPassword(!showPassword) }
        >
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Show Icon" clipPath="url(#clip0_2274_6199)">
          <path id="Vector" d="M15.6204 7.13891C15.8099 7.37516 15.9148 7.6821 15.9148 8.00035C15.9148 8.3186 15.8099 8.62554 15.6204 8.86179C14.4204 10.3147 11.689 13.1432 8.50038 13.1432C5.31181 13.1432 2.58038 10.3147 1.38038 8.86179C1.19085 8.62554 1.08594 8.3186 1.08594 8.00035C1.08594 7.6821 1.19085 7.37516 1.38038 7.13891C2.58038 5.68603 5.31181 2.85742 8.50038 2.85742C11.689 2.85742 14.4204 5.68603 15.6204 7.13891Z" stroke="#74817B" strokeLinecap="round" strokeLinejoin="round"/>
          <path id="Vector_2" d="M8.50056 10.2853C9.76292 10.2853 10.7863 9.26195 10.7863 7.99958C10.7863 6.73722 9.76292 5.71387 8.50056 5.71387C7.23819 5.71387 6.21484 6.73722 6.21484 7.99958C6.21484 9.26195 7.23819 10.2853 8.50056 10.2853Z" stroke="#74817B" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_2274_6199">
          <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
          </clipPath>
          </defs>
          </svg>
        </button>
      }
    </div>
  )
}

export const inputClasses = "w-full bodyLarger input"
export const disabledInputClasses = "!cursor-not-allowed"
