import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getCoachPreMeetingDetailsAsync } from "../../../services/adminService";
import { useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"
import List from "../../../Components/List";
import { useNavigate } from "react-router-dom";

const CoachPreMeeting = () => {

  const preMeetingFoundersAsync = useAsync(() => getCoachPreMeetingDetailsAsync(), []);
  const navigate = useNavigate();

  if (preMeetingFoundersAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (preMeetingFoundersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const preMeetingFoundersArray = preMeetingFoundersAsync.value.preMeetingFoundersArray

  const columnHeaderData = [
    {
      header: "Company Name",
      key: "companyName"
    },
    {
      header: "Invite Date",
      key: "date"
    },
  ]

  const handleViewFounderDetails = (id: string) => {
    navigate(`/dashboard/admin-coach-premeeting/${id}`);
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={"Companies Invited to Meet a Growth Coach"}
          tooltip={"This is a list of all the companies who have been invited to meet a growth coach"}
        />
        <List 
          contentArray={preMeetingFoundersArray}
          columnHeaderData={columnHeaderData}
          returnDetailsId={handleViewFounderDetails}
          detailsIdName="id"
        />
      </div>
    </div>  
  )
}

export default CoachPreMeeting