import { useFormContext } from "react-hook-form";
import { FieldValidator } from "./Form";
import { useContext, useEffect, useState } from "react";
import { generateId } from "../../utilities/helperFunctions";
import Tooltip from "../Tooltip";
import Button from "../Button";
import { LangContext, LangContextType, ToastContext, ToastContextType } from "../../utilities/customHooks";
import { Tag } from "../Tag";
import { disabledInputClasses, inputClasses } from "./InputField";
import textData from "../../textData.json";

// [TODO] Error handling is set to always required for now; a overhaul of "validator" approach is needed down the road.
export default function CustomList(
  {
    name,
    value,
    setValue,
    isViewOnly,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    name: string,
    value: string[],
    setValue: (value: string[]) => void,
    isViewOnly?: boolean,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const isValueEmpty = value.length === 0;

  useEffect(() => {
    if (!isLoaded && !isValueEmpty) {
      setKVPairs(value.map( s => ({[generateId()]: s})))
      setIsLoaded(true);
    }
  }, [value]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [input, setInput] = useState("");
  const [KVPairs, setKVPairs] = useState<{[x: string]: string}[]>([]);
  const { register, clearErrors } = useFormContext();

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const handleRemoveItemById = (id: string) => {
    const filteredPairs = KVPairs.filter(pair => Object.keys(pair)[0] !== id);
    setKVPairs(filteredPairs);
    const filteredValues = filteredPairs.map( pair => Object.values(pair)[0]);
    setValue(filteredValues);
  }

  const handleAddItem = () => {
    if (input !== "") {
      const newItem = {[generateId()]: input};
      setKVPairs( state => [...state, newItem]);
      setValue([...value, input]);
      setInput("");
      clearErrors(name);
    } else {
      showToast({
        toastType: "info",
        message: textData.Components.Form.CustomList.EmptyField[langState],
      })
    }
  }

  const noEntryFeedbackEle = (
    <div className="flex">
      {/* <p>{textData.Components.Form.CustomList.NoEntry[langState]}</p>
      <Tooltip content={textData.Components.Form.CustomList.NoEntryTooltip[langState]} position="bottom-right"/> */}
    </div>
  );

  return (
    isViewOnly 
    ?
    <div className="flex">
      {
        isValueEmpty
        ?
        noEntryFeedbackEle
        :
        KVPairs.map( pair => {
          const key = Object.keys(pair)[0];
          return (
            <div className="mr-2" key={key}>
              <Tag text={pair[key]} />
            </div>
          )
        })
      }
    </div>
    :
    <div
      {
        ...register(name, {
          validate: () => {
            if (isValueEmpty) {
              return textData.Components.Form.CustomList.AddEntryPrompt[langState];
            }
            return true;
          },
        })
      }
    >
      { 
        isValueEmpty
        ?
        noEntryFeedbackEle
        :
        <div className="flex">
          {
            KVPairs.map( pair => {
              const key = Object.keys(pair)[0];
              return (
                <div className="mr-2" key={key}>
                  <Tag 
                    text={pair[key]} 
                    isRemovable={!disabled} 
                    removeTagCallback={ () => handleRemoveItemById(key)} 
                  />
                </div>
              )
            })
          }
        </div>
      }
      {
        !disabled &&
      <div className="flex mt-3">
        <Button 
          buttonType={"primary"} 
          buttonText={textData.Components.Form.CustomList.AddEntryPrompt[langState]} 
          actionHandler={() => handleAddItem()}
          style="!mr-6"
        />
        <input 
          className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
          onChange={(e) => setInput(e.currentTarget.value)}
          value={input}
        />
      </div>
      }
    </div>
  )
}