import { useNavigate } from "react-router-dom";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, useAsync, useRequiredParams } from "../../../utilities/customHooks";
import { getFounderProgramApplicationDetails, getProgramDetails } from "../../../services/founderService";
import { convertFounderProfileDBStateToUIState } from "../../../utilities/stateConverters";
import * as T from "../../../utilities/frontendTypes";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import FormGroup from "../../../Components/FormGroup";
import { founderProfileFormGroupDefinition } from "../../../utilities/formDefinitionTemplates";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../../AdminPages/ProfilePages/NewAdmin";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import Form from "../../../Components/Form/Form";
import { useContext, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import textData from "../../../textData.json";

const FounderProgramApplicationDetails = () => {
  const { langState } = useContext(LangContext) as LangContextType
  const applicationId = useRequiredParams("applicationId");
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const [childClickedAccordionItemIndex, setChildClickedAccordionItemIndex] = useState(0);

  const [applicationDetails, setApplicationDetails] = useState<T.ApplicationDetails | null>(null);
  const [programDetailsRes, setProgramDetailsRes] = useState<T.ProgramDetailsResponse | null>(null);
  useEffect(() => {
    try {
      
      const fetchApplicationAndProgramDetails = async () => {
        showLoading();
        const applicationDetailsRes =  await getFounderProgramApplicationDetails(applicationId);
        
        const applicationDetails = applicationDetailsRes.applicationDetails;
        setApplicationDetails(applicationDetails);
        
        const programDetailsRes = await getProgramDetails(applicationDetails.programId);
        setProgramDetailsRes(programDetailsRes);

        hideLoading();
      }
      
      fetchApplicationAndProgramDetails().catch(console.error);
      
    } catch (err) {
      console.error(err);
    }
  }, [applicationId]);

  if (applicationDetails === null || programDetailsRes === null) {
    return <LoadingIndicator />;
  }

  const founderProfileGroupData = convertFounderProfileDBStateToUIState(applicationDetails.profileSnapshot);

  const {reasonNote, status} = applicationDetails;

  const applicationFormDefinition = programDetailsRes.programDetails.applicationFormDefinition;
  const additionalData = applicationDetails.additionalDataSnapshot;
  const isCollectingAdditionalData = (applicationFormDefinition !== undefined) && (additionalData !== undefined);

  return (
    <div className={`${detailsContentWrapperStyle}`}>
      <ContentPageTopButtons 
        closeCallback={() => navigate('/dashboard/founder-services')}
      />
      <DetailsContentHeader
        label={textData.Pages.FounderPages.ProgramPages.FounderProgramApplicationDetails.Label[langState]}
      />
      <div className="h-[90%] overflow-y-auto w-full">
        {
          (status === "approved" || status === "enrolled" || status === "rejected") &&
          <>
            <DetailsContentHeader
              label={textData.Pages.FounderPages.ProgramPages.FounderProgramApplicationDetails.NoteLabel[langState]}
              tooltip={textData.Pages.FounderPages.ProgramPages.FounderProgramApplicationDetails.Tooltip[langState]}
              level="h2"
            />
            <p className="mb-5 w-full pr-6">{reasonNote}</p>
          </>
        }
        <Accordion
          allowZeroExpanded={true}
          preExpanded={[childClickedAccordionItemIndex]}
          className="w-full overflow-y-auto pr-2"
        >
          {
            isCollectingAdditionalData &&
            <AccordionItem
              uuid={0}
              className="mb-10 pr-1"
              key={`applicationDetails-additionalData`}
              onClick={() => setChildClickedAccordionItemIndex(0)}
            >
              <AccordionItemHeading>
                <AccordionItemButton
                  className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                >
                  <DetailsContentHeader
                    label={textData.Pages.FounderPages.ProgramPages.FounderProgramApplicationDetails.Additional[langState]}
                    tooltip={textData.Pages.FounderPages.ProgramPages.FounderProgramApplicationDetails.AdditionalTooltip[langState]}
                    level="h2"
                  />
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />)}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className={`${formContainerStyle}`}> 
                  <div className={formWrapperStyle}>
                    <Form
                      formData={additionalData}
                      formDefinition={JSON.parse(applicationFormDefinition)}  
                      isViewOnly={true}        
                    />
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          }
          <AccordionItem
            uuid={1}
            className="mb-10 pr-1"
            key={`applicationDetails-applicationSnapshot`}
            onClick={() => setChildClickedAccordionItemIndex(1)}
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
              >
                <DetailsContentHeader
                  label={textData.Pages.FounderPages.ProgramPages.FounderProgramApplicationDetails.ApplicationSnapshot[langState]}
                  tooltip={textData.Pages.FounderPages.ProgramPages.FounderProgramApplicationDetails.ApplicationSnapshotTooltip[langState]}
                  level="h2"
                />
                <AccordionItemState>
                  {({ expanded }) => (expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />)}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <FormGroup 
                formGroupData={founderProfileGroupData} 
                formGroupDefinition={founderProfileFormGroupDefinition}
                readOnly={true}
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default FounderProgramApplicationDetails;

