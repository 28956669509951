import { useNavigate } from "react-router-dom";
import ErrorIndicator from "../../Components/ErrorIndicator";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from "../../utilities/customHooks";
import { useContext, useState } from "react";
import AdminDashboardStats from "../../Components/AdminDashboardStats";
import List, { KeyValuePair } from "../../Components/List";
import { getMemberOwnerHeaders } from "../../services/adminService";
import Button from "../../Components/Button";
import { checkUrlAndModifySpan } from "../../utilities/helperFunctions";

function AdminDashboard() {
  const [viewAll, setViewAll] = useState(false)
  const membershipsColumnHeaderData = [
    {
      header: "Startup",
      key: "companyName"
    },
    {
      header: "Action Item",
      key: "actionItem",
      sortOptions: ["Review Application", "Perform GC Review", "No Action Required", "Review GC Assessment", "Due Dilligence", "Review IPE Assessment"]
    },
    {
      header: "Last Updated",
      key: "date"
    },
    {
      header: "Contact Person",
      key: "contact"
    }
  ]

  const navigate = useNavigate();
  const handleViewMemberDetails = (memberHeaderUserId: string) => {
    navigate((`/dashboard/memberships/${memberHeaderUserId}`));
  }

  const memberHeadersAsync = useAsync(() => getMemberOwnerHeaders(), []);

  if (memberHeadersAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (memberHeadersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }
  
    //dashboard fix which is stupid but works  
    const menuFix = () => {
    const menu = document.getElementsByClassName('sideMenu');
    Array.from(menu).forEach(element => {
      element.classList.add('sideMenuCollapsed');
    });
    const hide = document.getElementsByClassName('mobileHide');
    Array.from(hide).forEach(element => {
      element.classList.add('profileHide');
    });
    const headerhide = document.getElementsByClassName('profilePageHide');
    Array.from(headerhide).forEach(element => {
      element.classList.add('profileHide');
    });
  }

    const currentUrl = window.location.pathname; 
    checkUrlAndModifySpan(currentUrl)
    if (currentUrl === '/dashboard/profile' || currentUrl.includes('/dashboard/memberships')) {
      menuFix();
    } else if (currentUrl === '/dashboard/program-details') {
      const menu = document.getElementsByClassName('sideMenu');
      Array.from(menu).forEach(element => {
        element.classList.remove('sideMenuCollapsed');
      });
      const hide = document.getElementsByClassName('mobileHide');
      Array.from(hide).forEach(element => {
        element.classList.remove('profileHide');
      });
      const headerhide = document.getElementsByClassName('profilePageHide');
      Array.from(headerhide).forEach(element => {
        element.classList.add('profileHide');
      });
    } else {
      const menu = document.getElementsByClassName('sideMenu');
      Array.from(menu).forEach(element => {
        element.classList.remove('sideMenuCollapsed');
      });
      const hide = document.getElementsByClassName('mobileHide');
      Array.from(hide).forEach(element => {
        element.classList.remove('profileHide');
      });
      const headerhide = document.getElementsByClassName('profilePageHide');
      Array.from(headerhide).forEach(element => {
        element.classList.remove('profileHide');
      });
    }
    

  const memberHeaders = memberHeadersAsync.value.ownerMembers.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  ;
  let limitedArray = [] as any
  if (memberHeaders.length > 5) {
    limitedArray = memberHeaders.slice(0, 5)
  }

  return (
    <div className="resourcesCont !pt-0">
      <h2 className="">Welcome back{memberHeadersAsync.value.name ? `, ${memberHeadersAsync.value.name}` : "!"}</h2>
      {memberHeaders.length > 5 && !viewAll
      ?
      <div className="w-full">
        <List 
          contentArray={limitedArray as KeyValuePair[]}
          columnHeaderData={membershipsColumnHeaderData}
          returnDetailsId={handleViewMemberDetails}
          detailsIdName="id"
        />
        <div className="w-full flex justify-end items-center">
          <p className="mr-4">{memberHeaders.length - 5} additional action items</p>
          <Button 
            buttonText="View All"
            buttonType="secondary"
            actionHandler={() => setViewAll(true)
            }
          />
        </div>
      </div>
      :
      <List 
        contentArray={memberHeaders.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) as KeyValuePair[]}
        columnHeaderData={membershipsColumnHeaderData}
        returnDetailsId={handleViewMemberDetails}
        detailsIdName="id"
        
      />
      }
      <div className="w-full">
        <h2 className="mb-2">Quick Facts</h2>
        <AdminDashboardStats />
      </div>
    </div>
  )
}

export default AdminDashboard