import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType, useAsync } from "../../../utilities/customHooks";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { getGrowthHours } from "../../../services/adminService";
import { useContext, useEffect, useState } from "react";
import List from "../../../Components/List";
import * as T from "../../../utilities/frontendTypes";
import DatePicker from 'react-datepicker';
import Button from "../../../Components/Button";
import { inputClasses } from "../../../Components/Form/InputField";


const GrowthCoachHoursReport = () => {

  const [ currentHeadersArrays, setCurrentHeadersArrays ] = useState<{id: string; companyName: string; date: Date; hoursWorked: number;}[]>([])
  const [ reportDateMin, setReportDateMin ] = useState<Date>(new Date())
  const [ reportDateMax, setReportDateMax ] = useState<Date>(new Date())
  const [ listHtml, setListHtml ] = useState(<></>)

  useEffect(() => {
    const calculateTotalHours = (array: {
        id: string;
        companyName: string;
        date: Date;
        hoursWorked: number;
      }[]) => {
        return array.reduce((total, item) => {
          return total + (item.hoursWorked || 0);
        }, 0);
      };
    const totalHours = calculateTotalHours(currentHeadersArrays);
    const newlistHtml = (
      currentHeadersArrays.length === 0 ?
        <>No Startups reviewed within provided dates</>
      :
      <div className="w-full">
        <List 
          contentArray={currentHeadersArrays}
          columnHeaderData={membershipsColumnHeaderData}
          isReverse
        />
        <div className="font-semibold bodyLarger">
          Total Hours: {totalHours}
        </div>
    </div>
    ) 
    setListHtml(newlistHtml)
      // eslint-disable-next-line 
  }, [currentHeadersArrays]);

  const handleReportDateMinChange = (date: Date | null) => {
    if (date) {
     setReportDateMin(date);
    }
  };

  const handleReportDateMaxChange = (date: Date | null) => {
    if (date) {
     setReportDateMax(date);
    }
  };

  const membershipsColumnHeaderData = [
    {
      header: "Startup",
      key: "companyName" 
    },
    {
      header: "Hours Worked",
      key: "hoursWorked",
    },
    {
      header: "Assessment Submitted",
      key: "date",
    }
  ]

  const founderDetailsAsync = useAsync(() => getGrowthHours(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const contentArray = founderDetailsAsync.value.foundersArray;

  const generateReport = (array: {
    id: string;
    companyName: string;
    date: Date;
    hoursWorked: number;
  }[]) => {    
    const normalizeDate = (date: Date) => {
        const normalizedDate = new Date(date);
        normalizedDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
        return normalizedDate;
      };
    const filteredArray = array.filter(item =>
        normalizeDate(item.date) >= normalizeDate(reportDateMin) && normalizeDate(item.date) <= normalizeDate(reportDateMax)
    );
    setCurrentHeadersArrays(filteredArray)
  }

  return (
    <div className="resourcesCont !pt-0 h-full !w-full">
      <h2 className="">Hours Worked Report</h2>
      <div className="flex w-full items-end"> 
        <div className="max-w-[256px] mr-4">
          <label className="w-full flex flex-col bodyLarger" htmlFor="MinDate">
          <p className="mb-2">From:</p>
          <DatePicker 
            className={inputClasses }
            selected={reportDateMin}
            id={"MinDate"}
            scrollableYearDropdown={true}
            showYearDropdown
            yearDropdownItemNumber={115}
            onChange={handleReportDateMinChange}
            maxDate={reportDateMax}
          />  
          </label>
        </div> 
        <div className="max-w-[256px] mr-4">
        <label className="w-full flex flex-col bodyLarger" htmlFor="MinDate">
          <p className="mb-2">To:</p>
          <DatePicker 
            className={inputClasses}
            selected={reportDateMax}
            id={"MaxDate"}
            scrollableYearDropdown={true}
            showYearDropdown
            yearDropdownItemNumber={115}
            onChange={handleReportDateMaxChange}
            minDate={reportDateMin}
            maxDate={new Date()}
          />
          </label>
        </div>
        <Button 
          buttonText="Generate Report"
          buttonType="primary"
          actionHandler={() => generateReport(contentArray)}
        />
      </div>
      {listHtml}
    </div>
  )
}

export default GrowthCoachHoursReport