import { Outlet, useNavigate } from "react-router-dom";
import { LoadingContext, LoadingContextType, OutletContextType, ToastContext, ToastContextType, useAsync, useReloadOnOutletSave, useRequiredParams } from "../../../utilities/customHooks";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import * as T from "../../../utilities/frontendTypes";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../../AdminPages/ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../../AdminPages/ProfilePages/NewAdmin";
import List, { KeyValuePair } from "../../../Components/List";
import { useContext, useState } from "react";
import { founderStatusUpdateReject, getCoachPreMeetingDetails, growthCoachOutcomeUpdate } from "../../../services/adminService";
import { inputClasses } from "../../../Components/Form/InputField";
import { dropdownArrowClasses } from "../../../Components/Form/DropdownSingleSelect";
import Button, { ButtonProp } from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import Form, { FormDefinition } from "../../../Components/Form/Form";
import { basic_text_validator } from "../../../utilities/formFieldValidators";

const CoachPreMeetingAdminDetails = () => {

  const memberId = useRequiredParams("memberId");
  const langState = "eng"
  const navigate = useNavigate();
  const [reloadOnOutletSave, setReloadOnOutletSave] = useState(false);
  const [reloadOnSave, setReloadOnSave] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coachStatus, setCoachStatus] = useState("")
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const coachProgressDetailsAsync = useAsync(() => getCoachPreMeetingDetails(memberId), [memberId]);
  
  if (coachProgressDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (coachProgressDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const handleDropdownChange = (e: any) => {
    setCoachStatus(e.target.value);
  };
  
  const updateCoachStatus = async (updatedFormData?: FormData) => {
    let notes = ""
    if (coachStatus === "Declined") {
        const tempNotes = updatedFormData as any
        notes = tempNotes.notes
    }
    showLoading();
    const res = coachStatus === "Declined" ? await founderStatusUpdateReject(memberId, notes) : await growthCoachOutcomeUpdate(memberId, coachStatus, 0) 
    hideLoading();
    if (res.success) {
      setReloadOnSave(!reloadOnSave);
      setReloadOnOutletSave(!reloadOnOutletSave)
      showToast({
        toastType: "success",
        message: "Growth Coach Outcome Successfully Updated!",
      });
      navigate("/dashboard");
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to proceed. If Error persists please conteact support",
      });
    }  
  }
  const primaryButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: updateCoachStatus,
  }

  const rejectForm: FormDefinition = {
    notes: {
      label: "Please add a note for the Company regaring why they are not able to move forward within the EIP Program",
      inputType: "textarea",
      validator: basic_text_validator,
    }
  }

  const modalHTML = (
    <div className="mt-2 mb-2">
    <Form
      formDefinition={rejectForm}
      formData={null}
      primaryButtonProp={primaryButtonProp}
    />  
    </div>
  )

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons 
            hideBackButton
            closeCallback={() => navigate('/dashboard/admin-coach-premeeting')}   
        />
        <DetailsContentHeader 
            label="Update Growth Coach Meeting Outcome"
        />
        <div className="flex flex-col justify-center items-center">
        <div className="relative">
          <select 
            id="dropdown" 
            value={coachStatus} 
            onChange={handleDropdownChange}
            className={inputClasses}
          >
            <option value="" disabled>Select an Outcome:</option>
            <option value="No Concerns">No Concern</option>
            <option value="Still Evaluating">Still Evaluating</option>
            <option value="Decline">Decline</option>
          </select>
          <span className={dropdownArrowClasses}></span>
        </div>
        <Button
          buttonText="Update Status"
          actionHandler={coachStatus === "Decline" ? () => setIsModalOpen(true) : () => updateCoachStatus()}
          disabled={coachStatus === "" ? true : false}
        />
        <Modal 
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          modalHeader={"Which IP Expert are you inviting selected companies to meet?"}
          contentLabel=""
          modalHTML={modalHTML}
        />
        </div>
      </div>
    </div>
  );
}

export default CoachPreMeetingAdminDetails