import { useNavigate, Outlet } from "react-router-dom";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons"
import { DetailsContentHeader, detailsContentWrapperStyle } from "../AdminPages/ProfilePages/NewAdmin"
import { contentPageContainerStyle } from "../AdminPages/ProfilePages/PartnerAdminList"
import { useAsync } from "../../utilities/customHooks";
import { getSowRequests } from "../../services/serviceProvider";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import List, { KeyValuePair } from "../../Components/List";
import * as T from "../../utilities/frontendTypes";

const SowRequests = () => {
    // click on one directs to new SOW page
  const navigate = useNavigate();
  const sowRequestsAsync = useAsync(() => getSowRequests(), []);

  if (sowRequestsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (sowRequestsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const sowRequests = sowRequestsAsync.value.sowHeaders;
  
  const columnHeaderData = [
    {
      header: "Startup",
      key: "startupName",
    },
    {
      header: "Service",
      key: "programName",
    }
  ];

  const createSowForRequest = (id: T.ApplicationId) => {
    navigate(`/dashboard/sow-requests/${id}`);
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={detailsContentWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label="SoW Requests"
        /> 
        <List 
          contentArray={sowRequests as KeyValuePair[]}
          columnHeaderData={columnHeaderData}
          detailsIdName="applicationId"
          returnDetailsId={createSowForRequest}
        />  
      </div>
    </div>  
  )
}

export default SowRequests