import { ReactNode} from "react";
import { useLang, LangContext } from "./customHooks"

export function LangContextProvider({ children }: { children: ReactNode }) {
  const lang = useLang();
  return (
    <LangContext.Provider value={lang}>
      {children}
    </LangContext.Provider>
  );
}