import instance from '../middleware/HTTPInterceptors';
import ErrorResponse from '../utilities/ErrorResponse';
import { HTTPResponse } from '../utilities/frontendTypes';
import { getAuthHeader } from '../utilities/helperFunctions';
import * as T from '../utilities/frontendTypes';

export async function getSowRequests(): Promise<T.SowRequestResponse> {
  try {
    const config = {
        headers: await getAuthHeader(),
      }

    const res = await instance.get("/api/providers/get-sow-requests", config);

    return res.data;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getCreateSowDetails (sowId: string): Promise<T.CreateSowRequestResponse> {
  try {
    const config = {
        headers: await getAuthHeader(),
      }

    const res = await instance.get(`/api/providers/get-create-sow-details/${sowId}`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function serviceProviderSubmitSow (sowDetails: T.SowDetailToAdmin): Promise<T.HTTPResponse> {
  try {
    const config = {
        headers: await getAuthHeader(),
      }

    const res = await instance.put(`/api/providers/submit-sow`, sowDetails, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getProviderDashboard(): Promise<{success: boolean, sowArray: {companyName: string, actionItem: string, lastUpdated: Date, tier: string, contactPerson: string, sowId: string, }[], name: string}>  {
  
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/providers/dashboard`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}