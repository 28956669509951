import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function IconButton (
  {
    icon,
    actionCallback,
    style,
    text
  }
  :
  {
    icon: IconDefinition,
    actionCallback: () => void,
    style?: string,
    text?: string
  }
) {

  return (
    <button
      type="button"
      className="hover:cursor-pointer w-fit"
      onClick={ (e) => {
        e.stopPropagation();
        actionCallback();
      }}
    >
      {text && <span className="mr-1 hover:underline">{`${text}`}</span>}
      <FontAwesomeIcon className={`${style}`} icon={icon} />
    </button>
  )

}