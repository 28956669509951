import { useLocation, useNavigate } from "react-router-dom";
import { useRequiredParams } from "../../../utilities/customHooks"
import { useAsync } from "../../../utilities/customHooks";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getProgramDetails } from "../../../services/adminService";
import * as T from "../../../utilities/frontendTypes";
import Form from "../../../Components/Form/Form";
import { programDetailsFormDefinition } from "../../../utilities/formDefinitionTemplates";
import { flattenObject } from "../../../utilities/helperFunctions";
import List, { KeyValuePair } from "../../../Components/List";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../ProfilePages/NewAdmin";
import { membershipsColumnHeaderData } from "../MembershipPages/MembersList";
// import { convertFounderMembersDBStateToUIState } from "../../../utilities/stateConverters";

export const programApplicationColumnHeaderData = [
  {
    header: "Service Name",
    key: "programName",
  },
  {
    header: "Applicant Name",
    key: "applicantName",
  },
  {
    header: "Company Name",
    key: "companyName",
  },
  {
    header: "Status",
    key: "status",
    sortOptions: ["pending", "approved", "rejected"],
  },
  {
    header: "Date Applied",
    key: "dateCreated",
  }
];

function ProgramDetails() {
  // const programId = useRequiredParams("programId");
  // const navigate = useNavigate();
  // const location = useLocation();
  // const handleViewApplicationDetails = (id: T.ApplicationId) => {
  //   navigate(`/dashboard/my-service-applications/${id}`, {state: {from: location.pathname}})
  // }
  // const handleViewUserDetails = (id: T.UserId) => {
  //   navigate(`/dashboard/memberships/${id}`, {state: {from: location.pathname}})
  // }
  // const programDetailsAsync = useAsync(() => getProgramDetails(programId), [programId]);

  // if (programDetailsAsync.status === "pending") {
  //   return <LoadingIndicator />;
  // }

  // if (programDetailsAsync.status === "rejected") {
  //   return <ErrorIndicator />;
  // }

  // // [TODO] passing unnecessary property fields into Form, clean up advised (see pattern in "New Program" as example)
  // const programDetails: T.ProgramDetails = programDetailsAsync.value.programDetails; 
  // const flattenedProgramDetails = flattenObject(programDetails);
  // const programDetailsFormData = {
  //   ...flattenedProgramDetails,
  //   // costRange: {
  //   //   low: programDetails.programData.costRangeLow,
  //   //   high: programDetails.programData.costRangeHigh,
  //   // }
  // }
  // const applicationHeaders = programDetailsAsync.value.programApplicationHeaders;
  // const memberHeaders = convertFounderMembersDBStateToUIState(programDetailsAsync.value.enrolledUsers);

  return (
    <div className={detailsContentWrapperStyle}>
      {/* <ContentPageTopButtons 
        hideBackButton
        closeCallback={() => navigate('/dashboard/services')}
      />
      <DetailsContentHeader label="Service Details"/>
      <div className={`${formContainerStyle} h-[75%]`}>
        <div className={formWrapperStyle}>
          <Form 
            formDefinition={programDetailsFormDefinition}
            formData={programDetailsFormData}
            disabled={true}
            readOnly={true}
            isViewOnly={true}
          />
        </div>

        <h2 className="my-5 font-bold">Enrolled Members</h2>
        {
          memberHeaders.length > 0 
          ?
          <List 
            contentArray={memberHeaders as KeyValuePair[]}
            columnHeaderData={membershipsColumnHeaderData}
            detailsIdName="id" // [TODO] userId?
            returnDetailsId={handleViewUserDetails}
          />
          :
          <p>There are no users enrolled in this service</p>
        }

        <h2 className="my-5 font-bold">Applications</h2>
        <List 
          contentArray={applicationHeaders as KeyValuePair[]}
          columnHeaderData={programApplicationColumnHeaderData}
          detailsIdName="id" // [TODO] applicationId?
          returnDetailsId={handleViewApplicationDetails}
        />
      </div> */}
    </div>
  )
}

export default ProgramDetails