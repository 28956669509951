import { useNavigate } from "react-router-dom";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import Form, { FormDefinition, selectionData, FormData } from "../../../Components/Form/Form";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync, useReloadOnOutletSave, useRequiredParams } from "../../../utilities/customHooks";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../../AdminPages/ProfilePages/NewAdmin";
import { adminRecommendationFormDefition } from "../../../utilities/formDefinitionTemplates";
import { useContext, useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { createNewSowRequest, founderStatusUpdateReject, getRecommendationDetails } from "../../../services/adminService";
import { ButtonProp } from "../../../Components/Button";
import { handleFormSaveResponse } from "../../../utilities/helperFunctions";
import Modal from "../../../Components/Modal";
import { basic_text_validator, selection_validator } from "../../../utilities/formFieldValidators";


const RecommendationAdminDetails = () => {

  const navigate = useNavigate();
  const memberId = useRequiredParams("memberId")
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const [reloadOnSave, setReloadOnSave] = useState(false);
  const { reloadOnOutletSave, setReloadOnOutletSave } = useReloadOnOutletSave();
  const [isModalOpen, setIsModalOpen] = useState(false);


  const recommendationDetailsAsync = useAsync(() => getRecommendationDetails(memberId), [memberId]);

  if (recommendationDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (recommendationDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const recommendationDetails = recommendationDetailsAsync.value.result.rec
  const formData: FormData = {
    rec: recommendationDetails
  }
  const firmSelectionData = recommendationDetailsAsync.value.result.firms

  const decline = () => console.log("decline")

  const rejectForm: FormDefinition = {
    notes: {
      label: "Please add a note for the Company regaring why they are not able to move forward within the EIP Program",
      inputType: "textarea",
      validator: basic_text_validator,
    }
  }

  const secondaryButtonProp: ButtonProp = {
    buttonText: "Decline",
    actionHandler: decline,
  }


const adminCreateSowRequest = async (updatedForm: FormData) => {
    const {firm, tier} = updatedForm
    const sowRequestDetails = {
      spId: firm as string,
      tier: tier as string,
      startupId: memberId,
    }
    showLoading();
    const response = await createNewSowRequest(sowRequestDetails)
    hideLoading();

    if (response.success) navigate("/login");
    handleFormSaveResponse(
      response,
      showToast,
      {message: `Statement of Work Request Sent!`, autoDisappearTimeInSeconds: 5},
      {message: `Statement of Work Request Not Sent, Please Try Again`, autoDisappearTimeInSeconds: 5}
    )
  }

  const updateCoachStatus = async (updatedFormData?: FormData) => {
    let notes = ""
        const tempNotes = updatedFormData as any
        notes = tempNotes.notes
    showLoading();
    const res = await founderStatusUpdateReject(memberId, notes)  
    hideLoading();
    if (res.success) {
      setReloadOnSave(!reloadOnSave);
      setReloadOnOutletSave(!reloadOnOutletSave)
      showToast({
        toastType: "success",
        message: "Growth Coach Outcome Successfully Updated!",
      });
      navigate("/dashboard");
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to proceed. If Error persists please conteact support",
      });
    }  
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: updateCoachStatus,
  }

  // const primaryButtonProp: ButtonProp = {
  //   buttonText: "Create SoW Request",
  //   actionHandler: () => setIsModalOpen(true),
  // }

  const sowCreateRequest: FormDefinition = {
    tier: {
      label: "Tier",
      inputType: "dropdownSelect",
      validator: selection_validator,
      additionalData: {
        selectionData: [
          {
            value: "2",
            text: "2",
          },
          {
            value: "3",
            text: "3",
          },
          {
            value: "Both 2 and 3",
            text: "Both",
          }
        ]
      },
    },
    firm: {
      label: "Select Law Firm",
      inputType: "dropdownSelect",
      validator: selection_validator,
      additionalData: {
        selectionData: firmSelectionData as selectionData[]
      },
    }
  }

  return (
    <div className={detailsContentWrapperStyle}>
    <ContentPageTopButtons 
      hideBackButton
      closeCallback={() => navigate('/dashboard/admin-expert-recommendations')}   
    />
    <DetailsContentHeader 
      label="Expert Assessment"
    />
    <div className="w-full">
      <h2 className="text-lg font-semibold mb-2">IP Assessment Score: 18</h2>
    </div>
    <div className={`${formContainerStyle} h-[75%]`}>
      <div className={formWrapperStyle}>
        <Form 
          formDefinition={adminRecommendationFormDefition}
          formData={formData}
          readOnly={true}
          primaryButtonProp={primaryButtonProp}
          secondaryButtonProp={secondaryButtonProp}
        />
      </div>
    </div>
    <Modal 
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        modalHeader={"Create Statement of Work Request"}
        contentLabel={""}
        modalHTML={
          <div className="mt-2 mb-2">
          <Form
            formDefinition={rejectForm}
            formData={null}
            primaryButtonProp={primaryButtonProp}
          />
          </div>
        }
        // modalHTML={
        //   <Form 
        //     formDefinition={sowCreateRequest} 
        //     formData={formData}   
        //     primaryButtonProp={{
        //       buttonText: "Submit",
        //       actionHandler: adminCreateSowRequest
        //     }}   
        //   />
        // }        
      />
  </div>
  )
}

export default RecommendationAdminDetails