import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { getMyPrograms } from "../../../services/adminService";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { OutletContextType, useAsync } from "../../../utilities/customHooks";
import * as T from "../../../utilities/frontendTypes";
import Button from "../../../Components/Button";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { useEffect, useState } from "react";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin";
import { ProgramsAccordion } from "../../FounderPages/ProgramPages/FounderProgramList";
import Card from "../../../Components/Card";

function MyPrograms() {

  const location = useLocation();
  
  useEffect(() => {
    setIsDetailsViewOpen(location.pathname !== "/dashboard/my-services");
  }, [location]);
  
  const [isDetailsViewOpen, setIsDetailsViewOpen] = useState(false);
  
  const [reloadOnOutletSave, setReloadOnOutletSave] = useState(false);

  const navigate = useNavigate();
  
  const myProgramsAsync = useAsync(() => getMyPrograms(), [location, reloadOnOutletSave]);

  if (myProgramsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (myProgramsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const myPrograms = myProgramsAsync.value.adminPrograms;

  const handleViewProgramDetails = (id: T.ProgramId) => {
    navigate(`/dashboard/my-services/${id}`)
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={`${contentPageWrapperStyle} !items-start`}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label="My Services"
          tooltip="List of services owned by you."
        />
        <div className="self-start mb-5">
          <Button 
            buttonType={"primary"} 
            buttonText={"Create Service"} 
            actionHandler={() => {navigate('/dashboard/my-services/new-service')}}        
          />
        </div>
        {/* <div className="flex flex-wrap overflow-y-auto ">
            {
            myPrograms.map(program => (
              <Card
                key={program._id}
                program={program}
                // onClickCallback={(id: string) => navigate(`/dashboard/founder-services/${id}`)}
                onClickCallback={(id: string) => handleViewProgramDetails(id)}
                isViewOnly={false}
              />
            ))
          }
          </div> */}
      </div>
      {/* Program Details Route */}
      <Outlet context={ { reloadOnOutletSave, setReloadOnOutletSave } satisfies OutletContextType} />
    </div>
  )
}

export default MyPrograms;