import { useContext } from "react";
import { LoadingContext, LoadingContextType } from "../utilities/customHooks";

const LoadingOverlay = () => {

  const {loadingState} = useContext(LoadingContext) as LoadingContextType;

  return (
    <div className={
      loadingState ?
      'fixed w-screen h-screen z-50 cursor-wait'
      :
      'hidden'
    }>
    </div>
  )
}

export default LoadingOverlay;