import parseStringToHTML from "html-react-parser";
import textData from "../../textData.json";
import { useContext } from "react";
import { LangContext, LangContextType } from "../../utilities/customHooks";

export const HTMLParser = (  
  {
    name,
    value,
    setValue,
  }
  :
  {
    name: string,
    value: string | null,
    setValue: (value: boolean) => void,
  }
  ) => {
    
  const { langState } = useContext(LangContext) as LangContextType

  return (
    <div>{value ? parseStringToHTML(value) : textData.Components.Form.HTMLParser.NoContent[langState]}</div>
  )
}
