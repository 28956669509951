import { disabledInputClasses, inputClasses } from "../Form/InputField";

export default function TextArea(
  {
    name,
    placeholder,
    value,
    setValue,
    disabled,
    readOnly,
  }
  :
  {
    name: string,
    placeholder?: string,
    value: string | number,
    setValue: (value: string) => void,
    disabled?: boolean,
    readOnly?: boolean,
  }
) {

  return (
    <div className="relative flex items-center">
      <textarea
        disabled={disabled}
        value={value}
        onChange={e => setValue(e.currentTarget.value)}
        placeholder={placeholder}
        id={name}
        readOnly={readOnly}
        className={`${`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`} w-full `}
      />
    </div>
  )
}