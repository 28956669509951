import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './utilities/AuthProvider';
import { ToastProvider } from './utilities/ToastProvider';
import { ConditionalRenderContextProvider } from './utilities/ConditionalRenderContextProvider';
import { LoadingContextProvider } from './utilities/LoadingContextProvider';
import { LangContextProvider } from './utilities/LangContextProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <LangContextProvider>
          <ConditionalRenderContextProvider>
            <LoadingContextProvider>
              <ToastProvider>
                <App />
              </ToastProvider>
            </LoadingContextProvider>
          </ConditionalRenderContextProvider>
        </LangContextProvider>  
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

