import { useContext, useEffect } from 'react'
import { AuthContext, AuthContextType, useAsync } from '../../utilities/customHooks';
import AdminDashboard from './AdminDashboard';
import TempAdminDashboard from './TempAdminDashboard';
import FounderDashboard from './FounderDashboard';
import TempExpertDashboard from './TempExpertDashboard';
import TempProviderDashboard from './TempProviderDashboard';
import IpExpertDashboard from './IpExpertDashboard';
import { checkUrlAndModifySpan } from '../../utilities/helperFunctions';
import { getName } from '../../services/userService';
import LoadingIndicator from '../../Components/LoadingIndicator';
import ErrorIndicator from '../../Components/ErrorIndicator';
import ProviderDashboard from './ProviderDashboard';
import GrowthCoachDashboard from './GrowthCoachDashboard';
import TempGrowthCoachDashboard from './TempGrowthCoachDashboard';

function Console() {
  const { userRole } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional
  const nameAsync = useAsync(() => getName(), [])
  if (nameAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (nameAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const name = nameAsync.value.name as string;
  const getDashboard = () => {
    switch (userRole) {
      case "superAdmin":
      case "partnerAdmin":
        return <AdminDashboard />;
      case "tempAdmin":
        return <TempAdminDashboard />;
      case "tempExpert":
        return <TempExpertDashboard />;
      case "tempProvider":
        return <TempProviderDashboard />;
      case "tempGrowthCoach":
        return <TempGrowthCoachDashboard />;
      case "serviceProvider":
        return <ProviderDashboard />;; // [TODO] Create dashboard page for service provider
      case "ipExpert":
          return <IpExpertDashboard />;
      case "startupFounder":
        return <FounderDashboard />;
      case "growthCoach":
        return <GrowthCoachDashboard />;
      default:
        throw new Error('Unknown user role');
    }
  }
  return (
    getDashboard()
  )
}

export default Console