/*

[TODO] THIS DATA FILE IS MEANT FOR A TEMPORARY SOLUTION, DELETE ASAP - B
[TODO] Refactor when we handle Organization properly in the backend

*/

import { Address, Coordinates } from "../Components/Form/AddressFields"


type Organization = {
  id: string, // [NOTE] meant for easier db migration visualization purpose only
  name: string,
  address: Address,
  regions: string[],
  coordinates: Coordinates,
}

export const organizations: Organization[] = [
  {
    id: "00",
    name: "communitech",
    regions: ["ON"],
    address: {
      streetNumber: "Charles St W",
      streetName: "151",
      unitNumber: null,
      city: "Kitchener",
      province: "ON",
      postalCode: "N2G 1H6",
      country: "CA"
    },
    coordinates: {
      lat: 43.450896,
      lng: -80.49791979999999,
    }
  },
  {
    id: "01",
    name: "investOttawa",
    regions: ["ON"],
    address: {
      streetNumber: "Bayview Station Rd",
      streetName: "7",
      unitNumber: null,
      city: "Kitchener",
      province: "ON",
      postalCode: "K1Y 2C5",
      country: "CA"
    },
    coordinates: {
      lat: 45.4096858,
      lng: -75.7280263,
    } 
  },
  {
    id: "02",
    name: "northForge",
    regions: ["MB", "SK"],
    address: {
      streetNumber: "Innovation Dr",
      streetName: "100",
      unitNumber: "441",
      city: "Winnipeg",
      province: "MB",
      postalCode: "R3T 6A8",
      country: "CA"
    },
    coordinates: {
      lat: 49.801866,
      lng: -97.1479078,
    } 
  }
]