import Form, { FormData } from "../Components/Form/Form";
import { helpFormDefition } from "../utilities/formDefinitionTemplates";
import { ButtonProp } from "../Components/Button";
import { useContext, useEffect, useState } from "react";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../utilities/customHooks";
import { useNavigate } from "react-router-dom";
import { handleFormSaveResponse, returnLangCheckedFormDef } from "../utilities/helperFunctions";
import { sendHelpEmail } from "../services/founderService";
import textData from "../textData.json";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import Plus from '../assets/Plus.png'
import Minus from '../assets/Minus.png'
import { faL } from "@fortawesome/free-solid-svg-icons";


const HelpContact = () => {
  const [childClickedAccordionItemIndex, setChildClickedAccordionItemIndex] = useState(0);
  const { langState } = useContext(LangContext) as LangContextType
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const navigate = useNavigate();
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const [faq, setFaq] = useState(true)

  async function submitHelpRequest (updatedForm: FormData) {
    const emailDetails = {
      message: updatedForm['message'] as string
      
    }
    showLoading();
    const response = await sendHelpEmail(emailDetails);
    hideLoading();
    
    if (response.success) navigate("/login");
      handleFormSaveResponse(
        response,
        showToast,
        {message: textData.Pages.Help.SuccessToast[langState], autoDisappearTimeInSeconds: 5},
        {message: textData.Pages.Help.FailToast[langState], autoDisappearTimeInSeconds: 5}
      )
    }
  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.Help.Button[langState],
    actionHandler: submitHelpRequest,
  }
  return (
    <div className="w-full h-full px-14 flex flex-col gap-14 items-center overflow-y-auto">
      <div className="flex flex-col helpCont1">
        <div className="flex flex-col w-full mb-14">
          <h1 className="mb-4 text-center">{textData.Pages.Help.HelpContact[langState]}</h1>
          <p className="bodyLarger text-center">{textData.Pages.Help.HelpContactSub1[langState]}</p>
          <p className="bodyLarger text-center">{textData.Pages.Help.HelpContactSub2[langState]}</p>
        </div>
        <Form 
          formDefinition={returnLangCheckedFormDef(helpFormDefition, langState)} 
          formData={null}
          primaryButtonProp={primaryButtonProp}
          buttonFullWidth
        />
      </div>
    </div>  
  )
}

export default HelpContact