import { FormGroupData } from "../Components/FormGroup";
import * as T from "./frontendTypes";

export const convertFounderProfileDBStateToUIState = (founderProfile: T.StartupFounderProfile | null): FormGroupData | null => {
  const founderProfileGroupData: FormGroupData | null = founderProfile ? {} : null;
  if (founderProfileGroupData !== null && founderProfile !== null) {
    const {personalInfo, pointOfContact, companyInfo, iPStrategy, programingEvaluation, ipReadiness} = founderProfile;
    if (personalInfo) {
      founderProfileGroupData.personalInfo = personalInfo;
    }
    if (pointOfContact) {
      founderProfileGroupData.pointOfContact = pointOfContact;
    }
    if (companyInfo) {
      const {
        registration, 
        mailingAddress, 
        basicInfo} = companyInfo;
      if (registration) {
        founderProfileGroupData.registration = registration;
      }
      if (mailingAddress) {
        founderProfileGroupData.mailingAddress = mailingAddress;
      }

      if (basicInfo) {
        founderProfileGroupData.basicInfo = basicInfo;
      }
    }
    if (iPStrategy) {
      founderProfileGroupData.iPStrategy = iPStrategy;
    }
    if (ipReadiness) {
      founderProfileGroupData.ipReadiness = ipReadiness;
    }
    if (programingEvaluation) {
      founderProfileGroupData.programingEvaluation = programingEvaluation;
    }
  }
  return founderProfileGroupData;
}

// export const convertFounderMembersDBStateToUIState = (enrolledUsers: T.User[]): T.MemberHeader[] => {
  
//   return enrolledUsers.map( user => {

//     if (!user.startupFounderProfile) throw new Error(`Enrolled user ${user} has corrupted data`);
//     if (!user.createdAt) throw new Error(`Enrolled user ${user} has no created At date `);
//     const { personalInfo, companyInfo, programingEvaluation } = user.startupFounderProfile;

//     if (!personalInfo || !companyInfo || !companyInfo.basicInfo || !programingEvaluation) {
//       throw new Error(`Enrolled user ${user} has corrupted data`)
//     }

//     const {firstName, lastName} = personalInfo;

//     return {
//       id: user._id,
//       email: user.email,
//       accountCreated: user.createdAt
      
//     }
//   })
// }