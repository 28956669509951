import { FormData } from "../../Components/Form/Form";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { getRegionalMembershipId, getUserProfile, updateUserProfile } from "../../services/userService";
import { AuthContext, AuthContextType, ConditionalRenderContext, ConditionalRenderContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync} from "../../utilities/customHooks";
import * as T from "../../utilities/frontendTypes";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button";
import { createFounderApplication, getLatestMembershipApplication } from "../../services/founderService";
import FormGroup from "../../Components/FormGroup";
import { updatedFounderProfileReadinessFormGroupDefinition, updatedFounderProfileFormGroupDefinition, founderProfileFormGroupDefinition } from "../../utilities/formDefinitionTemplates";
import { Address } from "../../Components/Form/AddressFields";
import { convertFounderProfileDBStateToUIState } from "../../utilities/stateConverters";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import { DetailsContentHeader } from "../AdminPages/ProfilePages/NewAdmin";
import { contentPageWrapperStyle } from "../AdminPages/ProfilePages/PartnerAdminList";
import { handleFormSaveResponse, returnLangCheckedFormGroupDef } from "../../utilities/helperFunctions";
import Tooltip from "../../Components/Tooltip";
import ErrorIndicator from "../../Components/ErrorIndicator";
import _ from 'underscore';
import Modal from "../../Components/Modal";
import textData from "../../textData.json";

function FounderProfile() {
  const { langState } = useContext(LangContext) as LangContextType

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const { memberApplicationStatus, setMemberApplicationStatus, userRegion } = useContext(AuthContext) as AuthContextType; 

  const navigate = useNavigate();
  const conditionalRenderContext = useContext(ConditionalRenderContext) as ConditionalRenderContextType;

  const [founderProfile, setFounderProfile] = useState<T.StartupFounderProfile | null>(null);

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const [isEditing, setIsEditing] = useState(false);
  const [isAttestaionChecked, setIsAttestaionChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applicationTier, setApplicationTier] = useState<number>(1)

  useEffect(() => {
    try {
      const fetchFounderProfile = async () => {
        showLoading();
        const founderProfileRes =  await getUserProfile("startupFounder");
        hideLoading();
      
        const initialProfile = founderProfileRes.profile as T.StartupFounderProfile | null;

        if(initialProfile?.ipReadiness) {
          setApplicationTier(3)
        }

        setFounderProfile(initialProfile);

        // Register conditional render state
        const conditionalRenderInitialState = {
          isPointOfContact: !!initialProfile?.personalInfo?.isPointOfContact,
          isLegalNameSameAsOperatingName: !!initialProfile?.companyInfo?.basicInfo?.isLegalNameSameAsOperatingName,
          isWorkingWithLawFirm: !!initialProfile?.iPStrategy?.isWorkingWithLawFirm,
          isWorkingWithOrganization: !!initialProfile?.iPStrategy?.isWorkingWithOrganization,
          isWorkingWithBai: !!initialProfile?.iPStrategy?.isWorkingWithBai,
          isFromOLMC: !!initialProfile?.programingEvaluation?.isFromOLMC,
          filePatentReady: !!initialProfile?.iPStrategy?.filePatentReady,
          fileTrademarkReady: !!initialProfile?.iPStrategy?.fileTrademarkReady,
          isProductForSaleOnMarket: !!initialProfile?.programingEvaluation?.isProductForSaleOnMarket,
          educationType: !!initialProfile?.ipReadiness?.education,
          specificJurisdiction: !!initialProfile?.ipReadiness?.jurisdiction,
          tradeSecretsList: !!initialProfile?.ipReadiness?.tradeSecrets,
          patentsNumber: !!initialProfile?.ipReadiness?.tradeSecrets,
        }
        conditionalRenderContext.replaceConditionalRenderState(conditionalRenderInitialState);
      }
      
      fetchFounderProfile().catch(console.error);
     
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line 
  }, []);
  
  useEffect(() => {

      const conditionalRenderInitialState = {
        educationType: !!founderProfile?.ipReadiness?.education,
        specificJurisdiction: !!founderProfile?.ipReadiness?.jurisdiction,
        tradeSecretsList: !!founderProfile?.ipReadiness?.tradeSecrets,
        patentsNumber: !!founderProfile?.ipReadiness?.tradeSecrets,
      }
      conditionalRenderContext.replaceConditionalRenderState(conditionalRenderInitialState);
    // eslint-disable-next-line 
  }, [applicationTier]);

  const latestMembershipApplicationAsync = useAsync(() => getLatestMembershipApplication(), []);
  
  if (latestMembershipApplicationAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (latestMembershipApplicationAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const latestMembershipApplication = latestMembershipApplicationAsync.value.data as T.ApplicationDetails;

  const isProfileUpdated = latestMembershipApplication ? !_.isEqual(founderProfile, latestMembershipApplication.profileSnapshot): true;

  // db state to UI state
  const founderProfileGroupData = convertFounderProfileDBStateToUIState(founderProfile);
  if (founderProfile?.ipReadiness !== null) {
    setApplicationTier
  }

  // logic to handle FormGroup validation on FounderProfile
  const handleSubmissionReject = (message?: string) => {
    showToast({
      toastType: "info",
      title: textData.Pages.FounderPages.FounderProfile.UnsuccessfulSubmit[langState],
      message: message || textData.Pages.FounderPages.FounderProfile.UnsuccessfulSubmitMessage[langState],
    })
  }

  const handleSubmitMembershipApplication = async () => {

    if (!founderProfile) {
      handleSubmissionReject();
      return;
    }

    const { personalInfo, pointOfContact, companyInfo, iPStrategy, programingEvaluation, ipReadiness } = founderProfile;

    if (!companyInfo) {
      handleSubmissionReject();
      return;
    }

    const { registration, 
            mailingAddress, 
            basicInfo } = companyInfo;

    if (
      personalInfo === null ||
      (!personalInfo.isPointOfContact && pointOfContact === null) || // is not point of contact but PoC data is missing
      basicInfo === null ||
      (registration === null) || 
      mailingAddress === null ||
      iPStrategy === null ||
      programingEvaluation === null ||
      applicationTier === 3 && ipReadiness === null 
    ) {
      handleSubmissionReject();
      return;
    }

    if (isEditing) {
      handleSubmissionReject(textData.Pages.FounderPages.FounderProfile.WhileEditing[langState]);
      return;
    }
    
    showLoading();
    const programId = (await getRegionalMembershipId()).membershipId;
    const lang = langState
    const response = await createFounderApplication(programId);
    hideLoading();
    if (response.success && !response.autoReject) {
      setMemberApplicationStatus(response.applicationStatus);
      navigate("/dashboard");
    } 
    handleFormSaveResponse (
      response, 
      showToast, 
      {message: textData.Pages.FounderPages.FounderProfile.SuccessfulSubmit[langState]}, 
      {title: textData.Pages.FounderPages.FounderProfile.AttemptUnsuccessfulTitle[langState], message: (response.message ? response.message : textData.Pages.FounderPages.FounderProfile.ServerErrorSubmitMessage[langState]), autoDisappearTimeInSeconds: 8}
    );
  }

  // these section keys must match sectionKey in formDefinition (in this case "founderProfileFormGroupDefinition")
  type FounderProfileSectionKey = "personalInfo" | "pointOfContact" | "basicInfo" | "registration" | "mailingAddress" | "iPStrategy" | "programingEvaluation" | "ipReadiness";
  async function handleSaveSection(sectionKey: string, updatedSectionFormData: FormData) {

    const emptyProfile = {
      personalInfo: null, 
      pointOfContact: null, 
      companyInfo: null,
      iPStrategy: null, 
      ipReadiness: null,
      programingEvaluation: null,
      sLASigningTimestamp: new Date(),
    }

    const updatedFounderProfile: T.StartupFounderProfile = founderProfile === null ? emptyProfile : {...founderProfile};
    switch (sectionKey as FounderProfileSectionKey) {
      case "personalInfo":
        updatedFounderProfile.personalInfo = updatedSectionFormData as T.PersonalInfo & { isPointOfContact: boolean; };
        if (updatedFounderProfile.personalInfo.isPointOfContact) {
          updatedFounderProfile.pointOfContact = null;
        }
        break;
      case "pointOfContact":
        updatedFounderProfile.pointOfContact = updatedSectionFormData as T.PersonalInfo & { email: string; roleTags: string;}
        break;
      case "basicInfo":
      case "registration":
      case "mailingAddress":
        if (!updatedFounderProfile.companyInfo) {
          updatedFounderProfile.companyInfo = {
            basicInfo: null,
            registration: null,
            mailingAddress: null,
          }
        }
        switch(sectionKey as 'basicInfo' 
        | 'registration' | 'mailingAddress') {
          case "basicInfo":
            updatedFounderProfile.companyInfo.basicInfo = updatedSectionFormData as T.CompanyBasicInfo;
            if (updatedFounderProfile.companyInfo.basicInfo.isLegalNameSameAsOperatingName) {
              updatedFounderProfile.companyInfo.basicInfo.legalName = null;
            }
            break;
          case "registration":
            updatedFounderProfile.companyInfo.registration = updatedSectionFormData as T.CompanyRegistrationInfo;
            break;
          case "mailingAddress":
            updatedFounderProfile.companyInfo.mailingAddress = updatedSectionFormData as Address;
            break;
          default:
            throw new Error(`Unknown sectionKey: '${sectionKey}' under companyInfo`);
        }
        break;
      case "iPStrategy":
        updatedFounderProfile.iPStrategy = updatedSectionFormData as T.IPStrategy;
        if (updatedFounderProfile.iPStrategy.bestMatch?.includes("trademarks") || updatedFounderProfile.iPStrategy.bestMatch?.includes("patents")) {
          if (updatedFounderProfile.iPStrategy.patentsReadyOptions?.includes("search") || updatedFounderProfile.iPStrategy.patentsReadyOptions?.includes("family") || updatedFounderProfile.iPStrategy.patentsReadyOptions?.includes("written")) {
            if (updatedFounderProfile.iPStrategy.trademarkClearanceSearch === true) {
              setApplicationTier(3)
            } else {
              setApplicationTier(1)
            }
          } else {
            setApplicationTier(1)
          }
        } else {
          setApplicationTier(1)
        }
        break;
      case "ipReadiness":
        updatedFounderProfile.ipReadiness = updatedSectionFormData as T.IPReadiness;
        break;
      case "programingEvaluation":
        const underRepresentedGroupsArray = updatedSectionFormData.underrepresentedFounderGroups as string[];
        const isOwnedByUnderrepresentedGroups = !underRepresentedGroupsArray.includes("none");
        updatedSectionFormData.isOwnedByUnderrepresentedGroups = isOwnedByUnderrepresentedGroups
        updatedFounderProfile.programingEvaluation = updatedSectionFormData as T.ProgramingEvaluation;
        break;
      default:
        throw new Error(`Unknown sectionKey: ${sectionKey}`);
    }

    setFounderProfile(updatedFounderProfile);

    showLoading();
    const res = await updateUserProfile("startupFounder", updatedFounderProfile);
    hideLoading();

    if (res.success) {
      showToast({
        toastType: "success",
        message: textData.Pages.FounderPages.FounderProfile.SectionToastSuccess[langState]
      })
    } else {
      showToast({
        toastType: "failure",
        message: textData.Pages.FounderPages.FounderProfile.SectionToastFail[langState]
      })
    }
  }

  const buttonOption = (() => {
    switch (memberApplicationStatus) {
      case "unsubmitted":
        return (
          {
            disabled: false,
            text: textData.Pages.FounderPages.FounderProfile.Submit[langState],
          }
        );
      case "rejected":
      case "flagged":
      case "pending": 
        return (
          {
            disabled: true,
            text: textData.Pages.FounderPages.FounderProfile.ReSubmit[langState]
          }
        );
        case "approved":
        return (
          {
            disabled: true,
            text: textData.Pages.FounderPages.FounderProfile.ReSubmit[langState]
          }
        );
      default:
        throw new Error(`Unknown application status: ${memberApplicationStatus}`);
    }
  })();

  const attestaionPrompt = () => {
    showToast({
      toastType: "failure",
      message: textData.Pages.FounderPages.FounderProfile.AttestationFailure[langState]
    })
  }

  const attestationTextEng = `I, ${founderProfile?.personalInfo?.firstName} ${founderProfile?.personalInfo?.lastName}, have legal authority to represent ${founderProfile?.companyInfo?.basicInfo?.operatingName} and attest that the support provided by Communitech / Invest Ottawa / North Forge under the ElevateIP program will not be used for any services that are already funded by another government program or grant. Any use of the ElevateIP program funds on services that are already funded by another government program or grant may lead to immediate termination from the ElevateIP program and/or the immediate return of such funding.`
  const attestationTextFr = `Je, ${founderProfile?.personalInfo?.firstName} ${founderProfile?.personalInfo?.lastName}, ai l'autorité légale de représenter ${founderProfile?.companyInfo?.basicInfo?.operatingName} et j'atteste que le soutien fourni par Communitech / Invest Ottawa / North Forge dans le cadre du programme ElevateIP ne sera pas utilisé pour des services qui sont déjà financés par un autre programme gouvernemental ou une subvention.  Toute utilisation des fonds du programme ÉleverlaPI pour des services qui sont déjà financés par un autre programme gouvernemental ou une subvention pourrait entraîner l'annulation immédiate du programme ÉleverlaPI et/ou la demande de restitution immédiate de ces fonds.`


  const modalProfileCheck = () => {
    
    if (!founderProfile) {
      handleSubmissionReject();
      return;
    }

    const { personalInfo, pointOfContact, companyInfo, iPStrategy, programingEvaluation } = founderProfile;

    if (!companyInfo) {
      handleSubmissionReject();
      return;
    }

    const { registration, 
            mailingAddress, 
            basicInfo } = companyInfo;

    if (
      personalInfo === null ||
      (!personalInfo.isPointOfContact && pointOfContact === null) || // is not point of contact but PoC data is missing
      basicInfo === null ||
      (basicInfo.isCompanyNotIncorporated && registration === null) || // is NOT incorporated but registration data is missing
      mailingAddress === null ||
      iPStrategy === null ||
      programingEvaluation === null
    ) {
      handleSubmissionReject();
      return;
    }

    if (isEditing) {
      handleSubmissionReject(textData.Pages.FounderPages.FounderProfile.WhileEditing[langState]);
      return;
    }

    setIsModalOpen(true)
  }


  const communitechAttestation = langState === "fr" ? attestationTextFr : attestationTextEng
  const investOttawaAttestation = langState === "fr" ? attestationTextFr : attestationTextEng

  const modalHTML  = (
    <div className="flex flex-col">
      <div>
          {
            userRegion === "investOttawa" ? <p className="mb-3">{investOttawaAttestation}</p> : <p className="mb-3">{communitechAttestation}</p>
          }
        <div className='flex w-full items-center ml-5 mb-1'>
          <input 
            type="checkbox" 
            checked={isAttestaionChecked}
            onChange={() => setIsAttestaionChecked(!isAttestaionChecked)}
          />
          <p className="ml-5">{textData.Pages.FounderPages.FounderProfile.Confirm[langState]}</p>
        </div>
      </div>
    </div>
  )

  return (
    <div className="overflow-y-auto">
      <ContentPageTopButtons />
      {
      memberApplicationStatus === "unsubmitted" ?
      <div className='flex flex-col justify-center align-middle items-center'>
        <h1 className='text-5xl font-bold text-center mb-5'>{textData.Pages.FounderPages.FounderProfile.Title[langState]}</h1>
          <h2 className="text-xl mb-2 text-center">{textData.Pages.FounderPages.FounderProfile.Subtitle[langState]}</h2>
          <ul className="ml-8 mb-5 list-disc">
              <li className="mb-2 w-auto">{textData.Pages.FounderPages.FounderProfile.Step1[langState]}</li>
              <li className="mb-2">{textData.Pages.FounderPages.FounderProfile.Step2[langState]}</li>
              <li className="mb-2">{textData.Pages.FounderPages.FounderProfile.Step3[langState]}</li>
          </ul>
        <FormGroup 
          formGroupData={founderProfileGroupData} 
          formGroupDefinition={founderProfile?.iPStrategy?.seekingEducation !== undefined ? returnLangCheckedFormGroupDef(founderProfileFormGroupDefinition, langState) : applicationTier === 3 ? returnLangCheckedFormGroupDef(updatedFounderProfileReadinessFormGroupDefinition, langState) : returnLangCheckedFormGroupDef(updatedFounderProfileFormGroupDefinition, langState)}
          saveFormSection={handleSaveSection}
          setIsEditing={setIsEditing}
          isProfilePageOnClick={() => modalProfileCheck()}
          isProfilePage={{
            buttonText: buttonOption.text,
            buttonDisabled: buttonOption.disabled
          }}
        />
      </div>
      :
      <div className="flex flex-col w-full justify-between">
        <DetailsContentHeader 
          label={`${founderProfile?.personalInfo ? `${founderProfile.personalInfo.firstName}'s` : textData.Pages.FounderPages.FounderProfile.New[langState] } ${textData.Pages.FounderPages.FounderProfile.Profile[langState]}`}
          tooltip={textData.Pages.FounderPages.FounderProfile.Tooltip[langState]}
        />

        <FormGroup 
          formGroupData={founderProfileGroupData} 
          formGroupDefinition={founderProfile?.iPStrategy?.seekingEducation !== undefined ? returnLangCheckedFormGroupDef(founderProfileFormGroupDefinition, langState) : applicationTier === 3 ? returnLangCheckedFormGroupDef(updatedFounderProfileReadinessFormGroupDefinition, langState) : returnLangCheckedFormGroupDef(updatedFounderProfileFormGroupDefinition, langState)}
          saveFormSection={handleSaveSection}
          setIsEditing={setIsEditing}
          isProfilePageOnClick={() => modalProfileCheck()}
          isProfilePage={{
            buttonText: buttonOption.text,
            buttonDisabled: buttonOption.disabled
          }}
        />
      </div>
      }  
      <Modal 
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        modalHeader={textData.Pages.FounderPages.FounderProfile.AttestationHeader[langState]}
        contentLabel={textData.Pages.FounderPages.FounderProfile.AttestatioLabel[langState]}
        primaryButton={{ buttonText: "Confirm", buttonCallback: () => isAttestaionChecked ? handleSubmitMembershipApplication() : attestaionPrompt() }}
        modalHTML={modalHTML}
      />
    </div>
  )
}

export default FounderProfile;