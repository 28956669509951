import Button from '../Components/Button';

function Disconnected() {

  const handleRetry = async () => {
    window.location.reload();
  };

  return (
    <div className={`flex bg-background h-full w-full items-center relative overflow-hidden justify-start flex-col text-text`}>
      <div className='flex flex-col w-[500px] grow'>
        <div className='w-100 text-center mt-10'>
          <h1 className='text-3xl'>Network Disconnected</h1>
          <p className='my-5'>Please check your network connection and try again.</p>
          <Button 
            buttonText='Retry'
            buttonType='primary'
            actionHandler={() => handleRetry()}
          />
        </div>
      </div>
    </div>
  )
}

export default Disconnected;