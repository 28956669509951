import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from "../../../utilities/customHooks";
import { getUserProfile, updateUserProfile } from "../../../services/userService";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import * as T from "../../../utilities/frontendTypes";
import Form, { FormData } from "../../../Components/Form/Form";
import { useContext, useState } from "react";
import { adminProfileFormDefinition } from "../../../utilities/formDefinitionTemplates";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { contentPageWrapperStyle } from "./PartnerAdminList";
import { DetailsContentHeader, formContainerStyle, formWrapperStyle } from "./NewAdmin";
import { handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { ButtonProp } from "../../../Components/Button";
import textData from "../../../textData.json";

function AdminProfile() {
  const { langState } = useContext(LangContext) as LangContextType

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const [formDisabledState, setFormDisabledState] = useState(true);

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const adminProfileAsync = useAsync(() => getUserProfile("partnerAdmin"), []);

  if (adminProfileAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (adminProfileAsync.status === "rejected") {
    return <ErrorIndicator />;
  }
  
  const adminProfile = adminProfileAsync.value.profile as T.AdminProfile | null;

  async function handleSave(updatedFormData: FormData) { 
    showLoading();
    const response = await updateUserProfile("partnerAdmin", updatedFormData as T.AdminProfile);
    hideLoading();

    if (response.success) setFormDisabledState(true);
    handleFormSaveResponse(response, showToast, {message: textData.Pages.AdminPages.ProfilePages.AdminProfile.ProfileUpdated[langState]});
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.AdminPages.ProfilePages.AdminProfile.Save[langState],
    actionHandler: handleSave,
  }

  return (
    <div className={contentPageWrapperStyle}>
      <ContentPageTopButtons 
        editCallback={() => setFormDisabledState(!formDisabledState)}
      />
      <DetailsContentHeader 
        label={`${adminProfile ? `${adminProfile.firstName}'s` : textData.Pages.AdminPages.ProfilePages.AdminProfile.New[langState] } ${textData.Pages.AdminPages.ProfilePages.AdminProfile.Profile[langState]}`}
        tooltip={textData.Pages.AdminPages.ProfilePages.AdminProfile.Tooltip[langState]}
      />
      <div className={formContainerStyle}> 
        <div className={formWrapperStyle}>
          <Form
            formData={adminProfile}
            formDefinition={adminProfileFormDefinition}
            primaryButtonProp={primaryButtonProp}
            disabled={formDisabledState}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminProfile;