import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import List from "../../../Components/List";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getRegionFounderDetailsAsync } from "../../../services/adminService";
import { useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"

const NoProfile = () => {
    
  const founderDetailsAsync = useAsync(() => getRegionFounderDetailsAsync(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const { foundersWithNoApplications } = founderDetailsAsync.value.regionalFounderDetails;


  const columnHeaderData = [
    {
      header: "Email",
      key: "email"
    },
    {
      header: "Date Registered",
      key: "createdAt"
    }
  ]

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={"Accounts Created with No Profile Submitted"}
          tooltip={"This is a list of all the companies who have created an account on the EIP Portal but have not completed and submitted a profile"}
        />
        <List 
          contentArray={foundersWithNoApplications}
          columnHeaderData={columnHeaderData}
        />
      </div>
    </div>  
  )
}

export default NoProfile