import { useAsync } from '../../utilities/customHooks';
import LoadingIndicator from '../LoadingIndicator';
import ErrorIndicator from '../ErrorIndicator';
import { getDownloadUrl } from '../../services/adminService';


const PdfDownloader = (
  {docKey} 
  : 
  {docKey: string}
  ) => {
    const getUrl = useAsync(() => getDownloadUrl(docKey), []);
    console.log(docKey)

    if (getUrl.status === "pending") {
      return <LoadingIndicator />;
    }
  
    if (getUrl.status === "rejected") {
      return <ErrorIndicator />;
    }
    const response = getUrl.value.url
  
  return (
    <div className='w-full mt-2'>
        <iframe title="pdfViewer" src={response} width="100%" height="100%" className='m-auto pdfViewWindow'></iframe>
    </div>
  );
};

export default PdfDownloader;