import { Coordinates } from './Form/AddressFields';
import { useContext, useEffect, useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, InfoWindowF, Marker } from '@react-google-maps/api';
import * as T from "../utilities/frontendTypes";
import { addressToString, getRegionNameFromRegion } from '../utilities/helperFunctions';
import LoadingIndicator from './LoadingIndicator';
import { AuthContext, AuthContextType, LangContext, LangContextType } from '../utilities/customHooks';
import textData from "../textData.json";
import Button from './Button';

export default function BasicGoogleMap (
  {
    center,
    zoom,
    markers,
    returnDetailsId,
  }
  :
  {
    center?: Coordinates,
    zoom?: number,
    markers: T.SPFirmHeader[],
    returnDetailsId: (id: string) => void,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const { settings } = useContext(AuthContext) as AuthContextType;
  const [ firmlistHtml, setFirmListHtml ] = useState(<></>)

  const mapOptions = {
    center: {
      lat: 43.450896,
      lng: -80.49791979999999,
    },
    zoom: 10,
  };

  // const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY; // protected and restricted google maps API key, should try to remain hidden as much as possible
  const googleMapsApiKey = "AIzaSyD8WcCw-5NY39GnYtJG_HncrdZT4YI4Ybk"
  if (!googleMapsApiKey) throw new Error ("Maps API Key not found in environment");
  const { isLoaded } = useJsApiLoader({ // make sure 'google' is loaded and ready to use
    id: 'google-map-script',
    googleMapsApiKey,
  })

  const containerStyle = {
    width: '100%',
    height: '100%',
    marginBottom: '1.25rem'
  };

  type MapOptions = google.maps.MapOptions;

  const [hoveringFirmId, setHoveringFirmId] = useState(''); // for hovering over markers
  const memoizedCenter = useMemo<google.maps.LatLngLiteral>(() => (center || mapOptions.center), [center, mapOptions.center]); // prevent accidental centering on unintended rerender
  const options = useMemo<MapOptions>(
    () => ({
      mapId: settings?.themeMode === "dark" ? "6dbb72a7510f6fb9" : "e5bcc833c8ce47fc", // [NOTE] map id, safe to expose
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    [settings?.themeMode]
  );

const firmCardsHtml = markers.map ((marker) => {
  let urlString = ""
  if (marker.url) {
    urlString = `https://${marker.url}`
  }
  return (
    <div className='border border-cSage w-full'>
      <button
        className='p-4 pb-4 w-full'
        key={marker.firmId}
        onClick={() => returnDetailsId(marker.firmId)}
        onMouseOver={() => setHoveringFirmId(marker.firmId)}
        onFocus={() => setHoveringFirmId(marker.firmId)}
        onMouseOut={() => setHoveringFirmId('')}
        onBlur={() => setHoveringFirmId('')}
      >
        <div className='flex flex-col text-left w-full'>
          <p className='font-semibold mb-2 bodyLarger'>{marker.name}</p>
          <p className='small'>{addressToString(marker.address)}</p>
          {marker.url &&
          <a className='spFirmMapButton mt-4' target='blank' href={urlString}>Visit Website</a>
          }
        </div>
      </button>
    </div>
    )
})

  useEffect( ()=> {

  },[hoveringFirmId])

  return (
    isLoaded 
    ? 
    (<div className='w-full !max-h-screen flex'>
      <div className='flex flex-col w-1/4 overflow-y-auto'>
        {firmCardsHtml}
      </div>
      <div className='h-full w-3/4 '>
        {/* // The map */}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={memoizedCenter}
            zoom={zoom || mapOptions.zoom}
            options={options}
          >
            {
              // Markers on the map
              markers.map( (markerData) => {
                const { position, firmId, name, address, url } = markerData;
                let urlString = ""
                if (url) {
                  urlString = `https://${url}`
                }
                return (
                  <MarkerF
                    position={position} 
                    key={firmId}
                    onClick={() => returnDetailsId(firmId)}
                    onMouseOver={() => setHoveringFirmId(firmId)}
                    onMouseOut={() => setHoveringFirmId('')}
                  >
                    {
                      // Each Marker shows an InfoWindow when hovered
                      hoveringFirmId === firmId &&
                      <InfoWindowF
                        position={position}
                      >
                        <div className='text-black pt-3 w-full'>
                          <p className='font-semibold mb-2 bodyLarger'>{name}</p>
                          <p>{addressToString(address)}</p>
                          {/* {url &&
                          <a className='spFirmMapButton mt-4' target='blank' href={urlString}>Visit Website</a>
                          } */}
                        </div>

                      </InfoWindowF>
                    }
                  </MarkerF>
                )
              })
            }
          </GoogleMap>
        </div>
      </div>) 
    : 
    <LoadingIndicator />
  )
};