import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType, useAsync } from "../../utilities/customHooks";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import { useContext } from "react";
import List from "../../Components/List";
import * as T from "../../utilities/frontendTypes";
import { getProviderDashboard } from "../../services/serviceProvider";


const ProviderDashboard = () => {

  const navigate = useNavigate();
  const columnHeaderData = [
    {
      header: "Startup",
      key: "companyName"
    },
    {
      header: "Status",
      key: "actionItem",
      sortOptions: [`New SoW Request`, "Awaiting Startup Approval", `Edit Request from Startup`, "Awaiting Communitech Approval", "Edit Requested from Communitech", "Approved & Ready for Signatures", "Ready to Begin Work", "Work In Progress", "Ready for Invoice", `Project Closed`]
    },
    {
      header: "Tier",
      key: "tier"
    },
    {
      header: "Last Updated",
      key: "lastUpdated"
    },
    {
      header: "Contact",
      key: "contactPerson"
    }
  ]

  const providerDashboardAsync = useAsync(() => getProviderDashboard(), []);

  if (providerDashboardAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (providerDashboardAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const handleViewMemberDetails = (memberHeaderUserId: string) => {
    navigate((`/dashboard/expert-assessments/${memberHeaderUserId}`));
  }

  const contentArray = providerDashboardAsync.value.sowArray;

  return (
    <div className="resourcesCont !pt-0 h-full">
      <h2 className="">Welcome Back, {providerDashboardAsync.value.name}</h2>
      {
        contentArray.length === 0 ?
          <h4 className="">No Statements of Work currently available</h4>
        :
          <List 
            contentArray={contentArray}
            columnHeaderData={columnHeaderData}
            returnDetailsId={handleViewMemberDetails}
            detailsIdName="memberId"
            isReverse
          />
      }
    </div>
  )
}

export default ProviderDashboard