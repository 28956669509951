import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { getSPFirms } from "../../../services/adminService";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { OutletContextType, useAsync, useAuth } from "../../../utilities/customHooks";
import Button from "../../../Components/Button";
import List, { KeyValuePair } from "../../../Components/List";
import * as T from "../../../utilities/frontendTypes";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList";
import { useState } from "react";
import BasicGoogleMap from "../../../Components/BasicGoogleMap";
import { organizations } from "../../../utilities/organizationData";
import Toggle from "../../../Components/Input/Toggle";
import { getLastPartOfPath } from "../../../utilities/helperFunctions";

function FirmList() {

  const navigate = useNavigate();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<"list" | "map">('list');
  const {userRegion} = useAuth();

  const regionalFirmsAsync = useAsync(() => getSPFirms(), [location]);

  if (regionalFirmsAsync.status === "pending" || userRegion === null) {
    return <LoadingIndicator />;
  }

  if (regionalFirmsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const regionalFirmHeaderData: T.SPFirmHeader[] = regionalFirmsAsync.value.data;
  const regionalFirmHeaders = regionalFirmHeaderData.map( header => (
    {
      ...header,
      isActive: header.isActive ? "Active" : "Inactive",
    }
  ));

  const handleViewFirmDetails = (id: T.FirmId) => {
    navigate(`/dashboard/sp-firms/${id}`)
  }

  return (
      <div className="resourcesCont !pt-0 !h-full">
        <div className="flex flex-col w-full">
          <h2>Service Providers</h2>
          <h4>Here are the service providers we partner with to deliver our funded services.</h4>
          <div className="flex justify-between w-full mt-6">
          <div className="flex gap-4 items-center my-4">  
            <p className="fontLarger font-semibold">List View</p>
              <Toggle
                label=""
                isDefaultChecked={viewMode === "map"}
                toggleActionCallback={() => setViewMode( m => m === "list" ? "map" : "list")}
              />
            <p className="fontLarger font-semibold">Map View</p>
          </div>
          <Button 
            buttonType={"primary"} 
            buttonText={"Add New Firm"} 
            actionHandler={() => {navigate('/dashboard/sp-firms/create')}}      
          />
          </div>
        </div>
       
        {
          viewMode === "list" &&
          <List 
            contentArray={regionalFirmHeaders as KeyValuePair[]}
            columnHeaderData={FirmListColumnHeaderData}
            detailsIdName="firmId"
            returnDetailsId={handleViewFirmDetails}
            listHighlightId={getLastPartOfPath(location.pathname)}
          /> 
        }
        {
          viewMode === "map" &&
          <BasicGoogleMap 
            center={organizations.find(org => org.name === userRegion)?.coordinates} // [TODO] ? style
            zoom={8}
            markers={regionalFirmHeaderData}
            returnDetailsId={handleViewFirmDetails}
          />
        }
        <div className="mt-5">
          Disclaimer: Please note that all services listed may not be financed by ISED, therefore ElevateIP, funding.
        </div>
    </div>
  )
}

export default FirmList;

export const FirmListColumnHeaderData = [
  {
    header: "Firm Name",
    key: "name",
  },
  {
    header: "Location",
    key: "location",
  },
  {
    header: "Web Site",
    key: "url",
  }
];