import { useNavigate } from "react-router-dom";
import { useAsync } from "../utilities/customHooks";
import LoadingIndicator from "./LoadingIndicator";
import ErrorIndicator from "./ErrorIndicator";
import { getRegionStatsAsync } from "../services/adminService";

const AdminDashboardStats = () => {
  const navigate = useNavigate();
  const founderDetailsAsync = useAsync(() => getRegionStatsAsync(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }
  
  const regionDetails = founderDetailsAsync.value.regionalDetails;
  const { awaitingReview, screened, unsubmitted, tier2Startups, tier3Startups, archived, allStartup } = regionDetails
  let meetingIpe = 0
  if (regionDetails.meetingIpe) {
    meetingIpe = regionDetails.meetingIpe
  }
  return (
    <div className="flex justify-between w-full flex-wrap">
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard/pending-applications")}>{awaitingReview}</button>
        <p className="text-center">new applications <br></br>awaiting review</p>
      </div>
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard/admin-expert-preinvite")}>{screened}</button>
        <p className="text-center">startups awaiting <br></br>IPE invitation</p>
      </div>
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard")}>{tier2Startups}</button>
        <p className="text-center">Tier 2 <br></br>startups</p>
      </div>
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard")}>{archived}</button>
        <p className="text-center">archived<br></br>startups</p>
      </div>
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard")}>{unsubmitted}</button>
        <p className="text-center">unsubmitted <br></br>applications</p>
      </div>
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard")}>{meetingIpe}</button>
        <p className="text-center">startups currently <br></br>meeting with IPE</p>
      </div>
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard")}>{tier3Startups}</button>
        <p className="text-center">Tier 3 <br></br>startups</p>
      </div>
      <div className="adminStatCont">
        <button className="h2 hover:underline" onClick={() => navigate("/dashboard")}>{allStartup}</button>
        <p className="text-center">total startups <br></br>served, ever</p>
      </div>
    </div>
  )
}

export default AdminDashboardStats