import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getInProgressDetailsAsync } from "../../../services/adminService";
import {  useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"

import List from "../../../Components/List";

const InProgress = () => {

  const inProgressFoundersAsync = useAsync(() => getInProgressDetailsAsync(), []);

  if (inProgressFoundersAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (inProgressFoundersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const inProgressFoundersArray = inProgressFoundersAsync.value.inProgressFoundersArray

  const columnHeaderData = [
    {
      header: "Company Name",
      key: "companyName"
    },
    {
      header: "Last Meeting Date",
      key: "date"
    }
  ]

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={"Companies who have met an IP Expert at Least Once"}
          tooltip={"This is a list of all the companies who have met with an IP Expert but for whom no recommendation has been submitted"}
        />
        <List 
          contentArray={inProgressFoundersArray}
          columnHeaderData={columnHeaderData}
        />
      </div>
    </div>  
  )
}

export default InProgress