import { useState } from 'react';

const Toggle = (
  { 
    label,
    isDefaultChecked,
    toggleActionCallback,
  }
  :
  {
    label: string,
    isDefaultChecked?: boolean,
    toggleActionCallback: (isChecked: boolean) => void,
  }
) => {

  const [isChecked, setIsChecked] = useState(isDefaultChecked || false); // component level UI state

  const toggleCheckbox = () => { // when toggled, update internal UI state as well as parent logic state 
                                 // this setup allows easy separation if we want to make toggleActionCallback optional
    toggleActionCallback(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <div className="flex items-center">
      {/* label click also triggers toggle */}
      <label htmlFor="toggle" className="cursor-pointer">
        {label}
      </label>
      {/* Toggle's functionality-part and ui-part are combined as one clickable button(div) */}
      <button
        onClick={toggleCheckbox}
        className={`${
          isChecked ? 'bg-cSecondaryGreen' : 'bg-cGreen'
        } w-12 h-6 rounded-full relative transition-colors`}
      >
        {/* Toggle is a hidden checkbox underneath */}
        <input
          type="checkbox"
          id="toggle"
          className="absolute w-0 h-0 opacity-0 hidden"
          checked={isChecked}
          onChange={toggleCheckbox}
        />
        {/* create the UI for Toggle */}
        <div
          className={`${isChecked ? 'translate-x-6 bg-background' : 'translate-x-0 bg-white'} 
            w-5 h-5 rounded-full absolute top-0.5 left-0.5 transition-transform `}
        />
      </button>
    </div>
  );
};

export default Toggle;