import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType, useAsync } from "../../utilities/customHooks";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import { getGrowthCoachDashboard } from "../../services/adminService";
import { useContext } from "react";
import List from "../../Components/List";
import * as T from "../../utilities/frontendTypes";
import { checkUrlAndModifySpan } from "../../utilities/helperFunctions";


const GrowthCoachDashboard = () => {
  const currentUrl = window.location.pathname; 
  checkUrlAndModifySpan(currentUrl)
  const navigate = useNavigate();
  const membershipsColumnHeaderData = [
    {
      header: "Startup",
      key: "companyName"
    },
    {
      header: "Application Date",
      key: "date"
    },
    {
      header: "Industry",
      key: "industry"
    },
    {
      header: "Status",
      key: "status",
      sortbyKeys: ["New Request", "Review in Progress"]
    },
    {
      header: "Days in Review",
      key: "daysInReview"
    }
  ]

  const founderDetailsAsync = useAsync(() => getGrowthCoachDashboard(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const handleViewMemberDetails = (memberHeaderUserId: string) => {
    navigate((`/dashboard/growth-coach-assessment/${memberHeaderUserId}`));
  }

  const contentArray = founderDetailsAsync.value.foundersArray;

  return (
    <div className="resourcesCont !pt-0 h-full">
      <h2 className="">Welcome Back, {founderDetailsAsync.value.name}</h2>
      <h4>New Growth Coach Requests</h4>
      <List 
        contentArray={contentArray}
        columnHeaderData={membershipsColumnHeaderData}
        returnDetailsId={handleViewMemberDetails}
        detailsIdName="id"
        isReverse
      />
    </div>
  )
}

export default GrowthCoachDashboard