import Form, { FormData } from "../Components/Form/Form";
import { helpFormDefition } from "../utilities/formDefinitionTemplates";
import { ButtonProp } from "../Components/Button";
import { useContext, useEffect, useState } from "react";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../utilities/customHooks";
import { useNavigate } from "react-router-dom";
import { handleFormSaveResponse, returnLangCheckedFormDef } from "../utilities/helperFunctions";
import { sendHelpEmail } from "../services/founderService";
import textData from "../textData.json";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import Plus from '../assets/Plus.png'
import Minus from '../assets/Minus.png'
import { faL } from "@fortawesome/free-solid-svg-icons";


const Glossary = () => {
  const [childClickedAccordionItemIndex, setChildClickedAccordionItemIndex] = useState(0);
  const { langState } = useContext(LangContext) as LangContextType
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const navigate = useNavigate();
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  return (
    <div className="flex flex-col items-center w-full">
        <h1 className="title mb-4">{textData.Pages.Help.Glossary[langState]}</h1>
        <p className="bodyLarger mb-8">{textData.Pages.Help.GlossarySub[langState]}</p>
      {langState === "eng" ?
      <div className="glossaryCont">
        <div className="flex glossarySect">
          <h3 className="glossaryLetter title font-semibold">B</h3>
          <div className="gap-8 flex flex-col">
            <div className="glossarySectCont">
              <h3 className="font-semibold">BAI</h3>
              <p>Business accelerators and incubators (BAIs) play a key role in the start and scale journey of many Canadian startups. While at their core all BAIs offer important startup services, there is considerable diversity amongst these organizations across Canada.</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">BN - Federal business number</h3>
              <p>The <span className="font-semibold">business number (BN)</span>, assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  <span className="font-semibold">If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.</span>  For more details on what a BN is, visit <a className="underline" target="_blank" href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html">this website.</a></p>
            </div>
          </div>
        </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">C</span>
          <div>
          <div className="glossarySectCont">
            <h3 className="font-semibold underline"><a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/corporations/type-corporation.html#ccpc">CCPC</a></h3>
            <div className="">
              <p>Canadian-controlled private corporation - The corporation is a CCPC if it meets all of the following requirements at the end of the tax year:</p>
              <ul className="list-disc ml-8">
                <li>it is a private corporation</li>
                <li>it is a corporation that was resident in Canada and was either incorporated in Canada or resident in Canada from June 18, 1971, to the end of the tax year it is not controlled directly or indirectly by one or more non-resident persons</li>
                <li>it is not controlled directly or indirectly by one or more non-resident persons</li>
                <li>it is not controlled directly or indirectly by one or more public corporations (other than a prescribed venture capital corporation, as defined in Regulation 6700 of the Income Tax Regulations)</li>
                <li>it is not controlled by a Canadian resident corporation that lists its shares on a designated stock exchange outside of Canada</li>
                <li>it is not controlled directly or indirectly by any combination of persons described in the three previous conditions</li>
                <li>if all of its shares that are owned by a non-resident person, by a public corporation (other than a prescribed venture capital corporation), or by a corporation with a class of shares listed on a designated stock exchange were owned by one person, that person would not own sufficient shares to control the corporation</li>
                <li>no class of its shares of capital stock is listed on a designated stock exchange</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">E</span>
          <div className="gap-8 flex flex-col">
            <div className=" glossarySectCont">
              <h3 className="font-semibold">EIP</h3>
              <p>ElevateIP - Elevate Intellectual Property (EIP) is a Federally funded program by ISED Canada (Innovation, Science and Economic Development Canada). Startups may apply to the program via the ElevateIP portal: elevate-ip.ca</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Environmental goal</h3>
              <p>Refers to the goals that a startup's activities, products or services have on the natural environment. This can include factors such as carbon footprint, resource usage, waste generation and overall sustainability practices. </p>
            </div>
          </div>
        </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">F</span>
          <div className="gap-8 flex flex-col">
            <div className=" glossarySectCont">
              <h3 className="font-semibold">Federal business number (BN)</h3>
              <p>The <span className="font-semibold">business number (BN)</span>, assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  <span className="font-semibold">If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.</span>  For more details on what a BN is, visit <a className="underline" target="_blank" href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html">this website.</a></p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Fiscal year (FY)</h3>
              <p>A business year defined for accounting purposes. For example, the Communitech fiscal year (FY) begins April 1, and ends March 31.</p>
            </div>
          </div>
        </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">I</span>
          <div className="gap-8 flex flex-col">
            <div className="glossarySectCont">
              <h3 className="font-semibold underline"><a target="_blank" href="https://www.investottawa.ca/">Invest Ottawa</a></h3>
              <p>A partner of Communitech also delivering ElevateIP support and services for companies with headquarters in Ottawa.</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">IP expert / advisor</h3>
              <p>An in-house staff member at Communitech with thorough knowledge of the intellectual property landscape in all industries. These individuals help the ElevateIP team at Communitech make informed decisions around statements of work to ensure the Federal funding is being spent strategically and responsibly. They are responsible for conducting IP Assessment interviews and providing IP coaching sessions to startups.</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">IP</h3>
              <p>Intellectual property (patents, trademarks, trade secrets, copyrights, industrial designs, etc.)</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">ISED</h3>
              <p>Innovation Science and Economic Development Canada, a Federal branch of the government and a funder of ElevateIP work at Communitech, Invest Ottawa and North Forge.</p>
            </div>
          </div>
        </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">N</span>
          <div className="glossarySectCont">
            <h3 className="font-semibold underline"><a target="_blank" href="https://northforge.ca/">North Forge</a></h3>
            <p>A partner of Communitech also delivering ElevateIP support and services for companies with headquarters located in Manitoba and Saskatchewan provinces.</p>
          </div>
        </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">P</span>
          <div className="gap-8 flex flex-col">
            <div className="glossarySectCont">
              <h3 className="font-semibold">Patent family</h3>
              <p>A patent family is a set of interrelated patent applications filed at one or more patent offices (in one or more countries) and typically has the same originating application, aiming to protect the same invention. For example, to seek patent protection for an invention in the U.S., Canada, and Europe, an inventor may file a patent application in each of the U.S., Canada and Europe. All three patent applications will then collectively be referred to as a patent family. Another example of a patent family is a set of provisional patent applications and non-provisional patent applications filed with the U.S. patent office for the same invention.</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">PCT (patent cooperation treaty)</h3>
              <p>A PCT application is an international patent application (a placeholder patent application) that preserves your priority date and also delays the date by which you need to file foreign patent applications. Many countries are members of the PCT and you can file a foreign patent application in any of those member countries which claims priority to a PCT application.  If you file a PCT application then you will be able to delay filing any foreign patent applications until 30 months after the PCT application’s priority date. (Some countries and regional patents permit filing 31, 34, or even 42 months after the PCT application’s priority date.)</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Portal / EIP portal</h3>
              <p>The platform used by ElevateIP so you can check your application status and browse educational resources. You are currently on the EIP portal!</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Priority date</h3>
              <p>Priority date refers to the earliest filing date in a family of patent applications. Where only a single patent application is involved, the priority date would be the filing date of that patent application. If an applicant has filed a number of related patent applications, the priority date would be the filing date of the earliest patent application that first disclosed the invention.  The priority date is how it is determined whether another patent filing or publicly available document qualifies as a prior art against your patent application.</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Provisional patent application (PPA)</h3>
              <p>A provisional patent application is an informal patent application, typically filed with the U.S. patent office, to establish a filing date for a formal patent application that is to be filed within a 12-month period of the provisional patent application filing date. It helps protect an invention from being copied during the 12-month period before the formal patent application is filed. It is intended to give an inventor time to pitch the idea, test its commercial feasibility, and/or refine a product before committing to the expensive and time-intensive process of a formal application.</p>
            </div>
          </div>
        </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">S</span>
          <div className="gap-8 flex flex-col">
            <div className="glossarySectCont">
              <h3 className="font-semibold">Service provider</h3>
              <p>The IP practitioner the startup chooses to work with. This organization must be onboarded and have signed a service agreement with Communitech before they can begin drafting statements of work (SOW)s for approval</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Social goal</h3>
              <p>Tangible and positive effects that a startup's activities, products, or services have on society and communities. This can be measured as contributions to various social causes, the extent to which it addresses societal challenges and its efforts to create shared value for all stakeholders.</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">SOW</h3>
              <p>Statement of work; the project document that defines the work being done by the startup and service provider including activities, timelines, deliverables and quotes.</p>
            </div>
          </div>
        </div>
        <div className="flex glossarySect">
          <span className="glossaryLetter title font-semibold">T</span>
          <div className="gap-8 flex flex-col">
            <div className="glossarySectCont">
              <h3 className="font-semibold">Trademark</h3>
              <p>A trademark can be any word, phrase, symbol, design or a combination of these things that identifies your goods or services. It’s how customers recognize you in the marketplace and distinguish you from your competitors.  The word “trademark” can refer to both trademarks and service marks. A trademark is used for goods, while a service mark is used for services.</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Tier 1 / Education</h3>
              <p>The first stage of ElevateIP programming involving free education around IP topics; startups must meet the minimum requirements as indicated in our <a className="underline" href="/dashboard/program-details#FAQ">FAQ page</a></p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Tier 2 / Strategy Development</h3>
              <p>The second stage of ElevateIP programming involving funding support for commercialization strategy, branding/trademark strategy, patentability/novelty/trademark clearance searches and other related services such as coaching sessions with IP expert</p>
            </div>
            <div className="glossarySectCont">
              <h3 className="font-semibold">Tier 3 / Strategy Implementation</h3>
              <p>The third stage of ElevateIP programming involving funding support for patent filings and prosecution, trademark registration and other related services. For a filing to be done in Tier 3, a review of search results must be conducted with an IP expert / advisor prior to approval.</p>
            </div>
          </div>
        </div>
      </div>
      :
      <div className="glossaryCont">
      <div className="flex glossarySect">
        <h3 className="glossaryLetter title font-semibold">A</h3>
        <div className="gap-8 flex flex-col">
          <div className="glossarySectCont">
            <h3 className="font-semibold">AIE</h3>
            <p>Les accélérateurs et incubateurs d'entreprises (AIE) jouent un rôle clé dans le démarrage et le développement de nombreuses jeunes entreprises canadiennes. Bien que tous les AIE offrent essentiellement des services de démarrage importants, il existe une diversité considérable parmi ces organisations à travers le Canada.</p>
          </div>
        </div>
      </div>
      <div className="flex glossarySect">
        <h3 className="glossaryLetter title font-semibold">D</h3>
        <div className="gap-8 flex flex-col">
          <div className="glossarySectCont">
            <h3 className="font-semibold">Date de priorité</h3>
            <p> La date de priorité fait référence à la date de dépôt la plus ancienne dans une famille de demandes de brevet. Lorsqu'il s'agit d'une seule demande de brevet, la date de priorité sera la date de dépôt de cette demande de brevet. Si un demandeur a déposé un certain nombre de demandes de brevet liées, la date de priorité sera la date de dépôt de la première demande de brevet qui a divulgué l'invention en premier.
La date de priorité permet de déterminer si un autre dépôt de brevet ou un document accessible au public peut être considéré comme une réalisation antérieure par rapport à votre demande de brevet.</p>
          </div>
          <div className="glossarySectCont">
            <h3 className="font-semibold">Demande de brevet provisoire (PPA)</h3>
            <p>Une demande de brevet provisoire est une demande de brevet informelle, généralement déposée auprès du bureau des brevets des États-Unis, pour établir une date de dépôt pour une demande de brevet officielle qui doit être déposée dans un délai de 12 mois à compter de la date de dépôt de la demande de brevet provisoire. Elle permet de protéger une invention contre toute copie pendant la période de 12 mois précédant le dépôt officiel de la demande de brevet. L’objectif est de donner à un inventeur le temps de présenter son idée, de tester sa faisabilité commerciale et/ou d’affiner un produit avant de s’engager dans le processus coûteux et chronophage d’une demande formelle.</p>
          </div>
        </div>
      </div>
      <div className="flex glossarySect">
        <span className="glossaryLetter title font-semibold">E</span>
        <div>
        <div className="glossarySectCont">
          <h3 className="font-semibold underline"><a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/corporations/type-corporation.html#ccpc">CCPC</a></h3>
          <div className="">
            <p>La société est une EPCC si elle répond à toutes les exigences suivantes à la fin de l'année d'imposition :</p>
            <ul className="list-disc ml-8">
              <li>elle est une société privée;</li>
              <li>elle une société qui résidait au Canada et qui était constituée au Canada ou résidait au Canada du 18 juin 1971 jusqu'à la fin de l'année d’imposition;</li>
              <li>elle n'est pas contrôlée directement ou indirectement par une ou plusieurs personnes non résidentes;</li>
              <li>elle n'est pas contrôlée, directement ou indirectement, par une ou plusieurs sociétés publiques (autres que des sociétés à capital de risque visées par règlement, au sens de l'article 6700 du Règlement de l'impôt sur le revenu);</li>
              <li>elle n'est pas contrôlée par une société résidente du Canada dont les actions sont cotées à une bourse de valeurs désignée à l'extérieur du Canada;</li>
              <li>elle n'est pas contrôlée, directement ou indirectement, par une combinaison quelconque de personnes mentionnées aux trois points précédents;elle n'est pas contrôlée, directement ou indirectement, par une combinaison quelconque de personnes mentionnées aux trois points précédents;</li>
              <li>aucune catégorie d'actions du capital-actions n'est cotée à une bourse de valeurs désignée;</li>
            </ul>
          </div>
        </div>
      </div>
        <div className="gap-8 flex flex-col">
          <div className=" glossarySectCont">
            <h3 className="font-semibold">EDT</h3>
            <p>Énoncé de travail; le document de projet qui définit le travail effectué par l’entreprise en démarrage et le fournisseur de services, y compris les activités, les délais, les livrables et les devis.</p>
          </div>
          <div className=" glossarySectCont">
            <h3 className="font-semibold">EPI</h3>
            <p>Élever la propriété intellectuelle (EPI) est un programme de ISDE Canada (Innovation, Sciences et Développement économique Canada) financé par le gouvernement fédéral. Les entreprises en démarrage peuvent postuler au programme par le biais du portail d’ÉleverlaPI : elevate-ip.ca.</p>
          </div>
          <div className="glossarySectCont">
            <h3 className="font-semibold">Exercice financier (EF)</h3>
            <p>Un exercice financier défini à des fins comptables. Par exemple, l’exercice financier de Communitech commence le 1er avril et se termine le 31 mars.</p>
          </div>
          <div className="glossarySectCont">
            <h3 className="font-semibold">Expert/conseiller en propriété intellectuelle</h3>
            <p>Un membre du personnel interne de Communitech ayant une connaissance approfondie du monde de la propriété intellectuelle dans tous les secteurs. Ces personnes aident l’équipe d’ÉleverlaPI de Communitech à prendre des décisions éclairées concernant les énoncés de travail afin de garantir que le financement fédéral soit dépensé de manière stratégique et responsable. Ces experts sont chargés de mener des entretiens d'évaluation de la PI et de proposer aux entreprises en démarrage des séances de coaching en propriété intellectuelle.</p>
          </div>
        </div>
      </div>
      <div className="flex glossarySect">
        <span className="glossaryLetter title font-semibold">F</span>
        <div className="gap-8 flex flex-col">
          <div className="glossarySectCont">
            <h3 className="font-semibold">Famille de brevets</h3>
            <p>Une famille de brevets est un ensemble de demandes de brevet interdépendantes déposées auprès d'un ou de plusieurs bureaux des brevets (dans un ou plusieurs pays) et ayant généralement la même demande d'origine, visant à protéger la même invention. Par exemple, pour demander la protection par brevet d’une invention aux États-Unis, au Canada et en Europe, un inventeur peut déposer une demande de brevet aux États-Unis, au Canada et en Europe. Les trois demandes de brevet seront alors collectivement désignées comme une famille de brevets. Un autre exemple de famille de brevets est un ensemble de demandes de brevet provisoires et de demandes de brevet non provisoires déposées auprès du bureau des brevets américain pour la même invention.</p>
          </div>
          <div className="glossarySectCont">
            <h3 className="font-semibold">Fournisseur de services</h3>
            <p>Le professionnel en PI avec lequel l’entreprise en démarrage choisit de travailler. Cette organisation doit être intégrée au programme d’ÉleverlaPI et avoir signé un accord de service avec Communitech avant de pouvoir commencer à rédiger des énoncés de travail (EDT) pour approbation.</p>
          </div>
        </div>
      </div>
      <div className="flex glossarySect">
        <span className="glossaryLetter title font-semibold">I</span>
        <div className="gap-8 flex flex-col">
          <div className="glossarySectCont">
            <h3 className="font-semibold underline"><a target="_blank" href="https://www.investottawa.ca/">Investir Ottawa</a></h3>
            <p>Un partenaire de Communitech fournissant également du soutien et des services d’ÉleverlaPI aux entreprises dont le siège social est à Ottawa.</p>
          </div>
          <div className="glossarySectCont">
            <h3 className="font-semibold">ISDE</h3>
            <p>ISDE - Innovation, Sciences et Développement économique Canada, une section fédérale du gouvernement et un bailleur de fonds des services d’ÉleverlaPI offerts chez Communitech, Investir Ottawa et North Forge.</p>
          </div>
        </div>
      </div>
      <div className="flex glossarySect">
        <span className="glossaryLetter title font-semibold">M</span>
        <div className="glossarySectCont">
          <h3 className="font-semibold underline"><a target="_blank" href="https://northforge.ca/">Marque de commerce</a></h3>
          <p>Une marque de commerce peut être n'importe quel mot, phrase, symbole, dessin ou une combinaison de ces éléments qui identifie vos produits ou services. C'est la façon dont les clients vous reconnaissent sur le marché et vous distinguent de vos concurrents.
            Le terme « marque de commerce » peut désigner à la fois des marques commerciales et des marques de service. Une marque de commerce est utilisée pour des produits, tandis qu'une marque de service est utilisée pour des services.
          </p>
        </div>
      </div>
      <div className="flex glossarySect">
        <span className="glossaryLetter title font-semibold">N</span>
        <div className="glossarySectCont">
          <h3 className="font-semibold underline">Niveau 1/Apprentissage</h3>
          <p> La première étape de la programmation d’ÉleverlaPI impliquant une formation gratuite sur des sujets liés à la PI; les entreprises en démarrage doivent répondre aux exigences minimales comme indiqué dans notre page <a className="underline" href="/dashboard/program-details#FAQ">FAQ</a></p>
        </div>
        <div className="glossarySectCont">
          <h3 className="font-semibold underline">Niveau 2/Développement de stratégie</h3>
          <p>La deuxième étape de la programmation d’ÉleverlaPI comportant un soutien financier pour la stratégie de commercialisation, la stratégie de marque/marque de commerce, les recherches de brevetabilité/nouveauté/autorisation de marque de commerce et d'autres services connexes tels que des séances d’accompagnement professionnel avec un expert en PI.</p>
        </div>
        <div className="glossarySectCont">
          <h3 className="font-semibold underline">Niveau 3/Mise en œuvre de stratégie</h3>
          <p>La troisième étape de la programmation d’ÉleverlaPI comportant un soutien financier pour les dépôts et les poursuites de brevets, l'enregistrement des marques et d'autres services connexes. Pour qu'un dépôt de brevet soit effectué au niveau 3, un examen des résultats de recherche doit être effectué avec un expert/conseiller en PI avant l'approbation.</p>
        </div>
        <div className="glossarySectCont">
          <h3 className="font-semibold underline"><a target="_blank" href="https://northforge.ca/">North Forge</a></h3>
          <p>Un partenaire de Communitech fournissant également du soutien et des services d’ÉleverlaPI aux entreprises situées au Manitoba et en Saskatchewan.</p>
        </div>
        <div className=" glossarySectCont">
          <h3 className="font-semibold">Numéro d’entreprise fédéral (BN)</h3>
          <p>Le <span className="font-semibold">numéro d'entreprise (NE)</span>, attribué à toutes les entreprises constituées en société par l'Agence du revenu du Canada, est un numéro à 9 chiffres unique à une entreprise ou une entité juridique. (Remarque : Le NE est suivi d'un identifiant de programme à deux lettres et d'un numéro de référence à quatre chiffres, tous deux inutiles pour cette demande). Ce numéro est différent du numéro de société à 7 chiffres et ne doit pas contenir de tiret.  Si aucun NE valide à 9 chiffres n’est fourni, la demande sera rejetée car il s’agit d’une condition requise pour participer au programme ÉleverlaPI.  Pour plus de détails sur ce qu'est un NE<a className="underline" target="_blank" href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html">ce Site Web.</a></p>
        </div>
      </div>
      <div className="flex glossarySect">
        <span className="glossaryLetter title font-semibold">O</span>
        <div className="glossarySectCont">
          <h3 className="font-semibold">Objectif environnemental</h3>
          <p>Désigne les objectifs que les activités, produits ou services d'une entreprise en démarrage ont en matière d'environnement. Cela peut inclure des facteurs tels que l’empreinte carbone, l’utilisation des ressources, la production de déchets et les pratiques globales de durabilité. </p>
        </div>
        <div className="glossarySectCont">
          <h3 className="font-semibold">Objectif social</h3>
          <p>Désigne les effets tangibles et positifs que les activités, produits ou services d’une entreprise en démarrage ont sur la société et les communautés. Cela peut être mesuré par ses contributions à diverses causes sociales, par la mesure dans laquelle elle répond aux défis sociétaux et par ses efforts visant à créer une valeur partagée pour toutes les parties prenantes.</p>
        </div>
      </div>
      <div className="flex glossarySect">
        <span className="glossaryLetter title font-semibold">P</span>
        <div className="gap-8 flex flex-col">
        <div className="glossarySectCont">
            <h3 className="font-semibold">PI</h3>
            <p>Propriété intellectuelle (brevets, marques, secrets commerciaux, droits d'auteur, dessins et modèles industriels, etc.).</p>
          </div>
          <div className="glossarySectCont">
            <h3 className="font-semibold">PCT (Traité de coopération en matière de brevets)</h3>
            <p>Une demande de PCT est une demande de brevet internationale (une demande de brevet fictive) qui préserve votre date de priorité et retarde également la date à laquelle vous devez déposer des demandes de brevet étrangères. De nombreux pays sont membres du PCT et vous pouvez déposer une demande de brevet étrangère dans n'importe lequel de ces pays membres qui revendiquent la priorité d'une demande de PCT.
            Si vous déposez une demande de PCT, vous pourrez retarder le dépôt de toute demande de brevet étrangère jusqu'à 30 mois après la date de priorité de la demande de PCT. (Certains pays et brevets régionaux autorisent le dépôt 31, 34, voire 42 mois après la date de priorité de la demande de PCT.)</p>
          </div>
          <div className="glossarySectCont">
            <h3 className="font-semibold">Portail/Portail EPI</h3>
            <p> La plateforme utilisée par ÉleverlaPI qui vous permet de vérifier l'état de votre demande et de parcourir les ressources pédagogiques. Vous êtes actuellement sur le portail EPI!</p>
          </div>
        </div>
      </div>    
    </div>
  }
    </div>  
  )
}

export default Glossary