import { useFormContext } from "react-hook-form";
import { FieldValidator } from "./Form"
import textData from "../../textData.json";
import { useContext } from "react";
import { LangContext, LangContextType } from "../../utilities/customHooks";

export default function CheckboxWithText(
  {
    text,
    name,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    text?: string, // text explaination besides the checkbox
    name: string,
    value: boolean,
    setValue: (key: string, value: boolean) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const { register } = useFormContext();

  return (
    <div className='flex items-center my-2'>
      <input 
        type="checkbox" 
        checked={value}
        readOnly={readOnly} 
        disabled={disabled} 
        {
          ...register(name, {
            ...validator,
            validate: () => {
              if (validator?.required && !value) {
                return textData.Components.Form.CheckboxWithText.Required[langState]
              }
              return true;
            }
          })
        }
        onChange={() => setValue(name, !value)}
        onKeyDown={(e) => { if (e.key === "Enter") setValue(name, !value)}}
      />
      {text && <p className="ml-5">{text}</p>}
    </div>
  )
}