import Form, { FormData } from "../../../Components/Form/Form";
import * as T from "../../../utilities/frontendTypes";
import { adminVerificationFormDefinition } from "../../../utilities/formDefinitionTemplates"
import { updateUserProfile } from "../../../services/userService";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../../../utilities/customHooks";
import { handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { ButtonProp } from "../../../Components/Button";
import textData from "../../../textData.json";

function AdminVerification ( { setTempAdminUserRole } : { setTempAdminUserRole: Dispatch<SetStateAction<T.UserRole>>} ) {
  const { langState } = useContext(LangContext) as LangContextType

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const { updateUserRole } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();
  useEffect(() => {
    setTempAdminUserRole("partnerAdmin" as T.UserRole);
  }, []) 

  async function handleSave(updatedFormData: FormData) {
    const updatedAdminProfile = {
      ...updatedFormData,
      userRole: "partnerAdmin"
    }

    showLoading();
    const response = await updateUserProfile("tempAdmin", updatedAdminProfile as T.User);
    hideLoading();

    if (response.success) {
      updateUserRole("partnerAdmin");
      navigate('/dashboard');
    }
    handleFormSaveResponse(response, showToast, {message: "Admin Profile Updated"});
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.AdminPages.ProfilePages.AdminVerification.Save[langState],
    actionHandler: (updatedFormData: FormData) => handleSave(updatedFormData),
  }
  
  return (
    <div className="flex w-[500px] grow items-center self-center">
      <Form               
        formData={null}
        formDefinition={adminVerificationFormDefinition}
        primaryButtonProp={primaryButtonProp}
      />
    </div>
  )
}

export default AdminVerification