import Cookies from "universal-cookie"
import * as T from "../utilities/frontendTypes";
import { FieldErrors, FieldValues } from "react-hook-form";
import { Address } from "../Components/Form/AddressFields";
import { ListRowContentValue } from "../Components/List";
import { Location } from "react-router-dom";
import { ToastInfo, ToastOption } from "./customHooks";
import uniqid from 'uniqid';
import { FormDefinition, selectionData } from "../Components/Form/Form";
import { FormGroupDefinition } from "../Components/FormGroup";

export async function getAuthHeader() {

  const cookie = new Cookies();

  const token = await cookie.get("authToken");

  const authHeader = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }

  return authHeader;
}

export async function getUploadAuthHeader() {

  const cookie = new Cookies();

  const token = await cookie.get("authToken");

  const authHeader = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  }

  return authHeader;
}

export const getRouteNameFromUserRole = (userRole: T.UserRole) => {
  switch (userRole) {
    case "partnerAdmin":
    case "superAdmin":
      return "admins";
    case "tempAdmin":
      // throw new Error("Currently temp admin should not be called to find the route name, please double check and make sure");
      return "temp-admins";
    case "serviceProvider":
      return "providers";
    case "startupFounder":
      return "founders";
    case "ipExpert":
      return "ipExpert";
    case "tempExpert":
      return "temp-experts";
    case "tempProvider":
      return "temp-providers";
    case "tempGrowthCoach":
      return "temp-growth-coach";
    default:
      throw new Error(`Unknown user role: ${userRole}`);
  }
}

/*
  Function: isFormInvalid

  Purpose: check if form is valid or not

  Return: boolean
*/
export const isFormInvalid = (errors: FieldErrors<FieldValues>) => {
  return Object.keys(errors).length > 0;
}

/*
  Function: checkInputErrorByKey

  Purpose: check if the error object contains errors that match to a given field name (fieldKey)

  Return: A filtered error object
*/
export const checkInputErrorByKey = (errors: FieldErrors<FieldValues>, fieldKey: string) => {
  const filteredErrors: FieldErrors<FieldValues> = Object.keys(errors) 
    .filter(key => key.includes(fieldKey)) // filter out errors that belong to the fieldKey
    .reduce((curr, key) => {
      return Object.assign(curr, { error: errors[key]}) // map errors
    }, {});

  return filteredErrors;
}

/*
  Function: flattenObject

  Purpose: Flatten a multidimensional object
 
  For example:
    flattenObject{ a: 1, b: { c: 2 } }
  Returns:
    { a: 1, c: 2}
 */

export const flattenObject = <T>(obj: Record<string, any>) => {
  const flattened: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value as Record<string, any>));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

/*
  Function: findNestedValueByKey

  Purpose: find object in a nested object (including arrays) by given key-value pair

  Return: result object
*/
export const findNestedValueByKey = (obj: Object, key: string) => {
  try {
    JSON.stringify(obj, (_, nestedValue) => {
      if (nestedValue && nestedValue[key]) throw nestedValue[key]
      return nestedValue;
    })
  } catch (result) {
    return result;
  }
}

// [TODO] add doc
export const addressToString = (address: Address): string => {
  
  const { streetNumber, streetName, unitNumber, city, province, country, postalCode} = address;

  const addressString = streetName !== "" ? `${streetNumber} ${streetName}${(unitNumber && unitNumber !== "") ? ` #${unitNumber}` : ""}, ${city}, ${province}, ${country}, ${postalCode}` : "";

  return addressString;
}

// [TODO] add doc
export type KeyValuePair = {
  [key: string]: ListRowContentValue;
}

// export const sortObjectsByKey = (
//   contentArray: KeyValuePair[], 
//   key: string, 
//   ascending?: boolean, 
//   options?: { sortOptions: ListRowContentValue[], currentOptionIndex: number }
// )  => {
//   if (options) {
//     const keyValue = options.sortOptions[options.currentOptionIndex];
//     return contentArray
//     .slice()
//     .sort((a: T.listComparison, b: T.listComparison) => {
//       if (a[key] === keyValue && b[key] !== keyValue) {
//         return -1;
//       } else if (a[key]!== keyValue && b[key] === keyValue) {
//         return 1;
//       } 
//       return 0;
//     });
//   } else {
//     return contentArray
//     .slice()
//     .sort((a: T.listComparison, b: T.listComparison) => {
//       const valueA = a[key];
//       const valueB = b[key];
//         if (valueA < valueB) {
//           return ascending ? -1 : 1;
//         } else if (valueA > valueB) {
//           return ascending ? 1 : -1;
//         }
//       return 0;
//     });
//   }
// };

export const sortObjectsByKey = (
  contentArray: KeyValuePair[], 
  key: string, 
  ascending?: boolean, 
  options?: { sortOptions: ListRowContentValue[], currentOptionIndex: number }
)  => {
  if (options) {
    console.log("heree")

    const keyValue = options.sortOptions[options.currentOptionIndex];
    let sortKey = key
    return contentArray
    .slice()
    .sort((a: KeyValuePair, b: KeyValuePair) => {
      if (a[sortKey] === keyValue && b[sortKey] !== keyValue) {
        return -1;
      } else if (a[sortKey] !== keyValue && b[sortKey] === keyValue) {
        return 1;
      } 
      return 0;
    });

  } else {
    return contentArray
    .slice()
    .sort((a: KeyValuePair, b: KeyValuePair) => {
      const valueA = a[key].toString().toLowerCase();
      const valueB = b[key].toString().toLowerCase();
      return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    });
  }
};

export const isStringNumeric = (str: string) => {
  return !isNaN(+str)
}

export const isObjectEmpty = (obj: Object) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const isFromUrls = (specificUrls: string[], location: Location) => {
  const from = location.state?.from || "/";
  console.log(from)
  console.log(specificUrls.some(url => from.includes(url)))
  return specificUrls.some(url => from.includes(url));
}


/*
  Function: handleFormSaveResponse

  Purpose:

  Return: 
*/
export const handleFormSaveResponse = (res: any, showToast: (toastOption: ToastOption) => void, successToastInfo?: ToastInfo, failureToastInfo?: ToastInfo ) => { // the 'any' here is intentional and safe

  if (typeof res.success !== "boolean") {
    throw new Error (`unknown res type: ${res}`);
  }
  if (res.success === true) {
    showToast({
      toastType: "success",
      ...successToastInfo,
    });
  } else {
    showToast({
      toastType: "failure",
      ...failureToastInfo,
    });
  }
}

export function generateId() {
  return uniqid();
};

/*
  Function: getRegionNameFromRegion

  Purpose:

  Return: 
*/
export const getRegionNameFromRegion = (region: T.UserRegion) => {

  switch (region) {
    case "communitech":
      return "Communitech";
    case "investOttawa":
      return "Invest Ottawa";
    case "northForge":
      return "North Forge";
    default:
      throw new Error(`Unknown region: ${region}`);
  }
}

/*
  Function: getIsOSDarkMode

  Purpose: detect whether the operation system is in dark mode

  Return: Boolean, true if dark mode
*/
export const getIsOSDarkMode = () => {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}


export const getUserLanguage = () => {
  return navigator.language;
}

/*
  Function: getLastPartOfPath

  Purpose: return last part of a path of a given path string

  Return: string
*/
export const getLastPartOfPath = (path: string) => {
  return path.slice(path.lastIndexOf("/") + 1, path.length);
}

/*
  Function: checkIsOnline

  Purpose: return true/false value indicating online status

  Return: boolean
*/
export const checkIsOnline = async (): Promise<boolean> => {
  try {
    if (!self.navigator.onLine) return false; // false check is reliable
    const request = new URL(self.location.origin); // same origin request to avoid CROS error
    request.searchParams.set('rand', Date.now().toString()); // random value to prevent cached responses
    const response = await fetch(request.toString(), { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
}

export const returnLangCheckedFormDef = (formDefinition: FormDefinition, lang: string): FormDefinition => {
  if (lang === "fr") {
    const result: FormDefinition = {};
    for (const key in formDefinition) {
      if (formDefinition.hasOwnProperty(key)) {
        const { label, frLabel, frTooltip, tooltip, additionalData, ...otherProperties } = formDefinition[key];
  
        result[key] = {
          label: frLabel as string,
          frLabel: frLabel,
          ...otherProperties,
        };
  
        // Check if there is a corresponding frTooltip and update content if needed
        if (tooltip && frTooltip) {
          result[key].tooltip = {
            content: frTooltip.content,
            position: frTooltip.position
          }
        }
        if (additionalData) {
          const frenchData = additionalData.selectionData.map((item) => ({
            value: item.value,
            text: item.frText || item.text, // Set frText if available, otherwise use text
          }));
          result[key].additionalData = {
            selectionData: frenchData 
          }
        }
      }
    }
    return result;
  }
  return formDefinition
}

export const returnLangCheckedFormGroupDef = (formGroupDefinition: FormGroupDefinition, lang: string): FormGroupDefinition => {
  if (lang === "fr") {
    const result: FormGroupDefinition = {}
    for (const key in formGroupDefinition) {
      if (formGroupDefinition.hasOwnProperty(key)) {
        const { sectionLabel, frSectionLabel, formDefinition, ...otherProperties } = formGroupDefinition[key];
        const langCheckedFormDef = returnLangCheckedFormDef(formDefinition, lang);
        result[key] = {
          sectionLabel: frSectionLabel as string,
          frSectionLabel: frSectionLabel,
          formDefinition: langCheckedFormDef,
          ...otherProperties,
        };
      }
    }
    return result
    }
    return formGroupDefinition
  }  

  export const returnFounderStatusStrings = (status: T.FounderStatus, gcReview?: string) => {
    switch (status) {
      case "n/a":
        return ["Predates Update", "", "Action items not tracked on portal", ""]
      case "unsubmitted":
        return ["Application Unsubmitted", "", "", ""]
      case "submitted":
        return ["Application Submitted", "redCircle", "Review Application", ""]
      case "rejected":
        return ["Application Rejected"]
      case "rejectedReapply":
        return ["Re-apply Requested"]
      case "screened":
        return ["Awaiting IPE/GC", "greenCircle", "Proceed with IPE/GC", "This company has been screened and can be invite to meet an IPE or can be sent to a growth Coach for further review"]
      case "growthCoachInvite":
        return ["Awaiting GC Assignment", "", "No Action Required", "A Growth Coach review has been requested.  Currently no Growth Coach has claimed this review."]
      case "growthCoachInProgress":
        return ["Awaiting GC Review", "", "No Action Required", "A Growth Coach has begun reviewing this Company."]
      case "growthCoachRecommendation":
        return ["GC Assessment Ready", "magentaCircle", "Review GC Assessment", `The Growth Coach has completed their assessment and has selected the outcome: ${gcReview}.`]
      case "ipExpertInvite":
        return ["IPE Invite Sent", "", "", "This startup is now meeting with the IP Expert. There are no actions required from you at this time."]
      case "ipExpertInProgress":
        return ["IPE Assement Underway", "", "", "This startup is now meeting with the IP Expert. There are no actions required from you at this time."]
      case "ipExpertRecommendation":
        return ["IPE Assessment Ready", "blueCircle", "Review IPE Assessment", "The IP Expert’s assessment is ready. Please review it."]
      case "dueDiligence":
        return ["IPE Requests Due Dilligence", "lilacCircle", "Due Dilligence", "IP Expert has requested further due dilligence.  Please see the notes and assign to IPE, GC or decline application once due dilligence is completed."]
      case "sowRequested":
        return ["Statement of Work Requested", "", "", "The startup and service provider have been notified to create a statement of work.  There are no actions required from you at this time."]
      case "sowAwaitingApproval":
      case "sowAwaitingSignature":
      case "sowApproved":
      case "sowSigned":
      case "sowInProgress":
      case "sowComplete":
      case "surveySent":
      case "surveyComplete":
        return ["Working with Service Provider"];
    }
  }

  export const returnFounderStatusStringsGC = (status: string | null) => {
    switch (status) {
      case "n/a":
        return ["Predates Update", "", "Action items not tracked on portal", ""]
      case "unsubmitted":
        return ["Application Unsubmitted", "", "No Action Required", ""]
      case "submitted":
        return ["Application Submitted", "redCircle", "Review Application", ""]
      case "rejected":
        return ["Application Rejected", "", "No Action Required", ""]
      case "rejectedReapply":
        return ["Re-apply Requested", "", "No Action Required", ""]
      case "screened":
        return ["Awaiting IPE Invite", "greenCircle", "Proceed with IPE/GC", "This company has been screened and can be invite to meet an IPE or can be sent to a growth Coach for further review"]
      case "growthCoachInvite":
        return ["Awaiting GC Assignment", "greenCircle", "Assign GC", "This startup has been selected for review by a Growth Coach.  Please assign yourself as the Growth Coach for the approproate Company."]
      case "growthCoachInProgress":
        return ["Awaiting GC Review", "pinkCircle", "Perform GC Review", "You have assigned yourself as the Growth Coach for this Account.  Please complete and submit your Assessment."]
      case "growthCoachRecommendation":
        return ["GC Assessment Ready", "magentaCircle", "Review GC Assessment", `The Growth Coach has completed their assessment and has selected the outcome:.`]
      case "ipExpertInvite":
        return ["IPE Invite Sent", "", "No Action Required", "This startup is now meeting with the IP Expert. There are no actions required from you at this time."]
      case "ipExpertInProgress":
        return ["IPE Assement Underway", "", "No Action Required", "This startup is now meeting with the IP Expert. There are no actions required from you at this time."]
      case "ipExpertRecommendation":
        return ["IPE Assessment Ready", "blueCircle", "Review IPE Assessment", "The IP Expert’s assessment is ready. Please review it."]
      case "dueDiligence":
        return ["IPE Requests Due Dilligence", "lilacCircle", "Due Dilligence", "IP Expert has requested further due dilligence.  Please see the notes and assign to IPE, GC or decline application once due dilligence is completed."]
      case "sowRequested":
      case "sowAwaitingApproval":
      case "sowAwaitingSignature":
      case "sowApproved":
      case "sowSigned":
      case "sowInProgress":
      case "sowComplete":
      case "surveySent":
      case "surveyComplete":
        return ["Working With Service Provider", "", "Working With Service Provider", ""];
      default:
        return ["Predates Update", "", "Action items not tracked on portal", ""]
    }
    }

  export const addCircles = () => {
    const paragraphs = document.querySelectorAll('p');
      paragraphs.forEach(p => {
        if (p.textContent === "New Assessment Request" || p.textContent === "New SoW Request" || p.textContent === "New Request"  ) {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("redCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("redCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Ongoing Assessment" || p.textContent === "Awaiting Communitech Approval") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("purpleCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("purpleCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Submitted Assessment" || p.textContent === "Awaiting Startup Approval") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("pinkCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("pinkCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Complete") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("greenCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("greenCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Review Application") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("redCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("redCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Proceed with IPE/GC" || p.textContent === "Project Closed" || p.textContent === "Assign GC") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("greenCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("greenCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Perform GC Review" || p.textContent === "Review in Progress") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("pinkCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("pinkCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Review GC Assessment" || p.textContent === "Edit Request from Startup") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("magentaCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("magentaCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Review IPE Assessment" || p.textContent === "Edit Requested from Communitech") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("blueCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("blueCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Due Dilligence" || p.textContent === "Approved & Ready for Signatures") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("lilacCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("lilacCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Ready to Begin Work") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("tealCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("tealCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Work In Progress") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("yellowCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("yellowCircle");
            parent.insertBefore(newSpan, p);
          }
        }
        if (p.textContent === "Ready for Invoice") {
          const parent = p.parentNode;
          const hasSpanWithClass = parent ? Array.from(parent.children).some(
            child => child.tagName === 'SPAN' && child.classList.contains("lightGreenCircle")
          ) : false;
          if (!hasSpanWithClass && parent) {
            const newSpan = document.createElement('span');
            newSpan.classList.add("lightGreenCircle");
            parent.insertBefore(newSpan, p);
          }
        }
    });
  };

  export const checkUrlAndModifySpan = (url: string) => {
    const currentActive = document.getElementsByClassName("sideMenuCont")
    Array.from(currentActive).forEach(element => {
      element.classList.remove('sideMenuActive');
    })

    if (url === "/dashboard") {
      const spanElement = document.getElementById('Dashboard');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }
    } else if (url === "/dashboard/application" || url === "/dashboard/profile" ) {
      const spanElement = document.getElementById('My Application');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } else if (url === "/dashboard/program-details") {
      const spanElement = document.getElementById('Program Details');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }  
    } else if (url === "/dashboard/resources" || url === "/dashboard/learning-pathway" || url.includes("/dashboard/education")) {
      const spanElement = document.getElementById('IP Education');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } 
    else if (url === "/dashboard/founder-sp-firms" || url.includes("/dashboard/sp-firms")) {
      const spanElement = document.getElementById('Service Providers');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } 
    else if (url === "/dashboard/help" || url === "/dashboard/help/contact-us" || url ===  "/dashboard/help/glossary") {
      const spanElement = document.getElementById('Help');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } else if (url.includes("/dashboard/memberships") || url.includes("/dashboard/pending-applications")) {
      const spanElement = document.getElementById('All Startups');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } else if (url === "/dashboard/admin-expert-preinvite") {
      const spanElement = document.getElementById('Bulk Emails');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } else if (url === "/dashboard/new-user") {
      const spanElement = document.getElementById('New User');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } else if (url === "/dashboard/expert-assessments") {
    const spanElement = document.getElementById('New Requests');
    if (spanElement) {
      spanElement.classList.add('sideMenuActive');
    }    
    } else if (url === "/dashboard/in-progress-assesments") {
      const spanElement = document.getElementById('In Progress');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } else if (url === "/dashboard/submitted-assesments") {
      const spanElement = document.getElementById('Submitted');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }   
    } else if (url === "/dashboard/growth-coach-in-progress") {
      const spanElement = document.getElementById('In Progress Assessments');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }    
    } else if (url === "/dashboard/growth-coach-reporting") {
      console.log("truuuuw")
      const spanElement = document.getElementById('Hours Report');
      if (spanElement) {
        spanElement.classList.add('sideMenuActive');
      }  
   }
  }

export const daysAgo = (date: Date) => {
    const currentDate = new Date(); // Get the current date
    const pastDate = new Date(date); // Convert the input to a Date object
    const differenceInTime = currentDate.getTime() - pastDate.getTime(); // Difference in milliseconds
    const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return differenceInDays;
  }