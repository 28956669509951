import { useContext, useState } from "react";
import { assignTier, growthCoachByapplication, updateApplicationStatus, sendCustomEmailAndApprove } from "../../services/adminService";
import Button, { ButtonProp } from "../Button";
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../../utilities/customHooks";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal";
import DropdownSelect from "../Input/DropdownSelect";
import TextArea from "../Input/TextArea";
import Tooltip from "../Tooltip";
import textData from "../../textData.json";
import { ApplicationStatus } from "../../utilities/frontendTypes";
import Form from "../Form/Form";
import { customEmailFormDefinition } from "../../utilities/formDefinitionTemplates";
import BooleanSelect from "../Form/BooleanSelect";
import { faL } from "@fortawesome/free-solid-svg-icons";

function ApproveReject (
  { 
    applicationId,
    applicationType,
    navigateUrl,
    applicantName, // currently not in use, for better ux
    // programName,
    requireTierAssignment,
    companyName
  }
  : 
  {
    applicationId: string,
    applicationType: "membership" | "program",
    navigateUrl: string,
    applicantName?: string,
    programName?: string,
    requireTierAssignment?: boolean
    companyName?: string
  }
) {
  const { langState, setLang } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();
  const { userRegion } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [selectedTier, setSelectedTier] = useState("1");
  const [reasonNote, setReasonNote] = useState("");
  const buttonText = isApproving ? "Confirm" : "Send"

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const [modalHTML, setModalHtml] = useState(<></>)
  const [reapply, setReapply] = useState(false)
  const handleCheckboxChange = () => {
    setReapply(!reapply);
  };


  const handleConfirmation = async () => {
    const approvedOrRejectedString = isApproving ? textData.Components.Features.ApproveReject.Approve[langState] as ApplicationStatus : textData.Components.Features.ApproveReject.Reject[langState] as ApplicationStatus;
    showLoading();
    const res = await updateApplicationStatus(applicationId, { reasonNote, status: isApproving ? "approved" : "rejected", reapply: reapply }, applicationType);
    hideLoading();
    if (res.success) {
      showToast({
        toastType: "success",
        message: `${textData.Components.Features.ApproveReject.SuccessMessage[langState]} ${approvedOrRejectedString}`,
      });
      navigate(navigateUrl);
    } else {
      showToast({
        toastType: "failure",
        message: `${textData.Components.Features.ApproveReject.FailureMessage[langState]} ${approvedOrRejectedString}`,
      });
    }
  }

  const handleGrowthCoach = async () => {
    showLoading();
    const res = await growthCoachByapplication(applicationId)
    hideLoading();
    if (res.success) {
      showToast({
        toastType: "success",
        message: "Status Updated",
      });
      navigate(navigateUrl);
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to update Statuses, lease try again.  If Error persists please conteact support",
      });
    }
  }

  const gcModalHtml = (
    <div className="w-full flex flex-col">
    <h1 className="mb-6">Growth Coach Review</h1>
    <p className="bodyLarger font-semibold mb-6">
      You are authorizing a Growth Coach review for {companyName}. By confirming, this applicant will be added to the list of companies to review.
    </p>
    <Button 
      buttonText="Confirm"
      actionHandler={() => handleGrowthCoach()}
    />
  </div>
  )

  const handleCustomEmail = async (tempupdatedForm: FormData) => {
    const tempMessage = tempupdatedForm as any
    const respondTo = tempMessage.respondTo
    const messageString = tempMessage.customMessage

    showLoading();
    const response = await sendCustomEmailAndApprove(messageString, applicationId, respondTo);
    hideLoading();

    if (!response) {
      showToast({
        toastType: "failure",
        message: "Unable to send Custom Email.  Please try again and if issue persists contact support.",
        autoDisappearTimeInSeconds: 8,
      })
    } else {
      setIsModalOpen(false);
      showToast({
        toastType: "success",
        message: "Custom Email Sent!",
        autoDisappearTimeInSeconds: 8,
      });
    }
  }

  const customEmailPrimaryButtonProp: ButtonProp = {
    buttonText: "Send Custom Email",
    actionHandler: handleCustomEmail
  }


  const introCallModal = (
    <div>
      <Form
        formDefinition={customEmailFormDefinition}
        primaryButtonProp={customEmailPrimaryButtonProp}
        formData={null}
      />
    </div>
  )

  const handleGrowthCoachModal = () => {
    setIsModalOpen(true)
    setIsApproving(true)
    setModalHtml(gcModalHtml)
  }

  const handleIntroCall = async () => {
    alert("Option here for IO/NF to send an automatic invite email for intro call.  Text Needed")
    // setIsModalOpen(true)
    // setIsApproving(true)
  }

  const confirmIpeModalHtml = (
    <div className="w-full flex flex-col">
      <h1 className="mb-6">IP Expert Assessment</h1>
      <p className="bodyLarger font-semibold mb-2">
      You are authorizing an IP Expert invitation for {companyName}. By confirming, this applicant will be added to the list of companies to invite.
      </p>
      <p className="mb-6">Please note: the invitation will not be sent with this button. Use the "bulk email" feature for this.</p>
      <Button 
        buttonText="Confirm"
        actionHandler={() => handleConfirmation()}
      />
    </div>
  )

  return (
    <div className="flex mb-5 w-full">
      <div className="flex flex-col gap-4 w-full">
        <Button 
          buttonType="primaryFull"
          buttonText="Tier 1 Only"
          actionHandler={ () => {
            setIsModalOpen(true);
            setIsApproving(false);
          }}
        />
        {userRegion === "communitech" &&
        <Button         
          buttonType="primaryFull"
          buttonText="Growth Coach Review"
          actionHandler={() => handleGrowthCoachModal()}
        />
        }
        {/* {userRegion === "investOttawa" &&
        <Button         
          buttonType="primaryFull"
          buttonText="Intro Call"
          actionHandler={handleIntroCall}
        />
        } */}
        <Button         
          buttonType="primaryFull"
          buttonText="IP Expert"
          actionHandler={ () => {
            setModalHtml(confirmIpeModalHtml)
            setIsModalOpen(true);
            setIsApproving(true);
          }}
        />
      </div>
      {/* <Tooltip content={textData.Components.Features.ApproveReject.ConfirmationTooltip[langState]} position={"top-left"} /> */}
      <Modal 
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        contentLabel="Approve or reject confirmation" // [TODO]
        modalHTML={!isApproving ?
          <div className="flex flex-col text-text">
            <div className="flex flex-col">
              <h1 className="mb-6 w-full text-center">Tier 1 Only</h1>
              <div>
              <p className="mb-6 bodyLarger font-semibold">You are rejecting {companyName} from moving forward in the application process.</p>
              <label className="flex items-center mb-6">
                <input
                  className=" mr-2"
                  type="checkbox"
                  checked={reapply ? true : false}
                  onChange={handleCheckboxChange}
                />
                Ask Company to modify application and reapply?
              </label>
              </div>
              <p className="mb-6 bodyLarger font-semibold">They will receive the following message:</p>
              {reapply ? 
                <div>
                <p className=" mb-6">Hello {applicantName}</p>
                <p className=" mb-6">Thank you for your application to the ElevateIP program. At the moment you are not eligible for Tier 2 or 3 funded services (IP Development and Implementation).  In order too reevaluate require the following modifications to your application.</p>
                <p className="mb-2">Please provide a detailed explanation of what needs to be updated prior to resubmitting the application:</p>
                <TextArea 
                  name={"approve-reject-reason-note"} 
                  value={reasonNote} 
                  setValue={v => setReasonNote(v as string)}
                />
                <p className="mb-6 mt-6">Please update your profile accordingly and resubmit your application.</p>
                <p className="mb-6">In the meantime, you are eligible for our free Tier 1 IP Education services. Please visit your portal to make the most of those resources.</p>
                <p className=" mb-6">You’re welcome to respond with any questions or concerns.</p>
                <p className=" mb-6">Kindly,</p>
                <p className="">ElevateIP Team,</p>
                <p className="underline">www.elevateip.ca</p>
                <p className="underline">elevateip@communitech.ca</p>
                <p className="font-semibold my-4 bodyLarger">Are you sure you want to decline this application with the instructions to reapply?</p>
                </div> 
              :
                <div> 
                <p className=" mb-6">Hello {applicantName}</p>
                <p className=" mb-6">Thank you for your application to the ElevateIP program. We regret to inform you that you are not eligible for Tier 2 or 3 funded services (IP Development and Implementation) at this time.</p>
                <p className="mb-2">Please provide an explanation:</p>
                <TextArea 
                  name={"approve-reject-reason-note"} 
                  value={reasonNote} 
                  setValue={v => setReasonNote(v as string)}
                />
                <p className="mb-6 mt-6">However, you are eligible for our free Tier 1 IP Education services. Please visit your portal to make the most of those resources.</p>
                <p className=" mb-6">You’re welcome to respond with any questions or concerns.</p>
                <p className=" mb-6">Kindly,</p>
                <p className="">ElevateIP Team,</p>
                <p className="underline">www.elevateip.ca</p>
                <p className="underline">elevateip@communitech.ca</p>
                <p className="font-semibold my-4 bodyLarger">Are you sure you want to decline this application?</p>
              </div>
            }
            <Button 
              buttonText="Confirm"
              actionHandler={() => handleConfirmation()}
            />
            <label>
                <input
                  className="mt-6 mr-2"
                  type="checkbox"
                  checked={reapply ? true : false}
                  onChange={handleCheckboxChange}
                />
                Allow Reapplication?
            </label>
            </div>
          </div>
          :
          modalHTML
        }        
      />
    </div>
  )
}

export default ApproveReject;