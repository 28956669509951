import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType, useAsync } from "../../../utilities/customHooks";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { getGrowthCoachInProg } from "../../../services/adminService";
import { useContext } from "react";
import List from "../../../Components/List";
import * as T from "../../../utilities/frontendTypes";
import { checkUrlAndModifySpan } from "../../../utilities/helperFunctions";


const GrowthCoachDashboard = () => {
    
//   const currentUrl = window.location.pathname; 
//   checkUrlAndModifySpan(currentUrl)
  const navigate = useNavigate();
  const membershipsColumnHeaderData = [
    {
      header: "Startup",
      key: "companyName"
    },
    {
      header: "Action Item",
      key: "actionItem",
    },
    {
      header: "Last Updated",
      key: "date"
    },
    {
      header: "Contact Person",
      key: "contact"
    }
  ]

  const founderDetailsAsync = useAsync(() => getGrowthCoachInProg(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const handleViewMemberDetails = (memberHeaderUserId: string) => {
    navigate((`/dashboard/growth-coach-assessment/${memberHeaderUserId}`));
  }

  const contentArray = founderDetailsAsync.value.foundersArray;

  return (
    <div className="resourcesCont !pt-0 h-full">
      <h2 className="">Your In Progress Assessments</h2>
      <List 
        contentArray={contentArray}
        columnHeaderData={membershipsColumnHeaderData}
        returnDetailsId={handleViewMemberDetails}
        detailsIdName="id"
        isReverse
      />
    </div>
  )
}

export default GrowthCoachDashboard