import { useState, useEffect, createContext, Dispatch, SetStateAction } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import * as T from "./frontendTypes";
import { RegisterInfo, checkIsUserAlive, getUserInfo, loginUser, logoutUser, registerUser } from "../services/userService";

type AsyncState<T> = 
  { status: "pending" } 
  |
  { 
    status: "rejected",
    error: unknown
  } 
  |
  {
    status: "resolved",
    value: T
  }

export function useAsync<T> (
  load: () => Promise<T>,
  dependencies: unknown[],
): AsyncState<T> {

  const [state, setState] = useState<AsyncState<T>>({ status: "pending" });

  useEffect(() => {

    let canceled = false;
    
    const func = async () => {
      try { 
        const value: T = await load();
        if (!canceled) {
          setState({ status: "resolved", value });
        }
      } catch(error) {
        if (!canceled) {
          setState({ status: "rejected", error });
        }
      }
    }
    func();

    return () => {
      canceled = true;
      setState({ status: "pending" })
    }
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  dependencies)

  return state;
}

export function useRequiredParams(paramName: string): string {
  const params = useParams();
  
  const param = params[paramName];

  if (param === undefined) {
    throw new Error(`Route param ${paramName} is required but is missing`)
  }

  return param;
}

/* 
  
  useAuth: the useAuth hook allows us to checkout user's auth state, uerRole, userRegion
  as well as handling login/logout

*/

export type AuthContextType = {
  updateUserRole: (userRole: T.UserRole) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuth: boolean) => void;
  userRole: T.UserRole | null;
  userRegion: T.UserRegion | null;
  userEmail: string | null;
  settings: T.Settings | null;
  setSettings: (settings: T.Settings) => void;
  memberApplicationStatus: T.MemberApplicationStatus;
  setMemberApplicationStatus: (status: T.MemberApplicationStatus) => void;
  login: (loginInfo: T.LoginInfo) => Promise<boolean>;
  logout: () => Promise<void>;
  register: (registerInfo: RegisterInfo) => Promise<boolean>
}

export const AuthContext = createContext<AuthContextType | null>(null);

// [NOTE] Currently refreashing browser loses router history
export function useAuth(): AuthContextType {
  
  const navigate = useNavigate();
  useEffect(() => {
    // Get and set initial auth states for cases like browser refresh
    const getInitialAuthState = async () => {  
      const isAuthenticated = await checkIsUserAlive();
      setAuthStates(isAuthenticated);
    }
    getInitialAuthState();
  }, [])

  const setAuthStates = async (isAuth: boolean) => {
    setIsAuthenticated(isAuth);
    if (isAuth) {
      const { userRole, userRegion, email, memberApplicationStatus, settings } = (await getUserInfo()).user;
      setUserRole(userRole);
      setUserRegion(userRegion);
      setUserEmail(email);
      setMemberApplicationStatus(memberApplicationStatus);
      setSettings(settings);
      
    } else {
      setUserRole(null);
      setUserRegion(null);
      setUserEmail(null);
      setMemberApplicationStatus(undefined);
      setSettings(null);
    }
  }

  const updateUserRole = (userRole: T.UserRole) => {
    setUserRole(userRole)
  }

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState<T.UserRole | null>(null);
  const [userRegion, setUserRegion] = useState<T.UserRegion | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [memberApplicationStatus, setMemberApplicationStatus] = useState<T.MemberApplicationStatus>(undefined);
  const [settings, setSettings] = useState<T.Settings | null>(null);

  return {
    updateUserRole,
    isAuthenticated,
    setIsAuthenticated,
    userRole,
    userRegion,
    userEmail,
    memberApplicationStatus,
    setMemberApplicationStatus,
    settings,
    setSettings,
    async login(loginInfo) {
      const isLoginSuccess = await loginUser(loginInfo);
      if (isLoginSuccess) {
        setAuthStates(true);
      }
      return isLoginSuccess;
    },
    async logout() {
      await logoutUser();
      setAuthStates(false);
      navigate('/')
    },
    async register(registerInfo) {
      const isRegisterSuccess = await registerUser(registerInfo);
      if (isRegisterSuccess) {
        setAuthStates(true)
      }
      return isRegisterSuccess;
    }
  };
}

/* 
  
  useToast: the useToast hook controls displays of toast

*/
export type ToastType = "success" | "info" | "failure";

export interface ToastContextType { 
  toastState: ToastState;
  showToast: (toastOption: ToastOption) => void;
  hideToast: () => void;
}

export interface ToastInfo {
  title?: string,
  message?: string,
  autoDisappearTimeInSeconds?: number,
}

export type ToastOption = {
  toastType: ToastType
} & ToastInfo

export type ToastState = {
  isShow: boolean;
} & ToastOption;

export const ToastContext = createContext<ToastContextType | null>(null);

export const useToast = (): {
  toastState: ToastState;
  showToast: (toastOption: ToastOption) => void;
  hideToast: () => void;
} => {

  const [toastState, setToastState] = useState<ToastState>({
    isShow: false,
    toastType: "info"
  });

  const showToast = (toastOption: ToastOption) => {
    setToastState({...toastOption, isShow: true});
  }

  const hideToast = () => {
    setToastState(s => ({...s, isShow: false}));
    const resetToastState = {
      isShow: false, 
      toastType: "info" as ToastType,
      title: undefined, 
      message: undefined, 
      autoDisappearTimeInSeconds: undefined
    }
    setTimeout(() => {
      setToastState(resetToastState);
    }, 300); // wait out the transition
  }

  return {
    toastState,
    showToast,
    hideToast,
  };
};

/* 
  
  useConditionalRenderContext: the useToast hook controls displays of toast

*/
type ConditionalRenderState = {[fieldKey: string]: boolean};

// [TODO] documentation of useConditionalRender
export type ConditionalRenderContextType = {
  conditionalRenderState: ConditionalRenderState,
  replaceConditionalRenderState: (newState: ConditionalRenderState) => void,
  updateConditionalRenderState: (fieldKey: string, value: boolean) => void,
}

export const ConditionalRenderContext = createContext<ConditionalRenderContextType | null>(null);

export function useConditionalRenderContext(): ConditionalRenderContextType {

  const [conditionalRenderState, setConditionalRenderState] = useState<ConditionalRenderState>({
    // [TODO] giving default value reduces integrity
    byPassTypeScriptNullPain: false,
  });

  // [NOTE] might be deletable? check and remove if time
  useEffect(() => {
  }, [conditionalRenderState])

  const replaceConditionalRenderState = (newState: ConditionalRenderState) => {
    setConditionalRenderState(newState);
  }
  
  return {
    conditionalRenderState,
    replaceConditionalRenderState,
    updateConditionalRenderState(fieldKey: string, value: boolean) {
      const updatedState = {
        ...conditionalRenderState,
        [fieldKey]: value,
      }
      // [TODO] clean up this budget solution
      if ("byPassTypeScriptNullPain" in updatedState) {
        delete updatedState.byPassTypeScriptNullPain;
      }

      setConditionalRenderState(updatedState);
    },
  };
}

/*

  useReloadOnOutletSave: reload parent based on child outlet component action

*/

export type OutletContextType = { 
  reloadOnOutletSave: boolean, 
  setReloadOnOutletSave: Dispatch<SetStateAction<boolean>>
}

export const useReloadOnOutletSave = () => {
  return useOutletContext<OutletContextType>();
}

/* 

  useLoading is a global loading hook for loading state control

*/
export interface LoadingContextType { 
  loadingState: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

export const LoadingContext = createContext<LoadingContextType | null>(null);

export const useLoading = (): {
  loadingState: boolean;
  showLoading: () => void;
  hideLoading: () => void;
} => {

  const [loadingState, setLoadingState] = useState(false);

  const showLoading = () => {
    setLoadingState(true);
  }

  const hideLoading = () => {
    setLoadingState(false);
    }

  return {
    loadingState,
    showLoading,
    hideLoading,
  };
};

export interface LangContextType { 
  langState: T.LangState;
  setLang: (lang: T.LangState) => void;
}

export const LangContext = createContext<LangContextType | null>(null);

export const useLang = (): {
  langState: T.LangState;
  setLang: (lang: T.LangState) => void;
} => {
  const [langState, setLangState] = useState("eng" as T.LangState);

  const setLang = (lang: T.LangState) => {
    setLangState(lang);
  }

  return {
    langState,
    setLang
  };
};
