import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button, { ButtonProp } from "../../../Components/Button";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader } from "./NewAdmin";
import Form, { FormData, FormDefinition } from "../../../Components/Form/Form";
import { createAdminProfileFormDefinition, createExpertProfileFormDefinition, createServiceProviderProfileFormDefinition  } from "../../../utilities/formDefinitionTemplates";
import * as T from "../../../utilities/frontendTypes";
import Modal from "../../../Components/Modal";
import { createUserProfile, getFirmsAsync, updateScore } from "../../../services/adminService";
import { handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { ToastContext, ToastContextType, LoadingContext, LoadingContextType, useAsync } from "../../../utilities/customHooks";
import { inputClasses } from "../../../Components/Form/InputField";
import { dropdownArrowClasses } from "../../../Components/Form/DropdownSingleSelect";
import { basic_text_validator, unique_email_validator } from "../../../utilities/formFieldValidators";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";

export const contentPageContainerStyle = "flex w-full justify-center h-full"

export const contentPageWrapperStyle = "relative flex flex-col h-full w-dashboardContentWidth max-w-maxDashboardContentWidth items-center text-text";

function NewUser() {
    
  const [ userType, setUserType ] = useState<string>("")
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempUpdatedForm, setTempUpdatedForm] = useState<FormData | null>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const navigate = useNavigate();
  const getFirms = useAsync(() => getFirmsAsync(), []);


  if (getFirms.status === "pending") {
    return <LoadingIndicator />;
  }

  if (getFirms.status === "rejected") {
    return <ErrorIndicator />;
  }

  const firms = getFirms.value.firms;

  const createGCFormDefinition: FormDefinition = {
    firstName: {
      label: "First Name",
      frLabel: "Prénom",
      inputType: "text",
      validator: basic_text_validator,
    },
    lastName: {
      label: "Last Name",
      frLabel: "Nom de famille",
      inputType: "text",
      validator: basic_text_validator,
    },
    uniqueEmail: {
      label: "Email",
      frLabel: "Courrier",
      inputType: "text",
      validator: unique_email_validator,
    },
    userRegion: { 
      label: "Select Growth Coach Region",
      frLabel: "Sélectionner Organisation/Admin",
      inputType: "dropdownSelect",
      validator: {
        required: {
          value: true,
          message: "Please select an option"
        }
      },
      additionalData: {
        selectionData: [
          {
            value: "communitech", 
            text: "Communitech"
          },
          {
            value: "northForge", 
            text: "North Forge"
          },
          {
            value: "investOttawa", 
            text: "Invest Ottawa"
          }
        ]
      }
    },
  }

  const createServiceProviderProfileFormDefinition: FormDefinition = {
    firstName: {
      label: "First Name",
      frLabel: "Prénom",
      inputType: "text",
      validator: basic_text_validator,
    },
    lastName: {
      label: "Last Name",
      frLabel: "Nom de famille",
      inputType: "text",
      validator: basic_text_validator,
    },
    uniqueEmail: {
      label: "Email",
      frLabel: "Courrier",
      inputType: "text",
      validator: unique_email_validator,
    },
    firm: {
      label: "Select Service Provider Firm",
      frLabel: "Sélectionner Organisation/Admin",
      inputType: "dropdownSelect",
      validator: {
        required: {
          value: true,
          message: "Please select an option"
        }
      },
      additionalData: {
        selectionData: firms
      }
    },
    userRegion: { 
      label: "Select Service Provider Region",
      frLabel: "Sélectionner Organisation/Admin",
      inputType: "dropdownSelect",
      validator: {
        required: {
          value: true,
          message: "Please select an option"
        }
      },
      additionalData: {
        selectionData: [
          {
            value: "communitech", 
            text: "Communitech"
          },
          {
            value: "northForge", 
            text: "North Forge"
          },
          {
            value: "investOttawa", 
            text: "Invest Ottawa"
          }
        ]
      }
    },
  }
  
  const getForm = (userType: string) => {
    if (userType === "tempAdmin") {
      return createAdminProfileFormDefinition
    } else if (userType === "tempExpert") {
        return createExpertProfileFormDefinition
    } else if (userType === "tempProvider") {
        return createServiceProviderProfileFormDefinition
    } else if (userType === "tempGrowthCoach") {
        return createGCFormDefinition
    } 
  }

  // Function to handle change in dropdown selection
  const handleDropdownChange = (e: any) => {
    setUserType(e.target.value);
  };

  const handleConfirmModal = (updatedForm: FormData) => {
    setIsModalOpen(true);
    setTempUpdatedForm(updatedForm);
  }
  
  const handleSave = async () => {
    if (tempUpdatedForm === null) throw new Error("Cannot save new admin with null tempUpdatedForm data");
    const {firstName, lastName, uniqueEmail, userRegion} = tempUpdatedForm;

    let updatedProfile: T.NewUserProfile = {
      firstName: firstName as string,
      lastName: lastName as string,
      userRole: userType as T.UserRole,
      email: (uniqueEmail as string),
      userRegion: userRegion as T.UserRegion,
    }
    if (tempUpdatedForm.firm) {
      updatedProfile.firm = tempUpdatedForm.firm as string
    }
    if (tempUpdatedForm.calendly) {
      updatedProfile.firm = tempUpdatedForm.firm as string
    }

    showLoading();
    const response = await createUserProfile(updatedProfile);
    hideLoading();
    setIsModalOpen(false);
    navigate('/dashboard');
    handleFormSaveResponse(
      response, 
      showToast, 
      {message: `New User successfully created`}, 
      {message: "Email already in use"}
    );
  }


  const primaryButtonProp: ButtonProp = {
    buttonText: "Add",
    actionHandler: handleConfirmModal
  }

  const modalHTML = (
    <div className='flex flex-col text-text'>
      <h1 className='text-center text-xl font-bold'>{"Confirm Details"}</h1>
      <Form
        disabled
        formDefinition={getForm(userType) as FormDefinition}
        formData={tempUpdatedForm}
      />
    </div>
  )


  return (
    <div className="resourcesCont !pt-0">
      <h1>Create new user</h1>
      <div className="w-full">
          <div className="flex flex-col w-full overflow-y-auto">
            <div className="flex flex-col w-full mb-4">
              <div className="relative">
                <select 
                  id="dropdown" 
                  value={userType} 
                  onChange={handleDropdownChange}
                  className={inputClasses}
                >
                  <option value="" disabled>Select a User Role:</option>
                  <option value="tempAdmin">Admin</option>
                  <option value="tempExpert">IP Expert</option>
                  <option value="tempProvider">Service Provider</option>
                  <option value="tempGrowthCoach">Growth Coach</option>
                </select>
                <span className={dropdownArrowClasses}></span>
              </div>
            </div>

          {
          userType &&
          <div className="flex flex-col w-full">
              <div className="self-start mb-5 w-full">
              <Form
                formData={null}
                formDefinition={getForm(userType) as FormDefinition}
                primaryButtonProp={primaryButtonProp}
                disabled={false}
              />
              </div>
          </div>
          }
          <Modal 
            isOpen={isModalOpen} 
            closeModal={() => setIsModalOpen(false)}
            modalHTML={modalHTML} 
            contentLabel={"Preview"}
            primaryButton={{buttonText: "Confirm", buttonCallback: () => handleSave()}}
          />
        </div>
      </div>
    </div>
  )
}

export default NewUser