import { useFormContext } from "react-hook-form";
import { FieldValidator } from "./Form";
import { ChangeEvent, useContext } from "react";
import { disabledInputClasses, inputClasses } from "./InputField";
import { LangContext, LangContextType } from "../../utilities/customHooks";
import textData from "../../textData.json";

export default function BooleanSelect(
  {
    name,
    value,
    setValue,
    isViewOnly,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    name: string,
    value: boolean | null,
    setValue: (value: boolean) => void,
    isViewOnly?: boolean,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const { register, clearErrors } = useFormContext();

  const handleSetValue = (e: ChangeEvent<HTMLInputElement>) => {
    const returnValue = e.currentTarget.value;
    setValue(returnValue === "yes")
    clearErrors(name);
  }

  return (
    isViewOnly 
    ?
    <fieldset className="flex text-text">
      {/* [TODO] remove after confirmation of UI/UX with team */}
      {/* <input
        type="radio"
        checked={true}
        disabled={true}
      /> */}
      <label
        className={`pl-2 ${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
      >
        {value === true ? textData.Components.Form.BooleanSelect.Yes[langState] : value === false ? textData.Components.Form.BooleanSelect.No[langState] : textData.Components.Form.BooleanSelect.NotSelected[langState]}
      </label>
    </fieldset>
    :
    <fieldset className="flex text-text">
      <input 
        className="hover:cursor-pointer"
        type="radio" 
        id={`${name}-yes`}
        checked={value === true}
        value="yes"
        disabled={disabled}
        readOnly={readOnly}
        {
          ...register(name, {
            validate: () => {
              if (value === null) {
                return textData.Components.Form.BooleanSelect.MakeSelection[langState];
              }
              return true;
            },
          })
        }
        onChange={handleSetValue}
      />
      <label 
        htmlFor={`${name}-yes`}
        className="hover:cursor-pointer pl-4 mr-20"
      >
        {textData.Components.Form.BooleanSelect.Yes[langState]}
      </label>
      <input 
        className="hover:cursor-pointer"
        type="radio" 
        id={`${name}-no`}
        name={name}
        checked={value === false} 
        value="no"
        onChange={handleSetValue}
        disabled={disabled}
        readOnly={readOnly}
      />
      <label
        className="hover:cursor-pointer pl-4"
        htmlFor={`${name}-no`}
      >
        {textData.Components.Form.BooleanSelect.No[langState]}
      </label>
    </fieldset>
  )
}