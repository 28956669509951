import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getMembershipApplicationHeaders } from "../../../services/adminService";
import { LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import List, { KeyValuePair } from "../../../Components/List";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin";
import { getLastPartOfPath } from "../../../utilities/helperFunctions";
import textData from "../../../textData.json";
import { useContext } from "react";

function PendingAppliactions() {
  // const { langState } = useContext(LangContext) as LangContextType
  const langState = "eng"
  const navigate = useNavigate();
  const location = useLocation();
  const membershipApplicationHeadersAsync = useAsync(() => getMembershipApplicationHeaders(), [location]);

  if (membershipApplicationHeadersAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (membershipApplicationHeadersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const applicationHeaders = [...membershipApplicationHeadersAsync.value.membershipApplicationHeaders];

  const handleViewApplicationDetails = (id: T.ApplicationId) => {
    navigate(`/dashboard/membership-applications/${id}`);
  }

  const columnHeaderData = [
    {
      header: textData.Pages.AdminPages.MembershipPages.MemberApplications.Col1[langState],
      key: "companyName",
    },
    {
      header: textData.Pages.AdminPages.MembershipPages.MemberApplications.Col2[langState],
      key: "dateCreated",
    },
    {
      header: textData.Pages.AdminPages.MembershipPages.MemberApplications.Col3[langState],
      key: "cityProvince",
    },
    {
      header: textData.Pages.AdminPages.MembershipPages.MemberApplications.Col4[langState],
      key: "bai",
    },
    {
      header: textData.Pages.AdminPages.MembershipPages.MemberApplications.Col5[langState],
      key: "status",
      sortOptions: ["pending", "approved", "rejected"],
    }
  ];
  
  return (
    <div className="resourcesCont !pt-0">
      <div className="flex flex-col w-full">
        <h2 className="mb-4">Pending Applications</h2>
        <List 
          contentArray={applicationHeaders as KeyValuePair[]}
          columnHeaderData={columnHeaderData}
          detailsIdName="id" // [TODO] should be applicationId, requires changes to what is passed from backend
          returnDetailsId={handleViewApplicationDetails}
        />  
      </div>
    </div>
  );
}
export default PendingAppliactions