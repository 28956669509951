import { createTheme } from "./utilities";

const baseTheme = createTheme({
  // Root colors
  primary: '0 80 92', // primary buttons
  secondary: "83 176 142", // secondary buttons
  terteiry: "138 138 138", // borders, prefer a gray-ish color
  background: "255 255 255", // main background, input background
  text: "0 0 0",
  danger: "245 100 134",
  success: "15 255 168",
  info: "44 207 222",
  highlight: "252 176 23",

  // Branch colors, can be unique or variation of Root colors.
  primaryLight: "51 167 185", // primary heading
  primaryDark: "0 61 71",
  secondaryLight: "138 215 187",
  secondaryDark: "0 71 45", // sub heading
  backgroundSecondary: "236 236 236", // side menu bg
  backgroundTerteiry: "196 196 196", // pop-up modals bg
  buttonText: "255 255 255",
  secondaryButtonText: "0 0 0"
});

export default baseTheme;