import { faX } from "@fortawesome/free-solid-svg-icons"
import IconButton from "./IconButton"

export const Tag = (
  {
    text,
    backgroundColorClassName,
    isRemovable,
    removeTagCallback,
    type,
    style,
  }
  :
  {
    text: string,
    backgroundColorClassName?: string,
    isRemovable?: boolean,
    removeTagCallback?: () => void,
    type?: "categoryTag",
    style?: string,
  }
) => {

  return (
    <span 
      className={
        `
          text-text px-3 py-1 mb-2 rounded-full w-fit inline-block
          ${backgroundColorClassName ? backgroundColorClassName : "bg-terteiry/20"}
          ${type === "categoryTag" ? "text-sm py-[2px] px-[6px] rounded-[6px] text-text/80" : ""}
          ${style}
        `
      }
    >
      {text}
      {
        isRemovable && removeTagCallback &&
        <IconButton
          icon={faX} 
          actionCallback={removeTagCallback} 
          style="text-text/70 hover:text-text w-[10px] pl-1"
        />
      }
    </span>
  )
}