import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getPreMeetingDetailsAsync } from "../../../services/adminService";
import { useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"
import List from "../../../Components/List";

const PreMeeting = () => {

  const preMeetingFoundersAsync = useAsync(() => getPreMeetingDetailsAsync(), []);

  if (preMeetingFoundersAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (preMeetingFoundersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const preMeetingFoundersArray = preMeetingFoundersAsync.value.preMeetingFoundersArray

  const columnHeaderData = [
    {
      header: "Company Name",
      key: "companyName"
    }
  ]

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={"Invited Companies who have not yet met an IP Expert"}
          tooltip={"This is a list of all the companies who have not yet met with an IP Expert, but have been invited to"}
        />
        <List 
          contentArray={preMeetingFoundersArray}
          columnHeaderData={columnHeaderData}
        />
      </div>
    </div>  
  )
}

export default PreMeeting