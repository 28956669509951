import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { getAllAdmins } from "../../../services/adminService";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import Button from "../../../Components/Button";
import List, { KeyValuePair } from "../../../Components/List";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader } from "./NewAdmin";
import { getLastPartOfPath } from "../../../utilities/helperFunctions";
import textData from "../../../textData.json";
import { useContext } from "react";

export const contentPageContainerStyle = "flex w-full justify-center h-full"

export const contentPageWrapperStyle = "relative flex flex-col h-full w-dashboardContentWidth max-w-maxDashboardContentWidth items-center text-text";

function PartnerAdminList() {
  const { langState } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();
  const location = useLocation();

  const allAdminsAsync = useAsync(() => getAllAdmins(), [location]);

  if (allAdminsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (allAdminsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const allAdminHeaders = allAdminsAsync.value.adminHeaders;

  const columnHeaderData = [
    {
      header: textData.Pages.AdminPages.ProfilePages.PartnerAdminList.Col1[langState],
      key: "adminName",
    },
    {
      header: textData.Pages.AdminPages.ProfilePages.PartnerAdminList.Col2[langState],
      key: "email",
    },
    {
      header: textData.Pages.AdminPages.ProfilePages.PartnerAdminList.Col3[langState],
      key: "userRegion",
      sortOptions: ["communitech", "investOttawa", "northForge"],
    },
    {
      header: textData.Pages.AdminPages.ProfilePages.PartnerAdminList.Col4[langState],
      key: "dateCreated",
    }
  ];

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={textData.Pages.AdminPages.ProfilePages.PartnerAdminList.PartnerAdminList[langState]}
          tooltip={textData.Pages.AdminPages.ProfilePages.PartnerAdminList.PartnerAdminListTooltip[langState]}
        />
        <List 
          contentArray={allAdminHeaders as KeyValuePair[]}
          columnHeaderData={columnHeaderData}
        />  
      </div>
      {/* New Admin Route */}
      <Outlet />
    </div>
  )
}

export default PartnerAdminList