import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faChevronLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import IconButton from "./IconButton";
import textData from "../textData.json";
import { useContext } from "react";
import { LangContext, LangContextType } from "../utilities/customHooks";

export default function ContentPageTopButtons(
  {
    backButtonTargetLocation,
    hideBackButton,
    editCallback,
    deleteCallback,
    closeCallback,
  } 
  : 
  {
    backButtonTargetLocation?: string,
    hideBackButton?: boolean,
    editCallback?: () => void,
    deleteCallback?: () => void,
    closeCallback?: () => void,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();
  return (
    <div 
        className="my-contentPageTopMargin flex justify-between w-full mb-5"
      >
      {
        !hideBackButton
        ?
        <button onClick={() => { backButtonTargetLocation ? navigate(backButtonTargetLocation) : navigate(-1)}}>
          <FontAwesomeIcon className="hover:cursor-pointer text-text" icon={faChevronLeft} />
        </button>
        :
        <div></div>
      }
      <div className="flex [&>button]:ml-5">
        {
          editCallback && 
          <div>
            <IconButton icon={faPenToSquare} actionCallback={editCallback} text={textData.Components.ContentPageTopButtons.Edit[langState]}/> 
          </div>
        }
        {deleteCallback && <IconButton icon={faTrashCan} actionCallback={deleteCallback} />}
        {closeCallback && <IconButton icon={faXmark} actionCallback={closeCallback} />}
      </div>
    </div>
  )
}