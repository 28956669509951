import { FormDefinition } from "../Components/Form/Form";
import { basic_number_validator, basic_text_validator, boolean_selection_validator, business_number_validator, date_validator, email_validator, multiselect_validator, phone_validator, selection_validator, url_validator } from "./formFieldValidators";

export const profileFormPersonalInfoDefinition: FormDefinition = {
        firstName: {
          label: "First Name",
          inputType: "text",
          frLabel: "Prénom",
          placeholder: "Your first name",
          validator: basic_text_validator,
        },
        lastName: {
          label: "Last Name",
          frLabel: "Nom de famille",
          inputType: "text",
          placeholder: "Your last name",
          validator: basic_text_validator,
        },
        // [TODO] handle conditional checkboxes and other field feature
        pronouns: {
          label: "Your Pronouns",
          frLabel: "Prénom",
          inputType: "customMultiSelectNarrow",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                text: "she/her",
                frText: "elle",
                value: "she"
              },
              {
                text: "he/him",
                frText: "il",
                value: "he"
              },
              {
                text: "they/them",
                frText: "eux",
                value: "they"
              },
              {
                text: "prefer not to say",
                frText: "préfère ne pas répondre",
                value: "notToSay"
              },
            ]
          }
        },
        title: {
          label: "Job Title",
          inputType: "text",
          frLabel: "Titre du poste",
          placeholder: "Job Title",
          validator: basic_text_validator,
        },
        phoneNumber: {
          label: "Phone Number",
          inputType: "text",
          placeholder: "Numbers only please",
          frLabel: "Numéro de téléphone",
          validator: phone_validator,
        }
        // isPointOfContact: {
        //   label: "I'm the point of contact",
        //   frLabel: "Je suis la personne ressource à contacter",
        //   inputType: "checkbox",
        //   tooltip: {
        //     content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the preffered point of contact below",
        //     position: "bottom-right"
        //   },
        //   frTooltip: {
        //     content: "Si vous préférez que les avis par courriel et les communications d’ÉPI soient envoyés à un autre point de contact, ne cochez pas cette case et indiquez le point de contact souhaité ci-dessous.",
        //     position: "bottom-right"
        //   },
        // }
      }
    
    export const profileFormPointOfContactDefinition: FormDefinition = {
        firstName: {
          label: "First Name",
          frLabel: "Prénom",
          inputType: "text",
          validator: basic_text_validator,
        },
        lastName: {
          label: "Last Name",
          frLabel: "Nom de famille",
          inputType: "text",
          validator: basic_text_validator,
        },
        // [TODO] pronouns is waiting on conditional checkboxes feature
        title: {
          label: "Job Title",
          frLabel: "Titre du poste",
          inputType: "text",
          validator: basic_text_validator,
        },
        email: {
          label: "Email",
          frLabel: "Courriel",
          inputType: "text",
          validator: email_validator,
        },
        phoneNumber: {
          label: "Phone Number",
          frLabel: "Numéro de téléphone",
          inputType: "text",
          validator: phone_validator,
        },
      }

    export const profileFormCompanyInfoDefinition: FormDefinition = {
      legalName: {
        // conditionalRenderDefinition: {
        //   fieldKey: "isLegalNameSameAsOperatingName",
        //   renderWhenTrue: false, 
        // },
        label: "Legal Name",
        frLabel: "Nom légal",
        inputType: "text",
        placeholder: "Company’s legal name",
        validator: basic_text_validator,
      },
        operatingName: {
          label: "Operating Name",
          frLabel: "Nom opérationnel",
          inputType: "text",
          placeholder: "Company’s operating name",
          validator: basic_text_validator,
        },
        // isLegalNameSameAsOperatingName: {
        //   label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
        //   frLabel: "Le nom légal de notre société est le même que son nom opérationnel.  Le nom légal complet doit être fourni.",
        //   inputType: "checkbox",
        // },
        description: {
          label: "Company Description",
          frLabel: "Description de l’entreprise",
          inputType: "textarea",
          placeholder: "Write your text here...",
          validator: basic_text_validator,
        },
        industry: {
          label: "Industry",
          frLabel: "Industrie",
          inputType: "dropdownSelect",
          placeholder: "Placeholder",
          tooltip: {
            content: "Click on the field and select an option from the list.",
            position: "bottom-right"
          },
          frTooltip: {
            content: "Cliquer sur le champ et sélectionner une option de la liste.",
            position: "bottom-right"
          },
          validator: selection_validator,
          additionalData: {
            selectionData: [
              {
                value: "Agriculture",
                text: "Agriculture",
                frText: "Agriculture",
              },
              {
                value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
                text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
                frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
              },
              {
                value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
                text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
                frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
              },
              {
                value: "Consumer Retail",
                text: "Consumer Retail",
                frText: "Consommation de détail",
              },
              {
                value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
                text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
                frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
              },
              {
                value: "Education",
                text: "Education",
                frText: "Éducation",
              },
              {
                value: "Financial Services",
                text: "Financial Services",
                frText: "Services financiers",
              },
              {
                value: "Food & Beverage",
                text: "Food & Beverage",
                frText: "Restauration/production d’aliments et boissons",
              },
              {
                value: "Forestry",
                text: "Forestry",
                frText: "Foresterie",
              },
              {
                value: "Life Sciences & Health",
                text: "Life Sciences & Health",
                frText: "Sciences de la vie et de la santé",
              },
              {
                value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
                text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
                frText: "Exploitation minière, pétrole et gaz : Comprend les activités d’extraction qui ne relèveraient pas autrement des technologies propres",
                },
                {
                  value: "Tourism & Culture: Includes entertainment and sports",
                  text: "Tourism & Culture: Includes entertainment and sports",
                  frText: "Culture et tourisme : comprend le divertissement et le sport",
                },
                {
                  value: "Transportation",
                  text: "Transportation",
                  frText: "Transport",
                },
                {
                  value: "Other",
                  text: "Other",
                  frText: "Autre",
                }
            ]
          }
        },
        websiteURL: {
          label: "Website URL",
          frLabel: "URL du site Web",
          inputType: "text",
          placeholder: "https://",
          validator: url_validator,
        },
        number: {
          label:  `Your 9-Digit Business Number<br><p className="bodySmaller">The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit <a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website)</a></p>`,
          frLabel:  `Le numéro d’entreprise (NE) est un numéro à 9 chiffres attribué à toutes les entreprises constituées en société par l’Agence du revenu du Canada. Détenir un numéro d’enregistrement est une condition pour participer à ÉleverlaPI. Pour plus d’informations sur les numéros d’entreprise, (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'> cliquez ici.</a>)",`,
          inputType: "text",
        //   tooltip: {
        //     content: `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit <a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/corporations/type-corporation.html#ccpc'>this website)</a>`,
        //     customWidth: //MAKE THIS A THING IF THEY WANT
        //     },
          validator: {
            ...business_number_validator,
            minLength: {
              value: 9,
              message: "Registration number must be nine digits",
            },
            maxLength: {
              value: 9,
              message: "Registration number must be nine digits",
            }
          }
        },
        date: {
          label: "Registration Date",
          inputType: "date",
          frLabel: "Date de l’enregistrement",
          validator: date_validator,
        },
        province: {
          label: "Registered Provinces and Territories",
          inputType: "multiSelectNarrow",
          frLabel: "Provinces et territoires enregistrés",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "AB",
                text: "Alberta",
                frText: "Alberta"
              },
              {
                value: "BC",
                text: "British Columbia",
                frText: "Colombie-Britannique"
              },
              {
                value: "MB",
                text: "Manitoba",
                frText: "Manitoba"
              },
              {
                value: "NB",
                text: "New Brunswick",
                frText: "Nouveau-Brunswick"
              },
              {
                value: "NL",
                text: "Newfoundland and Labrador",
                frText: "Terre-Neuve-et-Labrador"
              },
              {
                value: "NT",
                text: "Northwest Territories",
                frText: "Territoires du Nord-Ouest"
              },
              {
                value: "NS",
                text: "Nova Scotia",
                frText: "Nouvelle-Écosse"
              },
              {
                value: "NU",
                text: "Nunavut",
                frText: "Nunavut"
              },
              {
                value: "ON",
                text: "Ontario",
                frText: "Ontario"
              },
              {
                value: "PE",
                text: "Prince Edward Island",
                frText: "Île-du-Prince-Édouard"
              },
              {
                value: "QC",
                text: "Quebec",
                frText: "Québec"
              },
              {
                value: "SK",
                text: "Saskatchewan",
                frText: "Saskatchewan"
              },
              {
                value: "YT",
                text: "Yukon",
                frText: "Yukon"
              },
            ]
          }
        }
      }

    export const profileFormAddressDefinition: FormDefinition = {
        streetNumber: {
            label: "Street Number",
            frLabel: "Numéro civique",
            inputType: "text",
            validator: basic_text_validator,
          },
          streetName: {
            label: "Street Name",
            frLabel: "Nom de la rue",
            inputType: "text",
            validator: basic_text_validator,
          },
          unitNumber: {
            label: "Unit Number",
            frLabel: "Numéro d’appartement",
            tooltip: {
              content: "Optional Field"
            },
            frTooltip: {
              content: "Optionnel"
            },
            inputType: "text",
            validator: {
              ...basic_text_validator,
              required: false
            }
          },
          postalCode: {
            label: "Postal Code",
            frLabel: "Code postal",
            inputType: "text",
            validator: basic_text_validator,
          },
          city: {
            label: "City",
            frLabel: "Ville",
            inputType: "text",
            validator: basic_text_validator,
          },
          province: {
            label: "Province",
            frLabel: "Province",
            inputType: "text",
            validator: basic_text_validator,
          },
          country: {
            label: "Country",
            frLabel: "Pays",
            inputType: "text",
            validator: basic_text_validator,
          }
    }

    export const profileFormProgramingEvaluationDefinition: FormDefinition = {
        hasSocialOrEnvironmentalGoals: {
          frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
          label: "Does your company have social or environmental goals as part of its founding mission?",
          inputType: "boolean",
          tooltip: {
            content: `Environmental goal: Refers to the goals that a startup's activities, products, or services have on the natural environment. This can include factors such as carbon footprint, resource usage, waste generation, and overall sustainability practices. <br> Social goal: Tangible and positive effects that a startup's activities, products, or services have on society and communities. This can be measured as contributions to various social causes, the extent to which it addresses societal challenges, and its efforts to create shared value for all stakeholders.`
          },
          frTooltip: {
            content: `Objectif environnemental : Désigne les objectifs que les activités, produits ou services d'une entreprise en démarrage ont en matière d'environnement. Cela peut inclure des facteurs tels que l’empreinte carbone, l’utilisation des ressources, la production de déchets et les pratiques globales de durabilité. 
            <br> Objectif social : Désigne les effets tangibles et positifs que les activités, produits ou services d’une entreprise en démarrage ont sur la société et les communautés. Cela peut être mesuré par ses contributions à diverses causes sociales, par la mesure dans laquelle elle répond aux défis sociétaux et par ses efforts visant à créer une valeur partagée pour toutes les parties prenantes.`
          }
        },
        numFullTimeEmployees: {
          frLabel: "Votre entreprise compte combien d’employés à temps plein dans le monde?",
          label: "What is the number of full-time employees at your company globally?",
          inputType: "number",
          validator: basic_number_validator,
        },
        isProductForSaleOnMarket: {
          frLabel: "Le produit que vous cherchez à sécuriser comme PI est-il actuellement en vente sur le marché?",
          label: "Is the product you are looking to protect currently for sale on the market?",
          inputType: "boolean"
        },
        productForSaleInfo: {
          conditionalRenderDefinition: {
            fieldKey: "isProductForSaleOnMarket",
            renderWhenTrue: true, 
          },
          frLabel: "Veuillez sélectionner tous les énoncés qui s’appliquent",
          label: "Please select all that apply",
          inputType: "multiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "singleProduct",
                text: "The product I want to protect is currently for sale",
                frText: "Le produit que je désire sécuriser comme PI est présentement en vente",
              },
              {
                value: "multipleProducts",
                text: "There are multiple products I want to protect that are currently for sale",
                frText: "Plusieurs produits que je désire sécuriser comme PI sont présentement en vente",
              },
            ]
          }
        },
        revenueOverTwelveMonths: {
          frLabel: "Indiquez le revenu total de votre entreprise (en CAD) au cours de votre dernier exercice financier.",
          label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
          inputType: "number",
          placeholder: "$",
          validator: basic_number_validator,
        },
        // underrepresentedFounderGroups: {
        //   frLabel: "Votre entreprise est-elle détenue majoritairement (50,1 %) par un groupe sous-représenté? Veuillez choisir toutes les réponses qui s’appliquen.  Nous nous engageons à accroître la diversité des personnes dans nos programmes et à faire en sorte que toutes les personnes vivent des expériences équitables et inclusives. Afin de suivre nos progrès vers cet objectif et de nous conformer aux exigences de financement du gouvernement fédéral, nous vous encourageons à indiquer si votre entreprise est détenue majoritairement par l'un des groupes sous-représentés suivants.",
        //   label: "Underrepresented Groups: A startup/BAI owned by an underrepresented group refers to those that are majority owned (50.1%) by an underrepresented group which includes: women, Indigenous Peoples (including First Nations, Métis and Inuit), 2SLGBTQI+, visible minorities, persons with disabilities or any community of people that experience significant collective barriers to participating in mainstream society.  We are committed to increasing the diversity of people in our programs and ensuring that all people have equitable and inclusive experiences throughout. In order to monitor our progress on this goal and to comply with Federal Government funding requirements, we are encouraging you to share whether your company is majority owned by any of the following underrepresented groups.  Please select all that apply:",
        //   inputType: "multiSelectNarrow",
        //   validator: multiselect_validator,
        //   additionalData: {
        //     selectionData: [
        //       {
        //         value: "women",
        //         text: "Women",
        //         frText: "Femmes"
        //       },
        //       {
        //         value: "indigenous",
        //         text: "Indigenous Peoples (including First Nations, Métis and Inuit)",
        //         frText: "Autochtones"
        //       },
        //       {
        //         value: "2slgbtqi+",
        //         text: "2SLGBTQI+",
        //         frText: "2SLGBTQI+"
        //       },
        //       {
        //         value: "visibleMinorities",
        //         text: "Visible Minorities",
        //         frText: "Minorités visibles"
        //       },
        //       {
        //         value: "disabilities",
        //         text: "Persons with Disabilities",
        //         frText: "Personnes handicapées"
        //       },
        //       {
        //         value: "other",
        //         text: "Any community of people that experience significant collective barriers to participating in mainstream society",
        //         frText: "Autre"
        //       },
        //       {
        //         value: "notToSay",
        //         text: "Prefer not to say",
        //         frText: "Préfère ne pas répondre"
        //       },
        //       {
        //         value: "none",
        //         text: "None of the Above",
        //         frText: "Aucune de ces réponses"
        //       }
        //     ]
        //   }
        // },
        coPayEligible: {
          label: "Eligible for 10% Co-investment",
          frLabel: "",
          inputType: "boolean",
        },
        isFromOLMC: {
          label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
          frLabel:"Êtes-vous issu d'une communauté de langue officielle en situation minoritaire (CLOSM)?",
          inputType: "boolean",
        },
        officialLanguageMinorityCommunity: {
          conditionalRenderDefinition: {
            fieldKey: "isFromOLMC",
            renderWhenTrue: true,
          },
          label: "Which OLMC are you a part of?",
          frLabel: "De quelle CLOSM faites-vous partie?",
          inputType: "customDropdownSelect",
          // tooltip: {
          //   content:  "Click on the field and select an option from the list or Enter your own directly.",
          //   position: "bottom-right"
          // },
          // frTooltip: {
          //   content:  "Cliquer sur le champ et sélectionner une option de la liste ou saisissez votre propre classification directement.",
          //   position: "bottom-right"
          // },
          validator: selection_validator,
          additionalData: {
            selectionData: [
              {
                value: "ACF",
                frText: "Assemblée communautaire fransaskoise (ACF)",
                text: "Assemblée communautaire fransaskoise (ACF)",
              },
              {
                value: "AFO",
                frText: "Assemblée de la francophonie de l'Ontario (AFO)",
                text: "Assemblée de la francophonie de l'Ontario (AFO)",
              },
              {
                value: "FCFA",
                frText: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
                text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
              },
              {
                value: "SFM",
                frText: "Société franco-manitobaine (SFM)",
                text: "Société franco-manitobaine (SFM)",
              },
              {
                value: "SNA",
                frText: "Société nationale de l'Acadie (SNA)",
                text: "Société nationale de l'Acadie (SNA)",
              },
            ]
          }
        },
        // additionalLinks: {
        //   label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        //   frLabel: "",
        //   inputType: "text",
        //   validator: url_validator,
        // },
      }

    export const profileFormiPStrategyDefinition: FormDefinition = {
        bestMatch: {
          frLabel: "Lequel des énoncés suivants correspond le mieux à vos besoins actuels?",
          label: "Which of the following best match your needs at this time?",
          inputType: "multiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "education",
                text: "I’m seeking general IP Education (e.g. Where do I start? What is a patent?, etc.)",
                frText: "Je recherche une formation générale en PI (p. ex., par où dois-je commencer? Qu'est-ce qu'un brevet?, etc.).",
              },
              {
                value: "strategy",
                text: "I’m seeking support for IP Strategy Development (e.g, What/how/when/where should I protect? I need help with patentability/novelty searches., etc.)",
                frText: "Je recherche de l'aide pour l'élaboration d'une stratégie de PI (p. ex., quoi/comment/quand/où dois-je protéger? J'ai besoin d'aide pour des recherches de brevetabilité/nouveauté, etc.).",
              },
              {
                value: "trademarks",
                text: "I think I’m ready to register trademarks in the next 3-6 months",
                frText: "Je pense que je suis prêt(e) à enregistrer des marques dans les 3 à 6 prochains mois.",
              },
              {
                value: "patents",
                text: "I think I’m ready to file patents in the next 3-6 months or convert PCT / provisional or continuations",
                frText: "Je pense que je suis prêt(e) à déposer des brevets dans les 3 à 6 prochains mois ou à convertir un PCT, à déposer une demande de brevet provisoire ou à reconduire un brevet",
              },
            ]
          }
        },
        assignmentAgreement: {
          frLabel: "Les cofondateurs de votre entreprise ont-ils signé un « accord de cession de PI des cofondateurs » qui attribue la PI à la société?",
          label: 'Have you and your cofounders signed a "Co-founders IP assignment agreement" that assigns IP to the corporation? ',
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        patentsReadyOptions: {
          frLabel: "Veuillez indiquer lesquels des énoncés suivants s’appliquent à la technologie que vous avez l’intention de breveter :",
          label: "If you think you are ready to file patents in the next 3-6 months, please indicate which of the following apply to the technology you intend to patent:",
          inputType: "multiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "notApplicable",
                text: "Not applicable, as I am not seeking support to file patents in the next 3-6 months",
                frText: "Sans objet, car je ne recherche pas d'aide pour déposer des brevets dans les 3 à 6 prochains mois.",
              },
              {
                value: "search",
                text: "My IP service provider has conducted a favorable patentability/prior art/novelty search for the technology I plan to patent.",
                frText: "Mon fournisseur de services en PI a effectué une recherche de brevetabilité/d’état de la technologie/de nouveauté favorable pour la technologie que je prévois breveter.",
              },
              {
                value: "family",
                text: "I have a granted patent in the same patent family.",
                frText: "J'ai obtenu un brevet dans la même famille de brevets.",
              },
              {
                value: "written",
                text: "I have received a favorable written opinion from my PCT application.",
                frText: "J'ai reçu une opinion écrite favorable concernant ma demande de PCT.",
              },
              {
                value: "support",
                text: "I don’t have B or C or D and I am seeking support to file patent",
                frText: "Je n'ai ni B, ni C, ni D et je recherche de l'aide pour déposer un brevet",
              },
            ]
          }
        },
        trademarkClearanceSearch: {
          frLabel: "Si vous pensez être prêt à enregistrer une marque dans les 3 à 6 prochains mois, votre fournisseur de services IP a-t-il effectué une recherche d'autorisation ?",
          label: "If you think you are ready to register trademarks in the next 3-6 months, has your IP service provider completed a clearance search?",
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        isWorkingWithLawFirm: {
          frLabel: "Votre entreprise est présentement en contact avec une firme d’avocats et de PI",
          label: "Is your company currently working with an IP / Law firm?",
          inputType: "boolean",
        },
        workingLawFirms: {
          conditionalRenderDefinition: {
            fieldKey: "isWorkingWithLawFirm",
            renderWhenTrue: true,
          },
          frLabel: "Veuillez indiquer le nom des firmes",
          label: "Please list the name of firms",
          inputType: "customList",
        },
        isWorkingWithOrganization: {
          frLabel: "Votre entreprise est présentement en contact avec Invest Ottawa ou North Forge qui collaborent avec Communitech",
          label: "Is your company currently working with Communitech, Invest Ottawa, North Forge, Spark Center or Launch Lab?",
          inputType: "boolean",
        },
        workingOrganizations: {
          conditionalRenderDefinition: {
            fieldKey: "isWorkingWithOrganization",
            renderWhenTrue: true,
          },
          frLabel: "Veuillez indiquer quelle organisation",
          label: "Please specify which organization",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "communitech", 
                text: "Communitech"
              },
              {
                value: "northForge", 
                text: "North Forge"
              },
              {
                value: "investOttawa", 
                text: "Invest Ottawa"
              },
              {
                value: "spark", 
                text: "Spark Center"
              },
              {
                value: "launch", 
                text: "Launch Labs"
              }
            ]
          }
        },
        isWorkingWithBai: {
          frLabel: "Votre entreprise travaille-t-elle actuellement avec un autre IAA (Incubateur Accélérateur d’Affaires)?",
          label: "Is your company currently working with a different Business Accelerator and Incubator (BAI)?",
          inputType: "boolean",
        },
        otherBai: {
          conditionalRenderDefinition: {
            fieldKey: "isWorkingWithBai",
            renderWhenTrue: true,
          },
          frLabel: "Veuillez indiquer quelle organisation",
          label: "Specify which BAI",
          placeholder: "BAI names",
          inputType: "text",
          validator: basic_text_validator
        }
      }


    export const profileFormipReadinessDefinition: FormDefinition =  { 
            responsible: {
              label: "Who is responsible for IP within your company?",
              frLabel: "Qui est responsable de la propriété intellectuelle au sein de votre entreprise ?",
              placeholder: "Enter name",
              inputType: "text",
              validator: basic_text_validator
            },
            education: {
              label: "Does that person have any formal IP education?",
              frLabel: "Cette personne a-t-elle une formation formelle en propriété intellectuelle ?",
              inputType: "boolean",
              validator: boolean_selection_validator
            },
            educationType: {
              conditionalRenderDefinition: {
                fieldKey: "education",
                renderWhenTrue: true, 
              },
              label: "What type of formal IP education did that person receive?",
              frLabel: "Quel type de formation formelle en propriété intellectuelle cette personne a-t-elle reçu ?",
              inputType: "dropdownSelect",
              additionalData: {
                selectionData: [
                  {
                    value: "classes",
                    text: "IP classes",
                    frText: "Classes IP"
                  },
                  {
                    value: "webinars",
                    text: "Webinars provided by an accelerator/incubator focussed on IP",
                    frText: "Webinaires proposés par un accélérateur/incubateur axé sur la PI",

                  },
                  {
                    value: "ipon",
                    text: "IP Assist or IPON education",
                    frText: "IP Assist ou formation IPON",
                  },
                ]
              }
            },
            jurisdiction: {
              label: "Have you identified which jurisdictions are of particular interest for you and where you would seek patents if provided with sufficient funding?",
              frLabel: "Avez-vous identifié les juridictions qui vous intéressent particulièrement et où vous chercheriez à obtenir des brevets si vous disposiez d'un financement suffisant ?",
              inputType: "boolean",
              validator: boolean_selection_validator
            },
            specificJurisdiction: {
              conditionalRenderDefinition: {
                fieldKey: "jurisdiction",
                renderWhenTrue: true, 
              },
              label: "Please Specify",
              frLabel: "Veuillez préciser",
              placeholder: "Detail jurisdictions",
              inputType: "text",
              validator: basic_text_validator,
            },
            provider: {
              label: "Do you have an IP service provider/patent agent?",
              frLabel: "Avez-vous un fournisseur de services de propriété intellectuelle/un agent de brevets ?",
              inputType: "boolean",
              validator: basic_text_validator,
            },
            providerLastWorked: {
              conditionalRenderDefinition: {
                fieldKey: "provider",
                renderWhenTrue: true, 
              },
              label: "When did you last work with them?",
              frLabel: "Quand avez-vous travaillé pour la dernière fois avec eux ?",
              inputType: "dropdownSelect",
              validator: {
                required: {
                  value: true,
                  message: "Please select an option"
                }
              },
              additionalData: {
                selectionData: [
                  {
                    value: "under", 
                    text: "Within past 12 months",
                    frText: "Au cours des 12 derniers mois",
                  },
                  {
                    value: "over", 
                    frText: "Pas au cours des 12 derniers mois",
                    text: "Not within past 12 months"
                  }
                ]
              }
            },  
            ipSection: {
              label: "Do you have a documented IP section in your data room?",
              frLabel: "Avez-vous une section IP documentée dans votre data room ?",
              inputType: "dropdownSelect",
              validator: {
                required: {
                  value: true,
                  message: "Please select an option"
                }
              },
              additionalData: {
                selectionData: [
                  {
                    value: "yes", 
                    frText: "Oui",
                    text: "Yes"
                  },
                  {
                    value: "no",                     
                    frText: "Non",
                    text: "No"
                  },
                  {
                    value: "n/a", 
                    text: "Not applicable - We are self-funded",
                    frText: "Non applicable - Nous sommes autofinancés",
                  }
                ]
              }
            },
            software: {
              label: "Do you have an open source log where you keep track of all open source software, algorithms and/or datasets used within your product/service, along with the licensing terms and notes on compliance?",
              frLabel: "Disposez-vous d'un journal open source dans lequel vous gardez une trace de tous les logiciels open source, algorithmes et/ou ensembles de données utilisés dans votre produit/service, ainsi que les conditions de licence et les notes sur la conformité ?",
              inputType: "dropdownSelect",
              validator: {
                required: {
                  value: true,
                  message: "Please select an option"
                }
              },
              additionalData: {
                selectionData: [
                  {
                    value: "yes", 
                    text: "Yes",
                    frText: "Oui",
                  },
                  {
                    value: "no", 
                    text: "No",
                    frText: "Non",
                  },
                  {
                    value: "n/a", 
                    text: "Not applicable - Our product has no software",
                    frText: "Non applicable - Notre produit n'a pas de logiciel",
                  }
                ]
              }
            },
            tradeSecrets: {
              label: "Do you have any trade secret protection measures in place?",
              frLabel: "Avez-vous mis en place des mesures de protection des secrets commerciaux ?",
              inputType: "boolean",
              validator: boolean_selection_validator,
            },
            tradeSecretsList: {
              conditionalRenderDefinition: {
                fieldKey: "tradeSecrets",
                renderWhenTrue: true, 
              },
              label: "Please list your protection measures",
              frLabel: "Veuillez énumérer vos mesures de protection",
              placeholder: "Enter details here...",
              inputType: "textarea",
            },  
            ipBudget: {
              label: "Do you have a documented IP budget?",
              frLabel: "Avez-vous un budget IP documenté ?",
              inputType: "boolean",
              validator: boolean_selection_validator,
            },
            patentReady: {
              label: "Do you have any innovations ready to file patents for within the next 3-6 months?",
              frLabel: "Avez-vous des innovations prêtes à déposer des brevets dans les 3 à 6 prochains mois ?",
              inputType: "boolean",
              validator: boolean_selection_validator,
            },
            patentsNumber: {
              conditionalRenderDefinition: {
                fieldKey: "patentReady",
                renderWhenTrue: true, 
              },
              label: "How many innovations are ready to file within the next 3-6 months?",
              frLabel: "Combien d’innovations sont prêtes à être déposées dans les 3 à 6 prochains mois ?",
              inputType: "number",
              validator: basic_number_validator,
            },  
            searchesList: {
              conditionalRenderDefinition: {
                fieldKey: "patentReady",
                renderWhenTrue: true, 
              },
              label: "Concernant les innovations mentionnées à la question précédente, votre prestataire de services de PI a-t-il effectué des recherches de brevetabilité ou de nouveauté pour au moins une d'entre elles ? Veuillez préciser les recherches effectuées pour chaque innovation",
              frLabel: "",
              placeholder: "Enter details here...",
              inputType: "textarea",
            }, 
          }