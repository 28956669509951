import { text } from "stream/consumers";
import { FormDefinition, InputFieldType } from "../Components/Form/Form";
import { FormGroupDefinition } from "../Components/FormGroup";
import { address_validator, basic_number_validator, basic_text_validator, boolean_selection_validator, date_validator, email_validator, multiselect_validator, password_validator, phone_validator, selection_validator, unique_email_validator, url_validator, username_validator } from "./formFieldValidators";

const organizations = [
  {
    value: "communitech",
    text: "Communitech"
  },
  {
    value: "investOttawa",
    text: "Invest Ottawa"
  },
  {
    value: "northForge",
    text: "North Forge"
  },
]

export const loginFormDefinition: FormDefinition = {
  email: {
    label: "Your Email",
    frLabel: "Courriel",
    inputType: "text",
    placeholder: "Email Address",
    validator: email_validator,
  },
  password: {
    label: "Password",
    frLabel: "Mot de passe",
    placeholder: "Password",
    inputType: "text",
    validator: password_validator,
  }
}

export const screeningFormDefinition: FormDefinition = {
  isCCPC: {
    label: "Is your company a Canadian Controlled Private Corporation (<a target='_blank' className='underline' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>CCPC</a>)?",
    frLabel: "Est-ce que votre entreprise est une société privée sous contrôle canadien (<a className='underline' target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>CCPC</a>)?",
    inputType: "boolean"
  },
  under500: {
    label: "Does your company have less than 500 full time employees, globally?",
    frLabel: "Votre entreprise compte-t-elle moins de 500 employés à temps plein dans le monde?",
    inputType: "boolean",
  },
  address: {
    label: "Company Headquarters",
    frLabel: "L’adresse du siège social de l’entreprise",
    placeholder: "Start typing address...",
    tooltip: {
      content: "Your address allows us to match you with one of our partner in your region."
    },
    frTooltip: {
      content: ""
    },
    frPlaceholder: "", 
    inputType: "address",
    validator: address_validator,
  }
}

export const registerFormDefinition: FormDefinition = {
  username: {
    label: "Company Name",
    frLabel: "Nom de l'entreprise",
    placeholder: "Company name",
    inputType: "text",
    validator: username_validator,
  },
  uniqueEmail: {
    label: "Email",
    frLabel: "Courriel",
    placeholder: "Email address",
    inputType: "text",
    validator: unique_email_validator,
  },
  password: {
    label: "Password",
    frLabel: "Mot de passe",
    placeholder: "Create your password",
    frPlaceholder: "Créez votre mot de passe",
    inputType: "text",
    validator: password_validator,
  },
  // confirmPassword: {
  //   label: "Confirm Password",
  //   frLabel: "Confirmer mot de passe",
  //   inputType: "text",
  //   validator: password_validator,
  // },
}

export const founderProgramDetailsFormDefinition: FormDefinition = {
  name: {
    label: "Service Name",
    frLabel: "Nom du service",
    inputType: "text",
    validator: basic_text_validator
  },
  description: {
    label: "Service Description",
    frLabel: "Description du service",
    inputType: "textarea",
    validator: basic_text_validator
  }
}

export const lockedContentFormDefition: FormDefinition = {
  content: {
    inputType: "htmlParser",
    label: "Enrolled Service Content",
    frLabel: "Contenu du service inscrit"
  }
}

export const addressFormDefinition: FormDefinition = {
  streetNumber: {
    label: "Street Number",
    frLabel: "Numéro civique",
    inputType: "text",
    validator: basic_text_validator,
  },
  streetName: {
    label: "Street Name",
    frLabel: "Nom de la rue",
    inputType: "text",
    validator: basic_text_validator,
  },
  unitNumber: {
    label: "Unit Number",
    frLabel: "Numéro d’appartement",
    tooltip: {
      content: "Optional Field"
    },
    frTooltip: {
      content: "Optionnel"
    },
    inputType: "text",
    validator: {
      ...basic_text_validator,
      required: false
    }
  },
  postalCode: {
    label: "Postal Code",
    frLabel: "Code postal",
    inputType: "text",
    validator: basic_text_validator,
  },
  city: {
    label: "City",
    frLabel: "Ville",
    inputType: "text",
    validator: basic_text_validator,
  },
  province: {
    label: "Province",
    frLabel: "Province",
    inputType: "text",
    validator: basic_text_validator,
  },
  country: {
    label: "Country",
    frLabel: "Pays",
    inputType: "text",
    validator: basic_text_validator,
  }
};

export const programDetailsFormDefinition: FormDefinition = {
  ...founderProgramDetailsFormDefinition,
  tier: {
    label: "Tier",
    frLabel: "Niveau",
    inputType: "dropdownSelect",
    validator: selection_validator,
    additionalData: {
      selectionData:[
        {
          value: "1",
          text: "1"
        },
        {
          value: "2",
          text: "2"
        },
        {
          value: "3",
          text: "3"
        }
      ]
    }  
  },
  ownerName: {
    label: "Service Owner",
    frLabel: "Propriétaire du service",
    inputType: "text",
    tooltip: {
      content: "The current version of the App does not support changing service owners. Please contact support for more information. <br> If you are the owner of the service, go to <a href='/dashboard/profile'>Profile</a> and updating your name will also reflect here.",
      position: "top-left"
    },
    frTooltip: {
      content: "La version actuelle de l’application n’autorise pas le changement de propriétaire du service. Pour plus d’information, veuillez communiquer avec le Service de soutien. Si vous êtes le propriétaire du service, allez à Profil et la mise à jour de votre nom sera également rapportée ici.",
      position: "top-left"
    },
    validator: basic_text_validator,
    isViewOnly: true,
  },
  programRegion: {
    label: "Service Region",
    frLabel: "Région de service",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "communitech", 
          text: "Communitech"
        },
        {
          value: "northForge", 
          text: "North Forge"
        },
        {
          value: "investOttawa", 
          text: "Invest Ottawa"
        }
      ]
    },
  },
  lang: {
    label: "Language",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "eng",
          text: "Eng",
        },
        {
          value: "fr",
          text: "Fr",
        },
      ]
    }
  },
  reportingCategory: {
    label: "Reporting Category",
    inputType: "dropdownSelect",
    frLabel: "Catégorie de rapport",
    validator: selection_validator,
    additionalData: {
      selectionData:[
        {
          value: "baiEducation",
          text: "BAI Education",
          frText: "Formation IAA (Incubateur Accélérateur d’Affaires)"
        },
        {
          value: "generalAwareness",
          text: "General Awareness",
          frText: "Sensibilisation générale"
        },
        {
          value: "onlineModules",
          text: "Online Modules",
          frText: "Modules en ligne"
        },
        {
          value: "otherBaiEducation",
          text: "Other - BAI Education",
          frText: "Autre - Formation IAA"
        },
        {
          value: "otherStartupEducation",
          text: "Other - Startup Education",
          frText: "Autre - Formation pour entreprise en démarrage"
        },
        {
          value: "seminars",
          text: "Seminars",
          frText: "Séminaires"
        },
        {
          value: "webinars",
          text: "Webinars",
          frText: "Webinaires"
        },
        {
          value: "workshops",
          text: "Workshops",
          frText: "Ateliers"
        },
        {
          value: "assessment",
          text: "Assessment",
          frText: "Évaluation"
        },
        {
          value: "coachingAndAdvisory",
          text: "Coaching & Advisory",
          frText: "Mentorat et conseil"
        },
        {
          value: "commercialization",
          text: "Commercialization",
          frText: "Commercialisation"
        },
        {
          value: "other",
          text: "Other",
          frText: "Autre"
        },
        {
          value: "patent",
          text: "Patent",
          frText: "Brevet"
        },
        {
          value: "trademark",
          text: "Trademark",
          frText: "Marque de commerce"
        },
        {
          value: "copyrightRegistration",
          text: "Copyright Registration",
          frText: "Enregistrement de droits d’auteur"
        },
        {
          value: "patentApplication",
          text: "Patent Application",
          frText: "Application de brevet"
        },
        {
          value: "trademarkRegistration",
          text: "Trademark Registration",
          frText: "Enregistrement de marque de commerce"
        },
      ],
    }
  },
  deliverables: {
    label: "Deliverables",
    frLabel: "Livrables",
    inputType: "text",
    validator: basic_text_validator
  },
  notes: {
    label: "Notes",
    frLabel: "Remarques",
    inputType: "textarea",
    validator: basic_text_validator
  },
  isCoPay: {
    label: "Co-Pay Service",
    frLabel: "Service de co-paiement",
    tooltip: {
      content: "Startups receiving funding to implement their IP strategy will be required to co-invest at a minimum 10-35% of eligible costs.",
      position: "top-right"
    },
    frTooltip: {
      content: "Les startups recevant un financement pour mettre en œuvre leur stratégie de propriété intellectuelle devront co-investir au minimum 10 à 35 % des coûts éligibles.",
      position: "top-right"
    },
    inputType: "boolean",
  },
  isRequiringAttestation: {
    label: "This service requires founders to check attestation.",
    frLabel: "Ce service exige que les fondateurs vérifient l’attestation",
    inputType: "boolean",
  },
  applicationFormDefinition: {
    label: "Application Form Definition",
    frLabel: "Définitions du formulaire d’application",
    inputType: "formDefinition",
  },
  lockedContent: {
    label: "Locked Content - input in HTML, dev ovnly please",
    frLabel: "Contenu verrouillé - entrée en HTML, dév. seulement",
    inputType: "textarea",
  }
}

export const adminProfileFormDefinition: FormDefinition = {
  firstName: {
    label: "First Name",
    frLabel: "Prénom",
    inputType: "text",
    validator: {
      fieldType: "text",
    }
  },
  lastName: {
    label: "Last Name",
    frLabel: "Nom de famille",
    inputType: "text",
    validator: {
      fieldType: "text",
    }
  },
  email: {
    label: "Email",
    frLabel: "",
    inputType: "text",
    validator: email_validator,
  },
  applicationNotification: {
    label: "Receive email notifications for applications?",
    frLabel: "Pour recevoir des avis par courriel pour les applications dont vous êtes propriétaire du service",
    inputType: "checkbox",
  }
}

export const createAdminProfileFormDefinition: FormDefinition = {
  firstName: {
    label: "First Name",
    frLabel: "Prénom",
    inputType: "text",
    validator: basic_text_validator,
  },
  lastName: {
    label: "Last Name",
    frLabel: "Nom de famille",
    inputType: "text",
    validator: basic_text_validator,
  },
  uniqueEmail: {
    label: "Email",
    frLabel: "Courrier",
    inputType: "text",
    validator: unique_email_validator,
  },
  userRegion: { 
    label: "Select Admin Organization",
    frLabel: "Sélectionner Organisation/Admin",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "communitech", 
          text: "Communitech"
        },
        {
          value: "northForge", 
          text: "North Forge"
        },
        {
          value: "investOttawa", 
          text: "Invest Ottawa"
        }
      ]
    }
  },
}

export const newServiceProviderFirmFormDefinition: FormDefinition = {
  name: {
    label: "Name",
    frLabel: "Nom",
    inputType: "text",
    validator: basic_text_validator,
    placeholder: "Service provider name"
  },
  address: {
    label: "Enter Firm Location",
    frLabel: "Saisir l’emplacement de la firme",
    inputType: "address",
    validator: address_validator,
    placeholder: "Start typing address..."
  },
  contact: {
    label: "Contact Person",
    frLabel: "Personne ressource de l’entreprise pour communications ÉPI",
    inputType: "text",
    placeholder: "Contact person name",
    validator: basic_text_validator,
  },
  contactEmail: {
    label: "Email address",
    frLabel: "Courriel de la personne ressource",
    inputType: "text",
    validator: basic_text_validator,
    placeholder: "Email address"
  },
  lang: {
    label: "Language",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "eng",
          text: "Eng",
        },
        {
          value: "fr",
          text: "Fr",
        },
      ]
    }
  },
  region: {
    label: "Select Region",
    frLabel: "Sélectionner la région",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "communitech", 
          text: "Communitech"
        },
        {
          value: "northForge", 
          text: "North Forge"
        },
        {
          value: "investOttawa", 
          text: "Invest Ottawa"
        }
      ]
    }
  },
  description: {
    label: "Description",
    placeholder: "Description",
    frLabel: "Description",
    inputType: "textarea",
    validator: basic_text_validator,
  },
  // [TODO] build tag component
  industriesOfFocus: {
    label: "Industries Of Focus",
    placeholder: "Industries Of Focus",
    frLabel: "Industries ciblées",
    inputType: "textarea",
    validator: basic_text_validator,
  },
  websiteURL: {
    label: "Website",
    placeholder: "URL",
    frLabel: "URL du site Web",
    inputType: "text",
    validator: url_validator,
  },
  languages: {
    label: "Languages Offered",
    frLabel: "Langues offertes",
    inputType: "text",
    placeholder: "Languages",
    validator: basic_text_validator,
  },
  isActive: {
    label: "Add to Map?",
    frLabel: "Activation de cette firme prestataire de services",
    inputType: "checkbox",
  }
}

export const founderProfileFormGroupDefinition: FormGroupDefinition = {
  /* Contact Information */
  personalInfo: {
    sectionLabel: "Personal Information",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        label: "First Name",
        inputType: "text",
        frLabel: "Prénom",
        validator: basic_text_validator,
      },
      lastName: {
        label: "Last Name",
        frLabel: "Nom de famille",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] handle conditional checkboxes and other field feature
      pronouns: {
        label: "Your Pronouns",
        frLabel: "Pronom",
        inputType: "customMultiSelectNarrow",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "she/her",
              frText: "elle",
              value: "she"
            },
            {
              text: "he/him",
              frText: "il",
              value: "he"
            },
            {
              text: "they/them",
              frText: "eux",
              value: "they"
            },
            {
              text: "prefer not to say",
              frText: "préfère ne pas répondre",
              value: "notToSay"
            },
          ]
        }
      },
      title: {
        label: "Job Title",
        inputType: "text",
        frLabel: "Titre du poste",
        validator: basic_text_validator,
      },
      phoneNumber: {
        label: "Phone Number",
        inputType: "text",
        frLabel: "Numéro de téléphone",
        validator: phone_validator,
      },
      isPointOfContact: {
        label: "I'm the point of contact",
        frLabel: "Je suis la personne ressource à contacter",
        inputType: "checkbox",
        tooltip: {
          content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the preffered point of contact below",
          position: "bottom-right"
        },
        frTooltip: {
          content: "Si vous préférez que les avis par courriel et les communications d’ÉPI soient envoyés à un autre point de contact, ne cochez pas cette case et indiquez le point de contact souhaité ci-dessous.",
          position: "bottom-right"
        },
      }
    }
  },
  pointOfContact: {
    conditionalRenderDefinition: {
      fieldKey: "isPointOfContact",
      renderWhenTrue: false, 
    },
    sectionLabel: "Point of Contact",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        label: "First Name",
        frLabel: "Prénom",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        label: "Last Name",
        frLabel: "Nom de famille",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] pronouns is waiting on conditional checkboxes feature
      title: {
        label: "Job Title",
        frLabel: "Titre du poste",
        inputType: "text",
        validator: basic_text_validator,
      },
      email: {
        label: "Email",
        frLabel: "Courriel",
        inputType: "text",
        validator: email_validator,
      },
      phoneNumber: {
        label: "Phone Number",
        frLabel: "Numéro de téléphone",
        inputType: "text",
        validator: phone_validator,
      },
      // [TODO] roleTags is waiting on conditional checkboxes feature
    }
  },
  /* Company Information */
  basicInfo: {
    sectionLabel: "Company Information",
    frSectionLabel: "Renseignements sur l'entreprise",
    formDefinition: {
      operatingName: {
        label: "Operating Name",
        frLabel: "Nom opérationnel",
        inputType: "text",
        validator: basic_text_validator,
      },
      // isLegalNameSameAsOperatingName: {
      //   label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
      //   frLabel: "Le nom légal de notre société est le même que son nom opérationnel.  Le nom légal complet doit être fourni.",
      //   inputType: "checkbox",
      // },
      legalName: {
        // conditionalRenderDefinition: {
        //   fieldKey: "isLegalNameSameAsOperatingName",
        //   renderWhenTrue: false, 
        // },
        label: "Legal Name",
        frLabel: "Nom légal",
        inputType: "text",
        validator: basic_text_validator,
      },
      description: {
        label: "Company Description",
        frLabel: "Description de l’entreprise",
        inputType: "textarea",
        validator: basic_text_validator,
      },
      industry: {
        label: "Industry",
        frLabel: "Industrie",
        inputType: "dropdownSelect",
        tooltip: {
          content: "Click on the field and select an option from the list.",
          position: "bottom-right"
        },
        frTooltip: {
          content: "Cliquer sur le champ et sélectionner une option de la liste.",
          position: "bottom-right"
        },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "Agriculture",
              text: "Agriculture",
              frText: "Agriculture",
            },
            {
              value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
            },
            {
              value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
            },
            {
              value: "Consumer Retail",
              text: "Consumer Retail",
              frText: "Consommation de détail",
            },
            {
              value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
            },
            {
              value: "Education",
              text: "Education",
              frText: "Éducation",
            },
            {
              value: "Financial Services",
              text: "Financial Services",
              frText: "Services financiers",
            },
            {
              value: "Food & Beverage",
              text: "Food & Beverage",
              frText: "Restauration/production d’aliments et boissons",
            },
            {
              value: "Forestry",
              text: "Forestry",
              frText: "Foresterie",
            },
            {
              value: "Life Sciences & Health",
              text: "Life Sciences & Health",
              frText: "Sciences de la vie et de la santé",
            },
            {
              value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              frText: "Exploitation minière, pétrole et gaz : Comprend les activités d’extraction qui ne relèveraient pas autrement des technologies propres",
            },
            {
              value: "Tourism & Culture: Includes entertainment and sports",
              text: "Tourism & Culture: Includes entertainment and sports",
              frText: "Culture et tourisme : comprend le divertissement et le sport",
            },
            {
              value: "Transportation",
              text: "Transportation",
              frText: "Transport",
            },
            {
              value: "Other",
              text: "Other",
              frText: "Autre",
            }
          ]
        }
      },
      websiteURL: {
        label: "Website URL",
        frLabel: "URL du site Web",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  registration: {
    sectionLabel: "Registration Information",
    frSectionLabel: "Enregistrement de l’entreprise",
    formDefinition: {
      number: {
        label: `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website</a>)",`,
        frLabel:  `Le numéro d’entreprise (NE) est un numéro à 9 chiffres attribué à toutes les entreprises constituées en société par l’Agence du revenu du Canada. Détenir un numéro d’enregistrement est une condition pour participer à ÉleverlaPI. Pour plus d’informations sur les numéros d’entreprise, (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'> cliquez ici.</a>)",`,
        inputType: "text",
        validator: {
          ...basic_number_validator,
          minLength: {
            value: 9,
            message: "Registration number must be nine digits",
          },
          maxLength: {
            value: 9,
            message: "Registration number must be nine digits",
          }
        }
      },
      date: {
        label: "Registration Date",
        inputType: "date",
        frLabel: "Date de l’enregistrement",
        validator: date_validator,
      },
      province: {
        label: "Registered Provinces and Territories",
        inputType: "multiSelectNarrow",
        frLabel: "Provinces et territoires enregistrés",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "AB",
              text: "Alberta",
              frText: "Alberta"
            },
            {
              value: "BC",
              text: "British Columbia",
              frText: "Colombie-Britannique"
            },
            {
              value: "MB",
              text: "Manitoba",
              frText: "Manitoba"
            },
            {
              value: "NB",
              text: "New Brunswick",
              frText: "Nouveau-Brunswick"
            },
            {
              value: "NL",
              text: "Newfoundland and Labrador",
              frText: "Terre-Neuve-et-Labrador"
            },
            {
              value: "NT",
              text: "Northwest Territories",
              frText: "Territoires du Nord-Ouest"
            },
            {
              value: "NS",
              text: "Nova Scotia",
              frText: "Nouvelle-Écosse"
            },
            {
              value: "NU",
              text: "Nunavut",
              frText: "Nunavut"
            },
            {
              value: "ON",
              text: "Ontario",
              frText: "Ontario"
            },
            {
              value: "PE",
              text: "Prince Edward Island",
              frText: "Île-du-Prince-Édouard"
            },
            {
              value: "QC",
              text: "Quebec",
              frText: "Québec"
            },
            {
              value: "SK",
              text: "Saskatchewan",
              frText: "Saskatchewan"
            },
            {
              value: "YT",
              text: "Yukon",
              frText: "Yukon"
            },
          ]
        }
      }
    }
  },
  mailingAddress: {
    sectionLabel: "Mailing Address",
    frSectionLabel: "Adresse postale",
    formDefinition: addressFormDefinition,
  },
  programingEvaluation: {
    sectionLabel: "Service Evaluation",
    frSectionLabel: "Évaluation du service",
    formDefinition: {
      hasSocialOrEnvironmentalGoals: {
        frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
        label: "Does your company have social or environmental goals as part of its founding mission?",
        inputType: "boolean",
      },
      numFullTimeEmployees: {
        frLabel: "Nombre d’employés à temps plein (ETP)",
        label: "Number of Full Time Employees",
        inputType: "number",
        validator: basic_number_validator,
      },
      isProductForSaleOnMarket: {
        frLabel: "Le produit que vous cherchez à sécuriser comme PI est-il actuellement en vente sur le marché?",
        label: "Is the product you are looking to protect currently for sale on the market?",
        inputType: "boolean"
      },
      productForSaleInfo: {
        conditionalRenderDefinition: {
          fieldKey: "isProductForSaleOnMarket",
          renderWhenTrue: true, 
        },
        frLabel: "Veuillez sélectionner tous les énoncés qui s’appliquent",
        label: "Please select all that apply",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "singleProduct",
              text: "The product I want to protect is currently for sale",
              frText: "Le produit que je désire sécuriser comme PI est présentement en vente",
            },
            {
              value: "multipleProducts",
              text: "There are multiple products I want to protect that are currently for sale",
              frText: "Plusieurs produits que je désire sécuriser comme PI sont présentement en vente",
            },
          ]
        }
      },
      revenueOverTwelveMonths: {
        frLabel: "Indiquez le revenu total de votre entreprise (en CAD) au cours de votre dernier exercice financier.",
        label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
        inputType: "number",
        validator: basic_number_validator,
      },
      underrepresentedFounderGroups: {
        frLabel: "Nous nous engageons à accroître la diversité des personnes dans nos programmes et à faire en sorte que toutes les personnes vivent des expériences équitables et inclusives. Afin de suivre nos progrès vers cet objectif et de nous conformer aux exigences de financement du gouvernement fédéral, nous vous encourageons à indiquer si votre entreprise est détenue majoritairement par l'un des groupes sous-représentés suivants.",
        label: "Underrepresented Groups: A startup/BAI owned by an underrepresented group refers to those that are majority owned (50.1%) by an underrepresented group which includes: women, Indigenous Peoples (including First Nations, Métis and Inuit), 2SLGBTQI+, visible minorities, persons with disabilities or any community of people that experience significant collective barriers to participating in mainstream society.  We are committed to increasing the diversity of people in our programs and ensuring that all people have equitable and inclusive experiences throughout. In order to monitor our progress on this goal and to comply with Federal Government funding requirements, we are encouraging you to share whether your company is majority owned by any of the following underrepresented groups.  Please select all that apply:",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "women",
              text: "Women",
              frText: "Femmes"
            },
            {
              value: "indigenous",
              text: "Indigenous Peoples (including First Nations, Métis and Inuit)",
              frText: "Autochtones"
            },
            {
              value: "2slgbtqi+",
              text: "2SLGBTQI+",
              frText: "2SLGBTQI+"
            },
            {
              value: "visibleMinorities",
              text: "Visible Minorities",
              frText: "Minorités visibles"
            },
            {
              value: "disabilities",
              text: "Persons with Disabilities",
              frText: "Personnes handicapées"
            },
            // {
            //   value: "languageMinority",
            //   text: "Members of Official Language Minority Communities",
            //   frText: "Membres des communautés de langue officielle en situation minoritaire"
            // },
            {
              value: "other",
              text: "Any community of people that experience significant collective barriers to participating in mainstream society",
              frText: "Autre"
            },
            {
              value: "notToSay",
              text: "Prefer not to say",
              frText: "Préfère ne pas répondre"
            },
            {
              value: "none",
              text: "None of the Above",
              frText: "Aucune de ces réponses"
            }
          ]
        }
      },
      isFromOLMC: {
        label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
        frLabel:"Êtes-vous issu d'une communauté de langue officielle en situation minoritaire (CLOSM)?",
        inputType: "boolean",
      },
      officialLanguageMinorityCommunity: {
        conditionalRenderDefinition: {
          fieldKey: "isFromOLMC",
          renderWhenTrue: true,
        },
        label: "Which OLMC are you a part of?",
        frLabel: "De quelle CLOSM faites-vous partie?",
        inputType: "customDropdownSelect",
        // tooltip: {
        //   content:  "Click on the field and select an option from the list or Enter your own directly.",
        //   position: "top-left"
        // },
        // frTooltip: {
        //   content:  "Cliquer sur le champ et sélectionner une option de la liste ou saisissez votre propre classification directement.",
        //   position: "top-left"
        // },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "ACF",
              frText: "Assemblée communautaire fransaskoise (ACF)",
              text: "Assemblée communautaire fransaskoise (ACF)",
            },
            {
              value: "AFO",
              frText: "Assemblée de la francophonie de l'Ontario (AFO)",
              text: "Assemblée de la francophonie de l'Ontario (AFO)",
            },
            {
              value: "FCFA",
              frText: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
              text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
            },
            {
              value: "SFM",
              frText: "Société franco-manitobaine (SFM)",
              text: "Société franco-manitobaine (SFM)",
            },
            {
              value: "SNA",
              frText: "Société nationale de l'Acadie (SNA)",
              text: "Société nationale de l'Acadie (SNA)",
            },
          ]
        }
      },
      additionalLinks: {
        label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        frLabel: "",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  iPStrategy: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition: {
      seekingEducation: {
        frLabel: "Cherchez-vous à obtenir une formation générale en matière de propriété intellectuelle (par exemple, par où commencer? Qu’est-ce qu’un brevet?)",
        label: "Are you seeking general IP education (e.g. Where do I start? What is a patent?)",
        inputType: "boolean",
      },
      seekingSupport: {
        frLabel: "Cherchez-vous un soutien pour créer, améliorer ou réviser votre stratégie de propriété intellectuelle (par exemple, comment déterminer ce que je dois protéger? Qui peut m’aider à effectuer une recherche de brevetabilité ou de nouveauté?",
        label: "Are you seeking support to create, enhance or review your IP strategy (e.g. How do I determine what I’m protecting? Who can help with a patentability/novelty search?)",
        inputType: "boolean",
      },
      filePatentReady: {
        frLabel: "Vous serez prêt à déposer un brevet dans les 3 à 6 prochains mois?",
        label: "Are you ready to file patents in the next 3-6 months?",
        inputType: "boolean",
      },
      filePatentReadyYes: {
        conditionalRenderDefinition: {
          fieldKey: "filePatentReady",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer lesquels des éléments ci-dessous s'appliquent dans votre cas",
        label: "Please indicate which of the following apply to you",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "I filed a provisional patent application in the last 12 months",
              value: "filedPatentApplication",
              frText: "J'ai déposé une demande de brevet provisoire au cours des 12 derniers mois"
            },
            {
              text: "In the past 12 months I filed a regular patent application that will serve as the priority filing for subsequent filings",
              value: "filedPriorityPatentApplication",
              frText: "Au cours des 12 derniers mois, j'ai déposé une demande de brevet régulière qui servira de dépôt prioritaire pour les dépôts ultérieurs."
            },
            {
              text: "I filed a PCT application in the past 30 months",
              value: "filedPCTApplication",
              frText: "J'ai déposé une demande PCT au cours des 30 derniers mois"
            },
            {
              text: "I am seeking support to help me convert a provisional application or a PCT, or to file a subsequent application using my priority application",
              value: "supportApplication",
              frText: "Je recherche de l'aide pour m'aider à convertir une demande provisoire ou un PCT, ou à déposer une demande ultérieure en utilisant ma demande prioritaire."
            },
            {
              text: "My IP service provider completed a patentability or novelty search on the innovation that is captured in my provisional, PCT or priority national filing",
              value: "serviceProviderSearchInFiling",
              frText: "Mon fournisseur de services de PI a effectué une recherche de brevetabilité ou de nouveauté sur l'innovation qui est capturée dans mon dépôt national provisoire, PCT ou prioritaire."
            },
            {
              text: "I have one or more innovation that aren't yet the subject of a patent, and that I am ready to patent in the next 3-6 months",
              value: "readyPatentApplication",
              frText: "J'ai une ou plusieurs innovations qui ne font pas encore l'objet d'un brevet, et que je suis prêt à breveter d'ici 3 à 6 mois"
            },
            {
              text: "My IP service provider completed a patentability or novelty search on (all of) the innovation(s) that aren’t yet the subject of a patent",
              value: "serviceProviderSearch",
              frText: "on fournisseur de services de PI a effectué une recherche de brevetabilité ou de nouveauté sur (l'ensemble) de la ou des innovations qui ne font pas encore l'objet d'un brevet"
            }
          ]
        }
      },
      fileTrademarkReady: {
        frLabel: "Vous serez prêt à soumettre une marque de commerce dans les 3 à 6 prochains mois?",
        label: "Are you ready to register a trademark in the next 3-6 months?",
        inputType: "boolean",
      },      
      clearanceSearch: {
        conditionalRenderDefinition: {
          fieldKey: "fileTrademarkReady",
          renderWhenTrue: true,
        },
        frLabel: "Votre fournisseur de services IP a-t-il effectué une recherche d'autorisation?",
        label: "Has your IP service provider completed a clearance search?",
        inputType: "boolean",
      },
      isWorkingWithLawFirm: {
        frLabel: "Votre entreprise est présentement en contact avec une firme d’avocats et de PI",
        label: "Is your company currently working with an IP / Law firm?",
        inputType: "boolean",
      },
      workingLawFirms: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithLawFirm",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer le nom des firmes",
        label: "Please list the name of firms",
        inputType: "customList",
      },
      isWorkingWithOrganization: {
        frLabel: "Votre entreprise est présentement en contact avec Invest Ottawa ou North Forge qui collaborent avec Communitech",
        label: "Is your company currently working with Communitech, Invest Ottawa, North Forge, Spark Center or Launch Lab?",
        inputType: "boolean",
      },
      workingOrganizations: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithOrganization",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "dropdownSelect",
        validator: {
          required: {
            value: true,
            message: "Please select an option"
          }
        },
        additionalData: {
          selectionData: [
            {
              value: "communitech", 
              text: "Communitech"
            },
            {
              value: "northForge", 
              text: "North Forge"
            },
            {
              value: "investOttawa", 
              text: "Invest Ottawa"
            },
            {
              value: "spark", 
              text: "Spark Center"
            },
            {
              value: "launch", 
              text: "Launch Labs"
            }
          ]
        }
      },
      isWorkingWithBai: {
        frLabel: "Votre entreprise travaille-t-elle actuellement avec un autre IAA (Incubateur Accélérateur d’Affaires)?",
        label: "Is your company currently working with a different BAI?",
        inputType: "boolean",
      },
      otherBai: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithBai",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "text",
        validator: basic_text_validator
      }
    }
  }
}

export const founderProfileEvaluationFormGroupDefinition: FormGroupDefinition = {
  /* Contact Information */
  personalInfo: {
    sectionLabel: "Personal Information",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        frLabel: "Prénom",
        label: "First Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        frLabel: "Nom de famille",
        label: "Last Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] handle conditional checkboxes and other field feature
      pronouns: {
        frLabel: "Pronom",
        label: "Your Pronouns",
        inputType: "customMultiSelectNarrow",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "she/her",
              value: "she"
            },
            {
              text: "he/him",
              value: "he"
            },
            {
              text: "they/them",
              value: "they"
            },
            {
              text: "prefer not to say",
              value: "notToSay"
            },
          ]
        }
      },
      title: {
        frLabel: "",
        label: "Job Title",
        inputType: "text",
        validator: basic_text_validator,
      },
      phoneNumber: {
        frLabel: "No de téléphone",
        label: "Phone Number",
        inputType: "text",
        validator: phone_validator,
      },
      isPointOfContact: {
        label: "I'm the point of contact",
        frLabel: "",
        inputType: "checkbox",
        tooltip: {
          content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the prefered point of contact below",
          position: "bottom-right"
        },
        frTooltip: {
          content: "",
          position: "bottom-right"          
        }
      }
    }
  },
  pointOfContact: {
    conditionalRenderDefinition: {
      fieldKey: "isPointOfContact",
      renderWhenTrue: false, 
    },
    sectionLabel: "Point of Contact",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        frLabel: "Prénom",
        label: "First Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        frLabel: "Nom de famille",
        label: "Last Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] pronouns is waiting on conditional checkboxes feature
      title: {
        frLabel: "Titre de poste",
        label: "Job Title",
        inputType: "text",
        validator: basic_text_validator,
      },
      email: {
        frLabel: "",
        label: "Email",
        inputType: "text",
        validator: email_validator,
      },
      phoneNumber: {
        frLabel: "No de téléphone",
        label: "Phone Number",
        inputType: "text",
        validator: phone_validator,
      },
      // [TODO] roleTags is waiting on conditional checkboxes feature
    }
  },
  /* Company Information */
  basicInfo: {
    sectionLabel: "Company Information",
    frSectionLabel: "Renseignements sur l'entreprise",
    formDefinition: {
      operatingName: {
        frLabel: "",
        label: "Operating Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // isLegalNameSameAsOperatingName: {
      //   frLabel: "Le nom légal de notre entreprise est le même que son nom commercial. Le nom légal complet doit être fourni.",
      //   label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
      //   inputType: "checkbox",
      // },
      legalName: {
        // conditionalRenderDefinition: {
        //   fieldKey: "isLegalNameSameAsOperatingName",
        //   renderWhenTrue: false, 
        // },
        label: "Legal Name",
        frLabel: "Nom légal",
        inputType: "text",
        validator: basic_text_validator,
      },
      description: {
        label: "Company Description",
        frLabel: "Description de l’entreprise",
        inputType: "textarea",
        validator: basic_text_validator,
      },
      industry: {
        label: "Industry",
        frLabel: "Industrie",
        inputType: "dropdownSelect",
        tooltip: {
          content: "Click on the field and select an option from the list.",
          position: "bottom-right"
        },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "Agriculture",
              text: "Agriculture",
              frText: "Agriculture",
            },
            {
              value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
            },
            {
              value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
            },
            {
              value: "Consumer Retail",
              text: "Consumer Retail",
              frText: "Consommation de détail",
            },
            {
              value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
            },
            {
              value: "Education",
              text: "Education",
              frText: "Éducation",      
            },
            {
              value: "Financial Services",
              text: "Financial Services",
              frText: "Services financiers",
            },
            {
              value: "Food & Beverage",
              text: "Food & Beverage",
              frText: "Restauration/production d’aliments et boissons",
            },
            {
              value: "Forestry",
              text: "Forestry",
              frText: "Foresterie",
            },
            {
              value: "Life Sciences & Health",
              text: "Life Sciences & Health",
              frText: "Sciences de la vie et de la santé",
            },
            {
              value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              frText: "Exploitation minière, pétrole et gaz : Comprend les activités d’extraction qui ne relèveraient pas autrement des technologies propres",
            },
            {
              value: "Tourism & Culture: Includes entertainment and sports",
              text: "Tourism & Culture: Includes entertainment and sports",
              frText: "Culture et tourisme : comprend le divertissement et le sport",
            },
            {
              value: "Transportation",
              text: "Transportation",
              frText: "Transport",
            },
            {
              value: "Other",
              text: "Other",
              frText: "Autre",
            }
          ]
        }
      },
      websiteURL: {
        label: "Website URL",
        frLabel: "URL du site Web",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  registration: {
    sectionLabel: "Registration Information",
    frSectionLabel: "Enregistrement de l’entreprise",
    formDefinition: {
      number: {
        label:  `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website</a>)",`,
        frLabel:  `Le numéro d’entreprise (NE) est un numéro à 9 chiffres attribué à toutes les entreprises constituées en société par l’Agence du revenu du Canada. Détenir un numéro d’enregistrement est une condition pour participer à ÉleverlaPI. Pour plus d’informations sur les numéros d’entreprise, (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'> cliquez ici.</a>)",`,
        inputType: "text",
        validator: {
          ...basic_number_validator,
          minLength: {
            value: 9,
            message: "Registration number must be nine digits",
          },
          maxLength: {
            value: 9,
            message: "Registration number must be nine digits",
          }
        }
      },
      date: {
        label: "Registration Date",
        frLabel: "Date de l’enregistrement",
        inputType: "date",
        validator: date_validator,
      },
      province: {
        label: "Registered Provinces and Territories",
        inputType: "multiSelectNarrow",
        frLabel: "Provinces et territoires enregistrés",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "AB",
              text: "Alberta",
            },
            {
              value: "BC",
              text: "British Columbia",
            },
            {
              value: "MB",
              text: "Manitoba",
            },
            {
              value: "NB",
              text: "New Brunswick",
            },
            {
              value: "NL",
              text: "Newfoundland and Labrador",
            },
            {
              value: "NT",
              text: "Northwest Territories",
            },
            {
              value: "NS",
              text: "Nova Scotia",
            },
            {
              value: "NU",
              text: "Nunavut",
            },
            {
              value: "ON",
              text: "Ontario",
            },
            {
              value: "PE",
              text: "Prince Edward Island",
            },
            {
              value: "QC",
              text: "Quebec",
            },
            {
              value: "SK",
              text: "Saskatchewan",
            },
            {
              value: "YT",
              text: "Yukon",
            },
          ]
        }
      }
    }
  },
  mailingAddress: {
    sectionLabel: "Mailing Address",
    frSectionLabel: "",
    formDefinition: addressFormDefinition,
  },
  programingEvaluation: {
    sectionLabel: "Service Evaluation",
    frSectionLabel: "Évaluation du service",
    formDefinition: {
      hasSocialOrEnvironmentalGoals: {
        label: "Does your company have social or environmental goals as part of its founding mission?",
        frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
        inputType: "boolean",
      },
      numFullTimeEmployees: {
        label: "Number of Full Time Employees",
        frLabel: "",
        inputType: "number",
        validator: basic_number_validator,
      },
      isProductForSaleOnMarket: {
        frLabel: "",
        label: "Is the product you are looking to protect currently for sale on the market?",
        inputType: "boolean"
      },
      productForSaleInfo: {
        conditionalRenderDefinition: {
          fieldKey: "isProductForSaleOnMarket",
          renderWhenTrue: true, 
        },
        label: "Please select all that apply",
        frLabel: "",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "singleProduct",
              text: "The product I want to protect is currently for sale",
            },
            {
              value: "multipleProducts",
              text: "There are multiple products I want to protect that are currently for sale",
            },
          ]
        }
      },
      revenueOverTwelveMonths: {
        label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
        frLabel: "",
        inputType: "number",
        validator: basic_number_validator,
      },
      coPayEligible: {
          label: "Eligible for 10% Co-investment",
          frLabel: "",
          inputType: "boolean",
        },
      isFromOLMC: {
        label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
        frLabel: "",
        inputType: "boolean"
      },
      officialLanguageMinorityCommunity: {
        conditionalRenderDefinition: {
          fieldKey: "isFromOLMC",
          renderWhenTrue: true,
        },
        label: "Which OLMC are you a part of?",
        frLabel: "",
        inputType: "customDropdownSelect",
        // tooltip: {
        //   content:  "Click on the field and select an option from the list or Enter your own directly.",
        //   position: "top-left"
        // },
        // frTooltip: {
        //   content:  "",
        //   position: "top-left"
        // },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "ACF",
              text: "Assemblée communautaire fransaskoise (ACF)",
            },
            {
              value: "AFO",
              text: "Assemblée de la francophonie de l'Ontario (AFO)",
            },

            {
              value: "FCFA",
              text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
            },
            {
              value: "SFM",
              text: "Société franco-manitobaine (SFM)",
            },
            {
              value: "SNA",
              text: "Société nationale de l'Acadie (SNA)",
            },
          ]
        }
      },
      additionalLinks: {
        label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        frLabel: "",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  iPStrategy: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition: {
      seekingEducation: {
        frLabel: "",
        label: "Are you seeking general IP education (e.g. Where do I start? What is a patent?)",
        inputType: "boolean",
      },
      seekingSupport: {
        frLabel: "",
        label: "Are you seeking support to create, enhance or review your IP strategy (e.g. How do I determine what I’m protecting? Who can help with a patentability/novelty search?)",
        inputType: "boolean",
      },
      filePatentReady: {
        frLabel: "",
        label: "Are you ready to file patents in the next 3-6 months?",
        inputType: "boolean",
      },
      filePatentReadyYes: {
        conditionalRenderDefinition: {
          fieldKey: "filePatentReady",
          renderWhenTrue: true,
        },
        label: "Please indicate which of the following apply to you",
        frLabel: "",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "I filed a provisional patent application in the last 12 months",
              value: "filedPatentApplication"
            },
            {
              text: "In the past 12 months I filed a regular patent application that will serve as the priority filing for subsequent filings",
              value: "filedPriorityPatentApplication"
            },
            {
              text: "I filed a PCT application in the past 30 months",
              value: "filedPCTApplication"
            },
            {
              text: "I am seeking support to help me convert a provisional application or a PCT, or to file a subsequent application using my priority application",
              value: "supportApplication"
            },
            {
              text: "My IP service provider completed a patentability or novelty search on the innovation that is captured in my provisional, PCT or priority national filing",
              value: "serviceProviderSearchInFiling"
            },
            {
              text: "I have one or more innovation that aren't yet the subject of a patent, and that I am ready to patent in the next 3-6 months",
              value: "readyPatentApplication"
            },
            {
              text: "My IP service provider completed a patentability or novelty search on (all of) the innovation(s) that aren’t yet the subject of a patent",
              value: "serviceProviderSearch"
            }
          ]
        }
      },
      fileTrademarkReady: {
        label: "Are you ready to register a trademark in the next 3-6 months?",
        frLabel: "",
        inputType: "boolean",
      },      
      clearanceSearch: {
        conditionalRenderDefinition: {
          fieldKey: "fileTrademarkReady",
          renderWhenTrue: true,
        },
        frLabel: "",
        label: "Has your IP service provider completed a clearance search?",
        inputType: "boolean",
      },
      isWorkingWithLawFirm: {
        frLabel: "",
        label: "Is your company currently working with an IP / Law firm?",
        inputType: "boolean",
      },
      workingLawFirms: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithLawFirm",
          renderWhenTrue: true,
        },
        label: "Please list the name of firms",
        frLabel: "",
        inputType: "customList",
      },
      isWorkingWithOrganization: {
        label: "Is your company currently working with Communitech, Invest Ottawa, or North Forge?",
        frLabel: "",
        inputType: "boolean",
      },
      workingOrganizations: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithOrganization",
          renderWhenTrue: true,
        },
        label: "Please specify which organization",
        inputType: "dropdownSelect",
        validator: {
          required: {
            value: true,
            message: "Please select an option"
          }
        },
        additionalData: {
          selectionData: [
            {
              value: "communitech", 
              text: "Communitech"
            },
            {
              value: "northForge", 
              text: "North Forge"
            },
            {
              value: "investOttawa", 
              text: "Invest Ottawa"
            }
          ]
        }
      },
      isWorkingWithBai: {
        label: "Is your company currently working with a different BAI?",
        frLabel: "",
        inputType: "boolean",
      },
      otherBai: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithBai",
          renderWhenTrue: true,
        },
        label: "Please specify which organization",
        frLabel: "",
        inputType: "text",
        validator: basic_text_validator
      }
    }
  }
}

export const updatedFounderProfileReadinessEvaluationFormGroupDefinition: FormGroupDefinition = {
  /* Contact Information */
  personalInfo: {
    sectionLabel: "Personal Information",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        frLabel: "Prénom",
        label: "First Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        frLabel: "Nom de famille",
        label: "Last Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] handle conditional checkboxes and other field feature
      pronouns: {
        frLabel: "Prénom",
        label: "Your Pronouns",
        inputType: "customMultiSelectNarrow",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "she/her",
              value: "she"
            },
            {
              text: "he/him",
              value: "he"
            },
            {
              text: "they/them",
              value: "they"
            },
            {
              text: "prefer not to say",
              value: "notToSay"
            },
          ]
        }
      },
      title: {
        frLabel: "",
        label: "Job Title",
        inputType: "text",
        validator: basic_text_validator,
      },
      phoneNumber: {
        frLabel: "No de téléphone",
        label: "Phone Number",
        inputType: "text",
        validator: phone_validator,
      },
      isPointOfContact: {
        label: "I'm the point of contact",
        frLabel: "",
        inputType: "checkbox",
        tooltip: {
          content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the prefered point of contact below",
          position: "bottom-right"
        },
        frTooltip: {
          content: "",
          position: "bottom-right"          
        }
      }
    }
  },
  pointOfContact: {
    conditionalRenderDefinition: {
      fieldKey: "isPointOfContact",
      renderWhenTrue: false, 
    },
    sectionLabel: "Point of Contact",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        frLabel: "Prénom",
        label: "First Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        frLabel: "Nom de famille",
        label: "Last Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] pronouns is waiting on conditional checkboxes feature
      title: {
        frLabel: "",
        label: "Job Title",
        inputType: "text",
        validator: basic_text_validator,
      },
      email: {
        frLabel: "",
        label: "Email",
        inputType: "text",
        validator: email_validator,
      },
      phoneNumber: {
        frLabel: "No de téléphone",
        label: "Phone Number",
        inputType: "text",
        validator: phone_validator,
      },
      // [TODO] roleTags is waiting on conditional checkboxes feature
    }
  },
  /* Company Information */
  basicInfo: {
    sectionLabel: "Company Information",
    frSectionLabel: "Renseignements sur l'entreprise",
    formDefinition: {
      operatingName: {
        frLabel: "",
        label: "Operating Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // isLegalNameSameAsOperatingName: {
      //   frLabel: "Le nom légal de notre entreprise est le même que son nom commercial. Le nom légal complet doit être fourni.",
      //   label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
      //   inputType: "checkbox",
      // },
      legalName: {
        // conditionalRenderDefinition: {
        //   fieldKey: "isLegalNameSameAsOperatingName",
        //   renderWhenTrue: false, 
        // },
        label: "Legal Name",
        frLabel: "Nom légal",
        inputType: "text",
        validator: basic_text_validator,
      },
      description: {
        label: "",
        inputType: "textarea",
        validator: basic_text_validator,
      },
      industry: {
        label: "Industry",
        frLabel: "Industrie",
        inputType: "dropdownSelect",
        tooltip: {
          content: "Click on the field and select an option from the list.",
          position: "bottom-right"
        },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "Agriculture",
              text: "Agriculture",
              frText: "Agriculture",
            },
            {
              value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
            },
            {
              value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
            },
            {
              value: "Consumer Retail",
              text: "Consumer Retail",
              frText: "Consommation de détail",
            },
            {
              value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
            },
            {
              value: "Education",
              text: "Education",
              frText: "Éducation",    
            },
            {
              value: "Financial Services",
              text: "Financial Services",
              frText: "Services financiers",
            },
            {
              value: "Food & Beverage",
              text: "Food & Beverage",
              frText: "Restauration/production d’aliments et boissons",
            },
            {
              value: "Forestry",
              text: "Forestry",
              frText: "Foresterie",
            },
            {
              value: "Life Sciences & Health",
              text: "Life Sciences & Health",
              frText: "Sciences de la vie et de la santé",
            },
            {
              value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              frText: "Exploitation minière, pétrole et gaz : Comprend les activités d’extraction qui ne relèveraient pas autrement des technologies propres",
            },
            {
              value: "Tourism & Culture: Includes entertainment and sports",
              text: "Tourism & Culture: Includes entertainment and sports",
              frText: "Culture et tourisme : comprend le divertissement et le sport",
            },
            {
              value: "Transportation",
              text: "Transportation",
              frText: "Transport",
            },
            {
              value: "Other",
              text: "Other",
              frText: "Autre",
            }
          ]
        }
      },
      websiteURL: {
        label: "Website URL",
        frLabel: "URL du site Web",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  registration: {
    sectionLabel: "Registration Information",
    frSectionLabel: "Enregistrement de l’entreprise",
    formDefinition: {
      number: {
        label:  `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website</a>)",`,
        frLabel:  `Le numéro d’entreprise (NE) est un numéro à 9 chiffres attribué à toutes les entreprises constituées en société par l’Agence du revenu du Canada. Détenir un numéro d’enregistrement est une condition pour participer à ÉleverlaPI. Pour plus d’informations sur les numéros d’entreprise, (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'> cliquez ici.</a>)",`,
        inputType: "text",
        validator: {
          ...basic_number_validator,
          minLength: {
            value: 9,
            message: "Registration number must be nine digits",
          },
          maxLength: {
            value: 9,
            message: "Registration number must be nine digits",
          }
        }
      },
      date: {
        label: "Registration Date",
        frLabel: "Date de l’enregistrement",
        inputType: "date",
        validator: date_validator,
      },
      province: {
        label: "Registered Provinces and Territories",
        inputType: "multiSelectNarrow",
        frLabel: "Provinces et territoires enregistrés",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "AB",
              text: "Alberta",
            },
            {
              value: "BC",
              text: "British Columbia",
            },
            {
              value: "MB",
              text: "Manitoba",
            },
            {
              value: "NB",
              text: "New Brunswick",
            },
            {
              value: "NL",
              text: "Newfoundland and Labrador",
            },
            {
              value: "NT",
              text: "Northwest Territories",
            },
            {
              value: "NS",
              text: "Nova Scotia",
            },
            {
              value: "NU",
              text: "Nunavut",
            },
            {
              value: "ON",
              text: "Ontario",
            },
            {
              value: "PE",
              text: "Prince Edward Island",
            },
            {
              value: "QC",
              text: "Quebec",
            },
            {
              value: "SK",
              text: "Saskatchewan",
            },
            {
              value: "YT",
              text: "Yukon",
            },
          ]
        }
      }
    }
  },
  mailingAddress: {
    sectionLabel: "Mailing Address",
    frSectionLabel: "",
    formDefinition: addressFormDefinition,
  },
  programingEvaluation: {
    sectionLabel: "Service Evaluation",
    frSectionLabel: "Évaluation du service",
    formDefinition: {
      hasSocialOrEnvironmentalGoals: {
        label: "Does your company have social or environmental goals as part of its founding mission?",
        frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
        inputType: "boolean",
      },
      numFullTimeEmployees: {
        label: "Number of Full Time Employees",
        frLabel: "",
        inputType: "number",
        validator: basic_number_validator,
      },
      isProductForSaleOnMarket: {
        frLabel: "",
        label: "Is the product you are looking to protect currently for sale on the market?",
        inputType: "boolean"
      },
      productForSaleInfo: {
        conditionalRenderDefinition: {
          fieldKey: "isProductForSaleOnMarket",
          renderWhenTrue: true, 
        },
        label: "Please select all that apply",
        frLabel: "",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "singleProduct",
              text: "The product I want to protect is currently for sale",
            },
            {
              value: "multipleProducts",
              text: "There are multiple products I want to protect that are currently for sale",
            },
          ]
        }
      },
      revenueOverTwelveMonths: {
        label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
        frLabel: "",
        inputType: "number",
        validator: basic_number_validator,
      },
      coPayEligible: {
          label: "Eligible for 10% Co-investment",
          frLabel: "",
          inputType: "boolean",
        },
      isFromOLMC: {
        label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
        frLabel: "",
        inputType: "boolean"
      },
      officialLanguageMinorityCommunity: {
        conditionalRenderDefinition: {
          fieldKey: "isFromOLMC",
          renderWhenTrue: true,
        },
        label: "Which OLMC are you a part of?",
        frLabel: "",
        inputType: "customDropdownSelect",
        // tooltip: {
        //   content:  "Click on the field and select an option from the list or Enter your own directly.",
        //   position: "top-left"
        // },
        // frTooltip: {
        //   content:  "",
        //   position: "top-left"
        // },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "ACF",
              text: "Assemblée communautaire fransaskoise (ACF)",
            },
            {
              value: "AFO",
              text: "Assemblée de la francophonie de l'Ontario (AFO)",
            },

            {
              value: "FCFA",
              text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
            },
            {
              value: "SFM",
              text: "Société franco-manitobaine (SFM)",
            },
            {
              value: "SNA",
              text: "Société nationale de l'Acadie (SNA)",
            },
          ]
        }
      },
      additionalLinks: {
        label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        frLabel: "",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  iPStrategy: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition: {
      bestMatch: {
        frLabel: "Lequel des énoncés suivants correspond le mieux à vos besoins actuels?",
        label: "Which of the following best match your needs at this time?",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "education",
              text: "I’m seeking general IP Education (e.g. Where do I start? What is a patent?, etc.)",
              frText: "Je recherche une formation générale en PI (p. ex., par où dois-je commencer? Qu'est-ce qu'un brevet?, etc.).",
            },
            {
              value: "strategy",
              text: "I’m seeking support for IP Strategy Development (e.g, What/how/when/where should I protect? I need help with patentability/novelty searches., etc.)",
              frText: "Je recherche de l'aide pour l'élaboration d'une stratégie de PI (p. ex., quoi/comment/quand/où dois-je protéger? J'ai besoin d'aide pour des recherches de brevetabilité/nouveauté, etc.).",
            },
            {
              value: "trademarks",
              text: "I think I’m ready to register trademarks in the next 3-6 months",
              frText: "Je pense que je suis prêt(e) à enregistrer des marques dans les 3 à 6 prochains mois.",
            },
            {
              value: "patents",
              text: "I think I’m ready to file patents in the next 3-6 months or convert PCT / provisional or continuations",
              frText: "Je pense que je suis prêt(e) à déposer des brevets dans les 3 à 6 prochains mois ou à convertir un PCT, à déposer une demande de brevet provisoire ou à reconduire un brevet",
            },
          ]
        }
      },
      assignmentAgreement: {
        frLabel: "",
        label: 'Have you and your cofounders signed a "Co-founders IP assignment agreement" that assigns IP to the corporation? ',
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      patentsReadyOptions: {
        frLabel: "",
        label: " If you think you are ready to file patents in the next 3-6 months, please indicate which of the following apply to the technology you intend to patent:",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "notApplicable",
              text: "Not applicable, as I am not seeking support to file patents in the next 3-6 months",
              frText: "",
            },
            {
              value: "search",
              text: "My IP service provider has conducted a favorable patentability/prior art/novelty search for the technology I plan to patent.",
              frText: "",
            },
            {
              value: "family",
              text: "I have a granted patent in the same patent family.",
              frText: "",
            },
            {
              value: "written",
              text: "I have received a favorable written opinion from my PCT application.",
              frText: "",
            },
            {
              value: "support",
              text: "I don’t have B or C or D and I am seeking support to file patent",
              frText: "",
            },
          ]
        }
      },
      trademarkClearanceSearch: {
        frLabel: "",
        label: "If you think you are ready to register trademarks in the next 3-6 months, has your IP service provider completed a clearance search?",
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      isWorkingWithLawFirm: {
        frLabel: "Votre entreprise est présentement en contact avec une firme d’avocats et de PI",
        label: "Is your company currently working with an IP / Law firm?",
        inputType: "boolean",
      },
      workingLawFirms: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithLawFirm",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer le nom des firmes",
        label: "Please list the name of firms",
        inputType: "customList",
      },
      isWorkingWithOrganization: {
        frLabel: "Votre entreprise est présentement en contact avec Invest Ottawa ou North Forge qui collaborent avec Communitech",
        label: "Is your company currently working with Communitech, Invest Ottawa, North Forge, Spark Center or Launch Lab?",
        inputType: "boolean",
      },
      workingOrganizations: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithOrganization",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "dropdownSelect",
        validator: {
          required: {
            value: true,
            message: "Please select an option"
          }
        },
        additionalData: {
          selectionData: [
            {
              value: "communitech", 
              text: "Communitech"
            },
            {
              value: "northForge", 
              text: "North Forge"
            },
            {
              value: "investOttawa", 
              text: "Invest Ottawa"
            },
            {
              value: "spark", 
              text: "Spark Center"
            },
            {
              value: "launch", 
              text: "Launch Labs"
            }
          ]
        }
      },
      isWorkingWithBai: {
        frLabel: "Votre entreprise travaille-t-elle actuellement avec un autre IAA (Incubateur Accélérateur d’Affaires)?",
        label: "Is your company currently working with a different BAI?",
        inputType: "boolean",
      },
      otherBai: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithBai",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "text",
        validator: basic_text_validator
      }
    }
  },
  ipReadiness: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition:{
        responsible: {
          label: "Who is responsible for IP within your company?",
          frLabel: "",
          inputType: "text",
          validator: basic_text_validator
        },
        education: {
          label: "Does that person have any formal IP education?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        educationType: {
          conditionalRenderDefinition: {
            fieldKey: "education",
            renderWhenTrue: true, 
          },
          label: "What type of formal IP education did that person receive?",
          frLabel: "",
          inputType: "dropdownSelect",
          additionalData: {
            selectionData: [
              {
                value: "classes",
                text: "IP classes",
              },
              {
                value: "webinars",
                text: "Webinars provided by an accelerator/incubator focussed on IP",
              },
              {
                value: "ipon",
                text: "IP Assist or IPON education",
              },
            ]
          }
        },
        jurisdiction: {
          label: "Have you identified which jurisdictions are of particular interest for you and where you would seek patents if provided with sufficient funding?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        specificJurisdiction: {
          conditionalRenderDefinition: {
            fieldKey: "jurisdiction",
            renderWhenTrue: true, 
          },
          label: "Please Specify",
          frLabel: "",
          inputType: "text",
          validator: basic_text_validator,
        },
        provider: {
          label: "Do you have an IP service provider/patent agent?",
          frLabel: "",
          inputType: "boolean",
          validator: basic_text_validator,
        },
        providerLastWorked: {
          conditionalRenderDefinition: {
            fieldKey: "provider",
            renderWhenTrue: true, 
          },
          label: "When did you last work with them?",
          frLabel: "",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "under", 
                text: "Within past 12 months"
              },
              {
                value: "over", 
                text: "Not within past 12 months"
              }
            ]
          }
        },  
        ipSection: {
          label: "Do you have a documented IP section in your data room?",
          frLabel: "",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "yes", 
                text: "Yes"
              },
              {
                value: "no", 
                text: "No"
              },
              {
                value: "n/a", 
                text: "Not applicable - We are self-funded"
              }
            ]
          }
        },
        software: {
          label: "Do you have an open source log where you keep track of all open source software, algorithms and/or datasets used within your product/service, along with the licensing terms and notes on compliance?",
          frLabel: "",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "yes", 
                text: "Yes"
              },
              {
                value: "no", 
                text: "No"
              },
              {
                value: "n/a", 
                text: "Not applicable - Our product has no software"
              }
            ]
          }
        },
        tradeSecrets: {
          label: "Do you have any trade secret protection measures in place?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator,
        },
        tradeSecretsList: {
          conditionalRenderDefinition: {
            fieldKey: "tradeSecrets",
            renderWhenTrue: true, 
          },
          label: "Please list your protection measures",
          frLabel: "",
          inputType: "textarea",
        },  
        ipBudget: {
          label: "Do you have a documented IP budget?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator,
        },
        patentReady: {
          label: "Do you have any innovations ready to file patents for within the next 3-6 months? ",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator,
        },
        patentsNumber: {
          conditionalRenderDefinition: {
            fieldKey: "patentReady",
            renderWhenTrue: true, 
          },
          label: "How many innovations are ready to file within the next 3-6 months?",
          frLabel: "",
          inputType: "number",
          validator: basic_number_validator,
        },  
        searchesList: {
          conditionalRenderDefinition: {
            fieldKey: "patentReady",
            renderWhenTrue: true, 
          },
          label: "Regarding innovations mentioned in the previous question, has your IP service provider completed patentability or novelty searches for at least one of them? Please specify the searches conducted for each innovation",
          frLabel: "",
          inputType: "textarea",
        }, 
      }
    }
}

export const updatedFounderProfileEvaluationFormGroupDefinition: FormGroupDefinition = {
  /* Contact Information */
  personalInfo: {
    sectionLabel: "Personal Information",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        frLabel: "Prénom",
        label: "First Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        frLabel: "Nom de famille",
        label: "Last Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] handle conditional checkboxes and other field feature
      pronouns: {
        frLabel: "Prénom",
        label: "Your Pronouns",
        inputType: "customMultiSelectNarrow",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "she/her",
              value: "she"
            },
            {
              text: "he/him",
              value: "he"
            },
            {
              text: "they/them",
              value: "they"
            },
            {
              text: "prefer not to say",
              value: "notToSay"
            },
          ]
        }
      },
      title: {
        frLabel: "",
        label: "Job Title",
        inputType: "text",
        validator: basic_text_validator,
      },
      phoneNumber: {
        frLabel: "No de téléphone",
        label: "Phone Number",
        inputType: "text",
        validator: phone_validator,
      },
      isPointOfContact: {
        label: "I'm the point of contact",
        frLabel: "",
        inputType: "checkbox",
        tooltip: {
          content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the prefered point of contact below",
          position: "bottom-right"
        },
        frTooltip: {
          content: "",
          position: "bottom-right"          
        }
      }
    }
  },
  pointOfContact: {
    conditionalRenderDefinition: {
      fieldKey: "isPointOfContact",
      renderWhenTrue: false, 
    },
    sectionLabel: "Point of Contact",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        frLabel: "Prénom",
        label: "First Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        frLabel: "Nom de famille",
        label: "Last Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] pronouns is waiting on conditional checkboxes feature
      title: {
        frLabel: "",
        label: "Job Title",
        inputType: "text",
        validator: basic_text_validator,
      },
      email: {
        frLabel: "",
        label: "Email",
        inputType: "text",
        validator: email_validator,
      },
      phoneNumber: {
        frLabel: "No de téléphone",
        label: "Phone Number",
        inputType: "text",
        validator: phone_validator,
      },
      // [TODO] roleTags is waiting on conditional checkboxes feature
    }
  },
  /* Company Information */
  basicInfo: {
    sectionLabel: "Company Information",
    frSectionLabel: "Renseignements sur l'entreprise",
    formDefinition: {
      operatingName: {
        frLabel: "",
        label: "Operating Name",
        inputType: "text",
        validator: basic_text_validator,
      },
      // isLegalNameSameAsOperatingName: {
      //   frLabel: "Le nom légal de notre entreprise est le même que son nom commercial. Le nom légal complet doit être fourni.",
      //   label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
      //   inputType: "checkbox",
      // },
      legalName: {
        // conditionalRenderDefinition: {
        //   fieldKey: "isLegalNameSameAsOperatingName",
        //   renderWhenTrue: false, 
        // },
        label: "Legal Name",
        frLabel: "Nom légal",
        inputType: "text",
        validator: basic_text_validator,
      },
      description: {
        label: "Company Description",
        frLabel: "Description de l’entreprise",
        inputType: "textarea",
        validator: basic_text_validator,
      },
      industry: {
        label: "Industry",
        frLabel: "Industrie",
        inputType: "dropdownSelect",
        tooltip: {
          content: "Click on the field and select an option from the list.",
          position: "bottom-right"
        },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "Agriculture",
              text: "Agriculture",
              frText: "Agriculture",
            },
            {
              value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
            },
            {
              value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
            },
            {
              value: "Consumer Retail",
              text: "Consumer Retail",
              frText: "Consommation de détail",
            },
            {
              value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
            },
            {
              value: "Education",
              text: "Education",
            },
            {
              value: "Financial Services",
              text: "Financial Services",
              frText: "Services financiers",
            },
            {
              value: "Food & Beverage",
              text: "Food & Beverage",
              frText: "Restauration/production d’aliments et boissons",
            },
            {
              value: "Forestry",
              text: "Forestry",
              frText: "Foresterie",
            },
            {
              value: "Life Sciences & Health",
              text: "Life Sciences & Health",
              frText: "Sciences de la vie et de la santé",
            },
            {
              value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              frText: "Exploitation minière, pétrole et gaz : Comprend les activités d’extraction qui ne relèveraient pas autrement des technologies propres",
            },
            {
              value: "Tourism & Culture: Includes entertainment and sports",
              text: "Tourism & Culture: Includes entertainment and sports",
              frText: "Culture et tourisme : comprend le divertissement et le sport",
            },
            {
              value: "Transportation",
              text: "Transportation",
              frText: "Transport",
            },
            {
              value: "Other",
              text: "Other",
              frText: "Autre",
            }
          ]
        }
      },
      websiteURL: {
        label: "Website URL",
        frLabel: "URL du site Web",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  registration: {
    sectionLabel: "Registration Information",
    frSectionLabel: "Enregistrement de l’entreprise",
    formDefinition: {
      number: {
        label:  `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website</a>)",`,
        frLabel:  `Le numéro d’entreprise (NE) est un numéro à 9 chiffres attribué à toutes les entreprises constituées en société par l’Agence du revenu du Canada. Détenir un numéro d’enregistrement est une condition pour participer à ÉleverlaPI. Pour plus d’informations sur les numéros d’entreprise, (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'> cliquez ici.</a>)",`,
        inputType: "text",
        validator: {
          ...basic_number_validator,
          minLength: {
            value: 9,
            message: "Registration number must be nine digits",
          },
          maxLength: {
            value: 9,
            message: "Registration number must be nine digits",
          }
        }
      },
      date: {
        label: "Registration Date",
        frLabel: "Date de l’enregistrement",
        inputType: "date",
        validator: date_validator,
      },
      province: {
        label: "Registered Provinces and Territories",
        inputType: "multiSelectNarrow",
        frLabel: "Provinces et territoires enregistrés",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "AB",
              text: "Alberta",
            },
            {
              value: "BC",
              text: "British Columbia",
            },
            {
              value: "MB",
              text: "Manitoba",
            },
            {
              value: "NB",
              text: "New Brunswick",
            },
            {
              value: "NL",
              text: "Newfoundland and Labrador",
            },
            {
              value: "NT",
              text: "Northwest Territories",
            },
            {
              value: "NS",
              text: "Nova Scotia",
            },
            {
              value: "NU",
              text: "Nunavut",
            },
            {
              value: "ON",
              text: "Ontario",
            },
            {
              value: "PE",
              text: "Prince Edward Island",
            },
            {
              value: "QC",
              text: "Quebec",
            },
            {
              value: "SK",
              text: "Saskatchewan",
            },
            {
              value: "YT",
              text: "Yukon",
            },
          ]
        }
      }
    }
  },
  mailingAddress: {
    sectionLabel: "Mailing Address",
    frSectionLabel: "",
    formDefinition: addressFormDefinition,
  },
  programingEvaluation: {
    sectionLabel: "Service Evaluation",
    frSectionLabel: "Évaluation du service",
    formDefinition: {
      hasSocialOrEnvironmentalGoals: {
        label: "Does your company have social or environmental goals as part of its founding mission?",
        frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
        inputType: "boolean",
      },
      numFullTimeEmployees: {
        label: "Number of Full Time Employees",
        frLabel: "",
        inputType: "number",
        validator: basic_number_validator,
      },
      isProductForSaleOnMarket: {
        frLabel: "",
        label: "Is the product you are looking to protect currently for sale on the market?",
        inputType: "boolean"
      },
      productForSaleInfo: {
        conditionalRenderDefinition: {
          fieldKey: "isProductForSaleOnMarket",
          renderWhenTrue: true, 
        },
        label: "Please select all that apply",
        frLabel: "",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "singleProduct",
              text: "The product I want to protect is currently for sale",
            },
            {
              value: "multipleProducts",
              text: "There are multiple products I want to protect that are currently for sale",
            },
          ]
        }
      },
      revenueOverTwelveMonths: {
        label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
        frLabel: "",
        inputType: "number",
        validator: basic_number_validator,
      },
      coPayEligible: {
          label: "Eligible for 10% Co-investment",
          frLabel: "",
          inputType: "boolean",
        },
      isFromOLMC: {
        label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
        frLabel: "",
        inputType: "boolean"
      },
      officialLanguageMinorityCommunity: {
        conditionalRenderDefinition: {
          fieldKey: "isFromOLMC",
          renderWhenTrue: true,
        },
        label: "Which OLMC are you a part of?",
        frLabel: "",
        inputType: "customDropdownSelect",
        // tooltip: {
        //   content:  "Click on the field and select an option from the list or Enter your own directly.",
        //   position: "top-left"
        // },
        // frTooltip: {
        //   content:  "",
        //   position: "top-left"
        // },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "ACF",
              text: "Assemblée communautaire fransaskoise (ACF)",
            },
            {
              value: "AFO",
              text: "Assemblée de la francophonie de l'Ontario (AFO)",
            },

            {
              value: "FCFA",
              text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
            },
            {
              value: "SFM",
              text: "Société franco-manitobaine (SFM)",
            },
            {
              value: "SNA",
              text: "Société nationale de l'Acadie (SNA)",
            },
          ]
        }
      },
      additionalLinks: {
        label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        frLabel: "",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  iPStrategy: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition: {
      bestMatch: {
        frLabel: "Lequel des énoncés suivants correspond le mieux à vos besoins actuels?",
        label: "Which of the following best match your needs at this time?",        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "education",
              text: "I’m seeking general IP Education (e.g. Where do I start? What is a patent?, etc.)",
              frText: "Je recherche une formation générale en PI (p. ex., par où dois-je commencer? Qu'est-ce qu'un brevet?, etc.).",
            },
            {
              value: "strategy",
              text: "I’m seeking support for IP Strategy Development (e.g, What/how/when/where should I protect? I need help with patentability/novelty searches., etc.)",
              frText: "Je recherche de l'aide pour l'élaboration d'une stratégie de PI (p. ex., quoi/comment/quand/où dois-je protéger? J'ai besoin d'aide pour des recherches de brevetabilité/nouveauté, etc.).",
            },
            {
              value: "trademarks",
              text: "I think I’m ready to register trademarks in the next 3-6 months",
              frText: "Je pense que je suis prêt(e) à enregistrer des marques dans les 3 à 6 prochains mois.",
            },
            {
              value: "patents",
              text: "I think I’m ready to file patents in the next 3-6 months or convert PCT / provisional or continuations",
              frText: "Je pense que je suis prêt(e) à déposer des brevets dans les 3 à 6 prochains mois ou à convertir un PCT, à déposer une demande de brevet provisoire ou à reconduire un brevet",
            },
          ]
        }
      },
      assignmentAgreement: {
        frLabel: "",
        label: 'Have you and your cofounders signed a "Co-founders IP assignment agreement" that assigns IP to the corporation? ',
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      patentsReadyOptions: {
        frLabel: "",
        label: " If you think you are ready to file patents in the next 3-6 months, please indicate which of the following apply to the technology you intend to patent:",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "notApplicable",
              text: "Not applicable, as I am not seeking support to file patents in the next 3-6 months",
              frText: "",
            },
            {
              value: "search",
              text: "My IP service provider has conducted a favorable patentability/prior art/novelty search for the technology I plan to patent.",
              frText: "",
            },
            {
              value: "family",
              text: "I have a granted patent in the same patent family.",
              frText: "",
            },
            {
              value: "written",
              text: "I have received a favorable written opinion from my PCT application.",
              frText: "",
            },
            {
              value: "support",
              text: "I don’t have B or C or D and I am seeking support to file patent",
              frText: "",
            },
          ]
        }
      },
      trademarkClearanceSearch: {
        frLabel: "",
        label: "If you think you are ready to register trademarks in the next 3-6 months, has your IP service provider completed a clearance search?",
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      isWorkingWithLawFirm: {
        frLabel: "Votre entreprise est présentement en contact avec une firme d’avocats et de PI",
        label: "Is your company currently working with an IP / Law firm?",
        inputType: "boolean",
      },
      workingLawFirms: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithLawFirm",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer le nom des firmes",
        label: "Please list the name of firms",
        inputType: "customList",
      },
      isWorkingWithOrganization: {
        frLabel: "Votre entreprise est présentement en contact avec Invest Ottawa ou North Forge qui collaborent avec Communitech",
        label: "Is your company currently working with Communitech, Invest Ottawa, North Forge, Spark Center or Launch Lab?",
        inputType: "boolean",
      },
      workingOrganizations: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithOrganization",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "dropdownSelect",
        validator: {
          required: {
            value: true,
            message: "Please select an option"
          }
        },
        additionalData: {
          selectionData: [
            {
              value: "communitech", 
              text: "Communitech"
            },
            {
              value: "northForge", 
              text: "North Forge"
            },
            {
              value: "investOttawa", 
              text: "Invest Ottawa"
            },
            {
              value: "spark", 
              text: "Spark Center"
            },
            {
              value: "launch", 
              text: "Launch Labs"
            }
          ]
        }
      },
      isWorkingWithBai: {
        frLabel: "Votre entreprise travaille-t-elle actuellement avec un autre IAA (Incubateur Accélérateur d’Affaires)?",
        label: "Is your company currently working with a different BAI?",
        inputType: "boolean",
      },
      otherBai: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithBai",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "text",
        validator: basic_text_validator
      }
    }
  }
}






export const adminVerificationFormDefinition: FormDefinition = {
  username: {
    label: "New Username",
    frLabel: "",
    inputType: "text",
    validator: username_validator
  },
  password: {
    label: "New Password",
    frLabel: "",
    inputType: "text",
    validator: password_validator,
  },
  confirmPassword: {
    label: "Confirm New Password",
    frLabel: "",
    inputType: "text",
    validator: password_validator,
  },
}

export const helpFormDefition: FormDefinition = {
  message: {
    label: "Message",
    frLabel: "Message",
    inputType: "textarea",
    placeholder: "Type your message here...",
    validator: basic_text_validator
  }
}

export const contactUsFormDefinition: FormDefinition = {
  email: {
    label: "Your Email",
    frLabel: "Votre Courriel",
    placeholder: "Your email address",
    inputType: "text",
    validator: email_validator
  },
  subject: {
    label: "Name",
    frLabel: "Nom",
    placeholder: "Your full name",
    inputType: "text",
    validator: basic_text_validator
  },
  message: {
    label: "Message",
    frLabel: "Message",
    placeholder: "Type your message here...",
    inputType: "textarea",
    validator: basic_text_validator
  }
}

export const tierThreePreScoreApplicationDefition: FormDefinition = {
  responsible: {
    label: "Who is responsible for IP within your company?",
    frLabel: "",
    inputType: "text",
    validator: basic_text_validator
  },
  education: {
    label: "Does that person have any formal IP education?",
    frLabel: "",
    inputType: "boolean",
    validator: boolean_selection_validator
  },
  educationType: {
    conditionalRenderDefinition: {
      fieldKey: "education",
      renderWhenTrue: true, 
    },
    label: "What type of formal IP education did that person receive?",
    frLabel: "",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData: [
        {
          value: "classes",
          text: "IP classes",
        },
        {
          value: "webinars",
          text: "Webinars provided by an accelerator/incubator focussed on IP",
        },
        {
          value: "IP Assist or IPON education",
          text: "ipon",
        },
      ]
    }
  },
  jurisdiction: {
    label: "Have you identified which jurisdictions are of particular interest for you and where you would seek patents if provided with sufficient funding?",
    frLabel: "",
    inputType: "boolean",
    validator: boolean_selection_validator
  },
  specificJurisdiction: {
    conditionalRenderDefinition: {
      fieldKey: "jurisdiction",
      renderWhenTrue: true, 
    },
    label: "Please Specify",
    frLabel: "",
    inputType: "text",
    validator: basic_text_validator,
  },
  provider: {
    label: "Do you have an IP service provider/patent agent?",
    frLabel: "",
    inputType: "text",
    validator: basic_text_validator,
  },
  providerLastWorked: {
    conditionalRenderDefinition: {
      fieldKey: "provider",
      renderWhenTrue: true, 
    },
    label: "When did you last work with them?",
    frLabel: "",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "under", 
          text: "Within past 12 months"
        },
        {
          value: "over", 
          text: "Not within past 12 months"
        }
      ]
    }
  },  
  ipSection: {
    label: "Do you have a documented IP section in your data room?",
    frLabel: "",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "yes", 
          text: "Yes"
        },
        {
          value: "no", 
          text: "No"
        },
        {
          value: "n/a", 
          text: "Not applicable - We are self-funded"
        }
      ]
    }
  },
  software: {
    label: "Do you have an open source log where you keep track of all open source software, algorithms and/or datasets used within your product/service, along with the licensing terms and notes on compliance?",
    frLabel: "",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "yes", 
          text: "Yes"
        },
        {
          value: "no", 
          text: "No"
        },
        {
          value: "n/a", 
          text: "Not applicable - Our product has no software"
        }
      ]
    }
  },
  tradeSecrets: {
    label: "Do you have any trade secret protection measures in place?",
    frLabel: "",
    inputType: "boolean",
    validator: boolean_selection_validator,
  },
  tradeSecretsList: {
    conditionalRenderDefinition: {
      fieldKey: "tradeSecrets",
      renderWhenTrue: true, 
    },
    label: "Please list your protection measures",
    frLabel: "",
    inputType: "textarea",
  },  
  ipBudget: {
    label: "Do you have a documented IP budget?",
    frLabel: "",
    inputType: "boolean",
    validator: boolean_selection_validator,
  },
  patentReady: {
    label: "Do you have any innovations ready to file patents for within the next 3-6 months? ",
    frLabel: "",
    inputType: "boolean",
    validator: boolean_selection_validator,
  },
  patentsNumber: {
    conditionalRenderDefinition: {
      fieldKey: "patentReady",
      renderWhenTrue: true, 
    },
    label: "How many innovations are ready to file within the next 3-6 months?",
    frLabel: "",
    inputType: "number",
    validator: basic_number_validator,
  },  
  searchesList: {
    conditionalRenderDefinition: {
      fieldKey: "patentReady",
      renderWhenTrue: true, 
    },
    label: "Regarding innovations mentioned in the previous question, has your IP service provider completed patentability or novelty searches for at least one of them? Please specify the searches conducted for each innovation",
    frLabel: "",
    inputType: "textarea",
  }, 
}

export const updatedFounderProfileFormGroupDefinition: FormGroupDefinition = {
  /* Contact Information */
  personalInfo: {
    sectionLabel: "Personal Information",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        label: "First Name",
        inputType: "text",
        frLabel: "Prénom",
        validator: basic_text_validator,
      },
      lastName: {
        label: "Last Name",
        frLabel: "Nom de famille",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] handle conditional checkboxes and other field feature
      pronouns: {
        label: "Your Pronouns",
        frLabel: "Pronom",
        inputType: "customMultiSelectNarrow",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "she/her",
              frText: "elle",
              value: "she"
            },
            {
              text: "he/him",
              frText: "il",
              value: "he"
            },
            {
              text: "they/them",
              frText: "eux",
              value: "they"
            },
            {
              text: "prefer not to say",
              frText: "préfère ne pas répondre",
              value: "notToSay"
            },
          ]
        }
      },
      title: {
        label: "Job Title",
        inputType: "text",
        frLabel: "Titre du poste",
        validator: basic_text_validator,
      },
      phoneNumber: {
        label: "Phone Number",
        inputType: "text",
        frLabel: "Numéro de téléphone",
        validator: phone_validator,
      },
      isPointOfContact: {
        label: "I'm the point of contact",
        frLabel: "Je suis la personne ressource à contacter",
        inputType: "checkbox",
        tooltip: {
          content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the preffered point of contact below",
          position: "bottom-right"
        },
        frTooltip: {
          content: "Si vous préférez que les avis par courriel et les communications d’ÉPI soient envoyés à un autre point de contact, ne cochez pas cette case et indiquez le point de contact souhaité ci-dessous.",
          position: "bottom-right"
        },
      }
    }
  },
  pointOfContact: {
    conditionalRenderDefinition: {
      fieldKey: "isPointOfContact",
      renderWhenTrue: false, 
    },
    sectionLabel: "Point of Contact",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        label: "First Name",
        frLabel: "Prénom",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        label: "Last Name",
        frLabel: "Nom de famille",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] pronouns is waiting on conditional checkboxes feature
      title: {
        label: "Job Title",
        frLabel: "Titre du poste",
        inputType: "text",
        validator: basic_text_validator,
      },
      email: {
        label: "Email",
        frLabel: "Courriel",
        inputType: "text",
        validator: email_validator,
      },
      phoneNumber: {
        label: "Phone Number",
        frLabel: "Numéro de téléphone",
        inputType: "text",
        validator: phone_validator,
      },
      // [TODO] roleTags is waiting on conditional checkboxes feature
    }
  },
  /* Company Information */
  basicInfo: {
    sectionLabel: "Company Information",
    frSectionLabel: "Renseignements sur l'entreprise",
    formDefinition: {
      operatingName: {
        label: "Operating Name",
        frLabel: "Nom opérationnel",
        inputType: "text",
        validator: basic_text_validator,
      },
      // isLegalNameSameAsOperatingName: {
      //   label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
      //   frLabel: "Le nom légal de notre société est le même que son nom opérationnel.  Le nom légal complet doit être fourni.",
      //   inputType: "checkbox",
      // },
      legalName: {
        // conditionalRenderDefinition: {
        //   fieldKey: "isLegalNameSameAsOperatingName",
        //   renderWhenTrue: false, 
        // },
        label: "Legal Name",
        frLabel: "Nom légal",
        inputType: "text",
        validator: basic_text_validator,
      },
      description: {
        label: "Company Description",
        frLabel: "Description de l’entreprise",
        inputType: "textarea",
        validator: basic_text_validator,
      },
      industry: {
        label: "Industry",
        frLabel: "Industrie",
        inputType: "dropdownSelect",
        tooltip: {
          content: "Click on the field and select an option from the list.",
          position: "bottom-right"
        },
        frTooltip: {
          content: "Cliquer sur le champ et sélectionner une option de la liste.",
          position: "bottom-right"
        },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "Agriculture",
              text: "Agriculture",
              frText: "Agriculture",
            },
            {
              value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
            },
            {
              value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
            },
            {
              value: "Consumer Retail",
              text: "Consumer Retail",
              frText: "Consommation de détail",
            },
            {
              value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
            },
            {
              value: "Education",
              text: "Education",
              frText: "Éducation",
            },
            {
              value: "Financial Services",
              text: "Financial Services",
              frText: "Services financiers",
            },
            {
              value: "Food & Beverage",
              text: "Food & Beverage",
              frText: "Restauration/production d’aliments et boissons",
            },
            {
              value: "Forestry",
              text: "Forestry",
              frText: "Foresterie",
            },
            {
              value: "Life Sciences & Health",
              text: "Life Sciences & Health",
              frText: "Sciences de la vie et de la santé",
            },
            {
              value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              frText: "Exploitation minière, pétrole et gaz : comprend les activités extractives qui ne relèveraient pas autrement des technologies propres.",
            },
            {
              value: "Tourism & Culture: Includes entertainment and sports",
              text: "Tourism & Culture: Includes entertainment and sports",
              frText: "Culture et tourisme : comprend le divertissement et le sport",
            },
            {
              value: "Transportation",
              text: "Transportation",
              frText: "Transport",
            },
            {
              value: "Other",
              text: "Other",
              frText: "Autre",
            }
          ]
        }
      },
      websiteURL: {
        label: "Website URL",
        frLabel: "URL du site Web",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  registration: {
    sectionLabel: "Registration Information",
    frSectionLabel: "Enregistrement de l’entreprise",
    formDefinition: {
      number: {
        label:  `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website</a>)",`,
        frLabel:  `Le numéro d’entreprise (NE) est un numéro à 9 chiffres attribué à toutes les entreprises constituées en société par l’Agence du revenu du Canada. Détenir un numéro d’enregistrement est une condition pour participer à ÉleverlaPI. Pour plus d’informations sur les numéros d’entreprise, (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'> cliquez ici.</a>)",`,
        inputType: "text",
        validator: {
          ...basic_number_validator,
          minLength: {
            value: 9,
            message: "Registration number must be nine digits",
          },
          maxLength: {
            value: 9,
            message: "Registration number must be nine digits",
          }
        }
      },
      date: {
        label: "Registration Date",
        inputType: "date",
        frLabel: "Date de l’enregistrement",
        validator: date_validator,
      },
      province: {
        label: "Registered Provinces and Territories",
        inputType: "multiSelectNarrow",
        frLabel: "Provinces et territoires enregistrés",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "AB",
              text: "Alberta",
              frText: "Alberta"
            },
            {
              value: "BC",
              text: "British Columbia",
              frText: "Colombie-Britannique"
            },
            {
              value: "MB",
              text: "Manitoba",
              frText: "Manitoba"
            },
            {
              value: "NB",
              text: "New Brunswick",
              frText: "Nouveau-Brunswick"
            },
            {
              value: "NL",
              text: "Newfoundland and Labrador",
              frText: "Terre-Neuve-et-Labrador"
            },
            {
              value: "NT",
              text: "Northwest Territories",
              frText: "Territoires du Nord-Ouest"
            },
            {
              value: "NS",
              text: "Nova Scotia",
              frText: "Nouvelle-Écosse"
            },
            {
              value: "NU",
              text: "Nunavut",
              frText: "Nunavut"
            },
            {
              value: "ON",
              text: "Ontario",
              frText: "Ontario"
            },
            {
              value: "PE",
              text: "Prince Edward Island",
              frText: "Île-du-Prince-Édouard"
            },
            {
              value: "QC",
              text: "Quebec",
              frText: "Québec"
            },
            {
              value: "SK",
              text: "Saskatchewan",
              frText: "Saskatchewan"
            },
            {
              value: "YT",
              text: "Yukon",
              frText: "Yukon"
            },
          ]
        }
      }
    }
  },
  mailingAddress: {
    sectionLabel: "Mailing Address",
    frSectionLabel: "Adresse postale",
    formDefinition: addressFormDefinition,
  },
  programingEvaluation: {
    sectionLabel: "Service Evaluation",
    frSectionLabel: "Évaluation du service",
    formDefinition: {
      hasSocialOrEnvironmentalGoals: {
        frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
        label: "Does your company have social or environmental goals as part of its founding mission?",
        inputType: "boolean",
      },
      numFullTimeEmployees: {
        frLabel: "Nombre d’employés à temps plein (ETP)",
        label: "Number of Full Time Employees",
        inputType: "number",
        validator: basic_number_validator,
      },
      isProductForSaleOnMarket: {
        frLabel: "Le produit que vous cherchez à sécuriser comme PI est-il actuellement en vente sur le marché?",
        label: "Is the product you are looking to protect currently for sale on the market?",
        inputType: "boolean"
      },
      productForSaleInfo: {
        conditionalRenderDefinition: {
          fieldKey: "isProductForSaleOnMarket",
          renderWhenTrue: true, 
        },
        frLabel: "Veuillez sélectionner tous les énoncés qui s’appliquent",
        label: "Please select all that apply",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "singleProduct",
              text: "The product I want to protect is currently for sale",
              frText: "Le produit que je désire sécuriser comme PI est présentement en vente",
            },
            {
              value: "multipleProducts",
              text: "There are multiple products I want to protect that are currently for sale",
              frText: "Plusieurs produits que je désire sécuriser comme PI sont présentement en vente",
            },
          ]
        }
      },
      revenueOverTwelveMonths: {
        frLabel: "Indiquez le revenu total de votre entreprise (en CAD) au cours de votre dernier exercice financier.",
        label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
        inputType: "number",
        validator: basic_number_validator,
      },
      underrepresentedFounderGroups: {
        frLabel: "Nous nous engageons à accroître la diversité des personnes dans nos programmes et à faire en sorte que toutes les personnes vivent des expériences équitables et inclusives. Afin de suivre nos progrès vers cet objectif et de nous conformer aux exigences de financement du gouvernement fédéral, nous vous encourageons à indiquer si votre entreprise est détenue majoritairement par l'un des groupes sous-représentés suivants.",
        label: "Underrepresented Groups: A startup/BAI owned by an underrepresented group refers to those that are majority owned (50.1%) by an underrepresented group which includes: women, Indigenous Peoples (including First Nations, Métis and Inuit), 2SLGBTQI+, visible minorities, persons with disabilities or any community of people that experience significant collective barriers to participating in mainstream society.  We are committed to increasing the diversity of people in our programs and ensuring that all people have equitable and inclusive experiences throughout. In order to monitor our progress on this goal and to comply with Federal Government funding requirements, we are encouraging you to share whether your company is majority owned by any of the following underrepresented groups.  Please select all that apply:",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "women",
              text: "Women",
              frText: "Femmes"
            },
            {
              value: "indigenous",
              text: "Indigenous Peoples (including First Nations, Métis and Inuit)",
              frText: "Autochtones"
            },
            {
              value: "2slgbtqi+",
              text: "2SLGBTQI+",
              frText: "2SLGBTQI+"
            },
            {
              value: "visibleMinorities",
              text: "Visible Minorities",
              frText: "Minorités visibles"
            },
            {
              value: "disabilities",
              text: "Persons with Disabilities",
              frText: "Personnes handicapées"
            },
            {
              value: "other",
              text: "Any community of people that experience significant collective barriers to participating in mainstream society",
              frText: "Autre"
            },
            {
              value: "notToSay",
              text: "Prefer not to say",
              frText: "Préfère ne pas répondre"
            },
            {
              value: "none",
              text: "None of the Above",
              frText: "Aucune de ces réponses"
            }
          ]
        }
      },
      isFromOLMC: {
        label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
        frLabel:"Êtes-vous issu d'une communauté de langue officielle en situation minoritaire (CLOSM)?",
        inputType: "boolean",
      },
      officialLanguageMinorityCommunity: {
        conditionalRenderDefinition: {
          fieldKey: "isFromOLMC",
          renderWhenTrue: true,
        },
        label: "Which OLMC are you a part of?",
        frLabel: "De quelle CLOSM faites-vous partie?",
        inputType: "customDropdownSelect",
        // tooltip: {
        //   content:  "Click on the field and select an option from the list or Enter your own directly.",
        //   position: "top-left"
        // },
        // frTooltip: {
        //   content:  "Cliquer sur le champ et sélectionner une option de la liste ou saisissez votre propre classification directement.",
        //   position: "top-left"
        // },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "ACF",
              frText: "Assemblée communautaire fransaskoise (ACF)",
              text: "Assemblée communautaire fransaskoise (ACF)",
            },
            {
              value: "AFO",
              frText: "Assemblée de la francophonie de l'Ontario (AFO)",
              text: "Assemblée de la francophonie de l'Ontario (AFO)",
            },
            {
              value: "FCFA",
              frText: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
              text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
            },
            {
              value: "SFM",
              frText: "Société franco-manitobaine (SFM)",
              text: "Société franco-manitobaine (SFM)",
            },
            {
              value: "SNA",
              frText: "Société nationale de l'Acadie (SNA)",
              text: "Société nationale de l'Acadie (SNA)",
            },
          ]
        }
      },
      additionalLinks: {
        label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        frLabel: "",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  iPStrategy: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition: {
      bestMatch: {
        frLabel: "Lequel des énoncés suivants correspond le mieux à vos besoins actuels?",
        label: "Which of the following best match your needs at this time?",        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "education",
              text: "I’m seeking general IP Education (e.g. Where do I start? What is a patent?, etc.)",
              frText: "Je recherche une formation générale en PI (p. ex., par où dois-je commencer? Qu'est-ce qu'un brevet?, etc.).",
            },
            {
              value: "strategy",
              text: "I’m seeking support for IP Strategy Development (e.g, What/how/when/where should I protect? I need help with patentability/novelty searches., etc.)",
              frText: "Je recherche de l'aide pour l'élaboration d'une stratégie de PI (p. ex., quoi/comment/quand/où dois-je protéger? J'ai besoin d'aide pour des recherches de brevetabilité/nouveauté, etc.).",
            },
            {
              value: "trademarks",
              text: "I think I’m ready to register trademarks in the next 3-6 months",
              frText: "Je pense que je suis prêt(e) à enregistrer des marques dans les 3 à 6 prochains mois.",
            },
            {
              value: "patents",
              text: "I think I’m ready to file patents in the next 3-6 months or convert PCT / provisional or continuations",
              frText: "Je pense que je suis prêt(e) à déposer des brevets dans les 3 à 6 prochains mois ou à convertir un PCT, à déposer une demande de brevet provisoire ou à reconduire un brevet",
            },
          ]
        }
      },
      assignmentAgreement: {
        frLabel: "",
        label: 'Have you and your cofounders signed a "Co-founders IP assignment agreement" that assigns IP to the corporation? ',
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      patentsReadyOptions: {
        frLabel: "",
        label: " If you think you are ready to file patents in the next 3-6 months, please indicate which of the following apply to the technology you intend to patent:",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "notApplicable",
              text: "Not applicable, as I am not seeking support to file patents in the next 3-6 months",
              frText: "",
            },
            {
              value: "search",
              text: "My IP service provider has conducted a favorable patentability/prior art/novelty search for the technology I plan to patent.",
              frText: "",
            },
            {
              value: "family",
              text: "I have a granted patent in the same patent family.",
              frText: "",
            },
            {
              value: "written",
              text: "I have received a favorable written opinion from my PCT application.",
              frText: "",
            },
            {
              value: "support",
              text: "I don’t have B or C or D and I am seeking support to file patent",
              frText: "",
            },
          ]
        }
      },
      trademarkClearanceSearch: {
        frLabel: "",
        label: "If you think you are ready to register trademarks in the next 3-6 months, has your IP service provider completed a clearance search?",
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      isWorkingWithLawFirm: {
        frLabel: "Votre entreprise est présentement en contact avec une firme d’avocats et de PI",
        label: "Is your company currently working with an IP / Law firm?",
        inputType: "boolean",
      },
      workingLawFirms: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithLawFirm",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer le nom des firmes",
        label: "Please list the name of firms",
        inputType: "customList",
      },
      isWorkingWithOrganization: {
        frLabel: "Votre entreprise est présentement en contact avec Invest Ottawa ou North Forge qui collaborent avec Communitech",
        label: "Is your company currently working with Communitech, Invest Ottawa, North Forge, Spark Center or Launch Lab?",
        inputType: "boolean",
      },
      workingOrganizations: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithOrganization",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "dropdownSelect",
        validator: {
          required: {
            value: true,
            message: "Please select an option"
          }
        },
        additionalData: {
          selectionData: [
            {
              value: "communitech", 
              text: "Communitech"
            },
            {
              value: "northForge", 
              text: "North Forge"
            },
            {
              value: "investOttawa", 
              text: "Invest Ottawa"
            },
            {
              value: "spark", 
              text: "Spark Center"
            },
            {
              value: "launch", 
              text: "Launch Labs"
            }
          ]
        }
      },
      isWorkingWithBai: {
        frLabel: "Votre entreprise travaille-t-elle actuellement avec un autre IAA (Incubateur Accélérateur d’Affaires)?",
        label: "Is your company currently working with a different BAI?",
        inputType: "boolean",
      },
      otherBai: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithBai",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "text",
        validator: basic_text_validator
      }
    }
  }
}

export const updatedFounderProfileReadinessFormGroupDefinition: FormGroupDefinition = {
  /* Contact Information */
  personalInfo: {
    sectionLabel: "Personal Information",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        label: "First Name",
        inputType: "text",
        frLabel: "Prénom",
        validator: basic_text_validator,
      },
      lastName: {
        label: "Last Name",
        frLabel: "Nom de famille",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] handle conditional checkboxes and other field feature
      pronouns: {
        label: "Your Pronouns",
        frLabel: "Prénom",
        inputType: "customMultiSelectNarrow",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              text: "she/her",
              frText: "elle",
              value: "she"
            },
            {
              text: "he/him",
              frText: "il",
              value: "he"
            },
            {
              text: "they/them",
              frText: "eux",
              value: "they"
            },
            {
              text: "prefer not to say",
              frText: "préfère ne pas répondre",
              value: "notToSay"
            },
          ]
        }
      },
      title: {
        label: "Job Title",
        inputType: "text",
        frLabel: "Titre du poste",
        validator: basic_text_validator,
      },
      phoneNumber: {
        label: "Phone Number",
        inputType: "text",
        frLabel: "Numéro de téléphone",
        validator: phone_validator,
      },
      isPointOfContact: {
        label: "I'm the point of contact",
        frLabel: "Je suis la personne ressource à contacter",
        inputType: "checkbox",
        tooltip: {
          content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the preffered point of contact below",
          position: "bottom-right"
        },
        frTooltip: {
          content: "Si vous préférez que les avis par courriel et les communications d’ÉPI soient envoyés à un autre point de contact, ne cochez pas cette case et indiquez le point de contact souhaité ci-dessous.",
          position: "bottom-right"
        },
      }
    }
  },
  pointOfContact: {
    conditionalRenderDefinition: {
      fieldKey: "isPointOfContact",
      renderWhenTrue: false, 
    },
    sectionLabel: "Point of Contact",
    frSectionLabel: "",
    formDefinition: {
      firstName: {
        label: "First Name",
        frLabel: "Prénom",
        inputType: "text",
        validator: basic_text_validator,
      },
      lastName: {
        label: "Last Name",
        frLabel: "Nom de famille",
        inputType: "text",
        validator: basic_text_validator,
      },
      // [TODO] pronouns is waiting on conditional checkboxes feature
      title: {
        label: "Job Title",
        frLabel: "Titre du poste",
        inputType: "text",
        validator: basic_text_validator,
      },
      email: {
        label: "Email",
        frLabel: "Courriel",
        inputType: "text",
        validator: email_validator,
      },
      phoneNumber: {
        label: "Phone Number",
        frLabel: "Numéro de téléphone",
        inputType: "text",
        validator: phone_validator,
      },
      // [TODO] roleTags is waiting on conditional checkboxes feature
    }
  },
  /* Company Information */
  basicInfo: {
    sectionLabel: "Company Information",
    frSectionLabel: "Renseignements sur l'entreprise",
    formDefinition: {
      operatingName: {
        label: "Operating Name",
        frLabel: "Nom opérationnel",
        inputType: "text",
        validator: basic_text_validator,
      },
      // isLegalNameSameAsOperatingName: {
      //   label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
      //   frLabel: "Le nom légal de notre société est le même que son nom opérationnel.  Le nom légal complet doit être fourni.",
      //   inputType: "checkbox",
      // },
      legalName: {
        // conditionalRenderDefinition: {
        //   fieldKey: "isLegalNameSameAsOperatingName",
        //   renderWhenTrue: false, 
        // },
        label: "Legal Name",
        frLabel: "Nom légal",
        inputType: "text",
        validator: basic_text_validator,
      },
      description: {
        label: "Company Description",
        frLabel: "Description de l’entreprise",
        inputType: "textarea",
        validator: basic_text_validator,
      },
      industry: {
        label: "Industry",
        frLabel: "Industrie",
        inputType: "dropdownSelect",
        tooltip: {
          content: "Click on the field and select an option from the list.",
          position: "bottom-right"
        },
        frTooltip: {
          content: "Cliquer sur le champ et sélectionner une option de la liste.",
          position: "bottom-right"
        },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "Agriculture",
              text: "Agriculture",
              frText: "Agriculture",
            },
            {
              value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
              frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
            },
            {
              value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
              frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
            },
            {
              value: "Consumer Retail",
              text: "Consumer Retail",
              frText: "Consommation de détail",
            },
            {
              value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
              frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
            },
            {
              value: "Education",
              text: "Education",
              frText: "Éducation",
            },
            {
              value: "Financial Services",
              text: "Financial Services",
              frText: "Services financiers",
            },
            {
              value: "Food & Beverage",
              text: "Food & Beverage",
              frText: "Restauration/production d’aliments et boissons",
            },
            {
              value: "Forestry",
              text: "Forestry",
              frText: "Foresterie",
            },
            {
              value: "Life Sciences & Health",
              text: "Life Sciences & Health",
              frText: "Sciences de la vie et de la santé",
            },
            {
              value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
              frText: "Exploitation minière, pétrole et gaz : comprend les activités extractives qui ne relèveraient pas autrement des technologies propres.",
            },
            {
              value: "Tourism & Culture: Includes entertainment and sports",
              text: "Tourism & Culture: Includes entertainment and sports",
              frText: "Culture et tourisme : comprend le divertissement et le sport",
            },
            {
              value: "Transportation",
              text: "Transportation",
              frText: "Transport",
            },
            {
              value: "Other",
              text: "Other",
              frText: "Autre",
            }
          ]
        }
      },
      websiteURL: {
        label: "Website URL",
        frLabel: "URL du site Web",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  registration: {
    sectionLabel: "Registration Information",
    frSectionLabel: "Enregistrement de l’entreprise",
    formDefinition: {
      number: {
        label:  `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website</a>)",`,
        frLabel:  `Le numéro d’entreprise (NE) est un numéro à 9 chiffres attribué à toutes les entreprises constituées en société par l’Agence du revenu du Canada. Détenir un numéro d’enregistrement est une condition pour participer à ÉleverlaPI. Pour plus d’informations sur les numéros d’entreprise, (<a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'> cliquez ici.</a>)",`,
        inputType: "text",
        validator: {
          required: true,
          fieldType: "text" as InputFieldType,
          pattern: {
            value: /^\d+(\.\d+)?$/,
            message: "Please enter only numerical characters"
          },
          minLength: {
            value: 9,
            message: "Registration number must be nine digits",
          },
          maxLength: {
            value: 9,
            message: "Registration number must be nine digits",
          }
        }
      },
      date: {
        label: "Registration Date",
        inputType: "date",
        frLabel: "Date de l’enregistrement",
        validator: date_validator,
      },
      province: {
        label: "Registered Provinces and Territories",
        inputType: "multiSelectNarrow",
        frLabel: "Provinces et territoires enregistrés",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "AB",
              text: "Alberta",
              frText: "Alberta"
            },
            {
              value: "BC",
              text: "British Columbia",
              frText: "Colombie-Britannique"
            },
            {
              value: "MB",
              text: "Manitoba",
              frText: "Manitoba"
            },
            {
              value: "NB",
              text: "New Brunswick",
              frText: "Nouveau-Brunswick"
            },
            {
              value: "NL",
              text: "Newfoundland and Labrador",
              frText: "Terre-Neuve-et-Labrador"
            },
            {
              value: "NT",
              text: "Northwest Territories",
              frText: "Territoires du Nord-Ouest"
            },
            {
              value: "NS",
              text: "Nova Scotia",
              frText: "Nouvelle-Écosse"
            },
            {
              value: "NU",
              text: "Nunavut",
              frText: "Nunavut"
            },
            {
              value: "ON",
              text: "Ontario",
              frText: "Ontario"
            },
            {
              value: "PE",
              text: "Prince Edward Island",
              frText: "Île-du-Prince-Édouard"
            },
            {
              value: "QC",
              text: "Quebec",
              frText: "Québec"
            },
            {
              value: "SK",
              text: "Saskatchewan",
              frText: "Saskatchewan"
            },
            {
              value: "YT",
              text: "Yukon",
              frText: "Yukon"
            },
          ]
        }
      }
    }
  },
  mailingAddress: {
    sectionLabel: "Mailing Address",
    frSectionLabel: "Adresse postale",
    formDefinition: addressFormDefinition,
  },
  programingEvaluation: {
    sectionLabel: "Service Evaluation",
    frSectionLabel: "Évaluation du service",
    formDefinition: {
      hasSocialOrEnvironmentalGoals: {
        frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
        label: "Does your company have social or environmental goals as part of its founding mission?",
        inputType: "boolean",
      },
      numFullTimeEmployees: {
        frLabel: "Nombre d’employés à temps plein (ETP)",
        label: "Number of Full Time Employees",
        inputType: "number",
        validator: basic_number_validator,
      },
      isProductForSaleOnMarket: {
        frLabel: "Le produit que vous cherchez à sécuriser comme PI est-il actuellement en vente sur le marché?",
        label: "Is the product you are looking to protect currently for sale on the market?",
        inputType: "boolean"
      },
      productForSaleInfo: {
        conditionalRenderDefinition: {
          fieldKey: "isProductForSaleOnMarket",
          renderWhenTrue: true, 
        },
        frLabel: "Veuillez sélectionner tous les énoncés qui s’appliquent",
        label: "Please select all that apply",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "singleProduct",
              text: "The product I want to protect is currently for sale",
              frText: "Le produit que je désire sécuriser comme PI est présentement en vente",
            },
            {
              value: "multipleProducts",
              text: "There are multiple products I want to protect that are currently for sale",
              frText: "Plusieurs produits que je désire sécuriser comme PI sont présentement en vente",
            },
          ]
        }
      },
      revenueOverTwelveMonths: {
        frLabel: "Indiquez le revenu total de votre entreprise (en CAD) au cours de votre dernier exercice financier.",
        label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
        inputType: "number",
        validator: basic_number_validator,
      },
      underrepresentedFounderGroups: {
        frLabel: "Nous nous engageons à accroître la diversité des personnes dans nos programmes et à faire en sorte que toutes les personnes vivent des expériences équitables et inclusives. Afin de suivre nos progrès vers cet objectif et de nous conformer aux exigences de financement du gouvernement fédéral, nous vous encourageons à indiquer si votre entreprise est détenue majoritairement par l'un des groupes sous-représentés suivants.",
        label: "Underrepresented Groups: A startup/BAI owned by an underrepresented group refers to those that are majority owned (50.1%) by an underrepresented group which includes: women, Indigenous Peoples (including First Nations, Métis and Inuit), 2SLGBTQI+, visible minorities, persons with disabilities or any community of people that experience significant collective barriers to participating in mainstream society.  We are committed to increasing the diversity of people in our programs and ensuring that all people have equitable and inclusive experiences throughout. In order to monitor our progress on this goal and to comply with Federal Government funding requirements, we are encouraging you to share whether your company is majority owned by any of the following underrepresented groups.  Please select all that apply:",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "women",
              text: "Women",
              frText: "Femmes"
            },
            {
              value: "indigenous",
              text: "Indigenous Peoples (including First Nations, Métis and Inuit)",
              frText: "Autochtones"
            },
            {
              value: "2slgbtqi+",
              text: "2SLGBTQI+",
              frText: "2SLGBTQI+"
            },
            {
              value: "visibleMinorities",
              text: "Visible Minorities",
              frText: "Minorités visibles"
            },
            {
              value: "disabilities",
              text: "Persons with Disabilities",
              frText: "Personnes handicapées"
            },
            // {
            //   value: "languageMinority",
            //   text: "Members of Official Language Minority Communities",
            //   frText: "Membres des communautés de langue officielle en situation minoritaire"
            // },
            {
              value: "other",
              text: "Any community of people that experience significant collective barriers to participating in mainstream society",
              frText: "Autre"
            },
            {
              value: "notToSay",
              text: "Prefer not to say",
              frText: "Préfère ne pas répondre"
            },
            {
              value: "none",
              text: "None of the Above",
              frText: "Aucune de ces réponses"
            }
          ]
        }
      },
      isFromOLMC: {
        label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
        frLabel:"Êtes-vous issu d'une communauté de langue officielle en situation minoritaire (CLOSM)?",
        inputType: "boolean",
      },
      officialLanguageMinorityCommunity: {
        conditionalRenderDefinition: {
          fieldKey: "isFromOLMC",
          renderWhenTrue: true,
        },
        label: "Which OLMC are you a part of?",
        frLabel: "De quelle CLOSM faites-vous partie?",
        inputType: "customDropdownSelect",
        // tooltip: {
        //   content:  "Click on the field and select an option from the list or Enter your own directly.",
        //   position: "top-left"
        // },
        // frTooltip: {
        //   content:  "Cliquer sur le champ et sélectionner une option de la liste ou saisissez votre propre classification directement.",
        //   position: "top-left"
        // },
        validator: selection_validator,
        additionalData: {
          selectionData: [
            {
              value: "ACF",
              frText: "Assemblée communautaire fransaskoise (ACF)",
              text: "Assemblée communautaire fransaskoise (ACF)",
            },
            {
              value: "AFO",
              frText: "Assemblée de la francophonie de l'Ontario (AFO)",
              text: "Assemblée de la francophonie de l'Ontario (AFO)",
            },
            {
              value: "FCFA",
              frText: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
              text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
            },
            {
              value: "SFM",
              frText: "Société franco-manitobaine (SFM)",
              text: "Société franco-manitobaine (SFM)",
            },
            {
              value: "SNA",
              frText: "Société nationale de l'Acadie (SNA)",
              text: "Société nationale de l'Acadie (SNA)",
            },
          ]
        }
      },
      additionalLinks: {
        label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        frLabel: "",
        inputType: "text",
        validator: url_validator,
      },
    }
  },
  iPStrategy: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition: {
      bestMatch: {
        frLabel: "Lequel des énoncés suivants correspond le mieux à vos besoins actuels?",
        label: "Which of the following best match your needs at this time?",
                inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "education",
              text: "I’m seeking general IP Education (e.g. Where do I start? What is a patent?, etc.)",
              frText: "Je recherche une formation générale en PI (p. ex., par où dois-je commencer? Qu'est-ce qu'un brevet?, etc.).",
            },
            {
              value: "strategy",
              text: "I’m seeking support for IP Strategy Development (e.g, What/how/when/where should I protect? I need help with patentability/novelty searches., etc.)",
              frText: "Je recherche de l'aide pour l'élaboration d'une stratégie de PI (p. ex., quoi/comment/quand/où dois-je protéger? J'ai besoin d'aide pour des recherches de brevetabilité/nouveauté, etc.).",
            },
            {
              value: "trademarks",
              text: "I think I’m ready to register trademarks in the next 3-6 months",
              frText: "Je pense que je suis prêt(e) à enregistrer des marques dans les 3 à 6 prochains mois.",
            },
            {
              value: "patents",
              text: "I think I’m ready to file patents in the next 3-6 months or convert PCT / provisional or continuations",
              frText: "Je pense que je suis prêt(e) à déposer des brevets dans les 3 à 6 prochains mois ou à convertir un PCT, à déposer une demande de brevet provisoire ou à reconduire un brevet",
            },
          ]
        }
      },
      assignmentAgreement: {
        frLabel: "",
        label: 'Have you and your cofounders signed a "Co-founders IP assignment agreement" that assigns IP to the corporation? ',
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      patentsReadyOptions: {
        frLabel: "",
        label: " If you think you are ready to file patents in the next 3-6 months, please indicate which of the following apply to the technology you intend to patent:",
        inputType: "multiSelect",
        validator: multiselect_validator,
        additionalData: {
          selectionData: [
            {
              value: "notApplicable",
              text: "Not applicable, as I am not seeking support to file patents in the next 3-6 months",
              frText: "",
            },
            {
              value: "search",
              text: "My IP service provider has conducted a favorable patentability/prior art/novelty search for the technology I plan to patent.",
              frText: "",
            },
            {
              value: "family",
              text: "I have a granted patent in the same patent family.",
              frText: "",
            },
            {
              value: "written",
              text: "I have received a favorable written opinion from my PCT application.",
              frText: "",
            },
            {
              value: "support",
              text: "I don’t have B or C or D and I am seeking support to file patent",
              frText: "",
            },
          ]
        }
      },
      trademarkClearanceSearch: {
        frLabel: "",
        label: "If you think you are ready to register trademarks in the next 3-6 months, has your IP service provider completed a clearance search?",
        inputType: "boolean",
        validator: boolean_selection_validator
      },
      isWorkingWithLawFirm: {
        frLabel: "Votre entreprise est présentement en contact avec une firme d’avocats et de PI",
        label: "Is your company currently working with an IP / Law firm?",
        inputType: "boolean",
      },
      workingLawFirms: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithLawFirm",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer le nom des firmes",
        label: "Please list the name of firms",
        inputType: "customList",
      },
      isWorkingWithOrganization: {
        frLabel: "Votre entreprise est présentement en contact avec Invest Ottawa ou North Forge qui collaborent avec Communitech",
        label: "Is your company currently working with Communitech, Invest Ottawa, North Forge, Spark Center or Launch Lab?",
        inputType: "boolean",
      },
      workingOrganizations: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithOrganization",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "dropdownSelect",
        validator: {
          required: {
            value: true,
            message: "Please select an option"
          }
        },
        additionalData: {
          selectionData: [
            {
              value: "communitech", 
              text: "Communitech"
            },
            {
              value: "northForge", 
              text: "North Forge"
            },
            {
              value: "investOttawa", 
              text: "Invest Ottawa"
            },
            {
              value: "spark", 
              text: "Spark Center"
            },
            {
              value: "launch", 
              text: "Launch Labs"
            }
          ]
        }
      },
      isWorkingWithBai: {
        frLabel: "Votre entreprise travaille-t-elle actuellement avec un autre IAA (Incubateur Accélérateur d’Affaires)?",
        label: "Is your company currently working with a different BAI?",
        inputType: "boolean",
      },
      otherBai: {
        conditionalRenderDefinition: {
          fieldKey: "isWorkingWithBai",
          renderWhenTrue: true,
        },
        frLabel: "Veuillez indiquer quelle organisation",
        label: "Please specify which organization",
        inputType: "text",
        validator: basic_text_validator
      }
    }
  },
  ipReadiness: {
    sectionLabel: "IP Readiness",
    frSectionLabel: "Préparation à la PI",
    formDefinition:{
        responsible: {
          label: "Who is responsible for IP within your company?",
          frLabel: "",
          inputType: "text",
          validator: basic_text_validator
        },
        education: {
          label: "Does that person have any formal IP education?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        educationType: {
          conditionalRenderDefinition: {
            fieldKey: "education",
            renderWhenTrue: true, 
          },
          label: "What type of formal IP education did that person receive?",
          frLabel: "",
          inputType: "dropdownSelect",
          additionalData: {
            selectionData: [
              {
                value: "classes",
                text: "IP classes",
              },
              {
                value: "webinars",
                text: "Webinars provided by an accelerator/incubator focussed on IP",
              },
              {
                value: "ipon",
                text: "IP Assist or IPON education",
              },
            ]
          }
        },
        jurisdiction: {
          label: "Have you identified which jurisdictions are of particular interest for you and where you would seek patents if provided with sufficient funding?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        specificJurisdiction: {
          conditionalRenderDefinition: {
            fieldKey: "jurisdiction",
            renderWhenTrue: true, 
          },
          label: "Please Specify",
          frLabel: "",
          inputType: "text",
          validator: basic_text_validator,
        },
        provider: {
          label: "Do you have an IP service provider/patent agent?",
          frLabel: "",
          inputType: "boolean",
          validator: basic_text_validator,
        },
        providerLastWorked: {
          conditionalRenderDefinition: {
            fieldKey: "provider",
            renderWhenTrue: true, 
          },
          label: "When did you last work with them?",
          frLabel: "",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "under", 
                text: "Within past 12 months"
              },
              {
                value: "over", 
                text: "Not within past 12 months"
              }
            ]
          }
        },  
        ipSection: {
          label: "Do you have a documented IP section in your data room?",
          frLabel: "",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "yes", 
                text: "Yes"
              },
              {
                value: "no", 
                text: "No"
              },
              {
                value: "n/a", 
                text: "Not applicable - We are self-funded"
              }
            ]
          }
        },
        software: {
          label: "Do you have an open source log where you keep track of all open source software, algorithms and/or datasets used within your product/service, along with the licensing terms and notes on compliance?",
          frLabel: "",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "yes", 
                text: "Yes"
              },
              {
                value: "no", 
                text: "No"
              },
              {
                value: "n/a", 
                text: "Not applicable - Our product has no software"
              }
            ]
          }
        },
        tradeSecrets: {
          label: "Do you have any trade secret protection measures in place?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator,
        },
        tradeSecretsList: {
          conditionalRenderDefinition: {
            fieldKey: "tradeSecrets",
            renderWhenTrue: true, 
          },
          label: "Please list your protection measures",
          frLabel: "",
          inputType: "textarea",
        },  
        ipBudget: {
          label: "Do you have a documented IP budget?",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator,
        },
        patentReady: {
          label: "Do you have any innovations ready to file patents for within the next 3-6 months? ",
          frLabel: "",
          inputType: "boolean",
          validator: boolean_selection_validator,
        },
        patentsNumber: {
          conditionalRenderDefinition: {
            fieldKey: "patentReady",
            renderWhenTrue: true, 
          },
          label: "How many innovations are ready to file within the next 3-6 months?",
          frLabel: "",
          inputType: "number",
          validator: basic_number_validator,
        },  
        searchesList: {
          conditionalRenderDefinition: {
            fieldKey: "patentReady",
            renderWhenTrue: true, 
          },
          label: "Regarding innovations mentioned in the previous question, has your IP service provider completed patentability or novelty searches for at least one of them? Please specify the searches conducted for each innovation",
          frLabel: "",
          inputType: "textarea",
        }, 
      }
    }
}

//SOW forms

export const sowMain: FormDefinition = {
  spName: {
    label: "Service Provider Name",
    inputType: "text",
    validator: basic_text_validator,
    isViewOnly: true
  },
  startupName: {
    label: "Startup Name",
    inputType: "text",
    validator: basic_text_validator,
    isViewOnly: true

  },
  date: {
    label: "Date",
    inputType: "date",
    validator: basic_text_validator,
    isViewOnly: true

  },
  description: {
    label: "Description of Work to be Performed",
    inputType: "textarea",
    validator: basic_text_validator
  }
}

export const sowItemFormTier3: FormDefinition = {
  product: {
    label: "Product",
    inputType: "dropdownSelect",
    validator: selection_validator,
    additionalData: {
      selectionData:[
        {
          value: "EIP 3 - Trademark Registration",
          text: "Trademark Registration",
        },
        {
          value: "EIP 3 - Patent Application",
          text: "Patent Application",
        },
        {
          value: "EIP 3 - Copyright Registration",
          text: "Copyright Registration",
        }
      ]
    }  
  },
  taskDescription: {
    label: "Task Description",
    inputType: "textarea",
    validator: basic_text_validator
  },
  deliverable: {
    label: "Deliverable",
    inputType: "textarea",
    validator: basic_text_validator
  },
  effort:{
    label: "Effort (number of hours)",
    inputType: "number",
    validator: basic_text_validator
  },
  date: {
    label: "Date",
    inputType: "date",
    validator: date_validator,
    future: true
  },
  // costBreakdown: {
  //   label: "Cost Breakdown.  Please include a thorough breakdown for all line items (taxes, government fees, hourly rates etc.) e.g. $1,000 + HST = $1,130",
  //   inputType: "textarea",
  //   validator: basic_text_validator
  // },
  // costBreakdownTotal: {
  //   label: "Total from Cost Breakdown",
  //   inputType: "number",
  //   validator: basic_text_validator
  // }
}

export const sowItemFormTier2: FormDefinition = {
  product: {
    label: "Product",
    inputType: "dropdownSelect",
    validator: selection_validator,
    additionalData: {
      selectionData:[
        {
          value: "EIP 2 - Trademark",
          text: "Trademark",
        },
        {
          value: "EIP 2 - Patent",
          text: "Patent",
        }
      ]
    }  
  },
  taskDescription: {
    label: "Task Description",
    inputType: "textarea",
    validator: basic_text_validator
  },
  deliverable: {
    label: "Deliverable",
    inputType: "textarea",
    validator: basic_text_validator
  },
  effort:{
    label: "Effort (number of hours)",
    inputType: "number",
    validator: basic_text_validator
  },
  date: {
    label: "Date",
    inputType: "date",
    validator: date_validator,
    future: true
  },
  // costBreakdown: {
  //   label: "Cost Breakdown.  Please include a thorough breakdown for all line items (taxes, government fees, hourly rates etc.) e.g. $1,000 + HST = $1,130",
  //   inputType: "textarea",
  //   validator: basic_text_validator
  // },
  // costBreakdownTotal: {
  //   label: "Total from Cost Breakdown",
  //   inputType: "number",
  //   validator: basic_number_validator
  // }
}

export const paymentFormTier2: FormDefinition = {
  date: {
    label: "Date",
    inputType: "date",
    validator: date_validator,
    future: true
  },
}

export const paymentFormTier3: FormDefinition = {
  date: {
    label: "Date",
    inputType: "date",
    validator: date_validator,
    future: true
  },
  coInvestDate: {
    label: "Date",
    inputType: "date",
    validator: date_validator,
    future: true
  },
}

export const mileStonePaymentFormTier3: FormDefinition = {
  date: {
    label: "Milestone Payment Date",
    inputType: "date",
    validator: date_validator,
    future: true
  }
}

export const expertAssessmentFormDefition: FormDefinition = {
  responsibleForOversight: {
    label: "Who is responsible for overseeing IP in the company?",
    inputType: "textarea",
  },
  ipEducation: {
    label: "Does person responsible for IP have IP education?",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "ipon",
          text: "IPON, IP Assist, or eligible RIC (vL HCI, AAI)",
        },
        {
          value: "other",
          text: "Other or experience",
        },
        {
          value: "no",
          text: "No",
        }
      ]
    }
  },
  educationNotes: {
    label: "Education Notes",
    inputType: "textarea",
  },
  ipStrategy: {
    label: "Articulable IP Strategy developed with IP professional support?",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "3",
          text: "Strategy goes beyond just patents - holistic IP strategy with IP professional",
        },
        {
          value: "2",
          text: "Strategy is intenally drafted but well thought out",
        },
        {
          value: "1",
          text: "IP Strategy only covers patents",
        },
        {
          value: "0",
          text: "No",
        }
      ]
    }
  },
  patentPortfolio: {
    label: "Details of any existing patent portfolio or near-term plans for obtaining patent:",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "3",
          text: "Can articulate patents' importance for tech and have existing patents or applications",
        },
        {
          value: "2",
          text: "Seeking first patent but can articulate patents' importance",
        },
        {
          value: "1",
          text: "Seeking one or more patents but with little rationale",
        },
        {
          value: "0",
          text: "No",
        }
      ]
    }
  },
  patentPortfolioNotes: {
    label: "Patent Portfolio Notes",
    inputType: "textarea",
  },
  searches: {
    label: "If seeking fund to prosecute patent, have one of favourable searches listed above OR granted patent in patent family OR favourable search result from patent office",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "3",
          text: "All claims favourable",
        },
        {
          value: "2",
          text: "Some claims favourable",
        },
        {
          value: "1",
          text: "Few claims favourable",
        },
        {
          value: "0",
          text: "No allowable claims or N/A",
        }
      ]
    }
  },
  searchesPCT: {
    label: "If seeking new patent, provisional or PCT conversion:",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "3",
          text: "Company has favourable prior art/novetly/patentability search prepared by IP professional. Favourable WO from ISA also accepted.  Must have favourable search for each technology seeking support for",
        },
        {
          value: "2",
          text: "Significant number of claims favourable",
        },
        {
          value: "0",
          text: "No allowable claims or N/A",
        }
      ]
    }
  },
  searchesNotes: {
    label: "Searches Notes",
    inputType: "textarea",
  },
  impactUsPatent: {
    label: "Any product offer for sale that could impact ability to get US patent, or any disclosure that could impact ability to obtain patents?",
    inputType: "boolean",
    validator: {
      required: false
    }
  },
  overTwelveMonthsAgo: {
    label: "If yes, date of dislosure or offer for sale last 12 months?",
    inputType: "boolean",
    validator: {
      required: false
    }
  },
  ipOwnership: {
  label: "WRT any patent work sought, IP ownership by company? Any partners, universities, research insitutions, hospitals, customer pilots, lack of employee NDAs, lack of P assignments, founders not assigned IP to corp that could impact companies ability to own IP?",
  inputType: "dropdownSelect",
  additionalData: {
    selectionData:[
      {
        value: "5",
        text: "Ownership is completely clear",
      },
      {
        value: "4",
        text: "Company has exclusive license to commercialize the IP",
      },
      {
        value: "3",
        text: "Few Issues",
      },
      {
        value: "2",
        text: "Some Issues;",
      },
      {
        value: "0",
        text: "Company does not have ownership",
      }
    ]
  }
},
  jurisdictionOfInterest: {
    label: "Has company identified jurisdictions of interest?",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "2",
          text: "Yes, assessment has gone into jurisdictions",
        },
        {
          value: "1",
          text: "Yes, but don't have rationale for jurisdictions",
        },
        {
          value: "0",
          text: "No",
        }
      ]
    }
  },
  anyPatents: {
    label: "Any in-licensed or out-licensed patents?",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "0",
          text: "Yes",
        },
        {
          value: "1",
          text: "Yes but no issues WRT IP strategy and IP ownerships",
        },
        {
          value: "1",
          text: "No",
        }
      ]
    }
  },
  ipAgent: {
    label: "Do you have an IP agent? When did you last work with them? (Ongoing or past relationship)",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "2",
          text: "Yes, recent and ongoing",
        },
        {
          value: "1",
          text: "Yes but not within past 12 months",
        },
        {
          value: "0",
          text: "No",
        }
      ]
    }
  },
  dataRoom: {
    label: "Have documented IP section in data room/IP inventory?",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "Yes",
          text: "Yes",
        },
        {
          value: "No, but no investment required so no data room",
          text: "No, but no investment required so no data room",
        },
        {
          value: "No, but documented IP or Patent Inventory",
          text: "No, but documented IP or Patent Inventory",
        },
        {
          value: "No",
          text: "No",
        }
      ]
    }
  },
  oss: {
    label: "Have OSS log if use OSS?",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "2",
          text: "Yes, OSS log in place",
        },
        {
          value: "2",
          text: "Product includes no software",
        },
        {
          value: "0",
          text: "No",
        }
      ]
    }
  },
  tradeSecrets: {
    label: "Can company articulate how trade secrets are protected and sufficient protection in place?",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "2",
          text: "Mention more than just NDAs (for example need-to-know, access restrictions, cybersecurity)",
        },
        {
          value: "1",
          text: "Only mention NDAs (employees, contractors, suppliers)",
        },
        {
          value: "0",
          text: "No",
        }
      ]
    }
  },
  ipBudget: {
    label: "Does the company have a documented IP Budget?",
    inputType: "boolean",
    validator: {
      required: false
    }
  },
  tmAgent: {
    label: "If seeking support for trademarks, have clearance search by TM agent for each relevant jurisdiction?",
    inputType: "boolean",
    validator: {
      required: false
    }
  },
  tierSuggestion: {
    label: "Recommended Service Tier",
    inputType: "dropdownSelect",
    additionalData: {
      selectionData:[
        {
          value: "1",
          text: "1",
        },
        {
          value: "2",
          text: "2",
        },
        {
          value: "3",
          text: "3",
        }
      ]
    }
  },
  recommendation: {
    label: "Recommendation",
    inputType: "textarea",
  }
}

export const adminRecommendationFormDefition: FormDefinition = {
  rec: {
    label: "Recommendation:",
    inputType: "textarea",
    validator: basic_text_validator,
  },
  tierRec: {
    label: "Recommended Service Tier:",
    inputType: "text",
    validator: basic_text_validator,
  }
}

export const createExpertProfileFormDefinition: FormDefinition = {
  firstName: {
    label: "First Name",
    frLabel: "Prénom",
    inputType: "text",
    validator: basic_text_validator,
  },
  lastName: {
    label: "Last Name",
    frLabel: "Nom de famille",
    inputType: "text",
    validator: basic_text_validator,
  },
  uniqueEmail: {
    label: "Email",
    frLabel: "Courrier",
    inputType: "text",
    validator: unique_email_validator,
  },
  calendly: { 
    label: "Calendly Link",
    inputType: "text",
    validator: basic_text_validator,
  },
}

export const createServiceProviderProfileFormDefinition: FormDefinition = {
  firstName: {
    label: "First Name",
    frLabel: "Prénom",
    inputType: "text",
    validator: basic_text_validator,
  },
  lastName: {
    label: "Last Name",
    frLabel: "Nom de famille",
    inputType: "text",
    validator: basic_text_validator,
  },
  uniqueEmail: {
    label: "Email",
    frLabel: "Courrier",
    inputType: "text",
    validator: unique_email_validator,
  },
  firm: {
    label: "Select Service Provider Firm",
    frLabel: "Sélectionner Organisation/Admin",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "firm1", 
          text: "Firm 1"
        },
        {
          value: "firm2", 
          text: "Firm 2"
        },
        {
          value: "firm3", 
          text: "Firm 3"
        }
      ]
    }
  },
  userRegion: { 
    label: "Select Service Provider Region",
    frLabel: "Sélectionner Organisation/Admin",
    inputType: "dropdownSelect",
    validator: {
      required: {
        value: true,
        message: "Please select an option"
      }
    },
    additionalData: {
      selectionData: [
        {
          value: "communitech", 
          text: "Communitech"
        },
        {
          value: "northForge", 
          text: "North Forge"
        },
        {
          value: "investOttawa", 
          text: "Invest Ottawa"
        }
      ]
    }
  },
}

export const gcRejectFormDefinition: FormDefinition = {
  reasonNote: {
    label: "Please Provide A Reason for Rejecting this Application:",
    inputType: "textarea",
    validator: basic_text_validator,
    tooltip: {
      content: "This note will be visible to the startup",
    },
  }
}

export const customEmailFormDefinition: FormDefinition = {
  customMessage: {
    label: "Please Write Custom Message for Email Body:",
    inputType: "textarea",
    validator: basic_text_validator,
    tooltip: {
      content: "Please note greeting based on company's contact info and thank you/sign off is included in the email template, please only include the body of the email",
    },
  },
  respondTo: {
    label: "If a response is required, please provide an email to which the company may respond.",
    inputType: "text",
    tooltip: {
      content: "If no email is provided the general ElevateIP email is provided to receive response in the automated email.",
      position: "bottom-left"
    },
    validator: {
      ...email_validator,
      required: false
    }
  }
}