import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { addIdAsync } from "../../../services/founderService";
import { useAsync } from "../../../utilities/customHooks";

const LearningPathway = () => {
  const addId = useAsync(() => addIdAsync(), []);

//   if (addId.status === "pending") {
//     return <LoadingIndicator />;
//   }

//   if (addId.status === "rejected") {
//     return <ErrorIndicator />;
//   }
  
  return (
    <div className="resourcesCont !pt-0">
        <h4 className="mb-3 font-semibold">Explore Intellectual Property with the ElevateIP Learning Pathway!</h4>
        <p className="mb-3">Dive into the world of intellectual property through our carefully curated 7-part Learning Pathway. This pathway, including modules from CIPO and CIGI, provides a solid foundation in patents, trademarks, copyrights, and industrial designs, guiding you seamlessly from introductory concepts to an introduction on how to build an IP strategy.</p>
        <p className="mb-3">This learning pathway is essential for anyone looking to build a basic understanding of intellectual property. It's strongly advised for startups as a crucial step before exploring more advanced services. Acquire the knowledge to navigate the ever-changing world of intellectual property and elevate your business to new levels of success.</p>
        <p className="mb-3">To access each module, click on the title:</p>
        <ul>
        <li className="mb-3">
        1. <a className="font-semibold underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/ip-academy/discover-intellectual-property/discover-intellectual-property-bring-your-creations-life-learn-why-intellectual-property-matters" target="_blank">
        Discover IP Basics by CIPO:
        </a>
        <p>Understand the fundamentals of intellectual property and its significance in bringing creative ideas to life.</p>
        </li>

        <li className="mb-3">
        2. <a className="font-semibold underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/patents-learn-basics" target="_blank">
        Patents: Learn the Basics by CIPO:
        </a>
        <p>Gain insights into the basics of patents, including the protection of inventions and the patenting process.</p>
        </li>

        <li className="mb-3">
        3. <a className="font-semibold underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/trademark-learn-basic" target="_blank">
        Trademarks: Learn the Basics by CIPO:
        </a>
        <p>Explore the world of trademarks and learn how they play a crucial role in brand protection.</p>
        </li>

        <li className="mb-3">
        4. <a className="font-semibold underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/copyright-learn-basics" target="_blank">
        Copyrights: Learn the Basics by CIPO:
        </a>
        <p>Discover the basics of copyrights and their role in protecting creative works, from registration to rights enforcement.</p>
        </li>

        <li className="mb-3">
        5. <a className="font-semibold underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/industrial-designs-learn-basics" target="_blank">
        Industrial Designs: Learn the Basics by CIPO:
        </a>
        <p>Explore the protection of product aesthetics through industrial design basics.</p>
        </li>

        <li className="mb-3">
        6. <a className="font-semibold underline" href="https://cigimooc.org/course/view.php?id=6&section=0" target="_blank">
        Foundations of IP Strategy:
        </a>
        <p>Understand the foundational principles of crafting an IP strategy, aligning assets with business objectives.</p>
        </li>

        <li className="mb-3">
        7. <a className="font-semibold underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/plan-success-build-ip-strategy/plan-success-build-ip-strategy" target="_blank">
        Building an IP Strategy by CIPO:
        </a>
        <p>Engage in a learning module on considerations for creating a tailored IP strategy.</p>
        </li>
        </ul>`
    </div>
  )
}

export default LearningPathway