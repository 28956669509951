import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType, LangContext, LangContextType, useRequiredParams } from "../../../utilities/customHooks"
import { useAsync } from "../../../utilities/customHooks";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getMembershipApplicationDetails, getfounderDocuments } from "../../../services/adminService";
import ApproveReject from "../../../Components/Features/ApproveReject"
import * as T from "../../../utilities/frontendTypes";
import FormGroup from "../../../Components/FormGroup";
import { founderProfileEvaluationFormGroupDefinition, updatedFounderProfileEvaluationFormGroupDefinition, updatedFounderProfileReadinessEvaluationFormGroupDefinition } from "../../../utilities/formDefinitionTemplates";
import { convertFounderProfileDBStateToUIState } from "../../../utilities/stateConverters";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle } from "../ProfilePages/NewAdmin";
import textData from "../../../textData.json";
import { useContext, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CompanyNotes from "../../../Components/AdminNotes";
import PdfDownloader from '../../../Components/Form/pdfDisplay';
import Form from "../../../Components/Form/Form";
import { profileFormCompanyInfoDefinition, profileFormPersonalInfoDefinition, profileFormProgramingEvaluationDefinition, profileFormiPStrategyDefinition, profileFormipReadinessDefinition } from "../../../utilities/profileFormDefitions";
import { flattenObject } from "../../../utilities/helperFunctions";
import Modal from "../../../Components/Modal";


function MemberApplicationDetail() {
  // const { langState } = useContext(LangContext) as LangContextType
  const { userRegion } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional
  const langState = "eng"
  const [childClickedAccordionItemIndex, setChildClickedAccordionItemIndex] = useState(0);
  const applicationId = useRequiredParams("applicationId");
  const navigate = useNavigate();
  const [viewPdfName, setViewPdfName] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);

  const membershipApplicationDetailsAsync = useAsync(() => getMembershipApplicationDetails(applicationId), [applicationId]);

  const companyInfoToForm = (info: T.CompanyInfo) => {
    return flattenObject({basicInfo: info.basicInfo, mailingAddress: info.mailingAddress, registration: info.registration})
  }

  const personalFormModifier = () => {
    const formConts = document.getElementsByClassName('formComponentCont');
    if (formConts.length > 0) {
        formConts[0].classList.add('blockFormCont');
    }
    if (formConts.length > 1) {
        formConts[1].classList.add('blockFormCont');
    }
  }

  if (membershipApplicationDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (membershipApplicationDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }


  const applicationDetails: T.ApplicationDetails = membershipApplicationDetailsAsync.value.applicationDetails;
  const founderProfileGroupData = convertFounderProfileDBStateToUIState(applicationDetails.profileSnapshot);
  if (founderProfileGroupData === null) {
    throw new Error ("Founder Profile Does not exist")
  }

  // Check if underrepresentedFounderGroups exists and includes specific values
  const groups = founderProfileGroupData.programingEvaluation.underrepresentedFounderGroups as string[];
  founderProfileGroupData.programingEvaluation.coPayEligible = false;  
  if (groups && (groups.includes("women") || groups && groups.includes("indigenous"))) {
    founderProfileGroupData.programingEvaluation.coPayEligible = true;  
  }

  const viewPdf = (pdfName: string) => {
    setViewPdfName(pdfName)
    setIsModalOpen(true)
  }

  let docHtml = <></>
  if (applicationDetails.profileSnapshot.documents) {
    const docs = applicationDetails.profileSnapshot.documents as {[key: string]: string;}
    if (docs && docs._id) {
      delete docs._id;
    }
    docHtml =
    <div className="w-full flex flex-col">
    {Object.keys(applicationDetails.profileSnapshot.documents).map((key) => (
      <button key={key} className="flex items-center hover:underline" onClick={() => viewPdf(docs[key])}>
        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="13" height="16" viewBox="0 0 13 16" fill="none"><path d="M12.1253 4.96448V4.85029C12.1253 4.85029 12.1253 4.80514 12.1022 4.80514L12.0341 4.73698L6.36849 0.0230173H6.34547C6.34547 0.0230173 6.32245 0.0230174 6.30032 0H2.71948C1.22341 0 0 1.22341 0 2.71948V13.2805C0 14.7766 1.22341 16 2.71948 16H9.51819C11.0143 16 12.2377 14.7766 12.2377 13.2805V5.07693L12.1253 4.96448ZM6.45967 1.31547L10.4256 4.60151H7.13954C6.75445 4.60151 6.45967 4.30673 6.45967 3.92164V1.31547ZM9.40578 14.9811H2.60707C1.61028 14.9811 0.794081 14.1649 0.794081 13.1681V2.60703C0.794081 1.61025 1.61028 0.794045 2.60707 0.794045H5.55318V3.92164C5.55318 4.80511 6.25607 5.508 7.13954 5.508H11.2188V13.1681C11.2188 14.1649 10.4026 14.9811 9.40578 14.9811Z" fill="#1A2E25"/></svg>
        {docs[key]}
      </button>
    ))}
    </div>
  }
console.log(founderProfileGroupData.programingEvaluation)
  return (
    <div className="resourcesCont !pt-0">
      <div className="w-full">
        <div className="">
          <h3>Review Application</h3>
          <h3 className="font-semibold mb-8">{applicationDetails.profileSnapshot.companyInfo?.basicInfo?.operatingName}</h3>
        </div>
          <div className="flex flex-wrap just">
            <div className="inline-flex flex-col applicationFormWidth">
                {
                applicationDetails.flaggedNote && 
                  <div className="flex flex-col text-text applicationFormWidth mb-3 ">      
                    <p>{applicationDetails.flaggedNote}</p>
                  </div>
                }
                <h4 className="mb-6">{textData.Pages.FounderPages.FounderProfile.YourInfo[langState]}</h4>
                <Form
                  formDefinition={profileFormPersonalInfoDefinition}
                  formData={applicationDetails.profileSnapshot.personalInfo}
                  readOnly
                  disabled
                  modifierFunc={personalFormModifier}
                />
                <h4 className="mb-6 mt-8">{textData.Pages.FounderPages.FounderProfile.CompanyInfo[langState]}</h4>
                <Form
                  formDefinition={profileFormCompanyInfoDefinition}
                  formData={applicationDetails.profileSnapshot.companyInfo === null ? null : companyInfoToForm(applicationDetails.profileSnapshot.companyInfo)}
                  readOnly
                />
                <h4 className="mb-6 mt-8">{textData.Pages.FounderPages.FounderProfile.Readiness[langState]}</h4>
                <Form
                  formDefinition={profileFormiPStrategyDefinition}
                  formData={applicationDetails.profileSnapshot.iPStrategy}
                  readOnly
                  disabled
                />
                { applicationDetails.profileSnapshot.ipReadiness &&
                <div>
                  <h4 className="mb-6 mt-8">{textData.Pages.FounderPages.FounderProfile.ServiceEval[langState]}</h4>
                      <Form
                      formDefinition={profileFormipReadinessDefinition}
                      formData={applicationDetails.profileSnapshot.ipReadiness}
                      readOnly
                      disabled
                   />
                </div>
                } 
                <h4 className="mb-6 mt-8">{textData.Pages.FounderPages.FounderProfile.ServiceEval[langState]}</h4>
                <Form
                  formDefinition={profileFormProgramingEvaluationDefinition}
                  formData={founderProfileGroupData.programingEvaluation}
                  readOnly
                  disabled
                />
              </div>
              
              <div className="mb-6">
                <div className="appDetailsScoringCont">
                  {applicationDetails.ipPreScore && applicationDetails.applicationScore &&          
                  <div>
                    <h4 className="mb-2">Scoring: {applicationDetails.ipPreScore?.totalScore + applicationDetails.applicationScore?.totalScore}</h4>
                    <div className="flex flew-wrap w-full">
                      <div className="flex flex-col text-text w-1/2 mb-3">
                        <p className="bodyLarger">Persona Score: {applicationDetails.applicationScore?.totalScore}</p>
                        <ul className="small">
                          <li>Classification: {applicationDetails.applicationScore?.classification}</li>
                          <li>Age Score: {applicationDetails.applicationScore?.age}</li>
                          <li>Revenue Score: {applicationDetails.applicationScore?.revenue}</li>
                          <li>Employee Score: {applicationDetails.applicationScore?.fte}</li>
                        </ul>
                      </div>            
                      <div className="flex flex-col text-text w-1/2 mb-3">
                        <p className="bodyLarger">IP PreScore: {applicationDetails.ipPreScore?.totalScore}</p>
                        <ul className="small">
                          <li>IP Readiness - Patents: {applicationDetails.ipPreScore?.ipReadinessPatents}</li>
                          <li>IP Readiness - General: {applicationDetails.ipPreScore?.ipReadinessGeneral}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  }
                   <div className="flex flex-col w-full">
                      <p className="bodyLarger font-semibold w-full">Files</p>
                      {docHtml}
                    </div>
                    <ApproveReject 
                      applicationId={applicationId}
                      applicationType="membership"
                      navigateUrl={`/dashboard/memberships/${applicationDetails.userId}`}
                      // requireTierAssignment={true}
                      applicantName={`${applicationDetails.profileSnapshot.personalInfo?.firstName} ${applicationDetails.profileSnapshot.personalInfo?.firstName}`}
                      companyName={`${applicationDetails.profileSnapshot.companyInfo?.basicInfo?.operatingName}`}
                    />   
                </div>
              </div>
            </div>
          </div>
          <Modal 
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            modalHeader={viewPdfName}
            contentLabel={viewPdfName} // [TODO]
            fullWidth={true}
            modalHTML={
              <div className="flex w-full min-w-full">
                <PdfDownloader docKey={viewPdfName}/>
              </div>
            }
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                minWidth: '300px',
                width: 'full',
                background: "background",
              }
            }}
          />
      </div>
  )
}

export default MemberApplicationDetail