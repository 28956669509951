import { ReactNode} from "react";
import { useLoading, LoadingContext } from "./customHooks"

export function LoadingContextProvider({ children }: { children: ReactNode }) {
  const loading = useLoading();
  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  );
}