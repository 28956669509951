import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { devOnlyDbFixes, getCoachInProgressDetailsAsync, growthCoachOutcomeUpdate } from "../../../services/adminService";
import {  OutletContextType, useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"
import List from "../../../Components/List";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const CoachInProgressAdmin = () => {
  const [reloadOnOutletSave, setReloadOnOutletSave] = useState(false);
  const navigate = useNavigate();
  const inProgressFoundersAsync = useAsync(() => getCoachInProgressDetailsAsync(), [reloadOnOutletSave]);

  if (inProgressFoundersAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (inProgressFoundersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const inProgressFoundersArray = inProgressFoundersAsync.value.inProgressFoundersArray

  const columnHeaderData = [
    {
      header: "Company Name",
      key: "companyName"
    },
    {
      header: "Invite Date",
      key: "date"
    }
  ]

  const handleViewFounderDetails = (id: string) => {
    navigate(`/dashboard/admin-coach-in-progress/${id}`);
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={"Companies are awaiting Growth Coach Assessment"}
          // tooltip={"This is a list of all the companies who have met with a Growth Coach"}
        />
        <List 
          contentArray={inProgressFoundersArray}
          columnHeaderData={columnHeaderData}
          returnDetailsId={handleViewFounderDetails}
          detailsIdName="id"
        />
      </div>
      <Outlet context={ { reloadOnOutletSave, setReloadOnOutletSave } satisfies OutletContextType} />
    </div>  
  )
}

export default CoachInProgressAdmin