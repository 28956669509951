import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getInDdFoundersAsync } from "../../../services/adminService";
import {  OutletContextType, useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"
import List from "../../../Components/List";
import { Outlet, useNavigate } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import { useState } from "react";


const DueDiligenceAdmin = () => {
  const [reloadOnOutletSave, setReloadOnOutletSave] = useState(false);
  const navigate = useNavigate();
  const inDdFoundersAsync = useAsync(() => getInDdFoundersAsync(), [reloadOnOutletSave]);
  const handleViewFounderDetails = (id: T.UserId) => {
    navigate(`/dashboard/admin-dd-application/${id}`);
  }
  if (inDdFoundersAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (inDdFoundersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const inDdFoundersArray = inDdFoundersAsync.value.inDdFoundersArray

  const columnHeaderData = [
    {
      header: "Company Name",
      key: "companyName"
    }
  ]

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={"Companies Flagged for further Due Dilligence"}
          tooltip={"This is a list of all the companies who have been flagged for due diligence"}
        />
        <List 
          contentArray={inDdFoundersArray}
          columnHeaderData={columnHeaderData}
          detailsIdName="id"
          returnDetailsId={handleViewFounderDetails}
        />
      </div>
      <Outlet context={ { reloadOnOutletSave, setReloadOnOutletSave } satisfies OutletContextType} />
    </div>  
  )
}

export default DueDiligenceAdmin