import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType, useAsync } from "../../utilities/customHooks";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import { getExpertAssesmentStats } from "../../services/ipExpertService";
import { useContext } from "react";
import List from "../../Components/List";
import * as T from "../../utilities/frontendTypes";


const IpExpertDashboard = () => {

  const navigate = useNavigate();
  const columnHeaderData = [
    {
      header: "Startup",
      key: "companyName"
    },
    {
      header: "Status",
      key: "actionItem",
    },
    {
      header: "Invite Sent",
      key: "inviteSent"
    },
    {
      header: "Contact",
      key: "contactPerson"
    }
  ]

  const founderDetailsAsync = useAsync(() => getExpertAssesmentStats(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const handleViewMemberDetails = (memberHeaderUserId: string) => {
    navigate((`/dashboard/expert-assessments/${memberHeaderUserId}`));
  }

  const contentArray = founderDetailsAsync.value.foundersArray.filter(item => item.actionItem === "New Assessment Request");

  return (
    <div className="resourcesCont !pt-0">
      <div>
        <h1 className="">New Requests</h1>
        <p className="bodyLarger mb-2">The following startups have been emailed an invitation to meet with you.</p>
        <p className="bodyLarger">Click on the respective row to learn more and begin your assessment.</p>
      </div>      
      <List 
        contentArray={contentArray}
        columnHeaderData={columnHeaderData}
        returnDetailsId={handleViewMemberDetails}
        detailsIdName="memberId"
        isReverse
      />
    </div>
  )
}

export default IpExpertDashboard