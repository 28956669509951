import Form, { FormData } from '../Components/Form/Form';
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from '../utilities/customHooks';
import * as T from '../utilities/frontendTypes';
import { Navigate, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { loginFormDefinition } from '../utilities/formDefinitionTemplates';
import LoadingIndicator from '../Components/LoadingIndicator';
import Button, { ButtonProp } from '../Components/Button';
import textData from "../textData.json";
import { returnLangCheckedFormDef } from '../utilities/helperFunctions';
import leaf from '../assets/Group.png';
import ioLight from '../assets/io-light.png'
import nfLight from '../assets/NFColor+White.png'


function Login() {
  const { langState, setLang } = useContext(LangContext) as LangContextType
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const {showToast} = (useContext(ToastContext) as ToastContextType);
  const navigate = useNavigate();
  const { isAuthenticated, userRole, login } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional

  const handleLangToggle = async () => {
    if (langState === "eng") {
      setLang("fr")
    }
    if (langState === "fr") {
      setLang("eng")
    }
  }

  const handleLogin = async (updatedForm: FormData) => {
    const loginInfo: T.LoginInfo = {
      email: (updatedForm['email'] as string),
      password: updatedForm['password'] as string,
    }
    showLoading();
    const success = await login(loginInfo)
    hideLoading();
    if (success) {
      if (userRole === null) {
        return <LoadingIndicator />;
      }
      if (userRole && userRole === "tempAdmin") {
        navigate("/dashboard/admin-verification");
      }

      if (userRole && userRole === "tempExpert") {
        navigate("/dashboard/expert-verification");
      } 

      if (userRole && userRole === "tempProvider") {
        navigate("/dashboard/provider-verification");
      }
        navigate("/dashboard");
    } else {
      showToast({
        toastType: "failure",
        message: textData.Pages.Login.Invalid[langState],
      });
    };
  }

  if (isAuthenticated) {
    if (userRole === null) {
      return <LoadingIndicator />;
    }
    if (userRole === "tempAdmin") {
      return <Navigate to={'/dashboard/admin-verification'} />
    } else {
      return <Navigate to={'/dashboard'} />
    }
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.Login.Login[langState],
    actionHandler: handleLogin,
  }

  return (
    <div className='flex flex-col items-center w-full'>
      <div className='w-full flex'>
        <div className='flex flex-col w-1/3 oneThree'>
          <div className='bg-cGreen flex w-full'> 
            <div className='registerCont1 flex flex-col align-left !justify-start'> 
              <button onClick={() => navigate("/")}className='font-semibold my-8 h2 text-left headerRegisterEip'>{textData.Pages.LandingPage.pages.index.EIP[langState]}</button>
              <div className='flex flex-col my-8 h-full'>
                <h1 className='w-full'>{textData.Pages.Login.Title[langState]}<br></br>{textData.Pages.Login.Title2[langState]}</h1>
                <div className='flex flex-col'>
                  <div className='flex items-center py-4 w-full'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none" className='shrink-0'>
                    <path d="M26.6561 9.82148C26.4571 9.44087 26.6051 8.97046 26.9857 8.77148C27.3673 8.5725 27.8367 8.72046 28.0357 9.10107C28.5857 10.1501 29.0143 11.2694 29.302 12.4409C29.5827 13.5847 29.7327 14.7756 29.7327 15.9949C29.7327 20.1001 28.0684 23.8164 25.3786 26.5072C22.6878 29.198 18.9714 30.8613 14.8663 30.8613C10.7612 30.8613 7.0449 29.197 4.35408 26.5072C1.66327 23.8164 0 20.1001 0 15.9949C0 11.8898 1.66429 8.17352 4.35408 5.4827C7.0449 2.79189 10.7612 1.12862 14.8663 1.12862C16.1459 1.12862 17.3959 1.29291 18.5949 1.60311C19.8245 1.92046 20.9929 2.39189 22.0776 2.99495C22.4541 3.20413 22.5888 3.67862 22.3796 4.05413C22.1704 4.42964 21.6959 4.56536 21.3204 4.35617C20.35 3.81638 19.3041 3.39495 18.2041 3.11026C17.1449 2.83577 16.0265 2.69087 14.8673 2.69087C11.1939 2.69087 7.86735 4.17964 5.4602 6.58781C3.05306 8.99495 1.56327 12.3215 1.56327 15.9949C1.56327 19.6684 3.05204 22.9949 5.4602 25.4021C7.86735 27.8092 11.1939 29.299 14.8673 29.299C18.5408 29.299 21.8673 27.8103 24.2745 25.4021C26.6816 22.9949 28.1714 19.6684 28.1714 15.9949C28.1714 14.8929 28.0388 13.8266 27.7898 12.8123C27.5306 11.7572 27.1469 10.7541 26.6582 9.82046L26.6561 9.82148ZM7.07041 15.649C8.69694 17.2541 10.8082 19.5266 12.7663 22.2643C13.2173 22.896 13.9041 23.2245 14.6786 23.1807C15.4531 23.1368 16.098 22.7327 16.4755 22.0552C19.7551 16.1623 26.9531 3.97964 32 0.930664C32 0.930664 23.0857 2.25311 14.4663 17.3898L10.2673 12.6276C8.27755 10.3715 4.92959 13.5368 7.07041 15.6501V15.649Z" fill="#4F9D7A"/>
                    </svg>
                    <span className='bodyLarger pl-2'>{textData.Pages.Register.Free[langState]}</span >
                  </div>
                  <div className='flex items-center w-full'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none" className='shrink-0'>
                    <path d="M26.6561 9.82148C26.4571 9.44087 26.6051 8.97046 26.9857 8.77148C27.3673 8.5725 27.8367 8.72046 28.0357 9.10107C28.5857 10.1501 29.0143 11.2694 29.302 12.4409C29.5827 13.5847 29.7327 14.7756 29.7327 15.9949C29.7327 20.1001 28.0684 23.8164 25.3786 26.5072C22.6878 29.198 18.9714 30.8613 14.8663 30.8613C10.7612 30.8613 7.0449 29.197 4.35408 26.5072C1.66327 23.8164 0 20.1001 0 15.9949C0 11.8898 1.66429 8.17352 4.35408 5.4827C7.0449 2.79189 10.7612 1.12862 14.8663 1.12862C16.1459 1.12862 17.3959 1.29291 18.5949 1.60311C19.8245 1.92046 20.9929 2.39189 22.0776 2.99495C22.4541 3.20413 22.5888 3.67862 22.3796 4.05413C22.1704 4.42964 21.6959 4.56536 21.3204 4.35617C20.35 3.81638 19.3041 3.39495 18.2041 3.11026C17.1449 2.83577 16.0265 2.69087 14.8673 2.69087C11.1939 2.69087 7.86735 4.17964 5.4602 6.58781C3.05306 8.99495 1.56327 12.3215 1.56327 15.9949C1.56327 19.6684 3.05204 22.9949 5.4602 25.4021C7.86735 27.8092 11.1939 29.299 14.8673 29.299C18.5408 29.299 21.8673 27.8103 24.2745 25.4021C26.6816 22.9949 28.1714 19.6684 28.1714 15.9949C28.1714 14.8929 28.0388 13.8266 27.7898 12.8123C27.5306 11.7572 27.1469 10.7541 26.6582 9.82046L26.6561 9.82148ZM7.07041 15.649C8.69694 17.2541 10.8082 19.5266 12.7663 22.2643C13.2173 22.896 13.9041 23.2245 14.6786 23.1807C15.4531 23.1368 16.098 22.7327 16.4755 22.0552C19.7551 16.1623 26.9531 3.97964 32 0.930664C32 0.930664 23.0857 2.25311 14.4663 17.3898L10.2673 12.6276C8.27755 10.3715 4.92959 13.5368 7.07041 15.6501V15.649Z" fill="#4F9D7A"/>
                    </svg>
                    <span className='bodyLarger pl-2'>{textData.Pages.Register.Apply[langState]}</span >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='loginCont2 flex justify-end'> 
            <div className='reg-logos'>
              <a href='https://communitech.ca/' target="_blank" className='footerCTLogoReg flex '>
                <img
                  className="ctLogoLeafReg"
                  src={leaf}
                  alt="communitech Logo leaf"
                />
                <svg width="94" height="10" viewBox="0 0 94 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Group">
                    <path id="Vector" d="M0.671875 4.87725C0.671875 2.42665 2.33567 0.717773 4.65392 0.717773C5.6487 0.717773 6.55622 1.01883 7.44193 1.62093C7.46665 1.63693 7.47974 1.66747 7.47683 1.69656L7.31685 2.94586H7.26159C6.48496 2.28121 5.60943 1.92635 4.72227 1.92635C3.13702 1.92635 2.04916 3.11311 2.04916 4.85398C2.04916 6.60648 3.1472 7.80488 4.75572 7.80488C5.64288 7.80488 6.51986 7.43838 7.29504 6.77374H7.3503L7.51028 8.02449C7.51465 8.05357 7.50156 8.08266 7.47683 8.10011C6.61149 8.70222 5.67051 9.01491 4.66555 9.01491C2.31385 9.01345 0.671875 7.32785 0.671875 4.87725Z" fill="white"/>
                    <path id="Vector_2" d="M8.78906 4.86561C8.78906 2.51391 10.3976 0.717773 12.8482 0.717773C15.3104 0.717773 16.9189 2.51391 16.9189 4.86561C16.9189 7.21731 15.277 9.01345 12.8482 9.01345C10.4194 9.01345 8.78906 7.21731 8.78906 4.86561ZM15.5548 4.86561C15.5548 3.18001 14.512 1.92635 12.8482 1.92635C11.1844 1.92635 10.1533 3.18001 10.1533 4.86561C10.1533 6.5294 11.196 7.80488 12.8482 7.80488C14.5003 7.80488 15.5548 6.5294 15.5548 4.86561Z" fill="white"/>
                    <path id="Vector_3" d="M18.7578 8.71399V1.01751C18.7578 0.975335 18.7927 0.94043 18.8349 0.94043H20.3518C20.3809 0.94043 20.4071 0.956428 20.4201 0.982606L22.8504 5.84163H22.8838L25.3024 0.982606C25.3155 0.956428 25.3417 0.94043 25.3723 0.94043H26.8775C26.9197 0.94043 26.9546 0.975335 26.9546 1.01751V8.71545C26.9546 8.75762 26.9197 8.79253 26.8775 8.79253H25.778C25.7358 8.79253 25.7009 8.75762 25.7009 8.71545V4.95447C25.7009 4.16766 25.7126 3.36776 25.7228 2.58095H25.6893L24.7687 4.40036L22.9493 7.949H22.75L20.9539 4.40036C20.6427 3.78952 20.3329 3.19178 20.0333 2.58095H19.9998C20.0115 3.36776 20.0115 4.1662 20.0115 4.95447V8.71399C20.0115 8.75617 19.9766 8.79107 19.9344 8.79107H18.8363C18.7927 8.79253 18.7578 8.75762 18.7578 8.71399Z" fill="white"/>
                    <path id="Vector_4" d="M29.2188 8.71399V1.01751C29.2188 0.975335 29.2537 0.94043 29.2958 0.94043H30.8127C30.8418 0.94043 30.868 0.956428 30.8811 0.982606L33.3113 5.84163H33.3448L35.7634 0.982606C35.7765 0.956428 35.8026 0.94043 35.8332 0.94043H37.3385C37.3806 0.94043 37.4155 0.975335 37.4155 1.01751V8.71545C37.4155 8.75762 37.3806 8.79253 37.3385 8.79253H36.239C36.1968 8.79253 36.1619 8.75762 36.1619 8.71545V4.95447C36.1619 4.16766 36.1735 3.36776 36.1837 2.58095H36.1502L35.2311 4.40036L33.4117 7.949H33.2124L31.4148 4.40036C31.1036 3.78952 30.7938 3.19178 30.4942 2.58095H30.4608C30.4724 3.36776 30.4724 4.1662 30.4724 4.95447V8.71399C30.4724 8.75617 30.4375 8.79107 30.3953 8.79107H29.2973C29.2537 8.79253 29.2188 8.75762 29.2188 8.71399Z" fill="white"/>
                    <path id="Vector_5" d="M39.6094 5.63075V1.01751C39.6094 0.975335 39.6443 0.94043 39.6865 0.94043H40.8078C40.8499 0.94043 40.8848 0.975335 40.8848 1.01751V5.54348C40.8848 7.11856 41.4724 7.81665 42.7813 7.81665C44.0786 7.81665 44.6662 7.11856 44.6662 5.53185V1.01751C44.6662 0.975335 44.7011 0.94043 44.7433 0.94043H45.8646C45.9068 0.94043 45.9417 0.975335 45.9417 1.01751V5.60893C45.9417 7.7832 44.8654 9.01359 42.7813 9.01359C40.6187 9.01359 39.6094 7.84865 39.6094 5.63075Z" fill="white"/>
                    <path id="Vector_6" d="M48.1367 8.71399V1.01751C48.1367 0.975335 48.1716 0.94043 48.2138 0.94043H49.1431C49.1664 0.94043 49.1868 0.950611 49.2013 0.968063L52.2613 4.56761C52.7267 5.13335 53.1935 5.6991 53.7026 6.36374H53.736C53.6909 5.6991 53.6807 5.07663 53.6807 4.30146V1.01751C53.6807 0.975335 53.7156 0.94043 53.7578 0.94043H54.8675C54.9097 0.94043 54.9446 0.975335 54.9446 1.01751V8.83761C54.9446 8.87979 54.9097 8.9147 54.8675 8.9147H54.2698C54.2465 8.9147 54.2261 8.90451 54.2116 8.88706L50.8302 4.93265C50.3648 4.38872 49.898 3.82297 49.3773 3.1467H49.3555C49.4006 3.82297 49.4006 4.4658 49.4006 5.23225V8.71545C49.4006 8.75762 49.3657 8.79253 49.3235 8.79253H48.2138C48.1716 8.79253 48.1367 8.75762 48.1367 8.71399Z" fill="white"/>
                    <path id="Vector_7" d="M57.2841 0.939453H58.4054C58.4476 0.939453 58.4825 0.974358 58.4825 1.01653V8.71447C58.4825 8.75665 58.4476 8.79155 58.4054 8.79155H57.2841C57.2419 8.79155 57.207 8.75665 57.207 8.71447V1.01799C57.207 0.974358 57.2419 0.939453 57.2841 0.939453Z" fill="white"/>
                    <path id="Vector_8" d="M66.2134 1.01756V2.03852C66.2134 2.08069 66.1785 2.1156 66.1363 2.1156H63.7512C63.709 2.1156 63.6741 2.1505 63.6741 2.19268V8.71549C63.6741 8.75767 63.6392 8.79257 63.597 8.79257H62.4757C62.4335 8.79257 62.3986 8.75767 62.3986 8.71549V2.19268C62.3986 2.1505 62.3637 2.1156 62.3215 2.1156H59.9247C59.8826 2.1156 59.8477 2.08069 59.8477 2.03852V1.01756C59.8477 0.975379 59.8826 0.940474 59.9247 0.940474H66.1363C66.1785 0.939019 66.2134 0.973924 66.2134 1.01756Z" fill="white"/>
                    <path id="Vector_9" d="M68.8497 5.37478V7.5825C68.8497 7.62467 68.8846 7.65958 68.9268 7.65958H72.2791C72.3256 7.65958 72.3605 7.7003 72.3562 7.74539L72.2413 8.72272C72.2369 8.76199 72.2035 8.79107 72.1642 8.79107H67.6513C67.6091 8.79107 67.5742 8.75617 67.5742 8.71399V1.01751C67.5742 0.975335 67.6091 0.94043 67.6513 0.94043H72.1642C72.2035 0.94043 72.2369 0.969517 72.2413 1.00878L72.3562 1.98612C72.362 2.03266 72.3256 2.07192 72.2791 2.07192H68.9268C68.8846 2.07192 68.8497 2.10683 68.8497 2.149V4.17929C68.8497 4.22147 68.8846 4.25637 68.9268 4.25637H71.9882C72.0304 4.25637 72.0653 4.29128 72.0653 4.33346V5.22207C72.0653 5.26425 72.0304 5.29915 71.9882 5.29915H68.9268C68.8846 5.2977 68.8497 5.3326 68.8497 5.37478Z" fill="white"/>
                    <path id="Vector_10" d="M73.7539 4.87725C73.7539 2.42665 75.4177 0.717773 77.7359 0.717773C78.7307 0.717773 79.6383 1.01883 80.524 1.62093C80.5487 1.63693 80.5618 1.66747 80.5589 1.69656L80.3989 2.94586H80.3436C79.567 2.28121 78.6915 1.92635 77.8043 1.92635C76.219 1.92635 75.1312 3.11311 75.1312 4.85398C75.1312 6.60648 76.2292 7.80488 77.8377 7.80488C78.7249 7.80488 79.6019 7.43838 80.3771 6.77374H80.4323L80.5923 8.02449C80.5967 8.05357 80.5836 8.08266 80.5589 8.10011C79.6935 8.70222 78.7525 9.01491 77.7476 9.01491C75.3959 9.01345 73.7539 7.32785 73.7539 4.87725Z" fill="white"/>
                    <path id="Vector_11" d="M87.924 0.939453H89.0453C89.0875 0.939453 89.1224 0.974358 89.1224 1.01653V8.71447C89.1224 8.75665 89.0875 8.79155 89.0453 8.79155H87.924C87.8818 8.79155 87.8469 8.75665 87.8469 8.71447V5.46397C87.8469 5.42179 87.812 5.38689 87.7699 5.38689H84.0205C83.9783 5.38689 83.9434 5.42179 83.9434 5.46397V8.71447C83.9434 8.75665 83.9085 8.79155 83.8664 8.79155H82.745C82.7029 8.79155 82.668 8.75665 82.668 8.71447V1.01799C82.668 0.975812 82.7029 0.940907 82.745 0.940907H83.8664C83.9085 0.940907 83.9434 0.975812 83.9434 1.01799V4.16814C83.9434 4.21031 83.9783 4.24522 84.0205 4.24522H87.7699C87.812 4.24522 87.8469 4.21031 87.8469 4.16814V1.01799C87.8469 0.974358 87.8818 0.939453 87.924 0.939453Z" fill="white"/>
                    <path id="Vector_12" d="M90.8867 2.4069C90.8867 1.56047 91.5135 0.939453 92.3353 0.939453C93.157 0.939453 93.7838 1.56047 93.7838 2.4069C93.7838 3.25334 93.157 3.86127 92.3353 3.86127C91.5135 3.86127 90.8867 3.25334 90.8867 2.4069ZM93.5235 2.4069C93.5235 1.69572 93.0188 1.18524 92.3338 1.18524C91.6488 1.18524 91.1441 1.69572 91.1441 2.4069C91.1441 3.105 91.6488 3.62857 92.3338 3.62857C93.0188 3.62857 93.5235 3.105 93.5235 2.4069ZM91.8102 3.18935V1.69572C91.8102 1.65354 91.8451 1.61864 91.8873 1.61864H92.3469C92.7148 1.61864 92.9155 1.77426 92.9155 2.10294C92.9155 2.27165 92.8428 2.404 92.7076 2.48398C92.6669 2.50871 92.6509 2.55816 92.677 2.59742L93.0712 3.1559L93.0581 3.1879H92.709L92.296 2.57416H92.1404V3.1879H91.8102V3.18935ZM92.1404 2.31092H92.3091C92.4967 2.31092 92.5941 2.25274 92.5941 2.09712C92.5941 1.94151 92.4967 1.88333 92.3091 1.88333H92.1404V2.31092Z" fill="white"/>
                  </g>
                </svg>
                </a>
                <a href='https://www.investottawa.ca/' target="_blank" className="ioLogoRegister">
                  <img
                    src={ioLight}
                    alt="Invest Ottawa Logo"
                  />
                </a>
                <a href='https://northforge.ca/' target="_blank" className="nfLightRegister">
                  <img
                      src={nfLight}
                      alt="North Forge Logo"
                    />
                </a>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-start items-center w-2/3 twoThree'>
          <header className='w-full py-8 px-16 headerRegister'>
            <div className='w-full flex justify-end'>
              <button className='flex bodyLarger buttonSizer' onClick={() => handleLangToggle()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" className='mr-2 relative top-[1px]'>
                  <circle cx="8" cy="8.5" r="7.5" stroke="#000000"/>
                  <path d="M10.5 8.5C10.5 10.6651 10.1701 12.6029 9.65316 13.9813C9.39401 14.6723 9.0975 15.1969 8.79361 15.5396C8.4892 15.883 8.21987 16 8 16C7.78013 16 7.5108 15.883 7.20639 15.5396C6.9025 15.1969 6.60599 14.6723 6.34684 13.9813C5.82994 12.6029 5.5 10.6651 5.5 8.5C5.5 6.33494 5.82994 4.39713 6.34684 3.01871C6.60599 2.32765 6.9025 1.80314 7.20639 1.46038C7.5108 1.11704 7.78013 1 8 1C8.21987 1 8.4892 1.11704 8.79361 1.46038C9.0975 1.80314 9.39401 2.32765 9.65316 3.01871C10.1701 4.39713 10.5 6.33494 10.5 8.5Z" stroke="#000000"/>
                  <path d="M0.5 8.5H15.5" stroke="#000000" strokeLinecap="round"/>
                </svg>
                  <span className='relative'>{langState === "eng" ? "ENG" : "FR"}</span>
              </button>
              <Button
                buttonText={textData.Pages.LandingPage.pages.index.Register[langState]}
                buttonType='primary'
                actionHandler={() => navigate("/register")}
              />
            </div>
          </header>
          <header className='w-full py-8 px-16 headerRegister mobileHeaderRegister'>
            <div className='w-full flex justify-between'>
            <button onClick={() => navigate("/")}className='font-semibold my-8 h2 text-left headerRegisterEip'>{textData.Pages.LandingPage.pages.index.EIP[langState]}</button>
              <div className='flex items-center'>
                <button className='flex bodyLarger buttonSizer items-center' onClick={() => handleLangToggle()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" className='mr-2 relative top-[1px]'>
                    <circle cx="8" cy="8.5" r="7.5" stroke="#000000"/>
                    <path d="M10.5 8.5C10.5 10.6651 10.1701 12.6029 9.65316 13.9813C9.39401 14.6723 9.0975 15.1969 8.79361 15.5396C8.4892 15.883 8.21987 16 8 16C7.78013 16 7.5108 15.883 7.20639 15.5396C6.9025 15.1969 6.60599 14.6723 6.34684 13.9813C5.82994 12.6029 5.5 10.6651 5.5 8.5C5.5 6.33494 5.82994 4.39713 6.34684 3.01871C6.60599 2.32765 6.9025 1.80314 7.20639 1.46038C7.5108 1.11704 7.78013 1 8 1C8.21987 1 8.4892 1.11704 8.79361 1.46038C9.0975 1.80314 9.39401 2.32765 9.65316 3.01871C10.1701 4.39713 10.5 6.33494 10.5 8.5Z" stroke="#000000"/>
                    <path d="M0.5 8.5H15.5" stroke="#000000" strokeLinecap="round"/>
                  </svg>
                    <span className='relative'>{langState === "eng" ? "ENG" : "FR"}</span>
                </button>
                <Button
                  buttonText={textData.Pages.LandingPage.pages.index.Register[langState]}
                  buttonType='primary'
                  actionHandler={() => navigate("/register")}
                />
              </div>
            </div>
          </header>
            <div className='loginFormCont'>
            <h1 className='text-cGreen'>{textData.Pages.Login.Title[langState]}</h1>
            <Form 
              formDefinition={returnLangCheckedFormDef(loginFormDefinition, langState)} 
              formData={null}
              primaryButtonProp={primaryButtonProp}
              buttonFullWidth={true}
            />
            <div className='w-full text-center'>
              <button className='bodyLarger underline text-black'
                onClick={() => {navigate("/forgot-password")}}
            >
              {textData.Pages.Login.Forgot[langState]}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Login;
