import { useContext, useEffect, useState } from "react";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getUninvitedDetailsAsync, sendMassEmail, massStatusUpdate, sendCustomEmail } from "../../../services/adminService";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"
import Button, { ButtonProp } from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import { useNavigate } from "react-router-dom";
import List from "../../../Components/List";
import { dropdownArrowClasses } from "../../../Components/Form/DropdownSingleSelect";
import { inputClasses } from "../../../Components/Form/InputField";
import Form from "../../../Components/Form/Form";
import { customEmailFormDefinition } from "../../../utilities/formDefinitionTemplates";


const PreInvite = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [personaMin, setPersonaMin] = useState<number>(2)
  const [confirmUsers, setConfirmUsers] = useState(false);
  const [selectExpert, setSelectExpert] = useState(false);
  const [expertName, setExpertName] = useState("")
  const [typeEmail, setTypeEmail] = useState(false)
  const [reloadOnSave, setReloadOnSave] = useState(false)

  const uninvitedFoundersAsync = useAsync(() => getUninvitedDetailsAsync(), [reloadOnSave]);

  if (uninvitedFoundersAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (uninvitedFoundersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const uninvitedFoundersArray = (uninvitedFoundersAsync.value.uninvitedFoundersArray)
  const expertsArray = (uninvitedFoundersAsync.value.expertsArray)

  const columnHeaderData = [
    {
      header: "Company",
      key: "companyName",
    },
    {
      header: "Persona Score",
      key: "personaScore",
    }
  ];

  const emailArrayChange = (array: string[]) => {
    let ids: string[] = []
    array.forEach(string => {
      uninvitedFoundersArray.forEach(obj => {
        if (obj.companyName === string) {
          ids.push(obj.userId);
        }
      });
    });
    setSelectedUsers(ids)
  }

  const handleConfirmation = async (emailType: string, expertName?: string) => {
    let expertId = ""
    if (expertName) {
      const expert = expertsArray.find((expert) => expert.expertName === expertName);
      if (expert) {
        expertId = expert.id
      }
    }
    showLoading();
    const res = expertName ? await sendMassEmail(selectedUsers, emailType, expertName, expertId) : await sendMassEmail(selectedUsers, emailType);
    hideLoading();
    if (res.success) {
      setSelectedUsers([])
      setReloadOnSave(!reloadOnSave)
      setIsModalOpen(false)
      showToast({
        toastType: "success",
        message: "Invitation Successfully Sent!",
      });
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to send invitation, please try again.  If Error persists please conteact support",
      });
    }
  }
  
  const handleDropdownChange = (e: any) => {
    setExpertName(e.target.value);
  };

  const handleMassUpdate = async () => {
    showLoading();
    const res = await massStatusUpdate(selectedUsers, "growthCoachInProgress")
    hideLoading();
    if (res.success) {
      setIsModalOpen(false);
      setSelectedUsers([])
      setReloadOnSave(!reloadOnSave)
      showToast({
        toastType: "success",
        message: "Statuses Updated",
      });
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to update Statuses, lease try again.  If Error persists please conteact support",
      });
    }
  }

  const handleCustomEmail = async (tempupdatedForm: FormData) => {
    const tempMessage = tempupdatedForm as any
    const respondTo = tempMessage.respondTo
    const messageString = tempMessage.customMessage

    showLoading();
    const response = await sendCustomEmail(messageString, selectedUsers, respondTo);
    hideLoading();

    if (!response) {
      setIsModalOpen(false);
      setTypeEmail(false)
      showToast({
        toastType: "failure",
        message: "Unable to send Custom Email.  Please try again and if issue persists contact support.",
        autoDisappearTimeInSeconds: 8,
      })
    } else {
      setIsModalOpen(false);
      setTypeEmail(false)
      setSelectedUsers([])
      showToast({
        toastType: "success",
        message: "Custom Email Sent!",
        autoDisappearTimeInSeconds: 8,
      });
    }
  }

  const customEmailPrimaryButtonProp: ButtonProp = {
    buttonText: "Send Custom Email",
    actionHandler: handleCustomEmail
  }

  const emailModalHtml = 
    <div>
      <Form
        formDefinition={customEmailFormDefinition}
        primaryButtonProp={customEmailPrimaryButtonProp}
        formData={null}
      />
    </div>

  const modalHTML = 
    selectExpert ?
    <div className="flex flex-col justify-center items-center">
      <div className="relative mb-5 mt-5 w-full">
        <select 
          id="dropdown" 
          value={expertName} 
          onChange={handleDropdownChange}
          className={inputClasses}
        >
          <option value="" disabled>Select an IP Expert:</option>
          {expertsArray.map((expert) => (
          <option key={expert.id} value={expert.expertName}>
            {expert.expertName}
          </option>
          ))}

        </select>
        <span className={dropdownArrowClasses}></span>
      </div>
      <Button
        buttonText="IP Expert Invite"
        actionHandler={() => handleConfirmation("expert", expertName)}
        disabled={expertName === "" ? true : false}
      />
    </div>
    :
    <div className="flex flex-col justify-center items-center massEmailModal gap-4">
      <div className="">
        <Button
          buttonText="IP Expert Invite"
          actionHandler={() => setSelectExpert(true)}
          buttonType="primaryFull"
        />
      </div>
      <div className="">
        <Button
          buttonText="Growth Coach Review"
          actionHandler={() => handleMassUpdate()}
          buttonType="primaryFull"
        />
      </div>
      <div className="">
        <Button
          buttonText="Custom Email"
          actionHandler={() => setTypeEmail(true)}
          buttonType="primaryFull"
        />
      </div>
    </div>
  
    

// LIST OF REMOVALABLE USERS TO CONFIRM
// confirmUsers ?
//   <div className="flex flex-col justify-center items-center">
//   {selectedUsers.map(userId => {
//     const matchingObject = uninvitedFoundersArray.find(obj => obj.userId === userId);
//     if (matchingObject) {
//       let removedUser = [...selectedUsers]
//       removedUser.filter(user => user !== matchingObject.userId)
//     return (
//       <li key={matchingObject.userId}>
//         <button onClick={() => {setSelectedUsers(removedUser)}}
//           className="text-danger pl-2">Remove</button>
//         {matchingObject.companyName}
//       </li>
//       );
//     }
// })}
//   <Button
//     buttonText="Confirm"
//     actionHandler={() => setConfirmUsers(true)}
//   />      
// </div>
  const modalClose = () => {
    setIsModalOpen(false)
    setSelectExpert(false)
    setTypeEmail(false)
  }

  const handleChange = (event: { target: { value: any; }; }) => {
    const inputValue = event.target.value;
    setPersonaMin(inputValue)
  };

  return (
    <div className="resourcesCont !top-0">
      <h1>Mass Email and Status Updates</h1>
        <div className="w-full mb-5 flex">
          <div className="flex justify-center items-center">
            <Button 
              buttonType="primary"
              buttonText="Email or Update Selected"
              disabled={!(selectedUsers.length > 0)}
              actionHandler={ () => {
                setIsModalOpen(true);
              }}
            />
          </div>
          <div className="flex justify-center items-center ml-5">
            <p className="">Minimum Persona Score: </p>
            <select className="border border-terteiry rounded-md p-2 ml-2 text-text bg-background" defaultValue={personaMin} onChange={handleChange}>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1.5">1.5</option>
              <option value="2">2</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
              <option value="3.5">3.5</option>
              <option value="4">4</option>
            </select>
          </div>
        </div>
        <div className="w-full flex flex-col overflow-y-auto">
          <List
            contentArray={uninvitedFoundersArray}
            columnHeaderData={columnHeaderData}
            emailArrayChange={emailArrayChange}
            personaMin={personaMin}
            checkbox={true}
          />
        </div>        
        <Modal 
          isOpen={isModalOpen}
          closeModal={modalClose}
          modalHeader={selectExpert ? "Which IP Expert are you inviting selected companies to meet?" : "Select Email/Status Update"}
          contentLabel=""
          modalHTML={typeEmail ? emailModalHtml : modalHTML}
        />
      </div>
  )
}

export default PreInvite