import { SetStateAction, useContext, useEffect, useState } from "react";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import Form, {FormData } from "../../Components/Form/Form";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync, useRequiredParams } from "../../utilities/customHooks";
import { sowMain } from "../../utilities/formDefinitionTemplates";
import { DetailsContentHeader, detailsContentWrapperStyle } from "../AdminPages/ProfilePages/NewAdmin";
import { Payment, SowItem } from "../../utilities/frontendTypes";
import Button, { ButtonProp } from "../../Components/Button";
import moment from "moment";
import { contentPageContainerStyle } from "../AdminPages/ProfilePages/PartnerAdminList";
import { getCreateSowDetails, serviceProviderSubmitSow } from "../../services/serviceProvider";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import * as T from "../../utilities/frontendTypes";
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { handleFormSaveResponse } from "../../utilities/helperFunctions";

const CreateSOW = () => {
  const navigate = useNavigate();
  const applicationId = useRequiredParams("applicationId");
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const [initFormDetails, setInitFormDetails] = useState<FormData>({
    spName: "",
    startupName: "",
    date: "",
    description: ""}); 
  const [isAddingMilestone, setIsAddingMilestone] = useState(false)
  const [sowItemTableHtml, setSowItemTableHtml] = useState<React.ReactNode | null>(null);
  const [deliverableDateMin, setDeliverableDateMin] = useState<Date>(new Date())
  const [finalPayDateMin, setFinalPayDateMin] = useState<Date>(new Date())
  const [milestonePayDateMin, setMilestonePayDateMin] = useState<Date>(new Date())
  // const [postMileStoneDate, setPostMileStoneDate] = useState<Date>(new Date())
  const [coInvestDate, setCoInvestDate] = useState<Date>(new Date())
  const [coInvestAmount, setCoInvestAmount] = useState<number>(0)
  const [currentTotal, setCurrentTotal] = useState<number>(0)
  const [paymentDate, setPaymentDate] = useState<Date>(new Date())
  const [coPaymentDate, setCoPaymentDate] = useState<Date>(new Date())
  const [isAddingFinal, setIsAddingFinal] =  useState(false)
  const [finalTotal, setFinalTotal] = useState<number>(0)
  const date = new Date();
  const [createSowDetailsRes, setCreateSowDetailRes] = useState<T.CreateSowRequestResponse | null>(null);
  const [sowItemArray, setSowItemArray] = useState<SowItem[]>([]); 
  const [paymentArray, setPaymentArray] = useState<Payment[]>([]);
  const [combinedPaySowArray, setCombinedPaySowArray] = useState<(SowItem | Payment)[]>([])
  const [addingItem, setAddingItem] = useState(false)
  const [expenses, setExpenses] = useState<{
    description: string;
    amount: string;
    tax: string;
  }[]>([]);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [tax, setTax] = useState('');
  const [product, setProduct] = useState<string>("");
  const [taskDescription, setTaskDescription] = useState('');
  const [effort, setEffort] = useState("");
  const [deliverable, setDeliverable] = useState("");
  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());
  const [paymentDisabled, setPaymentDisabled] = useState(true)
  const [isEditing, setIsEditing] = useState(false)


  useEffect(() => {
    try {
    const createSowDetailsAsync = async () => { 
      const createSowDetailsRes = await getCreateSowDetails(applicationId);
      setCreateSowDetailRes(createSowDetailsRes);
    }
    createSowDetailsAsync();
    } catch (err) {
      console.error(err);
    }
      // eslint-disable-next-line 
  }, []);
  
  const isTier2 = false
  const firm = "Fake Firm For Fun"
  const startupName = "Cool Company"
  const coInvestPercentage = 30
  
  const intialForm = {
    spName: firm as string,
    startupName: startupName as string, 
    date: date,
    description: ""
  }

  useEffect(() => {
    const combinedArray = [...sowItemArray, ...paymentArray];
    const sortedArray = combinedArray.sort((a, b) => new Date(a.itemDate).getTime() - new Date(b.itemDate).getTime());
    let totalMilestoneCost = 0;
    let totalMilestoneTax = 0;
    let totalFinalCost = 0;
    let totalFinalTax = 0;
    sortedArray.forEach((item, index) => {
        // If the item is a sowItem
        if (item.hasOwnProperty('product')) {
          let sowItem = item as SowItem
            // Add costBreakdownTotal to totalCost and costBreakdownTotalTax to totalTax
          totalMilestoneCost += sowItem.costBreakdownTotal;
          totalMilestoneTax += sowItem.costBreakdownTotalTax;
          totalFinalCost += sowItem.costBreakdownTotal;
          totalFinalTax += sowItem.costBreakdownTotalTax;
        } else if (item.hasOwnProperty('type')) {
          let payment = item as Payment
          if (payment.type === "milestone") {
          payment.paymentAmount = totalMilestoneCost + totalMilestoneTax;
          totalMilestoneCost = 0;
          totalMilestoneTax = 0;
          } else if (payment.type === "final") {
            payment.paymentAmount = totalFinalCost + totalFinalTax; 
          }
        }
        // Set itemId proper>ty to index
        item.itemId = index;
    });

    setCombinedPaySowArray(sortedArray)
  }, [sowItemArray, paymentArray]);


  useEffect(() => {
    if(combinedPaySowArray.length > 0 && combinedPaySowArray[combinedPaySowArray.length - 1].hasOwnProperty('product')) {
      setPaymentDisabled(false)
    } else {
      setPaymentDisabled(true)
    }
    if(combinedPaySowArray.length > 0) {
      setDeliverableDateMin(combinedPaySowArray[combinedPaySowArray.length - 1].itemDate)
    }
    const generateSowItemTableHtml = () => {
      const html = combinedPaySowArray.map((item, index) => {
      if (item.hasOwnProperty('product')) {
        const {product, taskDescription, deliverable, effort, itemDate, costBreakdown, costBreakdownTotalTax, costBreakdownTotal} = item as SowItem;
        return (
          <div key={index} className="w-full border border-borders font-medium bg-backgrounds flex text-text relative">
            <div  className="h-full border-borders border-r" style={{ width: '13%', display: 'inline-block' }}>
              <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{product}</p>
            </div>
            <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
              <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{taskDescription}</p>
            </div>
            <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
              <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{deliverable}</p>
            </div>
            <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
              <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{effort}</p>
            </div>
            <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
              <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{moment(itemDate).format('MMM Do YYYY').toString()}</p>
            </div>
            <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
              <ul className="font-normal p-4">
                {costBreakdown.map((expense, index) => (
                  <li key={index}>
                  {expense.description}, ${parseFloat(expense.amount).toFixed(2)} {expense.tax !== "0" ? `+ Tax (${(parseFloat(expense.tax)) * 100}%) = $${((parseFloat(expense.amount) * (parseFloat(expense.tax))) + parseFloat(expense.amount)).toFixed(2)}` : "No Tax"}
                  </li>
                ))}
              </ul>
              <div className="px-4 pb-4">
                <p className="flex grow break-word font-normal text-text/80 self-center capitalize">Pre-Tax: ${costBreakdownTotal.toFixed(2)}</p>
                <p className="flex grow break-word font-normal text-text/80 self-center capitalize">Tax: ${costBreakdownTotalTax.toFixed(2)}</p>
                <p className="flex grow break-word font-normal text-text/80 self-center capitalize">Total: ${(costBreakdownTotal + costBreakdownTotalTax).toFixed(2)}</p>
              </div>
            </div>
                <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
                    <button className="px-2 py-1 bg-gray-200 mr-2">Edit</button>
                    <button onClick={() => setCombinedPaySowArray(combinedPaySowArray.splice(index, 1))} className="px-2 py-1 bg-gray-200">Remove</button>
                </div>
          </div>
        );
      } else if (item.hasOwnProperty('type')) {
          const {type, itemDate, coInvestDate, paymentAmount} = item as Payment
          if (type === "milestone") {
            return (
              <div key={index} className="w-full border border-borders font-medium bg-backgrounds text-text">
                <div className="h-full border-borders border-r" style={{ width: '67%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Progress Milestone Payment</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{moment(itemDate).format('MMM Do YYYY').toString()}</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Amount: ${paymentAmount.toFixed(2)}</p>
                </div>
              </div>
            );
          } else if (type === "final" && isTier2) {
            return (
              <div key={index} className="w-full border border-borders font-medium bg-backgrounds text-text">
                <div className="h-full border-borders border-r" style={{ width: '67%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Final Payment</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{moment(itemDate).format('MMM Do YYYY').toString()}</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Amount: ${finalTotal.toFixed(2)}</p>
                </div>
            </div>
            )
          } else if (type === "final" && !isTier2 && coInvestDate) {
            setCoInvestDate(coInvestDate);
            setCoInvestAmount(finalTotal * coInvestPercentage / 100)
            return (
              <>
              <div key={index} className="w-full border border-borders font-medium bg-backgrounds text-text">
                <div className="h-full border-borders border-r" style={{ width: '67%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Final Payment</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{moment(itemDate).format('MMM Do YYYY').toString()}</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Amount: ${finalTotal.toFixed(2)}</p>
                </div>
              </div>
              <div key={index} className="w-full border border-borders font-medium bg-backgrounds text-text">
                <div className="h-full border-borders border-r" style={{ width: '67%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Co-Invest Payment</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">{moment(coInvestDate).format('MMM Do YYYY').toString()}</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Co-Invest Amount: ${(finalTotal * (coInvestPercentage / 100)).toFixed(2)}</p>
                </div>
              </div>
             </>
            )
          } else {
            return <></>
          }
        }
      })
      setSowItemTableHtml(html);
    }
    generateSowItemTableHtml()
    }, [combinedPaySowArray]);

    const addToPaymentArray = (curretPayType: "milestone" | "final") => {
      setFinalTotal(finalTotal + currentTotal);
      const newPayment: Payment = {
        itemDate: paymentDate,
        type: curretPayType,
        coInvestDate: coInvestDate,
        paymentAmount: curretPayType === "milestone" ? currentTotal : finalTotal,
        itemId: combinedPaySowArray.length
      }
      setCurrentTotal(0)
      const newArray = [...paymentArray, newPayment];
      // setPostMileStoneDate(newArray[newArray.length - 1].itemDate)
      setDeliverableDateMin(paymentDate)
      setIsAddingMilestone(false)
      setIsAddingFinal(false)      
      setPaymentArray(newArray)
    }
  
  const updateCurrentTotal = (num: number) => {
    setCurrentTotal(currentTotal + num)
  }

  const addToItemsArray = () => {
    const costBreakdown = expenses
    const costBreakdownTotal = costBreakdown.reduce((total, item) => total + parseFloat(item.amount), 0);
    const costBreakdownTotalTax = costBreakdown.reduce((total, item) => {
      if (item.tax) {
        return total + (parseFloat(item.amount) * parseFloat(item.tax))
      } 
      return total;
    }, 0)
  
    updateCurrentTotal((costBreakdownTotal + costBreakdownTotalTax))
    const newSowItem: SowItem = {
      product: product,
      taskDescription: taskDescription,
      deliverable: deliverable,
      effort: parseFloat(effort),
      itemDate: deliveryDate,
      costBreakdown: costBreakdown,
      costBreakdownTotal: costBreakdownTotal,
      costBreakdownTotalTax: costBreakdownTotalTax,
      itemId: combinedPaySowArray.length
    }
    const newArray = [...sowItemArray, newSowItem];
    setMilestonePayDateMin(deliveryDate)
    setPaymentDate(deliveryDate)
    // setCoInvestDateMax(newArray[0].itemDate)
    setFinalPayDateMin(deliveryDate),
    setSowItemArray(newArray)
    setExpenses([])
    setTax("")
    setAmount("")
    setDescription("")
    setAddingItem(false)
    setProduct("");
    setTaskDescription('');
    setEffort("");
    setDeliverable("");
  }

  const handleInitFormSave = (updatedForm: FormData) => {
    if (updatedForm === null) throw new Error("Cannot save no with null data");
    const {spName, startupName, description, date} = updatedForm;
    setInitFormDetails({
      spName: spName,
      startupName: startupName,
      date: date,
      description: description
    })
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: handleInitFormSave,
  }

  // const saveSowButton = () => {
  //   alert("Thanks! End of testing process for now!")
  //   navigate("/dashboard/sow-requests")
  // }

  const handleAddExpense = () => {
    if (description && amount && tax) {
      const newExpense = { description, amount, tax };
      setExpenses([...expenses, newExpense]);
      setDescription('');
      setAmount('');
      setTax('');
    } else {
      alert('Please fill out all fields');
    }
  };

  const handleDescriptionChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setDescription(event.target.value);
  };

  const handleAmountChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setAmount(event.target.value);
  };

  const handleTaxChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setTax(event.target.value);
  };

  const handleProductChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setProduct(event.target.value);
  };

  const handleTaskDescriptionChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setTaskDescription(event.target.value);
  };
  
  const handleDeliverableChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setDeliverable(event.target.value);
  };

  const handleEffortChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setEffort(event.target.value);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDeliveryDate(date);
    }
  };

  const handlePaymentDateChange = (date: Date | null) => {
    if (date) {
      setPaymentDate(date);
    }
  };

  const handleCoPaymentDateChange = (date: Date | null) => {
    if (date) {
      setCoPaymentDate(date);
    }
  };

  
  async function saveSowButton() {
    const sowDetails = {
      description: initFormDetails.description as string,
      sowItemArray: sowItemArray,
      paymentArray: paymentArray,
      coInvestDate: coInvestDate,
      coInvestAmount: coInvestAmount
    } 
    showLoading();
    const response = await serviceProviderSubmitSow(sowDetails);
    hideLoading();

    if (response.success){
      handleFormSaveResponse(response, showToast, {message: "Statement of work successfully submitted"});
    }
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={detailsContentWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label="Create Statement of Work"
        /> 
        {initFormDetails.description
        ?
          <div className="w-full flex flex-col overflow-y-auto mb-10">
            <div className="w-full">
              <Button
                buttonText="Add Product"
                actionHandler={() => setAddingItem(true)
                }
                style={"mr-2"}
                disabled={isAddingMilestone || isAddingFinal || paymentArray.some(item => item.type === 'final')}
              />
              {!isTier2 &&
              <Button 
                buttonText="Add Milestone Payment"
                actionHandler={() => setIsAddingMilestone(true)}
                style={"mr-2"}
                disabled={isAddingMilestone || isAddingFinal || paymentDisabled} 
              />
              }
              <Button 
                buttonText={"Add Final Payment Date"}
                actionHandler={() => setIsAddingFinal(true)}
                disabled={isAddingMilestone || isAddingFinal || paymentDisabled}
              />
            </div>
            <div className="w-full">
              <div className="flex flex-col w-full mt-5">
                <div className="w-full border-borders font-medium bg-backgrounds text-text">
                  <div className="h-full border-borders" style={{ width: '13%', display: 'inline-block' }}>
                      <p className="flex grow p-4 font-semibold break-all text-text/80 self-center capitalize">Product</p>
                  </div>
                  <div className="h-full border-borders" style={{ width: '21%', display: 'inline-block' }}>
                      <p className="flex grow p-4 font-semibold break-all text-text/80 self-center capitalize">Task Description</p>
                  </div>
                  <div className="h-full border-borders" style={{ width: '21%', display: 'inline-block' }}>
                      <p className="flex grow p-4 font-semibold break-all text-text/80 self-center capitalize">Deliverable</p>
                  </div>
                  <div className="h-full border-borders" style={{ width: '12%', display: 'inline-block' }}>
                      <p className="flex grow p-4 font-semibold break-all text-text/80 self-center capitalize">Effort (hours)</p>
                  </div>
                  <div className="h-full border-borders" style={{ width: '12%', display: 'inline-block' }}>
                      <p className="flex grow p-4 font-semibold break-all text-text/80 self-center capitalize">Date</p>
                  </div>
                  <div className="h-full border-borders" style={{ width: '21%', display: 'inline-block' }}>
                      <p className="flex grow p-4 font-semibold break-all text-text/80 self-center capitalize">Cost Breakdown ($ CAD)</p>
                  </div>
                </div>
              </div>
            </div>
            {sowItemTableHtml}
            {
              addingItem &&
              <div className="w-full border border-border bg-backgrounds flex text-text">
                <div className="h-full border-borders border-r" style={{ width: '13%', display: 'inline-block' }}>
                  <label className="[&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text hidden" htmlFor="description">Product:</label>
                  <select className="flex grow p-4 break-word self-center max-w-full overflow-hidden whitespace-normal" id="product" name="product" style={{wordWrap: "break-word"}} onChange={handleProductChange}>
                    <option selected={true} disabled>Select a product</option>
                    <option value="EIP 3 - Trademark Registration">Trademark Registration</option>
                    <option value="EIP 3 - Patent Application">Patent Application</option>
                    <option value="EIP 3 - Copyright Registration">Copyright Registration</option>
                  </select>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
                  <label className="font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text hidden" htmlFor="taskDescription">Description:</label>
                  <input className="bg-background border-none p-4 w-full" type="textarea" id="taskDescription" defaultValue={"Enter Task Description:"} onChange={handleTaskDescriptionChange} />
                </div>
                <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
                  <label className="font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text hidden" htmlFor="deliverable">Deliverable:</label>
                  <input className="bg-background border-none p-4 w-full flex" type="textarea" id="deliverable" defaultValue={"Enter Deliverable:"}  onChange={handleDeliverableChange} />
                </div>
                <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                  <label className="font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text hidden" htmlFor="effort">Effort:</label>
                  <input className="bg-background border-none p-4 w-full flex" type="number" id="effort" defaultValue={0} onChange={handleEffortChange} />
                </div>
                <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                  <DatePicker 
                    className={"p-4 w-full text-text bg-background"}
                    selected={deliverableDateMin > deliveryDate ? deliverableDateMin : deliveryDate}
                    id={"Deliverable Date"}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    yearDropdownItemNumber={115}
                    onChange={handleDateChange}
                    minDate={deliverableDateMin}
                  />
                </div>
                <div className="h-full border-borders border-r p-4" style={{ width: '21%', display: 'inline-block' }}>
                  <form>
                    <div className="flex flex-col">
                      <label className="font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text" htmlFor="description">Description:</label>
                      <input className="border border-terteiry rounded-md p-2 w-full text-text bg-background" type="text" id="description" value={description} onChange={handleDescriptionChange} />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text" htmlFor="amount">Amount (Pre-Tax):</label>
                      <input className="border border-terteiry rounded-md p-2 w-full text-text bg-background" type="number" id="amount" value={amount} onChange={handleAmountChange} />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text" htmlFor="tax">Tax:</label>
                      <select className="border border-terteiry rounded-md p-2 w-full text-text bg-background" id="tax" value={tax} onChange={handleTaxChange}>
                      <option value="" disabled>Select Tax for item</option>
                        <option value="0">0%</option>
                        <option value="0.05">5%</option>
                        <option value="0.13">13%</option>
                      </select>
                    </div>
                    <div className="flex">
                      <button 
                        className=" text-buttonText border my-4
                        font-semibold rounded-md ${sizeStyle} shadow-md transition-all whitespace-nowrap bg-primary hover:bg-primary/80 p-1 px-3 text-md border-primary"
                        type="button" onClick={handleAddExpense}>
                        Add Line Item
                      </button> 
                    </div>
                  </form>
                  {expenses.length > 0 &&
                  <ul  className="bg-background text-text">
                    {expenses.map((expense, index) => (
                      <li key={index}>
                      {expense.description}, ${expense.amount} {expense.tax !== "0" ? `+ Tax (${(parseFloat(expense.tax)) * 100}%) = $${((parseFloat(expense.amount) * (parseFloat(expense.tax))) + parseFloat(expense.amount)).toFixed(2)}` : "No Tax"}
                      </li>
                    ))}
                  </ul>
                  }
                </div>
              </div>
            }
            {
              isAddingMilestone &&
              <div className="w-full border border-borders bg-backgrounds text-text">
                <div className="h-full border-borders border-r" style={{ width: '67%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Progress Milestone Payment</p>
                </div>
                  <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                    <DatePicker 
                      className={"p-4 w-full text-text bg-background"}
                      selected={paymentDate}
                      id={"MilestonePaymentDate"}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      yearDropdownItemNumber={115}
                      onChange={handlePaymentDateChange}
                      minDate={isEditing ? sowItemArray[0].itemDate : milestonePayDateMin}
                    />   
                  </div>
                <div className="h-full border-borders border-r" style={{ width: '21%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Amount: ${currentTotal.toFixed(2)}</p>
                </div>
              </div>
            }
            {
              isAddingFinal &&
              <div className="w-full border border-borders bg-backgrounds text-text">
                <div className="h-full border-borders border-r" style={{ width: '67%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Final Payment</p>
                </div>
                  <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>               
                    <div className="flex flex-col p-4">
                        <DatePicker 
                          title={"Final Payment Date"}
                          className={"w-full text-text bg-background"}
                          selected={finalPayDateMin > paymentDate ? finalPayDateMin : paymentDate}
                          id={"FinalPaymentDate"}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          yearDropdownItemNumber={115}
                          onChange={handlePaymentDateChange}
                          minDate={sowItemArray[sowItemArray.length - 1].itemDate}
                        />
                    </div>  
                  </div>
              </div>
            }
            {
                !isTier2 && isAddingFinal &&
                <div className="w-full border border-borders bg-backgrounds text-text">
                <div className="h-full border-borders border-r" style={{ width: '67%', display: 'inline-block' }}>
                  <p className="flex grow p-4 break-word font-normal text-text/80 self-center capitalize">Co-Invest Payment</p>
                </div>
                <div className="h-full border-borders border-r" style={{ width: '12%', display: 'inline-block' }}>
                  <DatePicker 
                    title={"Co-Investment Payment Date"}
                    className={"py-4 w-full text-text p-4 bg-background"}
                    selected={coPaymentDate}
                    id={"FinalPaymentDate"}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    yearDropdownItemNumber={115}
                    onChange={handleCoPaymentDateChange}
                    maxDate={sowItemArray[0].itemDate}
                  />
                </div>
              </div>
            }
            <div className="m-5 flex justify-center">
              {
                addingItem ?
                <Button
                  buttonText="Add"
                  actionHandler={addToItemsArray}
                  disabled={deliverable && taskDescription && effort && expenses.length > 0 && product ? false : true}
                />
                :
                isAddingMilestone ?
                <Button
                  buttonText="Add Milestone Payment"
                  actionHandler={() => addToPaymentArray("milestone")}
                />
                :
                isAddingFinal ?
                <Button
                  buttonText="Add Final Payment"
                  actionHandler={() => addToPaymentArray("final")}
                />
                :
                <Button
                  buttonText="Save Statement of Work"
                  actionHandler={saveSowButton}
                  disabled={!paymentArray.some(item => item.type === 'final')}
                />
              }
            </div>
          </div>
        :
          <Form 
          formData={intialForm}
          formDefinition={sowMain}
          primaryButtonProp={primaryButtonProp}
          /> 
        }
      </div>
    </div>
  )
}

export default CreateSOW